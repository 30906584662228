<ngx-spinner name="schedule"></ngx-spinner>
<div class="form-group mt-2">
  <ng-bootstrap-tabs #configurationTabs (onTabSelect)="onSelectTab($event)">
    <ng-bootstrap-tab [tabTitle]="'Overview'" [disabled]="isOverviewTabDisabled">
      <div class="row">

        <div class="col-12 pl-2 pr-2 pb-2">

          <form [formGroup]="fgDeviceSchedule">
            <div class="form-row">
              <div class="col-md-6 col-sm-12 mb-2">
                <label>Group Name</label>
                <div *ngIf="!isEditMode">
                  <app-popoverControl-component [dataText]="DeviceSchedule?.name ? DeviceSchedule.name : '&nbsp;'"
                    (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                </div>
                <input *ngIf="isEditMode" [class]="controlValidationClass(fgDeviceSchedule.get('name'))" name="name"
                  formControlName="name" type="text" placeholder="Name" />
                <div *ngIf="controlInvalidFlag(fgDeviceSchedule.get('name'))" class="invalid-feedback">Please provide a
                  valid
                  Group Name</div>
              </div>
              <div *ngIf="formState == formStates.New" class="col-md-6 col-sm-12 mb-2">
                <label class="d-sm-none d-md-block">&nbsp;</label>                
                <div class="text-center text-danger">For New Configuration, Please Complete<br/>Overview Tab & Press Save To Continue</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-12 mb-2">
                <label>Configuration Type<span *ngIf="formState==formStates.New && fgDeviceSchedule.get('typeId').value==null" class="text-center text-danger">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select Devices To Enabled Configuration Tabs</span></label>
                <div *ngIf="!isEditMode">
                  <app-popoverControl-component
                    [dataText]="fgDeviceSchedule.get('typeId').value=='0' ? 'Static' : fgDeviceSchedule.get('typeId').value=='1' ? 'Calendar' : '&nbsp;'"
                    (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                </div>
                <div *ngIf="isEditMode" class="input-group btn-group" btnRadioGroup formControlName="typeId">
                  <label class="container" btnRadio="0" tabindex="0" role="button">Static
                    <input type="radio" name="radio" *ngIf="fgDeviceSchedule.get('typeId').value=='0'"
                      checked="checked">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container" btnRadio="1" tabindex="0" role="button">Calendar
                    <input type="radio" name="radio" *ngIf="fgDeviceSchedule.get('typeId').value=='1'"
                      checked="checked">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 col-sm-12">
                <label>Description</label>
                <div *ngIf="!isEditMode">
                  <app-popoverControl-component
                    [dataText]="DeviceSchedule?.description ? DeviceSchedule.description : '&nbsp;'"
                    (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                </div>
                <textarea *ngIf="isEditMode" [class]="controlValidationClass(fgDeviceSchedule.get('description'))"
                  id="description" name="description" formControlName="description" type="text"
                  placeholder="Description"></textarea>
                <div *ngIf="controlInvalidFlag(fgDeviceSchedule.get('description'))" class="invalid-feedback">Please
                  provide a valid
                  Description</div>
                <!-- <label *ngIf="!isEditMode" ng-disabled="true" class="container" style="float: left;">{{fgDeviceSchedule.get('typeId').value == '0' ? 'Static Configuration' : 'Calendar Confguration'}}
                    <input type="radio" checked="checked" name="radio">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container" style="float: left;">Calendar Configuration
                    <input type="radio" checked="checked" name="radio">
                    <span class="checkmark"></span>
                  </label>
                -->
              </div>
            </div>
          </form>

        </div>

      </div>
      <div class="row">
        <div class="col-12 mt-2 mb-1 pl-2 pr-2">
          <app-devicesselected-view #devicesSelectedView (removingSelectedDeviceEvent)="removeSelectedDevice($event)">
          </app-devicesselected-view>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-2 mb-1 pl-2 pr-2">
          <app-devicesavailable-view #devicesAvailableView [DeviceScheduleUuidActive]="DeviceScheduleUuid"
            (addingSelectedDeviceEvent)="addSelectedDevice($event)">
          </app-devicesavailable-view>
        </div>
      </div>
    </ng-bootstrap-tab>

    <ng-bootstrap-tab [tabTitle]="'Configuration'" [hidden]="fgDeviceSchedule.get('typeId').value!='0'"
      [disabled]="isConfigurationTabDisabled">
      <app-dayschedulesdefault-form #daySchedulesDefaultForm *ngIf="DeviceSchedule"
        [ConfigMapOptions]="configMapOptions" [DeviceSchedule]="DeviceSchedule"
        (onFormEvent_EventScheduleTimeConfig)="onFormEvent_EventScheduleTimeConfig($event)">
      </app-dayschedulesdefault-form>
    </ng-bootstrap-tab>

    <ng-bootstrap-tab [tabTitle]="'Day Schedules'" [hidden]="fgDeviceSchedule.get('typeId').value!='1'"
      [disabled]="isDaySchedulesTabDisabled">
      <app-dayschedulesschedules-form #eventSchedulesForm *ngIf="DeviceSchedule" [DeviceSchedule]="DeviceSchedule"
        [ConfigMapOptions]="configMapOptions" (onFormEvent_EventSchedules)="onFormEvent_EventSchedules($event)">
      </app-dayschedulesschedules-form>
    </ng-bootstrap-tab>

    <ng-bootstrap-tab [tabTitle]="'Calendar'" [hidden]="fgDeviceSchedule.get('typeId').value!='1'" ng-type="myCalendar"
      [disabled]="isCalendarTabDisabled">
      <!-- customClass="{{!DeviceSchedule || DeviceSchedule?.uuid == '' ? 'opaque' : 'opaque'}}" -->
      <app-dayschedulescalendar-form #eventCalendarForm *ngIf="DeviceSchedule" [DeviceSchedule]="DeviceSchedule">
      </app-dayschedulescalendar-form>
    </ng-bootstrap-tab>
  </ng-bootstrap-tabs>
</div>
<!-- {{ 'eventSchedulesForm.eventScheduleForm.formState: '+eventSchedulesForm?.eventScheduleForm?.formState }}<br/>
{{ 'formState: '+formState }}<br/>
{{ 'isEditMode: '+isEditMode }}<br/>
{{ 'fgDeviceSchedule.valid: '+fgDeviceSchedule?.valid }}<br/>
{{ 'isOverviewTabDisabled: '+isOverviewTabDisabled }}<br/>
{{ 'isConfigurationTabDisabled: '+isConfigurationTabDisabled }}<br/>
{{ 'isEditMode.isDaySchedulesTabDisabled: '+isDaySchedulesTabDisabled }}<br/>
{{ 'isEditMode.isCalendarTabDisabled: '+isCalendarTabDisabled }}<br/> -->
<!-- {{ 'uuid: '+DeviceSchedule?.uuid }} -->
<!-- {{eventSchedulesForm?.DeviceSchedule | json}} -->

<!-- {{isEditMode}}
{{formState}} -->