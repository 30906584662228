import { FormControl, ValidatorFn } from '@angular/forms';
import { DeviceScheduleConfigurationsDto } from '../../models/deviceScheduleObjects';

export interface ValidationResult {
    [key: string]: boolean;
}
export class PasswordValidator {

    public static strong(min: number, digits: boolean, lower: boolean, upper: boolean, special: string): ValidatorFn {
        return (control: FormControl): { [key: string]: boolean } | null => {
            let specialPattern = new RegExp("["+special+"]")

            let hasDigits = /\d/.test(control.value) || !digits;
            let hasLower = /[a-z]/.test(control.value) || !lower;
            let hasUpper = /[A-Z]/.test(control.value) || !upper;
            let hasSpecial = special.trim()=="" || specialPattern.test(control.value);
            let hasMin = (control.value.toString().length == 0) || (control.value.toString().length >= min) 

            const valid = hasMin && hasDigits && hasUpper && hasLower && hasSpecial;
            if (!valid) {
                // return what´s not valid
                return { strong: true };
            }
            return null;
        }
    }
}

