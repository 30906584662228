export class ModelsDto {
    id: number = -1;
    code: string = "";
    description: string = "";
    firmwareUuidTarget: string = "";
    userDisplay: boolean = false;
    firmwareTarget: any = {
        uuid: "",
        code: "",
        description: "",
        timeStamp: "",
        fileName: "",
        fileHash: "",
        fileSize: 0,
        configMapId: 0

    };

    modelOptionValues: any = [

    ]


}