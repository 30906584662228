import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiIdService } from './_api-id.service';

@Injectable()
export class ModemsService extends ApiIdService {
  constructor(
    protected router: Router,
    protected http: HttpClient
  ) {
    super(router, http, 'modems');
  }

  read() {
    return this.all();
  }

  readById(id: number) {
    return this.get(id);
  }

  create(modem: any): Observable<any> {
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}`;
    return this.http.post<any>(
      apiParam,
      modem
    );
  }

}
