import { SettingsService, userTypes, userRoles } from '../core/settings/settings.service';
import { ClickType } from '@swimlane/ngx-datatable';

const Overview = {
    text: 'Overview',
    link: '/overview',
    icon: 'fas fa-home',
    userType: userTypes.EndUser,
    userRole: userRoles.Reader
};


const Map = {
    text: 'Map',
    link: '/map',
    icon: 'fas fa-map-marked-alt',
    userType: userTypes.EndUser,
    userRole: userRoles.Reader
};

const Charts = {
    text: 'Charts',
    link: '/charts',
    icon: 'fas fa-chart-area',
    userType: userTypes.EndUser,
    userRole: userRoles.Reader
};

const Alerts = {
    text: 'Alerts',
    link: '/alerts',
    icon: 'fas fa-life-ring',
    userType: userTypes.EndUser,
    userRole: userRoles.Reader
};

const Devices = {
    text: 'Devices',
    link: '/devices',
    icon: 'fas fa-road',
    userType: userTypes.EndUser,
    userRole: userRoles.Reader
    // submenu: [
    //     {
    //         text: 'Cellular',
    //         link: '/devices/cellular',
    //         icon: 'fas fa-signal-alt'
    //     },
    //     {
    //         text: 'WiFi',
    //         link: '/devices/wifi',
    //         icon: 'fas fa-wifi'
    //     }
    // ]
};

const Analytics = {
    text: 'Analytics',
    link: '/analytics',
    icon: 'fas fa-cogs',
    userType: userTypes.CloudSuper,
    userRole: userRoles.Reader
};

const Settings = {
    text: 'Settings',
    link: '/settings',
    icon: 'fas fa-sliders-h',
    userType: userTypes.CloudSuper,
    userRole: userRoles.Manager
};

const Users = {
    text: 'Users',
    link: '/users',
    icon: 'fas fa-users',
    userType: userTypes.EndUser,
    userRole: userRoles.Administrator
};



const Configurations ={
    text: 'Configurations',
    link: '/configurations',
    icon: 'fas fa-cog',
    userType: userTypes.EndUser,
    userRole: userRoles.Reader

};

const Administration = {
    text: 'Administration',
    link: '/administration',
    icon: 'fas fa-wrench',
    userType: userTypes.CloudAdmin,
    userRole: userRoles.Reader,
    submenu: [
        {
            text: 'Clients',
            link: '/administration/clients/all',
            icon: 'fas fa-handshake',
            userType: userTypes.CloudAdmin,
            userRole: userRoles.Reader

        },
        {
            text: 'Subscriptions',
            link: '/administration/subscriptions/all',
            icon: 'fas fa-usd-circle',
            userType: userTypes.CloudAdmin,
            userRole: userRoles.Reader

        },
        {
            text: 'Users',
            link: '/administration/users/all',
            icon: 'fas fa-users',
            userType: userTypes.CloudSuper,
            userRole: userRoles.Reader
        },
        {
            text: 'Devices',
            link: '/administration/devices/all',
            icon: 'fas fa-road',
            userType: userTypes.CloudAdmin,
            userRole: userRoles.Reader
        },
        {
            text:'Search',
            link: '/administration/search',
            icon: 'fas fa-search',
            userType: userTypes.CloudAdmin,
            userRole: userRoles.Reader

        },
        {
            text: 'Firmwares',
            link: '/administration/firmwares/all',
            icon: 'fas fa-code',
            userType: userTypes.CloudSuper,
            userRole: userRoles.Reader
        },
        {
            text: 'Modems',
            link: '/administration/modems/all',
            icon: 'fas fa-signal-alt',
            userType: userTypes.CloudSuper,
            userRole: userRoles.Reader
        },
        {
            text: 'Models',
            link: '/administration/models/all',
            icon: 'fas fa-microchip',
            userType: userTypes.CloudSuper,
            userRole: userRoles.Reader
        },
        {
            text: 'Sign Responses',
            link: '/administration/signResponses/all',
            icon: 'fas fa-envelope-square',
            userType: userTypes.CloudSuper,
            userRole: userRoles.Reader

        }
    
    ]
};


const headingMain = {
    text: 'Radarsign Cloud',
    heading: true
};

export const menu = [
    headingMain,
    Overview,
    Map,
    Charts,
    Alerts,
    Devices,
    // Schedules,
    Configurations,
    Analytics,
    Settings,
    Users,
    Administration
];
