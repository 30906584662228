import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './_api.service';

export class ApiIdService extends ApiService{
  
  constructor (
    protected router: Router,
    protected http: HttpClient, 
    apiRoute: string) {
    super(router, http, apiRoute);
  }
  
  all(): Observable<any> {
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}`);
  }

  get(id: number): Observable<any> {
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${id}`);
  }

  post(entity: any): Observable<any> {
    return this.http.post(`${this.getApiUrl()}/${this.apiRoute}`, entity);
  }

  put(entity: any): Observable<any> {
    return this.http.put(`${this.getApiUrl()}/${this.apiRoute}`, entity);
  }

  delete(id: number) {
    return this.http.delete(`${this.getApiUrl()}/${this.apiRoute}/${id}`);
  }

 
}
