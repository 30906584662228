<ngx-spinner name="devices"></ngx-spinner>
<div class="row">
  <div class="col-lg-12">
    <!-- START widget-->
    <div class="card bg-default">
      <div class="card-header">
        <div class="row">
          <div class="col-6">
            <h4 class="pt-2 mb-0">Devices{{this.formState == this.formStates.DeviceForm && deviceForm?.device?.label ==
              false &&
              deviceForm?.device?.serial == null ? "" :
              deviceForm?.device?.label != null && deviceForm?.device?.serial != null ? " - " +
              deviceForm?.device?.label :
              deviceForm?.device?.label == null && deviceForm?.device?.serial != null ? " - " +
              deviceForm?.device?.serial : ""}}
              {{ this.formState == this.formStates.Upload ? " - Upload Data Files" :
              deviceForm?.device?.label == null && deviceForm?.device?.serial == null ? " - Listing" : ""}}{{
              this.formState == this.formStates.DeviceForm &&
              deviceForm
              && deviceForm?.isEditMode ? (deviceForm?.formState == deviceForm?.formStates.New ? " - Add" : " - Edit")
              :
              "" }}
            </h4>
          </div>
        <div class="col-6 float-right text-right">
          <button *ngIf="this.formState == this.formStates.DeviceListing &&
            settingsService.userAccessCheck(userTypes.EndUser,userRoles.Manager)" class="btn btn-primary ml-2"
            (click)="buttonUpload($event)">
            <span class="fas fa-cloud-upload pr-2"></span>Upload Device Data Files
          </button>

          <button
            *ngIf="(this.formState == this.formStates.DeviceForm)  && deviceForm?.device &&  !deviceForm?.device.dataRequestRealTime && settingsService.userAccessCheck(userTypes.EndUser,userRoles.Manager)"
            class="btn btn-primary ml-2" title="Uploads Data on Next Connection"
            (click)="deviceForm.requestRealTimeData()"><span class="fas fa-download mr-2"></span>Request Data
            Now</button>
          <label *ngIf="deviceForm?.device && deviceForm?.device.dataRequestRealTime && deviceForm?.device.nextActivity"
            class="text-secondary text-md font-weight-bold ml-2 pr-2"><span class="fas fa-alarm-clock mr-2"></span>
            Next Connection ≈ {{deviceForm?.device.nextActivity | date: 'mediumDate'}}
            {{deviceForm?.device.nextActivity | date: 'shortTime'}}</label>
          <label
            *ngIf="deviceForm?.device && deviceForm?.device.dataRequestRealTime && !deviceForm?.device.nextActivity"
            class="text-secondary text-md font-weight-bold ml-2 pr-2"><span class="fas fa-alarm-clock mr-2"></span>
            Download Requested</label>

          <button *ngIf="this.formState == null ? true : this.formState != this.formStates.DeviceListing"
            [disabled]="this.formState == this.formStates.DeviceForm && deviceForm?.formState == deviceForm?.formStates.Saving"
            type="button" class="btn btn-primary ml-2" (click)="buttonListing($event)">
            <span class="fa fas fa-th-list pr-2"></span>Listing</button>

          <button *ngIf="this.formState == this.formStates.DeviceListing &&
            settingsService.userAccessCheck(userTypes.EndUser,userRoles.Manager)" class="btn btn-primary ml-2"
            (click)="buttonRefresh()">
            <img *ngIf="!dataLoading" src="assets/img/spinner-arrows-static.png" width="22" height="22">
            <img *ngIf="dataLoading" src="assets/img/spinner-arrows.png" width="22" height="22">
          </button>

          <button *ngIf="this.formState == this.formStates.DeviceForm && !deviceForm?.isEditMode &&
            settingsService.userAccessCheck(userTypes.EndUser,userRoles.Manager)"
            [disabled]="deviceForm?.formState != deviceForm?.formStates.Read" type="button" class="btn btn-primary ml-2"
            (click)="buttonEdit($event)" id="highlight">
            <span class="fas fa-edit pr-2"></span>Edit</button>
          <button
            *ngIf="deviceForm == null ? false : this.formState == this.formStates.DeviceForm && deviceForm?.isEditMode"
            [disabled]="deviceForm?.formState != deviceForm?.formStates.New && deviceForm?.formState != deviceForm?.formStates.Edit"
            type="button" class="btn btn-success ml-2" (click)="buttonSave($event)">
            <span class="fas fa-save pr-2"></span>Save</button>
          <button
            *ngIf="deviceForm == null ? false : this.formState == this.formStates.DeviceForm && deviceForm?.isEditMode && deviceForm?.formState == deviceForm?.formStates.Edit"
            [disabled]="deviceForm?.formState != deviceForm?.formStates.Edit" type="button" class="btn btn-cancel ml-2"
            (click)="buttonCancel($event)">
            <span class="fas fa-ban pr-2"></span>Cancel</button>
        </div>
      </div>
    </div>

    <div class="bg-body">
      <div *ngIf="this.formState == this.formStates.Upload" class="bg-body">
        <div class="col-12 no-gutters mt-2 mb-0">
          <app-upload [multipleFiles]="true" [uploadUrl]="deviceDailySummariesService.fileUploadUrl()" [expectedFileType]="'csv'"></app-upload>
        </div>
      </div>

      <div *ngIf="this.formState == this.formStates.DeviceForm" class="pl-3 pr-3 pt-0 mt-0">
        <app-devicewifi-form #deviceForm [deviceUuid]="deviceUuid" (onFormEventDevice)="onFormEventDevice($event)">
        </app-devicewifi-form>
      </div>

      <div *ngIf="this.formState == this.formStates.DeviceListing" class="bg-body">
        <app-devicesview [chartView]=false [connectivityTypeId]="connectivityTypeId"  (onDataLoading)="onDataLoading($event)"
          (onDeviceSelected)="onDeviceSelected($event)">
        </app-devicesview>
      </div>
    </div>
    
  </div>
</div>

<!-- END widget-->

<!-- {{formState}} -->
<!-- {{deviceForm ? "deviceForm?.formState="+deviceForm?.formState : ""}} -->