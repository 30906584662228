import { Component, EventEmitter, ViewChild, OnInit, Input, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceSchedulesService, ClientsService, ConfigMapsService, DevicesService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DevicesSelectedViewComponent } from '../../views/devicesselected-view/devicesselected-view.component';
import { DevicesAvailableViewComponent } from '../../views/devicesavailable-view/devicesavailable-view.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceScheduleDto } from '../../../../models/deviceScheduleObjects';
import { DaySchedulesTimeConfigFormComponent } from '../dayschedulestimeconfig/dayschedulestimeconfig-form.component'
import { DaySchedulesCalendarFormComponent } from '../dayschedulescalendar/dayschedulescalendar-form.component';
import { DaySchedulesSchedulesFormComponent } from '../dayschedulesschedules/dayschedulesschedules-form.component';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject, } from 'rxjs';
import { DaySchedulesDefaultFormComponent } from '../dayschedulesdefault/dayschedulesdefault-form.component';
import { TabsControlComponent } from '../../controlsUi/tabsControl/tabsControl.component';
import { TabControlComponent } from '../../controlsUi/tabsControl/tabControl.component';

export enum ConfigurationsFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-configurations-form',
  templateUrl: './configurations-form.component.html',
  styleUrls: ['./configurations-form.component.scss'], 
})

export class ConfigurationsFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEventDevice: EventEmitter<any> = new EventEmitter<any>();


  @ViewChild('configurationTabs') public configurationTabs: TabsControlComponent;
  @ViewChild('devicesSelectedView') public devicesSelectedView: DevicesSelectedViewComponent;
  @ViewChild('devicesAvailableView') public devicesAvailableView: DevicesAvailableViewComponent;
  @ViewChild('daySchedulesDefaultForm') public daySchedulesDefaultForm: DaySchedulesDefaultFormComponent;
  @ViewChild('eventSchedulesForm') public eventSchedulesForm: DaySchedulesSchedulesFormComponent;
  @ViewChild('eventCalendarForm') public eventCalendarForm: DaySchedulesCalendarFormComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public formLoaded = false;
  public myVal: number = 0;
  public formStates = ConfigurationsFormState;
  private _formState: ConfigurationsFormState = ConfigurationsFormState.Initializing;
  get formState(): ConfigurationsFormState {
    return this._formState;
  }
  @Input() set formState(newFormState: ConfigurationsFormState) {
    let updateFormState: ConfigurationsFormState = null;

    this.fgDeviceSchedule.disable();

    switch (newFormState) {

      case this.formStates.Read: {
        updateFormState = newFormState;
        this.deviceScheduleRead();
        //this.settingsService.hideSpinner();
        break;
      }
      case this.formStates.New: {
        updateFormState = newFormState;
        this.fgDeviceSchedule.enable();
        //this.settingsService.hideSpinner();
        break;
      }
      case this.formStates.Edit: {
        updateFormState = newFormState;
        this.fgDeviceSchedule.enable();
        this.deviceScheduleEdit();
        //this.settingsService.hideSpinner();
        break;
      }
      case this.formStates.Save: {
        this._formState = newFormState;
        this.fgDeviceSchedule.enable();
        this.deviceScheduleSave();
        break;
      }
      case this.formStates.Cancelled: {
        this.deviceScheduleCancel();
        //this.settingsService.hideSpinner();
        break;
      }
      default: {
        updateFormState = newFormState;
        //this.settingsService.hideSpinner();
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
  }

  private _deviceScheduleUuid: string = '';

  get DeviceScheduleUuid(): string {
    return this._deviceScheduleUuid;
  }

  @Input() set DeviceScheduleUuid(newDeviceScheduleUuid: string) {
    if (this.librariesService.guidValidate(newDeviceScheduleUuid)) {
      this._deviceScheduleUuid = newDeviceScheduleUuid;

      if (this.formLoaded) this.getDeviceSchedule();
    }
    else {
      this._deviceScheduleUuid = '';
    }
    if (newDeviceScheduleUuid.toLowerCase() === 'add') {

      this.DeviceSchedule = new DeviceScheduleDto();
      this.formState = this.formStates.New;
      this.formLoaded_getDevicesAvailable();

    }
  }

  formLoaded_getDevicesAvailable() {

    if (this.formLoaded &&
      this.daySchedulesDefaultForm &&
      this.eventSchedulesForm &&
      this.eventCalendarForm) {

      this.daySchedulesDefaultForm.formState = this.daySchedulesDefaultForm.formStates.New;
      this.eventSchedulesForm.formState = this.eventSchedulesForm.formStates.New;
      this.eventCalendarForm.formState = this.eventCalendarForm.formStates.New;
      this.getDevicesAvailable();
    }
    else {
      var self = this;
      setTimeout(() => {
        self.formLoaded_getDevicesAvailable();
      }, 100);
    }

  }


  public _deviceSchedule: DeviceScheduleDto = new DeviceScheduleDto();
  get DeviceSchedule(): DeviceScheduleDto {
    return this._deviceSchedule;
  }
  @Input() set DeviceSchedule(newDeviceSchedule: DeviceScheduleDto) {
    this._deviceSchedule = newDeviceSchedule;
  }

  public configMaps: any[] = [];
  public configMapOptions: any[] = [];

  // public clients: any[] = [];

  get isEditMode(): boolean {
    if (!this.devicesAvailableView) return false;
    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          this.devicesAvailableView.isEditMode = true;
          this.devicesSelectedView.isEditMode = true;
          return true;
        }
      default:
        {
          this.devicesAvailableView.isEditMode = false;
          this.devicesSelectedView.isEditMode = false;
          return false;
        }
    }
  }

  get isSaveDisabled(): boolean {
    return (this.isEditMode && this.formState == this.formStates.New && this.devicesAssigned.length == 0 && this.fgDeviceSchedule.valid == true) ||
      (this.isEditMode && this.formState != this.formStates.New &&
        (
          this.isConfigurationTabDisabled ||
          this.isDaySchedulesTabDisabled ||
          (this.isCalendarTabDisabled && this.isOverviewTabDisabled)
        ))
  }

  get isOverviewTabDisabled(): boolean {
    return (this.fgDeviceSchedule.get('typeId').value == '0' && this.daySchedulesDefaultForm && this.daySchedulesDefaultForm.ConfigurationValid == false)
      || (this.fgDeviceSchedule.get('typeId').value == '1' && this.eventSchedulesForm && (this.eventSchedulesForm.eventScheduleForm.formState == this.eventSchedulesForm.eventScheduleForm.formStates.Edit))
  }

  get isConfigurationTabDisabled(): boolean {
    return this.fgDeviceSchedule.get('typeId').value == null || (this.fgDeviceSchedule.get('typeId').value == '0' &&
      (!this.DeviceSchedule || this.DeviceSchedule?.uuid == '' || this.devicesAssigned.length == 0)
    )
  }

  get isDaySchedulesTabDisabled(): boolean {
    return this.fgDeviceSchedule.get('typeId').value == null || (this.fgDeviceSchedule.get('typeId').value == '1' &&
      (!this.DeviceSchedule || this.DeviceSchedule.uuid == '' || this.devicesAssigned.length == 0)
    )
  }

  get isCalendarTabDisabled(): boolean {
    return this.fgDeviceSchedule.get('typeId').value == null || (this.fgDeviceSchedule.get('typeId').value == '1' &&
      (!this.DeviceSchedule || this.DeviceSchedule.uuid == '' || this.devicesAssigned.length == 0
        || (this.eventSchedulesForm &&
          (this.eventSchedulesForm.eventScheduleForm.formState == this.eventSchedulesForm.eventScheduleForm.formStates.Edit)

        )
      ))

  }

  public isModified = false;
  public devicesAvailable: any[] = new Array();
  public devicesAssigned: any[] = new Array();

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public deviceService: DevicesService,
    public deviceSchedulesService: DeviceSchedulesService,
    public configMapsService: ConfigMapsService,
    public clientsService: ClientsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    public router: Router,
    private formBuilder: FormBuilder,
  ) { }


  public fgDeviceSchedule: FormGroup = this.formBuilder.group({
    configMapId: null,
    typeId: null,
    name: ["", [Validators.required, Validators.minLength(3)]],
    description: "",
  });

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

    this.fgDeviceSchedule.get('configMapId').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {
      if (this.fgDeviceSchedule.enabled &&
        (this.formState == this.formStates.New ||
          this.formState == this.formStates.Edit)) {
        this.getDevicesAvailable(val);
        this.getConfigMaps();
      }
    });

    setTimeout(() => {
      this.getConfigMaps();
      this.getDeviceSchedule();
      this.formLoaded = true;
    }, 250);

  }


  getDeviceSchedule(successFormState: ConfigurationsFormState = ConfigurationsFormState.Read): any {
    console.log('getDeviceSchedule', this.formState)
    this.DeviceSchedule = null;

    if (!this.settingsService.client || !this.DeviceScheduleUuid) {
      //console.log('getDeviceSchedule', this.settingsService.client, this.DeviceScheduleUuid)
      return;
    }

    this.settingsService.showSpinner("schedule", false, 250);

    return this.deviceSchedulesService.readByUuid(this.DeviceScheduleUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.settingsService.hideSpinner("schedule")

          this.fgDeviceSchedule.patchValue(
            {
              configMapId: result.configMapId,
              typeId: result.typeId.toString(),
              name: result.name,
              description: result.description,
            });

          this.DeviceSchedule = this.librariesService.objectCreate(result, DeviceScheduleDto);
          this.DeviceSchedule.deviceScheduleConfigurationsDefault_Refresh();

          this.devicesSelectedView.DevicesSelected = this.DeviceSchedule.devicesAssigned;
          this.devicesAssigned = this.DeviceSchedule.devicesAssigned;

          var self = this
          setTimeout(() => {
            self.formState = successFormState;
          }, 500);

        },
        error => {
          this.settingsService.hideSpinner("schedule")
          this.notificationsService.error('Server Error (getDeviceSchedule)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.formState = ConfigurationsFormState.Read;
        });
  }

  refreshDevicesAssigned() {
    //need to create this logic to v
  }

  getDevicesAvailable(configMapId: number = 0): any {
    console.log("getDevicesAvailable")

    this.devicesAvailable = new Array();
    this.devicesAvailableView.DevicesAvailable = this.devicesAvailable;

    if (!this.settingsService.client || !this.fgDeviceSchedule.value) return;
    if (configMapId == 0) configMapId = this.fgDeviceSchedule.value.configMapId;
    if (!configMapId) return;

    var self = this;

    return this.deviceService.readByConfigMapIdCurrent(configMapId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any[]) => {

          //creates in Available array from results if device does not exist in Assigned array
          self.devicesAvailable = result.filter(
            function (item) {

              return self.devicesAssigned.map(function (x) { return x.uuid; }).indexOf(item.uuid) === -1;
              //return self.devicesAssigned.indexOf(item.uuid) === -1
            }
          );

          // //Looks for devices that may already be in current schedule and clears the uuid
          // //so they show in view
          // self.devicesAvailable.forEach(function (device) {
          //     if (device.DeviceScheduleUuid == self.DeviceSchedule.uuid)
          //     {
          //       device.DeviceScheduleUuid = self.librariesService.guidEmpty();
          //     }
          // });

          self.devicesAvailableView.DevicesAvailable = self.devicesAvailable;
          //console.log("devicesAvailable", self.devicesAvailable)
        },
        error => {
          this.notificationsService.error("Server Error (getDevicesAvailable)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }


  getConfigMaps(): any {
    this.configMaps = [];

    if (!this.settingsService.client) return;

    //alert(this.deviceUuid);

    return this.configMapsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.configMaps = result;

          if (this.fgDeviceSchedule.value && !this.fgDeviceSchedule.value.configMapId &&
            (this.formState == this.formStates.New || this.formState == this.formStates.Initializing) &&
            this.configMaps.length > 0) {
            setTimeout(() => {
              this.fgDeviceSchedule.patchValue(
                {
                  configMapId: this.configMaps[0].id
                });
            }, 400);
          }

        },
        error => {
          this.notificationsService.error("Server Error (getConfigMaps)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getConfigMapOptions(): any {
    this.configMapOptions = [];

    if (!this.settingsService.client || !this.DeviceScheduleUuid ||
      !this.DeviceSchedule || !this.devicesAssigned ||
      this.devicesAssigned.length == 0) {
      return;
    }

    //this.settingsService.showSpinner("schedule", false, 250);

    return this.configMapsService.readConfigMapOptionsByDeviceIds(
      this.DeviceSchedule.configMapId,
      this.devicesAssigned.map(a => a.uuid)
    )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          //this.settingsService.hideSpinner("schedule");
          this.configMapOptions = result;
        },
        error => {
          //this.settingsService.hideSpinner("schedule");
          this.notificationsService.error('Server Error (getConfigMapOptions)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          // this.formState = ConfigurationsFormState.Read;
        });
  }

  getConfigMapOptionName_fgDeviceSchedule(): any {

    if (this.fgDeviceSchedule && this.fgDeviceSchedule.value) {

      var elementPos = this.configMaps.map(function (x) { return x.id; }).indexOf(this.fgDeviceSchedule.value.configMapId);
      if (elementPos >= 0) return this.configMaps[elementPos].code;
    }
    return "";
  }

  controlValidationClass(control) {
    return 'form-control'
      + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? ' is-invalid' : ' is-valid') : '');
  }

  controlInvalidFlag(control) {
    if (!control.touched) {
      return null;
    }
    return control.invalid;
  }


  deviceScheduleRead() {
    this.getConfigMapOptions();
    if (this.eventSchedulesForm) this.eventSchedulesForm.formState = this.eventSchedulesForm.formStates.Read;
    if (this.eventCalendarForm) this.eventCalendarForm.formState = this.eventCalendarForm.formStates.Read;
    if (this.daySchedulesDefaultForm) this.daySchedulesDefaultForm.formState = this.daySchedulesDefaultForm.formStates.Read;
    if (this.configurationTabs) this.configurationTabs.selectTab(this.configurationTabs.tabs.first)
  }

  deviceScheduleEdit() {
    this.librariesService.validateFormGroup(this.fgDeviceSchedule);
    this.isModified = false;
    this.onFormEventDevice.emit('edit');
    console.log(this.eventSchedulesForm.eventScheduleForm);
    this.eventSchedulesForm.formState = this.eventSchedulesForm.formStates.Edit;
    console.log(this.isCalendarTabDisabled)
    if (this.configurationTabs.tabs.first.active == false && this.configurationTabs.tabs.last.active == false) {
      this.eventSchedulesForm.eventScheduleForm.formState = this.eventSchedulesForm.eventScheduleForm.formStates.Edit;
    }
    console.log(this.isCalendarTabDisabled);
    console.log("OverView: " + this.isOverviewTabDisabled)
    this.eventCalendarForm.formState = this.eventCalendarForm.formStates.Edit;
    this.daySchedulesDefaultForm.formState = this.daySchedulesDefaultForm.formStates.Edit;
    this.devicesAvailable = new Array();
    this.getDevicesAvailable();
    this.getConfigMapOptions();

  }

  deviceStaticEdit() {

    this.librariesService.validateFormGroup(this.fgDeviceSchedule);
    this.isModified = false;
    this.onFormEventDevice.emit('edit');
    this.daySchedulesDefaultForm.formState = this.daySchedulesDefaultForm.formStates.Edit;
    this.eventCalendarForm.formState = this.eventCalendarForm.formStates.Edit;
    this.devicesAvailable = new Array();
    this.getDevicesAvailable();
    this.getConfigMapOptions();

  }

  deviceScheduleSave() {

    if (this.daySchedulesDefaultForm) this.daySchedulesDefaultForm.isConfigurationValid();

    if (!this.fgDeviceSchedule.valid) {
      this.librariesService.validateFormGroup(this.fgDeviceSchedule);
      this.formState = this.DeviceScheduleUuid === '' ? this.formStates.New : this.formStates.Edit;
      this.onFormEventDevice.emit('edit');
      this.notificationsService.warn('Validation Warning (Device Master Schedule)', 'Verify Required Data', { timeOut: 5000, clickToClose: true });
      return;
    }

    if (this.daySchedulesDefaultForm && this.daySchedulesDefaultForm.ConfigurationValid)
      this.DeviceSchedule.deviceScheduleConfigurationValuesDefault_Apply(this.daySchedulesDefaultForm.DeviceConfigurationValuesDefaultOptions)

    this.formState = this.formStates.Saving;
    this.onFormEventDevice.emit('saved');
    this.fgDeviceSchedule.value.clientUuid = this.settingsService.client.uuid;

    console.log('deviceScheduleSave', this.DeviceSchedule);

    this.settingsService.showSpinner("schedule");

    if (this.DeviceScheduleUuid === '') {
      let ds: any = new Object;

      ds.name = this.fgDeviceSchedule.value.name;
      ds.description = this.fgDeviceSchedule.value.description;
      ds.configMapId = this.fgDeviceSchedule.value.configMapId;
      ds.typeId = this.fgDeviceSchedule.value.typeId;
      ds.devicesAssigned = this.devicesAssigned;

      //ds.deviceSchedules = this.DeviceSchedule.deviceSchedules;

      return this.deviceSchedulesService.create(ds)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            this._deviceScheduleUuid = result.uuid;
            //this.formState = this.formStates.Edit;
            this.settingsService.hideSpinner("schedule")
            this.getDeviceSchedule(ConfigurationsFormState.Edit);
            this.notificationsService.success('Success', 'Group Configuration Created', { timeOut: 2000, clickToClose: true });
          },
          error => {
            this.settingsService.hideSpinner("schedule")
            this.notificationsService.error('Server Error (deviceScheduleSave: POST)',
              this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.New;
          });
    } else {
      return this.deviceSchedulesService.readByUuid(this.DeviceScheduleUuid)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            result.name = this.fgDeviceSchedule.value.name;
            result.description = this.fgDeviceSchedule.value.description;
            result.configMapId = this.fgDeviceSchedule.value.configMapId;
            result.typeId = this.fgDeviceSchedule.value.typeId;
            result.devicesAssigned = this.devicesAssigned;
            result.devicesAvailable = null;
            result.deviceScheduleConfigurations = this.DeviceSchedule.deviceScheduleConfigurations;
            result.deviceScheduleConfigurationValuesDefault = this.DeviceSchedule.deviceScheduleConfigurationValuesDefault;

            return this.deviceSchedulesService.put(result)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {
                  this._deviceScheduleUuid = result.uuid;
                  this.formState = this.formStates.Read;

                  this.settingsService.hideSpinner("schedule")
                  this.onFormEventDevice.emit('saved');
                  this.notificationsService.success('Success', 'Group Configuration Saved!', { timeOut: 2000, clickToClose: true });
                },
                error => {
                  this.settingsService.hideSpinner("schedule")
                  this.notificationsService.error('Server Error (deviceSchedulesSave: PUT)',
                    this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                  this.formState = this.formStates.Edit;
                });

          },
          error => {
            this.settingsService.hideSpinner("schedule")
            this.notificationsService.error('Server Error (deviceSchedulesSave: readByUuid)',
              this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = ConfigurationsFormState.Read;
          });
    }
  }

  deviceScheduleCancel() {
    this.formState = this.formStates.Read;

    this.configurationTabs.tabs.first.hidden = false;
    this.configurationTabs.tabs.first.disabled = false;
    this.configurationTabs.selectTab(this.configurationTabs.tabs.first)

    this.getDeviceSchedule();
    this.eventSchedulesForm.formState = this.eventSchedulesForm.formStates.Read;
    this.eventCalendarForm.formState = this.eventCalendarForm.formStates.Read;

    this.devicesSelectedView.DevicesSelected = this.DeviceSchedule ? this.DeviceSchedule.devicesAssigned : new Array();
    this.devicesAvailableView.DevicesAvailable = new Array();

    this.onFormEventDevice.emit('cancelled');
    this.getConfigMapOptions();
  }

  removeSelectedDevice(deviceSelectedSelected) {
    // this.devicesAvailableView.dataView.rows.unshift(deviceSelectedSelected[0]);
    // this.devicesAvailableView.dataView.rows = [...this.devicesAvailableView.dataView.rows];

    var elementPos = this.devicesAssigned.map(function (x) { return x.uuid; }).indexOf(deviceSelectedSelected[0].uuid);

    this.devicesAvailable.push(deviceSelectedSelected[0])
    this.devicesAssigned.splice(elementPos, 1)

    this.devicesSelectedView.DevicesSelected = this.devicesAssigned;
    this.devicesAvailableView.DevicesAvailable = this.devicesAvailable;

    // this.devicesAssigned = this.devicesSelectedView.dataView.rows;
    // this.devicesAvailable = this.devicesAvailableView.dataView.rows;
    this.getConfigMapOptions();
  }

  addSelectedDevice(deviceAvailableSelected) {
    //this.devicesSelectedView.dataView.rows.unshift(deviceAvailableSelected[0]);
    //this.devicesSelectedView.dataView.rows = [...this.devicesSelectedView.dataView.rows];

    var elementPos = this.devicesAvailable.map(function (x) { return x.uuid; }).indexOf(deviceAvailableSelected[0].uuid);

    this.devicesAssigned.push(deviceAvailableSelected[0])
    this.devicesAvailable.splice(elementPos, 1)

    this.devicesSelectedView.DevicesSelected = this.devicesAssigned;
    this.devicesAvailableView.DevicesAvailable = this.devicesAvailable;

    //this.devicesAssigned = this.devicesSelectedView.dataView.rows;
    //this.devicesAvailable = this.devicesAvailableView.dataView.rows;
    this.getConfigMapOptions();
  }

  onSelectTab(data: TabControlComponent): void {
    if (data.title.toLowerCase() == "overview" || data.title.toLowerCase() == "day schedules")
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        console.log('onSelectTab', data)
      }, 10);
    if (data.title.toLowerCase() == "overview") {
      if (this.daySchedulesDefaultForm && this.daySchedulesDefaultForm.ConfigurationValid)
        this.DeviceSchedule.deviceScheduleConfigurationValuesDefault_Apply(this.daySchedulesDefaultForm.DeviceConfigurationValuesDefaultOptions)
    }
  }

  onSelectCalendar(event) {
    this.eventCalendarForm.DeviceSchedule = this.DeviceSchedule


  }
  onSelectDevicesScope(event) {

    setTimeout(() => {
      this.devicesSelectedView.DevicesSelected = this.DeviceSchedule.devicesAssigned;
      this.devicesAvailableView.DevicesAvailable = this.devicesAvailable;
      console.log("devicesAvailable", this.devicesAvailable)
    }, 100);

  }


  onFormEvent_EventScheduleTimeConfig(event) {

  }

  onFormEvent_EventSchedules(event) {

    switch (event.toString().toLowerCase()) {
      case 'refresh': {
        this.eventCalendarForm.DeviceSchedule = this.DeviceSchedule
        break;
      }

      default: {
        //statements; 
        break;
      }
    }

  }

  popoverEvent(event) {
    console.log(event);
  }




}
