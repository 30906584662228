// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

export const environment = {
  production: true,
  apiUrl: "api-dev.radarsign.com",
  deviceLocationsNewActiveDays: 1000,
  deviceLocationsMostActiveDays: 1000,
  deviceLocationsMostActiveMaxRecords: 500,
  agGridLicenseKey: 'Using_this_AG_Grid_Enterprise_key_( AG-047347 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Radarsign, LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Radarsign Cloud )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Radarsign Cloud )_need_to_be_licensed___( Radarsign Cloud )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 1 November 2024 )____[v2]_MTczMDQxOTIwMDAwMA==a6f0ac2aa43a1ee550ad97ef737d6c60',
  dayScheduleDefaultEventName: "Day Startup Configuation (Global Settings For All Day Schedules)"
};

