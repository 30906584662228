import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiIdService } from './_api-id.service';

@Injectable()
export class ConfigMapsService extends ApiIdService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'configmaps');
  }

  read() {
    return this.all();
  }

  readById(id: number) {
    return this.get(id);
  }


  readConfigOptions(id: number): Observable<any> {
  
    const apiParam = `${this.getApiUrl()}/${this.apiRoute}/${id}/configoptions`;

    return this.http.get(apiParam);

  }

  readConfigMapOptionsByDeviceIds(id: number, guids: string[]): Observable<any> {

    const apiParam = `${this.getApiUrl()}/${this.apiRoute}/${id}/deviceconfigmapoptions`;

    return this.http.post(apiParam, guids);
    //return this.http.get(apiParam, { params: { deviceUuids: guids } });

  }

}
