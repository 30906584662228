import { FormControl, ValidatorFn } from '@angular/forms';
import { DeviceScheduleConfigurationsDto } from '../../models/deviceScheduleObjects';

export interface ValidationResult {
    [key: string]: boolean;
}
export class EmailValidator {

    public static strong(control) {
        if (control.value) {
          const matches = control.value.match('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$');
          return matches ? null : { 'invalidEmail': true };
        } else {
          return null;
        }
    }

    public static check(email) {
      if (!email) return false; 
      const matches = email.match('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$');
      console.log('matches', matches);
      return matches ? true : false;
    }
    
}

