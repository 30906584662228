<div class="card-body">
  <div class="form-group row">
    <div class="col-lg-12">
      <form [formGroup]="fgModem">
        <div class="row">
          <div class="col-12 m-0 p-0">
            <div class="form-row">
              <div class="col-sm-4 col-12 mb-2">
                <label>Name</label>
                <div *ngIf="!isEditMode" class="form-control">{{modem?.name ? modem.name : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgModem.get('name'))"
                  name="name" formControlName="name" type="text" placeholder="Modem Name" />
                <div *ngIf="librariesService.controlInvalidFlag(fgModem.get('name'))" class="invalid-feedback">Please
                  provide a modem name</div>
              </div>
              <div class="col-sm-8 col-12 mb-2">
                <label>Description</label>
                <div *ngIf="!isEditMode" class="form-control">{{modem?.description ? modem.description :
                    '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgModem.get('description'))"
                  id="description" name="description" formControlName="description" type="text" placeholder="Description" />
                <div *ngIf="librariesService.controlInvalidFlag(fgModem.get('description'))" class="invalid-feedback">Please
                  provide a description</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="pt-0 text-right uuid-opaque">{{ (this.modemId && this.modemId != -1) ?
    this.modemId.toString() : "&nbsp;"}}</div>
</div>