import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

export enum deviceNotifyTypes {
  LowBattery = 10,
  CriticalBattery = 20,
  TamperingTrigger = 30,
  Offline = 40,
  Communication = 50,
  DataCollection = 60,
  ConfigurationUpdate = 70,
  LocationChange = 80
}


@Injectable()
export class DeviceNotificationsService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'devicenotifications');
  }

  read() {
    return this.all();
  }

  getCount(categoryId): Observable<any> {
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/count/${categoryId}`);
  }

  getCleared(beginDate: Date, endDate: Date): Observable<any> {
    let apiParam = `${beginDate.getUTCMonth() + 1}-${beginDate.getUTCDate()}-${beginDate.getUTCFullYear()}`;
    apiParam = apiParam + `/${endDate.getUTCMonth() + 1}-${endDate.getUTCDate()}-${endDate.getUTCFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/cleared/${apiParam}`);
  }

  getActive() {
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/Active`);
  }

  reset(uuid: string) {
    const apiParam = `${this.getApiUrl()}/${this.apiRoute}/reset/${uuid}`;

    return this.http.put<any>(
      apiParam, {}
    );
  }

  clear(id: string) {
    const apiParam = `${this.getApiUrl()}/${this.apiRoute}/clear/${id}`;

    return this.http.put<any>(
      apiParam, {}
     );
  }

  readByUuid(uuid: string) {
    return this.get(uuid);
  }
}
