import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class DeviceConfigurationsService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient) {
    super(router, http, 'deviceconfigurations');
  }

  read() {
    return this.all();
  }

  readByUuid(uuid: string) {

    //const apiParam = `${this.getApiUrl()}/${this.apiRoute}/${uuid}`;
    const apiParam = `/assets/deviceConfiguration.json`;

    return this.http.get<any>(apiParam);

  }

  create(deviceConfigurationParameters: any, uuid: string, clientUuid: string): Observable<any> {
    const apiParam = `${this.getApiUrl()}/${this.apiRoute}/${uuid}`;

    return this.http.put<any>(
      apiParam,
      deviceConfigurationParameters
    );
  }

}
