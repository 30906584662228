import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AlertsService } from '../../../core/alerts/alerts.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { UsersService } from '../../../core/api/users.service';
import { EmailValidator } from '../../../core/validators/email.validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'resetpassword.component.html',
    styleUrls: ['./resetpassword.component.scss'],
    providers: [AuthenticationService, AlertsService, RouterModule, SettingsService]
})

export class ResetPasswordComponent implements OnInit {
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authenticationService: AuthenticationService,
        public settingsService: SettingsService,
        public usersService: UsersService,
        private notificationsService: NotificationsService,
        private formBuilder: FormBuilder) { }

        public fgReset: FormGroup = this.formBuilder.group({
            email: ["", [Validators.required, EmailValidator.strong]]
          });

    ngOnInit() {
        // reset status
        this.authenticationService.clear();
        this.settingsService.clearClients();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/resetpassword';
    }

    back(e) {
        console.log('back!');
        e.preventDefault();
        this.router.navigateByUrl('/');
    }

    reset(e) {
        if (!this.fgReset.valid) {
            let errorMsg = this.fgReset.get('email').errors.required ? "Email Address Is Required" : "Email Address Invalid" 
            this.notificationsService.error("Reset Password Error", errorMsg, {
                            timeOut: 1500,
                            clickToClose: true,
                            clickIconToClose: true
                        });
        } else {
            this.settingsService.clearClients();
            
            this.usersService.reset(this.fgReset.value.email)
                .subscribe(data => {
                        this.notificationsService.success("Submitted", "Password Reset Request Has Been Processed", {
                            timeOut: 1500,
                            clickToClose: true,
                            clickIconToClose: true
                        });
                        this.router.navigateByUrl('/');
                    },
                    error => {
                        if (error.status==401) {
                            this.notificationsService.error("Reset Password Error", "Email Address Incorrect", {
                                timeOut: 1500,
                                clickToClose: true,
                                clickIconToClose: true
                            });
                        } else {
                            this.notificationsService.error("Server Error: " + error.status, error.statusText, 
                            {
                                timeOut: 1500,
                                showProgressBar: true,
                                clickToClose: true,
                                clickIconToClose: true
                            });
                        }
                    });
        }
    }
}
