import { Component, ViewEncapsulation, ViewChild, OnInit, AfterViewInit, HostListener, Input, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import 'chartjs-plugin-datalabels';
import { IonRangeSliderComponent } from "ng2-ion-range-slider";

import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { ColorsService } from '../../../core/colors/colors.service';
import { EventsService } from '../../../core/events/events.service';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { SpeedBinsPercentChartComponent } from '../../../shared/component/charts/speedbins-percent-chart/speedbins-percent-chart.component';
import * as $ from 'jquery';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-charts-speedbins',
  templateUrl: './charts-speedbins.component.html',
  styleUrls: ['./charts-speedbins.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartsSpeedGroupsComponent implements OnInit, AfterViewInit,OnDestroy {
  @ViewChild('sliderBinGroups') sliderBinGroups: IonRangeSliderComponent;
  @ViewChild('chartSpeedBinsPercent') chartSpeedBinsPercent: SpeedBinsPercentChartComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();
  
  private stickyTrigger: boolean = false;
  private stickyTriggerScroll: number = 0;

 

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    //console.log("scrolling: window.scrollY:"+window.scrollY);
    //-parseInt($('#chartSpeedBinsPercent').css('margin-top'))
    var cbHeight = $('#cardBase').height()
      -$('#cardBaseHeaderRow').height()-$('#cardBaseHeaderRow').position().top

    if($('#chartOptionsStickySpacer').height() <= 0)
      $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height()-parseInt($('#chartSpeedBinsPercent').css('margin-top')) + 'px' });

    var spaceHeight = $('#chartOptionsStickySpacer').height()
      -$('#cardBaseHeaderRow').height()-$('#cardBaseHeaderRow').position().top
    
    //console.log("cbHeight-window.scrollY="+(cbHeight-window.scrollY));
    //console.log("spaceHeight-window.scrollY="+(spaceHeight-window.scrollY));

    if (this.showChartOptions && document.documentElement.scrollTop < cbHeight) {
      this.showChartOptions = true;
      this.showChartOptionsSticky = false;
      $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height()-parseInt($('#chartSpeedBinsPercent').css('margin-top')) + 'px' });
      console.log("scrolling if options: Spacer Set, spaceHeight="+$('#cardBase').height());
    } else if (this.showChartOptionsSticky && document.documentElement.scrollTop < spaceHeight) {   
      this.showChartOptions = true;
      this.showChartOptionsSticky = false;
      this.stickyTrigger = true;
      this.stickyTriggerScroll = document.documentElement.scrollTop;

      $("#cardBase").css({ width: '', top: '' });
      console.log("scrolling if sticky: cardBase clear, spaceHeight="+spaceHeight);
    } else if (this.stickyTrigger) {
      //console.log("scrolling stickyTrigger: window.scrollY ="+window.scrollY+', '+this.stickyTriggerScroll); 
      if (document.documentElement.scrollTop - this.stickyTriggerScroll > 20) this.stickyTrigger = false;
    } else {
      // if (this.showChartOptionsSticky == true) 
      // {
      // this.chartOptionsStickySpacerHeight = 10 * $('#cardBaseHeaderRow').position().top+$('#cardBaseHeaderRow').height();
      // if (this.chartOptionsStickySpacerHeight>0) $("#chartOptionsStickySpacer").css({ height: this.chartOptionsStickySpacerHeight+'px'});
      // }
      var new_width = $('#cardContainer').width();
      $('#cardBase').width(new_width);

      var new_top = $('#headerRightNavbar').position().top + $('#headerRightNavbar').height();
      if (this.showChartOptionsSticky != true || spaceHeight < 0) {
        $("#cardBase").css({ top: (new_top) + 'px' });
        this.showChartOptionsSticky = true;
        console.log("scrolling else: Set new_top");
        
        //setTimeout(() => {this.onWindowScroll(event); }, 250);
      }
      
      if (this.showChartOptions != false) {
        this.showChartOptions = false;
      }

       console.log("scrolling else: new_top:"+new_top+", spaceHeight="+spaceHeight);
    }

  }
  @HostListener('window:resize', ['$event'])
  onWindowResize($event) {
    //console.log("scrolling: window.scrollY:"+window.scrollY);
    if (this.showChartOptionsSticky) {
      var new_width = $('#cardContainer').width()
      $('#cardBase').width(new_width)
    }
  }

  public chartDpsData: any = null
  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  private paramSub: any;
  public showDevicesView: boolean = true;
  public showChartOptions: boolean = true;
  public showChartOptionsSticky: boolean = false;
  public chartOptionsStickySpacerHeight: number = 0;

  // private _deviceLocationUuid: string = "";
  @Input() public deviceLocation: any = null;  
  get deviceLocationUuid(): string {
    if (this.deviceLocation) return this.deviceLocation.uuid
    return ""
  }

  public _device: any = null;

  @Input() set device(newDeviceLocation: any) {

    if (newDeviceLocation && this.librariesService.guidValidate(newDeviceLocation.uuid)) {

      this._device = newDeviceLocation;
    }
    else {
      this._device = null;
    }
  }

  // set deviceLocationUuid(newDeviceLocationUuid: string) {
  //   this.fgChart.patchValue({
  //     deviceLocationUuid: newDeviceLocationUuid
  //   });

  //   if (this.librariesService.guidValidate(newDeviceLocationUuid)) {
  //     this._deviceLocationUuid = newDeviceLocationUuid;
  //     this.showDevicesView = false;
  //     this.getDevice()
  //   }
  //   else {
  //     this._deviceLocationUuid = "";
  //     this.deviceLocation = null;
  //     this.showDevicesView = true;
  //   }
  // }
  
  get speedTicks(): number[]  {
    if (this.deviceLocation){
      return this.deviceLocation.unitIdSpeed == 1 ? this.speedTicksKph : this.speedTicksMph
    }

    return  [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    ]
  }

  speedTicksMph: number[] = [
    5,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    55,
    60,
    0
  ]

  speedTicksKph: number[] = [
    10,
    20,
    30,
    35,
    50,
    60,
    70,
    80,
    90,
    100,
    110,
    120,
    0
  ]

  speedBinsFormat = (num: number) => {
    //var max: string = this.speedTicks[num][1].toString();
    return this.speedTicks[num] == 0 ? "Max" : this.speedTicks[num].toString()
  };
  speedBinsFormatOLD = (num: number) => {
    var min: string = this.speedTicks[num][0].toString();
    //var max: string = this.speedTicks[num][1].toString();
    return min + (this.speedTicks[num][1] > 0 ? "-" + this.speedTicks[num][1].toString() : "+")
  };
  updateSliderBinGroups(slider, event) {
    //console.log("Slider One updated: from=" + slider.from + " to=" + slider.to);
  }
  finishSliderBinGroups(slider, event) {
    //console.log("Slider One finish: from=" + slider.from + " to=" + slider.to);

    this.fgChart.patchValue({
      binGroupSplitOne: slider.from,
      binGroupSplitTwo: slider.to
    });
  }

  public fgChart: FormGroup = this.formBuilder.group({
    dateRange: [new Date(), new Date()],
    binGroupsEnabled: false,
    binGroupSplitOne: 0,
    binGroupSplitTwo: 0,
    binGroupLeftDisplay: "0",
    binGroupCenterDisplay: "0",
    binGroupRightDisplay: "0",
  });

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {

    var date1: Date = new Date(localStorage.getItem('chartsStandardDateRangeOneBegin'))
    var date2: Date = new Date(localStorage.getItem('chartsStandardDateRangeOneEnd'))
    
    var localBinGroupsEnabled: string = localStorage.getItem('chartsStandardBinGroupsEnabled')
    var localBinGroupSplitOne: string = localStorage.getItem('chartsStandardBinGroupSplitOne')
    var localBinGroupSplitTwo: string = localStorage.getItem('chartsStandardBinGroupSplitTwo')
    var localBinGroupLeftDisplay: string = localStorage.getItem('chartsStandardBinGroupLeftDisplay')
    var localBinGroupCenterDisplay: string = localStorage.getItem('chartsStandardBinGroupCenterDisplay')
    var localBinGroupRightDisplay: string = localStorage.getItem('chartsStandardBinGroupRightDisplay')

    this.fgChart.patchValue({
      dateRange: [date1 > new Date('1/1/2000') ? date1 : new Date(), date2 > new Date('1/1/2000') ? date2 : new Date()],
      binGroupsEnabled: localBinGroupsEnabled ? (localBinGroupsEnabled=="true"): false,
      binGroupSplitOne: localBinGroupSplitOne ? +localBinGroupSplitOne : 4,
      binGroupSplitTwo: localBinGroupSplitTwo ? +localBinGroupSplitTwo : 7,
      binGroupLeftDisplay: localBinGroupLeftDisplay ? localBinGroupLeftDisplay.toString() : "2",
      binGroupCenterDisplay: localBinGroupCenterDisplay ? localBinGroupCenterDisplay.toString() : "2",
      binGroupRightDisplay: localBinGroupRightDisplay ? localBinGroupRightDisplay.toString() : "2",
    });
  }

  ngOnInit() {

  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.onChangesFormSetup();
  }

  public timeSegmentRefresh() {
    var dateStart = new Date(this.fgChart.get('dateRange').value[0]);
    var dateEnd = new Date(this.fgChart.get('dateRange').value[1]);
    var today = new Date();
    var yesterday = new Date(today.getFullYear(),today.getMonth(), today.getDate())
    yesterday.setDate(yesterday.getDate()-1)
    if (dateEnd > yesterday){
      dateEnd = yesterday
      //this.fgChart.get('dateRange').value[1] = yesterday;
      this.fgChart.patchValue({
        dateRange: [dateStart, dateEnd]
      });
    }

    var timeDiff = dateEnd.getTime() - dateStart.getTime();
    let daysDiff = (timeDiff / (1000 * 3600 * 24)).round(0)

    if (daysDiff > 7) {
      this.fgChart.patchValue({timeSegment: "0"})
    }

    if (daysDiff > 365) {
      this.notificationsService.error("", "Date Period should be less then 365 days", { timeOut: 15000, clickToClose: true });
      
      let dateEndNew = new Date(dateEnd);
      let dateStartNew = new Date(dateEnd.setDate(dateEnd.getDate() - 365));
 
      //this.fgChart.get('dateRange').value[1] = dateEndNew;
      //this.fgChart.get('dateRange').value[0] = dateStartNew;

      this.fgChart.patchValue({
        dateRange: [dateStartNew, dateEndNew]
      });

    }
  }

  public chartsRefresh() {
    if (!this.deviceLocationUuid || this.deviceLocationUuid == "") return

    localStorage.setItem('chartsStandardBinGroupsEnabled', this.fgChart.get('binGroupsEnabled').value)
    localStorage.setItem('chartsStandardBinGroupSplitOne', this.fgChart.get('binGroupSplitOne').value)
    localStorage.setItem('chartsStandardBinGroupSplitTwo', this.fgChart.get('binGroupSplitTwo').value)
    localStorage.setItem('chartsStandardBinGroupLeftDisplay', this.fgChart.get('binGroupLeftDisplay').value)
    localStorage.setItem('chartsStandardBinGroupCenterDisplay', this.fgChart.get('binGroupCenterDisplay').value)
    localStorage.setItem('chartsStandardBinGroupRightDisplay', this.fgChart.get('binGroupRightDisplay').value)

  }

  onChangesFormSetup(): void {


    this.fgChart.get('dateRange').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {

      this.timeSegmentRefresh()
      this.chartsRefresh()

      localStorage.setItem('chartsStandardDateRangeOneBegin', this.fgChart.get('dateRange').value[0].toLocaleString());
      localStorage.setItem('chartsStandardDateRangeOneEnd', this.fgChart.get('dateRange').value[1].toLocaleString());
    });


  }

  onDeviceSelected(event) {

    if (event && event.uuid && event.uuid.toString().length > 10) {
      this.router.navigate(['/charts/timespan', event.uuid.toString()], { replaceUrl: false })
    }
  }

  showChartOptionsClick(event) {
    if (this.showChartOptions && this.showChartOptionsSticky == false && document.documentElement.scrollTop < $('#cardBase').height()) {
      $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height() + 'px' });
      this.showChartOptionsSticky = true;
      $('html, body').animate({
        scrollTop: (
            $("#chartSpeedBinsPercent").position().top-
            $('#cardBaseHeaderRow').height()-
            $('#cardBaseHeaderRow').position().top )
      }, 200);
      
      setTimeout(() => {this.onWindowScroll(event); }, 250);
    }
    this.showChartOptions = !this.showChartOptions;

  }

  public savePdf() {
    let doc = new jsPDF();
    let allContainers = $('[class="chartjs-size-monitor"]').parent();
    let allCanvas = [];

    let locationName = $('[class*="location_name"]').text().replace(' ', '_');
    let dateBegin = this.fgChart.get('dateRange').value[0];
    let formattedDateBegin = `${dateBegin.getMonth() + 1}-${dateBegin.getDate()}-${dateBegin.getFullYear()}`;

    let dateEnd = this.fgChart.get('dateRange').value[1];
    let formattedDateEnd = `${dateEnd.getMonth() + 1}-${dateEnd.getDate()}-${dateEnd.getFullYear()}`;

    allContainers.each(function (index, elem) {
      html2canvas(elem as HTMLCanvasElement).then(function(canvas) {
        allCanvas.push({canvas, index});

        if (allContainers.length === allCanvas.length) {
          for (let i = 0, pageNumber = 0; i < allCanvas.length; i++, pageNumber++) {
            let canvas = allCanvas.find(can => {return can.index === i}).canvas as HTMLCanvasElement;
            let imgData = canvas.toDataURL('image/png');

            let marginLeft = 15;
            let marginTop = 15;
            let chartWidth = 180;
            let chartHeight = 120;
            let chartPositionY = pageNumber * 150;

            doc.addImage(imgData, 'PNG', marginLeft, chartPositionY + marginTop, chartWidth, chartHeight);
            if (i % 2) {
              pageNumber = -1;
              if (i !== allCanvas.length - 1) {
                doc.addPage();
              }
            }
          }
          doc.save(`${locationName}_${formattedDateBegin}_to_${formattedDateEnd}.pdf`);
        }
      });
    });
  }

  onChartJsEvent(event)
  {

    switch (event.toString()) {
      case "click":
        {
          if (this.showChartOptionsSticky == true && 
            this.showChartOptions == true)
            {
              this.showChartOptions = false
            }
          break;
        }
      case "---":
        {
          break;
        }
    }

  }

}

