import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class SubscriptionScopesService extends ApiUuidService {
    constructor(
        protected router: Router,
        protected http: HttpClient,
    ) {
        super(router, http, 'subscriptionScopes');
    }


    Read() {
        return this.all();
    }


}