
import {filter,  takeUntil } from 'rxjs/operators';
import { Component, OnInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceSchedulesService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject } from 'rxjs';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-configurationview',
  templateUrl: './configurationview.component.html',
  styleUrls: ['./configurationview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationViewComponent implements OnInit, AfterViewInit, OnDestroy {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public deviceSchedulesTemp: any[] = [];
  public deviceSchedules: any[] = [];
  public deviceSchedulesSelected: any[] = [];
  private _connectivityTypeId: number = -1;

  get connectivityTypeId(): number {
    return this._connectivityTypeId;
  }

  @Input() set connectivityTypeId(Id: number) {
    this._connectivityTypeId = Id;
    this.updateFilter(this.datatableFilter.nativeElement.value);
  }

  @Output() onDeviceScheduleSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @ViewChild('datatableFilter') datatableFilter: ElementRef;

  //ag-grid variables start

  public gridOptions: GridOptions;
  public chartThemes;
  public rowStyle;
  public isSelected: boolean = false;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  @Input() public chartView: boolean = false;

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public deviceSchedulesService: DeviceSchedulesService,
    public errorsService: ErrorsService,
    public router: Router,
    public route: ActivatedRoute) {
      this.chartThemes = [
        'ag-pastel',
        'ag-default',
        'ag-material-dark',
        'ag-vivid-dark',
        'ag-solar',
      ];
      this.rowStyle = {
        cursor: 'pointer'
      }
      this.gridOptions = <GridOptions>{
        rowData: this.gridData,
        columnDefs: this.columnDefs,
        rowStyle: this.rowStyle,
        rowHeight: 40,
        cursor: "pointer",
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        onCellClicked: (e) => {
          e.node.setSelected(true)
          this.isSelected = true;
      },
      //chartThemeOverrides: this.chartThemeOverrides,
      enableCharts: false,
      enableRangeSelection: false
      // pagination: true,
      // rowSelection: 'single'
    }
  }

  columnDefs = [
    { headerName: "Name", field: "name", sortable: true, resizable: true, minWidth: 250, maxWidth: 300, suppressMenu: true },
    { headerName: "Description", field: "description", sortable: true, resizable: true, minWidth: 250, maxWidth: 300, suppressMenu: true },
    { headerName: "Device Count", field: "deviceCount", sortable: true, resizable: true, minWidth: 250, maxWidth: 300, suppressMenu: true },
    { headerName: "Configuration Type", field: "fileSize", sortable: true, resizable: true, minWidth: 300, suppressMenu: true,
      cellRenderer: (data) => {
        let myType = '';

        if (data.data.typeId == 0) {
          myType = 'Static'
        } else {
          myType = 'Calendar';
        }
        return myType;
      }, 
    },
  ]
    

  ngOnInit() {
    console.log(this.route.routeConfig.component.name+":ngOnInit", this)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.toLowerCase().split("?")[0] == '/configurations') {
          console.log(this.route.routeConfig.component.name + ":NavigationEnd")

         this.dataRefresh();
        }
      }
    }); 
    
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    console.log(this.route.routeConfig.component.name+":ngAfterViewInit", this)
    this.dataRefresh();
  }

  getDeviceSchedules(): any {
    if (!this.settingsService.client) {
      return;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 25);

    this.settingsService.showSpinner("deviceconfiguration", false, 250);

    return this.deviceSchedulesService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.settingsService.hideSpinner("deviceconfiguration");

          this.deviceSchedules = result;
          this.deviceSchedulesTemp = result;
          if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value);
        },
        error => {
          this.settingsService.hideSpinner("deviceconfiguration");
          this.notificationsService.error('Server Error (getDeviceSchedules)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  updateFilter(event) {
    const val = event; // event.target.value.toLowerCase();
    // filter our data
    const temp = this.deviceSchedulesTemp.filter(function (d) {
      return (
        (d.description != null && d.description.toLowerCase().indexOf(val) !== -1) ||
        d.name.toLowerCase().indexOf(val) !== -1 ||
        val.toString().trim() === ''
      );
    });

    // update the rows
    this.deviceSchedules = temp;

    // Whenever the filter changes, always go back to the first page
    // this.dataView.offset = 0;
  }

  onSelect({ selected }) {
    this.deviceSchedulesSelected.splice(0, this.deviceSchedulesSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onDeviceScheduleSelected.emit(selected[0]);
    }
  }

  dataRefresh() {
    var self = this;
    if (!this.settingsService.client) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }
    else {
      setTimeout(function () {
        self.getDeviceSchedules()
      }, 500);
    }
  }

  onRowSelected({selected}) {
    //console.log(event);
    this.deviceSchedulesSelected.splice(0, this.deviceSchedulesSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onDeviceScheduleSelected.emit(selected[0]);
    }
  }

  onRowClicked(event) {
    console.log(event)

    if (this.isSelected && !this.chartView) {
      this.router.navigate(['/configurations/', event.node.data.uuid]);
    }

    else if(this.isSelected && this.chartView){
      console.log(event.node)
      this.router.navigate(['/charts/', event.node.data.deviceLocation.uuid]);
    }
 
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

  onResize(event) {
    this.gridOptions.api.sizeColumnsToFit();
 
  }

}
