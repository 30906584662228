<div class="card-body">
  <div class="form-group row">
    <div class="col-lg-12">
      <form [formGroup]="fgClient">
        <!-- <div class="form-row">
          <div class="col-md-6 col-sm-12 mb-2">
            <label>Customer</label>
            <ng-select #clientUuid [selectOnTab]="true" [items]="clients" bindValue="uuid" bindLabel="name" [clearable]="false"
              id="clientUuid" name="clientUuid" formControlName="clientUuid" placeholder="Customer">
            </ng-select>
          </div>
        </div> -->
        <div class="row">
          <div class="col-sm-6 col-12 m-0 p-0">
            <div class="form-row">
              <div class="col-sm-12 col-12 mb-2">
                <label>Client Name</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.name ? client.name : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('name'))" id="name"
                  name="name" formControlName="name" type="text" placeholder="Client Name" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('name'))" class="invalid-feedback">Please
                  provide a client name</div>
              </div>
              <div class="col-sm-12 col-12 mb-2">
                <label>Street Address Line 1</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.address1 ? client.address1 : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('address1'))"
                  id="address1" name="address1" formControlName="address1" type="text" placeholder="Street Address" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('address1'))" class="invalid-feedback">Please
                  provide the street address</div>
              </div>
              <div class="col-sm-12 col-12 mb-2">
                <label>Street Address Line 2</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.address2 ? client.address2 : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('address2'))"
                  id="address2" name="address2" formControlName="address2" type="text" placeholder="Street Address" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('address2'))" class="invalid-feedback">Please
                  provide additional address information</div>
              </div>
              <div class="col-sm-6 col-12 mb-2">
                <label>City Name</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.city ? client.city : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('city'))" id="city"
                  name="city" formControlName="city" type="text" placeholder="City Name" autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('city'))" class="invalid-feedback">Please
                  provide a valid a city name</div>
              </div>
              <div class="col-sm-3 col-6 mb-2">
                <label>State or Province Code</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.stateProvinceCode ? client.stateProvinceCode :
                    '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('stateProvinceCode'))"
                  id="stateProvinceCode" name="stateProvinceCode" formControlName="stateProvinceCode" type="text"
                  placeholder="State or Province" autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('stateProvinceCode'))" class="invalid-feedback">Please
                  provide a code</div>
              </div>
              <div class="col-sm-3 col-6 mb-2">
                <label>Postal Code</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.postalCode ? client.postalCode :
                    '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('postalCode'))"
                  id="postalCode" name="postalCode" formControlName="postalCode" type="text" placeholder="Postal Code"
                  autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('postalCode'))" class="invalid-feedback">Please
                  provide a postal code</div>
              </div>
              <div class="col-sm-12 col-12 mb-2">
                <label>Country</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.country ? client.country : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('country'))" id="country"
                  name="country" formControlName="country" type="text" placeholder="Country Name" autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('country'))" class="invalid-feedback">Please
                  enter a country name</div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-12 m-0 p-0 pl-sm-3 pl-0">
            <div class="form-row">
              <div class="col-sm-6 col-12 mb-2">
                <label>Phone Number</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.phoneMain ? client.phoneMain : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('phoneMain'))"
                  id="phoneMain" name="phoneMain" formControlName="phoneMain" type="text" placeholder="Phone Number"
                  autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('phoneMain'))" class="invalid-feedback">Please
                  enter a phone number</div>
              </div>
              <div class="col-sm-6 col-12 mb-2">
                <label>Fax Number</label>
                <div *ngIf="!isEditMode" class="form-control">{{client?.phoneFax ? client.phoneFax : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgClient.get('phoneFax'))"
                  id="phoneFax" name="phoneFax" formControlName="phoneFax" type="text" placeholder="Fax Number"
                  autocomplete="off" />
                <div *ngIf="librariesService.controlInvalidFlag(fgClient.get('phoneFax'))" class="invalid-feedback">Please
                  enter a fax number</div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
  <div class="pt-0 text-right uuid-opaque">{{ (this.clientUuid && this.clientUuid.toString() !="") ?
    this.clientUuid.toString() : "&nbsp;"}}</div>
  <!-- {{fgClient.value | json}}<br><br> -->
</div>