<ngx-spinner name="devices"></ngx-spinner>
<div class="row">
  <!-- START dashboard main content-->
  <div class="col-lg-12">
    <!-- START chart-->
    <div class="row">
      <div class="col-lg-12">
        <!-- START widget-->
        <div class="card bg-default">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <h4 class="pt-2 mb-0">Registered Devices{{showDeviceForm && deviceAdminForm?.device ? " - " +
                  deviceAdminForm?.device.label : " - Listing"}}{{ showDeviceForm && deviceAdminForm &&
                  deviceAdminForm.isEditMode
                  ? (deviceAdminForm.formState == deviceAdminForm.formStates.Edit ? " - Edit" : "") : "" }}
                </h4>
              </div>
              <div class="col-6 float-right text-right">
                <button *ngIf="!showDeviceForm" class="btn btn-primary mr-2" (click)="buttonUpdate(devicesUpdateModal)"
                  [disabled]="showDeviceForm">
                  <span class="fa fa-cloud-upload pr-2"></span>Update Devices</button>
                <button *ngIf="!showDeviceForm" class="btn btn-primary mr-2" (click)="buttonAdd(deviceRegisterModal)"
                  [disabled]="showDeviceForm">
                  <span class="fas fa-plus-square pr-2"></span>Add</button>
                <button *ngIf="deviceAdminForm && showDeviceForm"
                  [disabled]="deviceAdminForm.formState == deviceAdminForm.formStates.Saving" type="button"
                  class="btn btn-primary ml-2" (click)="buttonBack($event)">
                  <span class="fa fas fa-th-list pr-2"></span>Listing</button>
                <button *ngIf="deviceAdminForm && showDeviceForm && !deviceAdminForm.isEditMode"
                  [disabled]="deviceAdminForm.formState == deviceAdminForm.formStates.Edit" type="button"
                  class="btn btn-primary ml-2" (click)="buttonEdit($event)">
                  <span class="fas fa-edit pr-2"></span>Edit</button>
                <button *ngIf="deviceAdminForm && showDeviceForm && deviceAdminForm.isEditMode"
                  [disabled]="deviceAdminForm.formState != deviceAdminForm.formStates.New && deviceAdminForm.formState != deviceAdminForm.formStates.Edit"
                  type="button" class="btn btn-success ml-2" (click)="buttonSave($event)">
                  <span class="fas fa-save pr-2"></span>Save</button>
                <button
                  *ngIf="deviceAdminForm && showDeviceForm && deviceAdminForm.isEditMode && deviceAdminForm.formState == deviceAdminForm.formStates.Edit"
                  [disabled]="deviceAdminForm.formState != deviceAdminForm.formStates.Edit" type="button"
                  class="btn btn-cancel ml-2" (click)="buttonCancel($event)">
                  <span class="fas fa-ban pr-2"></span>Cancel</button>

                <button *ngIf="!showDeviceForm" class="btn btn-primary m1-2" title="Refresh"
                  (click)="buttonRefresh()">
                  <img *ngIf="!dataLoading" src="assets/img/spinner-arrows-static.png" width="22" height="22">
                  <img *ngIf="dataLoading" src="assets/img/spinner-arrows.png" width="22" height="22">
                </button>

              </div>
            </div>
          </div>
          <div class="bg-body">
            <div *ngIf="showDeviceForm">
              <div class="card-body pt-0">
                <div class="form-group mt-2">
                  <tabset #staticTabs>
                    <tab heading="Device Information" (select)="onSelectTab($event)">
                      <app-deviceadministration-form #deviceAdminForm [DeviceUuid]="deviceUuid"
                        (onFormEventDevice)="onFormEventDevice($event)"></app-deviceadministration-form>
                    </tab>


                    <tab heading="Connections" (selectTab)="refreshConnections($event)">
                      <app-deviceconnectionsview [lteMode]=false showFirmware="true"></app-deviceconnectionsview>
                    </tab>
                    <tab heading="Telemetry" (selectTab)="refreshTelemetries($event)">
                      <app-devicetelemetriesview [showIndex]="true"></app-devicetelemetriesview>
                    </tab>
                    <tab heading="LTE Logs" (selectTab)="onSelectTab($event)">
                      <app-deviceconnectionsview [lteMode]=true></app-deviceconnectionsview>

                    </tab>

                  </tabset>
                </div>
              </div>
            </div>
            <!-- END widget-->
            <div *ngIf="!showDeviceForm" class="bg-body">
              <div>
                <app-deviceregistrationsview (onDeviceSelected)="onDeviceSelected($event)"
                  (onDataLoading)="onDataLoading($event)">
                </app-deviceregistrationsview>
              </div>
            </div>
          </div>
        </div>
        <!-- {{deviceAdminForm ? "deviceAdminForm.formState="+deviceAdminForm.formState : ""}} -->
      </div>
    </div>
    <ng-template #deviceRegisterModal>
      <div class="modal-header">
        <h3 class="m-0">Register New Device</h3>
      </div>
      <div class="modal-body mt-0 pt-1">
        <form [formGroup]="fgDevice">
          <div class="form-row">
            <div class="col-sm-6 col-6 mb-2">
              <label class="mt-2">Client Name</label>
              <div class="form-control">{{settingsService.client.name}}</div>

              <label class="mt-2">Serial Number</label>
              <input #serial [class]="librariesService.controlValidationClass(fgDevice.get('serial'))" id="serial"
                name="serial" formControlName="serial" type="text" placeholder="Device Serial Number" />
              <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('serial'))" class="invalid-feedback">Please
                provide a valid
                Serial Number (6 Characters Minimum)</div>

              <label class="mt-2">Device Label</label>
              <input #label [class]="librariesService.controlValidationClass(fgDevice.get('label'))" id="label"
                name="label" formControlName="label" type="text" placeholder="Device Label">
              <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('label'))" class="invalid-feedback">Please
                enter a
                device label
              </div>
            </div>
            <div class="col-sm-6 col-6 mb-2">
              <label class="mt-2">Model</label>
              <ng-select #modelId [selectOnTab]="true" [items]="models" bindValue="id" bindLabel="code"
                [clearable]="false" id="modelId" name="modelId" formControlName="modelId" placeholder="Model">
              </ng-select>
              <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('modelId'))" class="invalid-feedback">Model
                Is Required</div>

              <label class="mt-2">Target Firmware</label>
              <ng-select #firmwareUuidTarget [selectOnTab]="true" [items]="firmwares" bindValue="uuid" bindLabel="code"
                [clearable]="false" id="firmwareUuidTarget" name="firmwareUuidTarget"
                formControlName="firmwareUuidTarget" placeholder="Firmware">
              </ng-select>
              <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('firmwareUuidTarget'))"
                class="invalid-feedback">Firmware Is Required</div>
              <label class="mt-2">Connectvity Type</label>
              <ng-select #connectivityTypeId [selectOnTab]="true" [items]="connectivityTypes" bindValue="id"
                bindLabel="code" [clearable]="false" id="connectivityTypeId" name="connectivityTypeId"
                formControlName="connectivityTypeId" placeholder="Connectivity">
              </ng-select>
              <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('connectivityTypeId'))"
                class="invalid-feedback">Connectivity Is Required</div>
            </div>
          </div>

          <div *ngIf="fgDevice.get('connectivityTypeId').value == 10" class="form-row">
            <div class="col-sm-6 col-6 mb-2">
              <div>
                <label class="mt-2">Imei Number</label>
                <input [class]="librariesService.controlValidationClass(fgDevice.get('modemImei'))" id="modemImei"
                  name="modemImei" formControlName="modemImei" type="text" placeholder="Modem IMEI Number" />
                <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('modemImei'))" class="invalid-feedback">
                  Please provide a valid
                  IMEI Number</div>
                <label class="mt-2">Modem</label>
                <ng-select #modemId [selectOnTab]="true" [items]="modems" bindValue="id" bindLabel="name"
                  [clearable]="false" id="modemId" name="modemId" formControlName="modemId" placeholder="Modem Type">
                </ng-select>
                <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('modemId'))" class="invalid-feedback">
                  Modem
                  Type is Required</div>
              </div>
            </div>
            <div class="col-sm-6 col-6 mb-2">
              <div>
                <label class="mt-2">Sim ICCID</label>
                <input [class]="librariesService.controlValidationClass(fgDevice.get('simIccid'))" id="simIccid"
                  name="simIccid" formControlName="simIccid" type="text" placeholder="Sim ICCID Number" />
                <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('simIccid'))" class="invalid-feedback">
                  Please provide a valid
                  ICCID Number</div>
                <label class="mt-2">Sim IMSI</label>
                <input [class]="librariesService.controlValidationClass(fgDevice.get('simImsi'))" id="simImsi"
                  name="modemImei" formControlName="simImsi" type="text" placeholder="Sim IMSI Number" />
                <div *ngIf="librariesService.controlInvalidFlag(fgDevice.get('simImsi'))" class="invalid-feedback">
                  Please provide a valid
                  IMSI Number</div>
              </div>
            </div>
          </div>




        </form>
        <div class="col-sm-12 col-12 mt-3 text-center"><button type="button" class="btn btn-danger mr-3"
            (click)="deviceRegisterConfirm()">Register</button>
          <button type="button" class="btn btn-cancel" (click)="deviceRegisterCancel()">Cancel</button>
        </div>
        <!-- {{deviceRegForm ? "deviceRegForm.formState="+deviceRegForm.formState : ""}} -->
      </div>
    </ng-template>
    <ng-template #devicesUpdateModal>
      <div class="modal-header">
        <h3 class="m-0">Update Devices</h3>
      </div>
      <div class="col-12 mt-1 p-2 text-center">
        <label class="text-secondary text-center text-bold text-center">Devices:
          <div class="form-row text-primary text-center pl-2">
            {{serialsSelected}}
          </div>
        </label>
      </div>
      <div class="modal-body mt-0 pt-1">
        <form [formGroup]="fgDeviceUpdate">
          <div class="row">
            <div class="col-6 text-center">
              <label class="mt-2 mr-3">Update Target</label>
              <ng-select formControlName="updateOptions" name="updateOptions" [items]="bulkItemList" bindLabel="option"
                bindValue="value" [clearable]="false" (change)="dropDownChange($event)"
                placeholder="Client or Firmware">
              </ng-select>
            </div>

            <div class="col-6 text-center">
              <label class="mt-2 mr-3">Select Client/Firmware</label>
              <ng-select *ngIf="fgDeviceUpdate.get('updateOptions').value == 0" #clientUuid2 [selectOnTab]="true"
                [items]="clients" bindValue="uuid" bindLabel="name" [clearable]="false" name="firmwareUuidTarget"
                formControlName="clientUuid" placeholder="Select Client">
              </ng-select>
              <ng-select *ngIf="fgDeviceUpdate.get('updateOptions').value == 1" #firmwareUuidTarget [selectOnTab]="true"
                [items]="firmwares" bindValue="uuid" bindLabel="code" [clearable]="false" id="firmwareUuidTarget"
                name="firmwareUuidTarget" formControlName="firmwareUuidTarget" placeholder="Select Firmware">
              </ng-select>
              <!--<select class="form-control" [ngModel]="toUpdateList" placeholder="Please make a selection">
                <option *ngFor="let item of toUpdateList" [ngValue]="item.uuid">{{item.name || item.code}}</option>

              </select>-->
            </div>

          </div>
        </form>
        <div class="col-sm-12 col-12 mt-3 text-center">
          <button type="button" class="btn btn-primary mr-3"
            [disabled]="fgDeviceUpdate.get('clientUuid').value == null && fgDeviceUpdate.get('firmwareUuidTarget').value == null "
            (click)="devicesUpdate()">Update</button>
          <button type="button" class="btn btn-cancel" (click)="deviceRegisterCancel()">Cancel</button>
        </div>
      </div>

    </ng-template>
  </div>
</div>