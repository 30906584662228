<div class="row">
  <div id="cardContainer" class="col-12">
    <div id="cardBase" [ngClass]="showChartOptionsSticky ? 'card bg-default card-header-sticky' : 'card bg-default'">
      <div id="cardBaseHeader" class="card-header">
        <div id="cardBaseHeaderRow" class="row">
          <div class="col-8">
            <h4 class="pt-2">Statistics Charts:
              {{ fgChart.get('dateRange').value[0]==fgChart.get('dateRange').value[1] ? 
                (fgChart.get('dateRange').value[0] | date:'MM-dd-yyyy') : 
                (fgChart.get('dateRange').value[0] | date:'MM-dd-yyyy')  +' to ' + (fgChart.get('dateRange').value[1] | date:'MM-dd-yyyy') }},
              {{fgChart.get('timeSegment').value=="60" ? "60 Minute" : (fgChart.get('timeSegment').value=="30" ? "30 Minute" : "Full Day") }}
              Time Segment,
              {{timePeriodToString(fgChart.get('timeFilterOneBegin').value)+'-'+timePeriodToString(fgChart.get('timeFilterOneEnd').value)}}
              {{ fgChart.get('timePeriodTwoEnabled').value == true ?
                ' & '+timePeriodToString(fgChart.get('timeFilterTwoBegin').value)+'-'+timePeriodToString(fgChart.get('timeFilterTwoEnd').value)
                :''}}
            </h4>
          </div>
          <div class="col-4 mb-2 float-right text-right">
            <button type="button" class="btn btn-primary mx-2 m1-2" (click)="buttonListing($event)"><span class="fa fas fa-th-list pr-2"></span>Listing</button>
            <button *ngIf="dataLoading" class="btn btn-primary" (click)="savePdf()" disabled=true>Save as PDF</button>
            <button *ngIf="!dataLoading"  class="btn btn-primary" (click)="savePdf()">Save as PDF</button>
            <button type="button" class="btn btn-primary ml-2" (click)="showChartOptionsClick($event)"
              title="Show/Hide Chart Dispaly Options">
              <span [ngClass]="showChartOptions ? 'fas fa-chevron-down' : 'fas fa-chevron-left'"></span></button>
          </div>
        </div>

        <div *ngIf="showChartOptions" class="row card-body pl-3 pr-3 mt-0 pt-0">
          <form [formGroup]="fgChart" class="col-md-12 p-0">

            <div class="form-row row">
              <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                <label>Date Range</label>
                <div class="input-group">
                  <input readonly style="background-color: white;" class="form-control" #drp="bsDaterangepicker"
                    bsDaterangepicker [bsConfig]="{showWeekNumbers: false}" [(ngModel)]="fgChart.get('dateRange').value" 
                    formControlName="dateRange" [minDate]="minDate" [maxDate]="maxDate"/>
                  <!-- ^^ Neeed to change this to use Reactive Forms -->
                  <div class="input-group-append">
                    <span class="input-group-text input-group-text-override fas fa-calendar btn btn-primary"
                      (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></span>
                  </div>

                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                <label>Chart Time Segments</label>
                  <div *ngIf="!timeSegmentsDisabled" class="input-group btn-group" btnRadioGroup formControlName="timeSegment">
                    <label class="form-control btn btn-primary border-0" btnRadio="0" tabindex="0"
                      role="button">Full-Day</label>
                    <label class="form-control btn btn-primary border-0" btnRadio="30" tabindex="0"
                      role="button">30-Minutes</label>
                    <label class="form-control btn btn-primary border-0" btnRadio="60" tabindex="0"
                      role="button">60-Minutes</label>
                  </div>
                  <div *ngIf="timeSegmentsDisabled" class="input-group btn-group">
                    <div class="form-control btn btn-primary border-0 active" style="cursor: default;">Full-Day</div>
                    <div class="form-control btn border-1" style="cursor: default;">30-Minutes</div>
                    <div class="form-control btn border-1" style="cursor: default;">60-Minutes</div>
                  </div>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 mb-2">
                <label>Additional Chart Options</label>
                <div class="mt-2">
                  <label class="checkbox c-checkbox" style="display: inline">
                    <input formControlName="timePeriodTwoEnabled" type="checkbox" />
                    <span class="fas fa-check"></span>2nd Time Range</label>
                </div>
              </div>


            </div>
            <div class="form-row row">
              <!-- ************************************* time ranges ************************************* -->
              <div
                [ngClass]="fgChart.get('timePeriodTwoEnabled').value ? 'col-lg-6 col-md-12 col-sm-12' : 'col-lg-12 col-md-12 col-sm-12'">
                <div class="sliderTimePeriodOneText">
                  <label><span *ngIf="fgChart.get('timePeriodTwoEnabled').value">1st </span>Time Segment of Day</label>
                </div>

                <!-- https://github.com/IonDen/ion.rangeSlider| -->
                <ion-range-slider #sliderTimePeriodOne id="sliderTimePeriodOne" name="sliderTimePeriodOne" type="double"
                  [from]="fgChart.get('timeFilterOneBegin').value" [to]="fgChart.get('timeFilterOneEnd').value" min="0"
                  max="24" step="0.5" grid="true" grid_num="24" prefix="" postfix="" decorate_both="true"
                  [prettify]="timePeriodFormat" (onUpdate)="updateSliderTimePeriodOne(sliderTimePeriodOne, $event)"
                  (onChange)="updateSliderTimePeriodOne(sliderTimePeriodOne, $event)"
                  (onFinish)="finishSliderTimePeriodOne(sliderTimePeriodOne, $event)">
                </ion-range-slider>
              </div>
              <div *ngIf="fgChart.get('timePeriodTwoEnabled').value" class="col-lg-6 col-md-12 col-sm-12">
                <div class="sliderTimePeriodTwoText">
                  <label>2nd Time of Day Range (Applicable Charts)</label>
                </div>
                <!-- https://github.com/IonDen/ion.rangeSlider| -->
                <!-- https://github.com/PhilippStein/ng2-ion-range-slider -->
                <ion-range-slider #sliderTimePeriodTwo id="sliderTimePeriodTwo" name="sliderTimePeriodTwo"
                  disable="false" [from]="fgChart.get('timeFilterTwoBegin').value"
                  [to]="fgChart.get('timeFilterTwoEnd').value" type="double" to="24"
                  [from_min]="fgChart.get('timeFilterOneEnd').value" from_max="24" from_shadow="true"
                  [to_min]="fgChart.get('timeFilterOneEnd').value" to_max="24" to_shadow="false" min="0" max="24"
                  step="0.5" grid="true" grid_num="24" prefix=" " postfix="" decorate_both="true"
                  [prettify]="timePeriodFormat" (onUpdate)="updateSliderTimePeriodTwo(sliderTimePeriodTwo, $event)"
                  (onChange)="updateSliderTimePeriodTwo(sliderTimePeriodTwo, $event)"
                  (onFinish)="finishSliderTimePeriodTwo(sliderTimePeriodTwo, $event)">
                </ion-range-slider>
                <!-- disable="true" -->
              </div>
            </div>

          </form>
        </div>

      </div>
    </div>

    <div id="chartOptionsStickySpacer" [style.display]="showChartOptionsSticky ? '' : 'none'"></div>

    <div id="chartVehiclesViolators" *ngIf="true" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 pl-2 pr-2">
          <app-stats-vehiclesviolators-chart #chartVehiclesViolators [chartHeight]="500"
            [deviceLocation]="deviceLocation" [dateBegin]="fgChart.get('dateRange').value[0]"
            [dateEnd]="fgChart.get('dateRange').value[1]" [timeSegment]="+(fgChart.get('timeSegment').value)"
            [timeFilterBegin]="fgChart.get('timeFilterOneBegin').value"
            [timeFilterEnd]="fgChart.get('timeFilterOneEnd').value" (onChartJsEvent)="onChartJsEvent($event)">
          </app-stats-vehiclesviolators-chart>
        </div>
      </div>
    </div>

    <div id="chartLawAbidersViolators" *ngIf="true" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 mt-3 pl-2 pr-2">
          <app-stats-lawabidersviolators-chart #chartLawAbidersViolators [chartHeight]="500"
            [deviceLocation]="deviceLocation" [dateBegin]="fgChart.get('dateRange').value[0]"
            [dateEnd]="fgChart.get('dateRange').value[1]" [timeSegment]="+(fgChart.get('timeSegment').value)"
            [timeFilterBegin]="fgChart.get('timeFilterOneBegin').value"
            [timeFilterEnd]="fgChart.get('timeFilterOneEnd').value" (onChartJsEvent)="onChartJsEvent($event)">
          </app-stats-lawabidersviolators-chart>
        </div>
      </div>
    </div>

    <div id="chartSpeedersPercent" *ngIf="true" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 mt-3 pl-2 pr-2">
          <app-stats-speederspercent-chart #chartSpeedersPercent [chartHeight]="500" [deviceLocation]="deviceLocation"
            [dateBegin]="fgChart.get('dateRange').value[0]" [dateEnd]="fgChart.get('dateRange').value[1]"
            [timeSegment]="+(fgChart.get('timeSegment').value)"
            [timeFilterBegin]="fgChart.get('timeFilterOneBegin').value"
            [timeFilterEnd]="fgChart.get('timeFilterOneEnd').value" (onChartJsEvent)="onChartJsEvent($event)">
          </app-stats-speederspercent-chart>
        </div>
      </div>
    </div>

    <div id="chartSpeedAveragePeak" *ngIf="true" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 mt-3 pl-2 pr-2">
          <app-stats-speedaveragepeak-chart #chartSpeedAveragePeak [chartHeight]="500" [deviceLocation]="deviceLocation"
            [dateBegin]="fgChart.get('dateRange').value[0]" [dateEnd]="fgChart.get('dateRange').value[1]"
            [timeSegment]="+(fgChart.get('timeSegment').value)"
            [timeFilterBegin]="fgChart.get('timeFilterOneBegin').value"
            [timeFilterEnd]="fgChart.get('timeFilterOneEnd').value" (onChartJsEvent)="onChartJsEvent($event)">
          </app-stats-speedaveragepeak-chart>
        </div>
      </div>
    </div>
  </div>
</div>
