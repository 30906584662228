import { Component, OnInit, ViewChild, ViewEncapsulation, EventEmitter, Input, Output, ElementRef, Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { EventsService } from '../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { MenuService } from '../../../../../src/app/core/menu/menu.service';
import { SettingsService} from '../../../core/settings/settings.service';
import { ErrorsService } from '../../../core/errors/errors.service';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { takeUntil, filter } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DevicesService } from '../../../core/api/api.services';
import { SignResponsesViewComponent } from '../../../shared/component/views/signResponsesView/signResponsesView.component';


@Component({
	selector: 'app-signResponses-admin',
	templateUrl: './signResponses.component.html',
	styleUrls: ['./signResponses.component.scss'],
	encapsulation: ViewEncapsulation.None
})


export class SignResponsesAdminComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() onSignResponsesSelected: EventEmitter<any> = new EventEmitter();
	@ViewChild(DatatableComponent) public dataView: DatatableComponent;
	@ViewChild(SignResponsesViewComponent) public signResponsesView: SignResponsesViewComponent;

	@Input() public dateBegin: Date = null;
	@Input() public dateEnd: Date = null;

	//Observables
	private onDestroy$: Subject<void> = new Subject<void>();

	public deviceConnectionsTemp: any[] = [];
	public deviceConnections: any[] = [];
	public deviceConnectionsSelected: any[] = [];
	private _deviceUuid = '';
	public showDeviceForm: boolean = false;

	get deviceUuid(): string {
		this.showDeviceForm = true;
		return this._deviceUuid;
	}

	constructor(
		public menu: MenuService,
		public eventsService: EventsService,
		public notificationsService: NotificationsService,
		public settingsService: SettingsService,
		public deviceService: DevicesService,
		public librariesService: LibrariesService,
		public errorsService: ErrorsService){

		}

	ngOnInit() {
		
	}

	ngAfterViewInit(): void {

	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
	}

}

