<div class="card-body pb-0">

  <div [hidden]="!DailyEventScheduleTime" class="form-group row">
    <div class="col-12 pr-2 pl-2">
      <form [formGroup]="fgDailyEventScheduleTime">

        <div class="row">

          <div class="col-md-6 col-sm-7 order-sm-1 col-12 order-2 m-0 p-0">
            <div class="form-row" >
              <div class="col-sm-12 col-12 mb-2 pr-md-3 pr-sm-2">
                <div *ngIf="!isEditMode || isDefaultConfigTime">
                  <label>Event Time</label>
                  <div readonly class="form-control">{{
                    fgDailyEventScheduleTime?.value && librariesService.dateIsValid(this.fgDailyEventScheduleTime.value.timeControl) ? 
                    librariesService.datePipe.transform(this.fgDailyEventScheduleTime.value.timeControl, 'h:mm a')+ ' @ Device Local Time' : 
                    '&nbsp;'}}</div>
                </div>
                <div *ngIf="isEditMode && !isDefaultConfigTime" class="row">
                  <div class="col-md-6 col-sm-12">
                    <timepicker formControlName="timeControl"></timepicker>
                  </div>
                  <div class="col-md-6 col-sm-12 mt-3 text-center">
                    <pre *ngIf="!librariesService.controlInvalidFlag(fgDailyEventScheduleTime.get('timeControl'), false)"
                      class="alert alert-info text-center">Enter Device Local Time</pre>
                    <pre *ngIf="librariesService.controlInvalidFlag(fgDailyEventScheduleTime.get('timeControl'), false)"
                      class="alert alert-danger text-center">Time Exists In Event Schedule</pre>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-12 mb-2 pr-md-3 pr-sm-2">
                <label>Event Name</label>
                <div *ngIf="!isEditMode || isDefaultConfigTime" readonly class="form-control">
                  {{fgDailyEventScheduleTime?.value ? fgDailyEventScheduleTime?.value.name : '&nbsp;'}}</div>
                <input *ngIf="isEditMode && !isDefaultConfigTime"
                  [class]="librariesService.controlValidationClass(fgDailyEventScheduleTime.get('name'))" name="name"
                  formControlName="name" type="text" placeholder="Event Name" />
                <div *ngIf="librariesService.controlInvalidFlag(fgDailyEventScheduleTime.get('name'))"
                  class="invalid-feedback">Please provide an Event name</div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-5 order-sm-2 col-12 order-1 pr-0 float-right text-right">
            <button *ngIf="!isEditMode" [disabled]="false" type="button" class="btn btn-primary ml-2"
              (click)="buttonCancel($event)">
              <span class="fas fa-clock pr-2"></span>Event Times</button>

            <button *ngIf="isEditMode" [disabled]="!buttonOkEnabled()" type="button" class="btn btn-success ml-2"
              (click)="buttonOk($event)">
              <span class="fas fa-check pr-2"></span>Done</button>

            <button *ngIf="false && isEditMode" [disabled]="false" type="button" class="btn btn-cancel ml-2"
              (click)="buttonCancel($event)">
              <span class="fas fa-times pr-2"></span>Cancel</button>
          </div>

        </div>

        <div class="row">
          <div class="col-12 pb-0">
            <app-dayschedulestimeconfig-form [DailyEventSchedule]="DailyEventSchedule"
              [DeviceSchedule]="DeviceSchedule"
              [ConfigMapOptions]="ConfigMapOptions" [DailyEventScheduleTime]="DailyEventScheduleTime"
              (onFormEvent_EventScheduleTimeConfig)="onFormEvent_EventScheduleTimeConfig($event)"
              #eventScheduleTimeConfigForm>
            </app-dayschedulestimeconfig-form>
          </div>
        </div>

      </form>

    </div>
  </div>
</div>
<!-- isDefaultConfigTime: {{isDefaultConfigTime}} -->
<!-- {{fgDailyEventScheduleTime.value |json}} -->
<!-- _dailyEventScheduleTimeIndex:{{this._dailyEventScheduleTimeIndex.toString()}} -->