import { Component, ElementRef, EventEmitter, ViewEncapsulation, OnInit, AfterViewInit, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../../core/settings/settings.service';
import { FirmwaresService, ConfigMapsService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { PasswordValidator } from '../../../../../core/validators/password.validator';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';;
import { Subject } from 'rxjs';

export enum FirmwareFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8
}

@Component({
  selector: 'app-firmware-form',
  templateUrl: './firmware-form.component.html',
  styleUrls: ['./firmware-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FirmwareFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEvent: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  get formTitle() {
    if (this.firmwareUuid && this.firmwareUuid.toString() != "") {
      return 'Edit Firmware'
    } else return 'Add Firmware'
  }

  public lockForm: boolean = true;
  public fileLoaded: boolean = false;
  public formLoaded: boolean = false;
  public formStates = FirmwareFormState;
  private _formState: FirmwareFormState = this.formStates.Initializing;
  get formState(): FirmwareFormState {
    return this._formState
  }
  @Input() set formState(newFormState: FirmwareFormState) {

    var updateFormState: FirmwareFormState = null
    this.fgFirmware.disable()

    switch (newFormState) {

      case this.formStates.Read: {
        updateFormState = newFormState
        this.formRead()
        break
      }
      case this.formStates.New: {
        updateFormState = newFormState
        this.fgFirmware.enable()
        this.formAdd()
        break
      }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.fgFirmware.enable()
        this.formEdit()
        break
      }
      case this.formStates.Save: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        this.fgFirmware.enable()
        this.formSave()
        break
      }
      case this.formStates.Saved: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        break
      }
      case this.formStates.Cancelled: {
        this.formCancel()
        break
      }
      default:
        {
          updateFormState = newFormState
        }
    }

    if (updateFormState != null) {
      this._formState = updateFormState
    }
  }

  private _firmwareUuid: string = "";
  get firmwareUuid(): string {
    return this._firmwareUuid;
  }
  @Input() set firmwareUuid(newFirmwareUuid: string) {
    if (this.librariesService.guidValidate(newFirmwareUuid)) {
      this._firmwareUuid = newFirmwareUuid;
      this.lockForm = false;
        
    } else {
      this._firmwareUuid = "";
    }

    if (newFirmwareUuid.toLowerCase() == 'add') {      
      this.formState = this.formStates.Edit
    }
  }

  private firmware: any = null; //new FirmwareCrudDto();
  public configMaps: any[] = [];

  get isEditMode(): boolean {

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return this.lockForm ? false : true
        }
      default:
        {
          return false
        }
    }
  }

  public isModified = false;

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    public firmwaresService: FirmwaresService,
    public configMapsService: ConfigMapsService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  public fgFirmware: FormGroup = this.formBuilder.group({
    uuid: ['', [Validators.required]],
    code: ['', [Validators.required]],
    description: '',
    timeStamp: '',
    fileName: '',
    fileHash: '',
    fileSize: 0,
    configMapId: null
  });

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

    let self = this;
    setTimeout(() => {
      if (self.firmware == null) self.formLoaded = true
      self.getConfigMaps();
      self.getFirmware()
    }, 500);
  }

  clearFirmware() {
    this.firmware = null;
    this.fgFirmware.patchValue(
      {
        uuid: '',
        code: '',
        description: '',
        timeStamp: '',
        fileName: '',
        fileHash: '',
        fileSize: 0,
        configMapId: null
      }
    )
  }

  getConfigMaps(): any {
    this.configMaps = [];

    if (!this.settingsService.client) return;

    //alert(this.deviceUuid);

    return this.configMapsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.configMaps = result;
        },
        error => {
          this.notificationsService.error("Server Error (getConfigMaps)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getFirmware(): any {

    this.clearFirmware()

    if (!this.firmwareUuid) return;

    return this.firmwaresService.readByUuid(this.firmwareUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          this.fgFirmware.patchValue(
            {
              uuid: result.uuid,
              code: result.code,
              description: result.description,
              timeStamp: result.timeStamp,
              fileName: result.fileName,
              fileHash: result.fileHash,
              fileSize: result.fileSize,
              configMapId: result.configMapId
            }
          )
          
          this.formState = this.fileLoaded ? this.formStates.Edit : this.formStates.Read;
          this.fileLoaded = false;
          this.firmware = result;

          //this.deviceLocationsTemp = result;
        },
        error => {
          this.notificationsService.error("Server Error (getFirmware)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.formState = this.formStates.Read
        });
  }

  onSuccessItem(event) {
    if (event && event.status == 200 && event.response) {
      var firmwareAdd = JSON.parse(event.response.toString());
      this.firmwareUuid = firmwareAdd.uuid
      this.formState = this.formStates.Edit
      this.fileLoaded = true;
      this.lockForm = false;
    }
  }


  formRead() {

  }

  formAdd() {
    //do setup

    this.isModified = false;


    //Send Event
    this.onFormEvent.emit("add")
  }

  formEdit() {
    //do setup

    var self = this;

    this.isModified = false;

    //Send Event
    this.onFormEvent.emit("edit")

    // setTimeout(() => {

    // }, 1000);

  }

  formSave() {

    if (!this.fgFirmware.valid || !this.librariesService.guidValidate(this.fgFirmware.value.uuid)) {
      this.librariesService.validateFormGroup(this.fgFirmware)
      this.formState = this.firmwareUuid == "" ? this.formStates.New : this.formStates.Edit
      this.onFormEvent.emit("edit")
      this.notificationsService.warn("Validation Warning (Firmware)", "Verify Required Data", { timeOut: 5000, clickToClose: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEvent.emit("saving")

    if (this.firmwareUuid == "") {
      return this.firmwaresService.create(this.fgFirmware.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            
            //if successful...
            this.firmwareUuid = result.uuid

            this.formState = this.formStates.Read
            this.onFormEvent.emit("saved")
            this.notificationsService.success("Success", "Firmware Saved", { timeOut: 2000, clickToClose: true });
          },
          error => {
            this.notificationsService.error("Server Error (firmwareSave)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });
    } else {
      return this.firmwaresService.readByUuid(this.firmwareUuid)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            result.uuid = this.fgFirmware.value.uuid
            result.code = this.fgFirmware.value.code
            result.description = this.fgFirmware.value.description
            result.configMapId = this.fgFirmware.value.configMapId

            return this.firmwaresService.updateByUuid(result, this.firmwareUuid)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {

                  this.firmwareUuid = result.uuid

                  this.formState = this.formStates.Read
                  
                  this.onFormEvent.emit("saved")
                  this.notificationsService.success("Success", "Firmware Saved", { timeOut: 2000, clickToClose: true });
                },
                error => {
                  this.notificationsService.error("Server Error (firmwareSave: put)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                  this.formState = this.formStates.Edit
                });

          },
          error => {
            this.notificationsService.error("Server Error (firmwareSave: getFirmware)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });

    }
  }

  formCancel() {
    this.librariesService.untouchedFormGroup(this.fgFirmware)
    this.formState = this.formStates.Read
    this.onFormEvent.emit("cancelled")
    this.getFirmware()
  }

}
