<ngx-spinner name="device"></ngx-spinner>
<div class="form-group">
  <tabset #staticTabs>
    <tab heading="Device Information" (select)="onSelectTab($event)">
      <div class="row">
        <div class="col-lg-6 pl-0 pr-0">
          <div class="col-lg-12">
            <form [formGroup]="fgDevice">
              <div class="form-row">
                <div class="col-md-6 col-sm-12 mb-2">
                  <label>Serial Number</label>
                  <div *ngIf="!isEditMode">
                    <app-popoverControl-component editable=false [dataText]="device?.serial ? device.serial : '&nbsp;'"
                      (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                  </div>
                  <div *ngIf="isEditMode">
                    <app-popoverControl-component editable=false [dataText]="device?.serial ? device.serial : '&nbsp;'"
                      (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 mb-2">
                  <label>Model</label>
                  <div *ngIf="!isEditMode">
                    <app-popoverControl-component editable=false [dataText]="device?.model?.code ? device?.model?.code : 'None
                    Selected'" (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                  </div>
                  <div *ngIf="isEditMode">
                    <app-popoverControl-component editable=false [dataText]="device?.model?.code ? device?.model?.code : 'None
                    Selected'" (PopoverEvent)="popoverEvent($event)"></app-popoverControl-component>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-12 mb-2">
                  <label>Device Label</label>
                  <div *ngIf="!isEditMode">
                    <app-popoverControl-component [dataText]="device?.label ? device?.label : '&nbsp;'"
                      (PopoverEvent)="popoverEvent($event)">
                    </app-popoverControl-component>
                  </div>
                  <input *ngIf="isEditMode" [class]='controlValidationClass(inputLabel)' id="label" name="label"
                    formControlName="label" type="text" placeholder="Device Reference Label" />
                  <div *ngIf="controlInvalidFlag(inputLabel)" class="invalid-feedback">Please provide a valid Device
                    Label
                    (Minimum 10 Characters)</div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-12 mb-2">
                  <label>Device Configuration</label>
                  <button id="configurationLink" type="button" class="btn btn-xs" style="background-color: transparent;" data-toggle="tooltip" 
                          data-placement="right" title="Edit Configuration" (click)="onConfigurationClick($event)"
                          [hidden]="!device?.deviceSchedule?.uuid">
                    <a id="anchorLink" class="fas fa-cog fa-lg"></a>
                  </button>
                  <div *ngIf="!isEditMode">
                    <app-popoverControl-component [dataText]="device?.deviceSchedule?.name ? device?.deviceSchedule?.name
                        : 'None Selected'" (PopoverEvent)="popoverEvent($event)">
                    </app-popoverControl-component>
                  </div>
                  <ngx-select *ngIf="isEditMode" id="deviceScheduleDropdown" [formControl]="deviceScheduleDropdown"
                    [allowClear]="true" [items]="deviceSchedules" optionValueField="uuid" optionTextField="name"
                    [autoSelectSingleOption]="false" (removed)="deviceScheduleDropdown_removed($event)"
                    (typed)="deviceScheduleDropdown_typed($event)" placeholder="None Selected">
                    <input *ngIf="isEditMode" type="hidden" formControlName="deviceScheduleUuid"
                      name="deviceScheduleUuid">
                  </ngx-select>
                </div>
              </div>

            </form>

            <div class="col-lg-12 pl-md-0 pl-sm-0 pl-0 pr-0 pb-2">
              <label>Current Device Location</label>
              <div class="mgl-map-card">
                <div #mapContainer class="mgl-map-container border">
                  <span *ngIf="mapImageUrl == ''">Location Coordinates Invalid</span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-6 pl-md-3 pl-sm-3 pl-3 pb-2">
          <label>Recent Device Statistics: </label>
          <i class="fas fa-backward ml-2" #backArrow title="Go Back 30 Days" (click)="goBack($event)"></i>
          <i class="fas fa-spinner fa-pulse ml-2" hidden #loadBack></i>
          <i class="fas fa-forward ml-2" hidden #forwardArrow title="Go Forward 30 Days" (click)="goForward($event)"></i>
          <i class="fas fa-spinner fa-pulse ml-2" hidden #loadForward></i>
          <div class="form-row">
            <div class="col-md-12">
              <div class="p-1 bg-light">
                <table>
                  <tbody>
                    <td class="col-md-5">30-Day
                      Violators{{ ViolatorsDataDate=="" ? " [No Data]" : ", Last Received "+ViolatorsDataDate}}
                    </td>
                    <td class="col-md-3 text-right">
                      <div id="violatorsLegend"></div>
                    </td>
                  </tbody>
                </table>
                <div flot [dataset]="violatorsData" [options]="violatorsOptions" [height]="188"></div>
              </div>
            </div>
          </div>

          <div class="form-row mt-3">
            <div class="col-md-12">
              <div class="p-1 bg-light">
                <table>
                  <tbody>
                    <td class="col-md-5">30-Day
                      Voltages{{ VoltagesDataDate=="" ? " [No Data]" : ", Last Received "+VoltagesDataDate}}</td>
                    <td class="col-md-1 text-right">
                      <div id="voltagesLegend"></div>
                    </td>
                  </tbody>
                </table>
                <div flot [dataset]="voltagesData" [options]="voltagesOptions" [height]="188"></div>
              </div>
            </div>
          </div>

          <div class="form-row mt-3">
            <div class="col-md-12 ">
              <div class="p-1 bg-light">
                <table>
                  <tbody>
                    <td class="col-md-5">30-Day
                      Connections{{ ConnectionsDataDate=="" ? " [No Data]" : ", Last Received "+ConnectionsDataDate}}
                    </td>
                    <td class="col-md-1 text-right">
                      <div id="connectionsLegend"></div>
                    </td>
                  </tbody>
                </table>
                <div flot [dataset]="connectionsData" [options]="connectionsOptions" [height]="188"></div>
              </div>
            </div>
          </div>

          <!-- REMOVED PER CHARLIE -->
          <!-- <div class="form-row mt-3">
            <div class="col-md-12">
              <div class="p-1 bg-light">
                <table>
                  <tbody>
                    <td class="col-md-5">Temperatures{{ TemperaturesDataDate=="" ? " [No Data]" : ", Last Received "+TemperaturesDataDate}}</td>
                    <td class="col-md-1 text-right">
                      <div id="temperaturesLegend"></div>
                    </td>
                  </tbody>
                </table>
                <div flot [dataset]="temperaturesData" [options]="temperaturesOptions" [height]="125"></div>
              </div>
            </div>
          </div> -->

        </div>
      </div>
    </tab>
    <!-- <tab heading="Configuration">

      <div class="form-group row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <app-deviceconfiguration-form></app-deviceconfiguration-form>
            </div>
          </div>
        </div>
      </div>

    </tab> -->
    <tab heading="Locations" (select)="onSelectTab($event)">

      <div class="form-group row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <!-- <app-devicelocationsview></app-devicelocationsview> -->
              <app-devicelocations-form [device]="device" (locationModified)="locationModifiedEvent()"></app-devicelocations-form>
            </div>
          </div>
        </div>
      </div>

    </tab>
    <tab heading="Connections" (selectTab)=" refreshConnections($event)">

      <div class="form-group row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <app-deviceconnectionsview [lteMode]=false [showFirmware]="false"></app-deviceconnectionsview>
            </div>
          </div>
        </div>
      </div>

    </tab>
    <tab heading="Data" (selectTab)="refreshData($event)">

      <div class="form-group row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <app-devicedataview></app-devicedataview>
            </div>
          </div>
        </div>
      </div>

    </tab>
    <tab heading="Telemetry" (selectTab)="refreshTelemetries($event)">

      <div class="form-group row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <app-devicetelemetriesview></app-devicetelemetriesview>
            </div>
          </div>
        </div>
      </div>

    </tab>
    <tab *ngIf="cameraEnabled" heading="Images" (selectTab)="observe($event)">

      <div class="form-group row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <app-deviceimagesview></app-deviceimagesview>
            </div>
          </div>
        </div>
      </div>

    </tab>
  </tabset>
</div>
<!-- {{fgDevice.value | json}} -->