<div class="row">
    <div [ngClass]="this.showRadar ? 'col-lg-6 col-12 pl-0 pr-lg-2 pr-0 mt-2' : 'col-12 pl-0 pr-lg-2 pr-0 mt-2'">
        <div class="card">
            <div class="card-header">
                <h5 class="mt-0 mb-0 font-weight-bold">General</h5>

            </div>
            <div class="card-body">
                <form [formGroup]="fgDeviceConfiguration">
                    <div class="form-row" [hidden]='!this.showRadar'>
                        <div class="col-sm-12 mb-2">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input #Radar type="checkbox" [checked]='deviceConfiguration?.Radar' id="Radar"
                                        name="Radar" formControlName="Radar"
                                        [attr.disabled]="!isEditMode ? '' : null" />
                                    <span class="fas fa-check"></span>Radar Enabled
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" [hidden]='!this.showRadar'>
                        <div class="col-md-6 col-sm-12 mb-2">
                            <label>Speed Limit</label>
                            <div *ngIf="!isEditMode" class="form-control">
                                {{deviceConfiguration?.SpeedLimit ? deviceConfiguration.SpeedLimit : '&nbsp;'}}
                            </div>
                            <input *ngIf="isEditMode" [class]='controlValidationClass(inputSpeedLimit)' id="SpeedLimit"
                                name="SpeedLimit" formControlName="SpeedLimit" type="text" placeholder="Speed Limit" />
                            <div *ngIf="controlInvalidFlag(inputSpeedLimit)" class="invalid-feedback">
                                {{getSpeedLimitMessage()}}</div>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-2">
                            <label>Unit of Measure</label>
                            <div *ngIf="!isEditMode" class="form-control">
                                {{fgDeviceConfiguration.get('DisplayMode').value=='0' ? 'Miles per Hour' : 'Kilometers
                                per Hour'}}
                            </div>
                            <div class="input-group btn-group" btnRadioGroup *ngIf="isEditMode"
                                formControlName="DisplayMode">
                                <label (change)="onDisplayModeChange('0')"
                                    [class]="deviceConfiguration?.DisplayMode == '0' ? 'form-control btn btn-primary border-0 active ' : 'form-control btn btn-primary border-0'"
                                    btnRadio="0" tabindex="0" role="button">MPH</label>
                                <label (change)="onDisplayModeChange('1')"
                                    [class]="deviceConfiguration?.DisplayMode == '1' ? 'form-control btn btn-primary border-0 active ' : 'form-control btn btn-primary border-0'"
                                    btnRadio="1" tabindex="0" role="button">KPH</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 col-sm-12 mb-2" [hidden]='!this.showRadar'>
                            <p>Options</p>
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input #Blinky type="checkbox"
                                        [attr.disabled]="!isEditMode || isReadOnly('Blinky') ? '' : null"
                                        [checked]='deviceConfiguration?.Blinky' id="Blinky" name="Blinky"
                                        formControlName="Blinky" />
                                    <span class="fas fa-check"></span>Blinky Enabled
                                </label>
                            </div>
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input #Possum type="checkbox"
                                        [attr.disabled]="!isEditMode || isReadOnly('Possum') ? '' : null"
                                        [checked]='deviceConfiguration?.Possum' id="Possum" name="Possum"
                                        formControlName="Possum" />
                                    <span class="fas fa-check"></span>Possum Enabled
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-2">
                            <p *ngIf="showBeaconsOverride">Output Relays Override</p>
                            <div class="checkbox c-checkbox" *ngIf="showBeaconsOverride">
                                <label>
                                    <input #BeaconsOverride type="checkbox"
                                        [attr.disabled]="!isEditMode || isReadOnly('BeaconsOverride') ? '' : null"
                                        [checked]='deviceConfiguration?.BeaconsOverride' id="BeaconsOverride"
                                        name="BeaconsOverride" formControlName="BeaconsOverride" />
                                    <span class="fas fa-check"></span>Beacon Mode
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showRelay1Override">
                                <label>
                                    <input #Relay1Override type="checkbox"
                                        [attr.disabled]="!isEditMode || isReadOnly('Relay1Override') ? '' :  (fgDeviceConfiguration.get('BeaconsOverride').value  ? '' : null)"
                                        [checked]='fgDeviceConfiguration.get("BeaconsOverride").value || deviceConfiguration?.Relay1Override'
                                        name="Relay1Override" formControlName="Relay1Override"/>
                                    <span class="fas fa-check"></span>Output 1 Closed
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showRelay2Override">
                                <label>
                                    <input #Relay2Override type="checkbox"
                                        [attr.disabled]="!isEditMode || isReadOnly('Relay2Override') ? '' : (fgDeviceConfiguration.get('BeaconsOverride').value  ? '' : null)"
                                        [checked]='fgDeviceConfiguration.get("BeaconsOverride").value ||deviceConfiguration?.Relay2Override'
                                        name="Relay2Override" formControlName="Relay2Override" />
                                    <span class="fas fa-check"></span>Output 2 Closed
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>

    <div class="col-lg-6 col-sm-12 pl-lg-2 pl-0 pr-0 mt-2">
        <div class="card" [hidden]='!this.showRadar'>
            <div class="card-header">
                <h5 class="mt-0 mb-0 font-weight-bold">Display</h5>
            </div>
            <div class="card-body">
                <form [formGroup]="fgDeviceConfigurationDisplay">
                    <div class="form-row">
                        <div class="col-sm-12 mb-2">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input #Display type="checkbox"
                                        [attr.disabled]="!isEditMode || isReadOnly('Display') ? '' : null"
                                        [checked]='deviceConfiguration?.Display' id="Display" name="Display"
                                        formControlName="Display" />
                                    <span class="fas fa-check"></span>Display Enabled
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 col-sm-12 mb-2">
                            <label>Minimum Speed</label>
                            <div *ngIf="!isEditMode" class="form-control">
                                {{deviceConfiguration?.MinSpeed ? deviceConfiguration.MinSpeed : '&nbsp;'}}
                            </div>
                            <input *ngIf="isEditMode" [attr.disabled]="(!Display.checked ? '' : null)"
                                [class]='controlValidationClass(inputMinSpeed)' id="MinSpeed" name="MinSpeed"
                                formControlName="MinSpeed" type="text" placeholder="Minimum Speed" />
                            <div *ngIf="controlInvalidFlag(inputMinSpeed)" class="invalid-feedback">Please Provide A
                                Value Less Than Maximum Speed</div>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-2">
                            <label>Maximum Speed</label>
                            <div *ngIf="!isEditMode" class="form-control">
                                {{deviceConfiguration?.MaxSpeed ? deviceConfiguration.MaxSpeed : '&nbsp;'}}
                            </div>
                            <input *ngIf="isEditMode" [attr.disabled]="(!Display.checked ? '' : null)"
                                [class]='controlValidationClass(inputMaxSpeed)' id="MaxSpeed" name="MaxSpeed"
                                formControlName="MaxSpeed" type="text" placeholder="Maximum Speed" />
                            <div *ngIf="controlInvalidFlag(inputMaxSpeed)" class="invalid-feedback">Please Provide A
                                Value Greater Than Minimum Speed And Less Than {{this.inputDisplayMode.value == '0' ?
                                "125" : "205"}}
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 mb-2">
                            <p>Over Maximum Behavior</p>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input
                                        [attr.disabled]="!isEditMode || isReadOnly('OverMaxMode') || !Display.checked ? '' : null"
                                        type="radio" (change)="onOverMaxModeChange(1)" [value]="1"
                                        [checked]="deviceConfiguration?.OverMaxMode==1" class="form-check-input"
                                        name="OverMaxMode">Dashes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input
                                        [attr.disabled]="!isEditMode || isReadOnly('OverMaxMode') || !Display.checked ? '' : null"
                                        type="radio" (change)="onOverMaxModeChange(0)" [value]="0"
                                        [checked]="deviceConfiguration?.OverMaxMode==0" class="form-check-input"
                                        name="OverMaxMode">Blank
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<div class="row" [hidden]='!this.showRadar'>
    <div class="col-lg-6 col-sm-12 pl-0 pr-lg-2 pr-0 mt-2">

        <div class="card">
            <div class="card-header">
                <h5 class="mt-0 mb-0 font-weight-bold">Alert 1</h5>

            </div>
            <div class="card-body">
                <form [formGroup]="fgDeviceConfigurationAlert1">
                    <div class="form-row">
                        <div class="col-md-3 col-sm-12 mb-2">
                            <label>Speed</label>
                            <div *ngIf="!isEditMode" class="form-control">
                                {{deviceConfiguration?.SpeedAlert1 ? deviceConfiguration.SpeedAlert1 : '&nbsp;'}}</div>
                            <input *ngIf="isEditMode" [class]='controlValidationClass(inputSpeedAlert1)'
                                [attr.disabled]="(!Display.checked ? '' : null)" id="SpeedAlert1" name="SpeedAlert1"
                                formControlName="SpeedAlert1" type="text" placeholder="Speed Alert 1" />
                            <div *ngIf="isEditMode && controlInvalidFlag(inputSpeedAlert1)" class="invalid-feedback">
                                Please provide a valid Speed Alert 1 from Minimum Speed to Maximum Speed</div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 col-sm-12 mb-2">
                            <div *ngIf="showAlert1FlashRate">
                                <label>Display Behavior</label>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="0" (change)="onAlert1FlashRateChange(0)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1FlashRate') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1FlashRate==0" class="form-check-input"
                                            name="Alert1FlashRate">On Steady
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="20" (change)="onAlert1FlashRateChange(20)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1FlashRate') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1FlashRate==20"
                                            class="form-check-input" name="Alert1FlashRate">Flash Fast
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="40" (change)="onAlert1FlashRateChange(40)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1FlashRate') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1FlashRate==40"
                                            class="form-check-input" name="Alert1FlashRate">Flash Slow
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="50" (change)="onAlert1FlashRateChange(50)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1FlashRate') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1FlashRate==50"
                                            class="form-check-input" name="Alert1FlashRate">Flash MUTCD
                                    </label>
                                </div>
                            </div>
                             <div *ngIf="showAlert1HighLeds && (!showAlert1StrobeBlue || !showAlert1StrobeRed || !showAlert1StrobeWhite)">
                                <br>
                                <label>Strobes</label>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="0" (change)="onAlert1HighLedsChange(0)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1HighLeds') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1HighLeds==0" class="form-check-input"
                                            name="Alert1HighLeds">Disabled
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="1" (change)="onAlert1HighLedsChange(1)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1HighLeds') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1HighLeds==1" class="form-check-input"
                                            name="Alert1HighLeds">Flash Once
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="2" (change)="onAlert1HighLedsChange(2)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1HighLeds') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1HighLeds==2" class="form-check-input"
                                            name="Alert1HighLeds">Strobe
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="3" (change)="onAlert1HighLedsChange(3)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1HighLeds') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1HighLeds==3" class="form-check-input"
                                            name="Alert1HighLeds">Strobe Alternating
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="showAlert1HighLeds && (showAlert1StrobeBlue || showAlert1StrobeRed || showAlert1StrobeWhite)">
                                <br>
                                <label>Strobes</label>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert1StrobeDisabled type="radio" [value]="0"
                                            (change)="onAlert1StrobeChange(0)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1StrobeBlue == false && deviceConfiguration?.Alert1StrobeRed == false
                                         && deviceConfiguration?.Alert1StrobeWhite == false"
                                            class="form-check-input" name="Alert1Strobes" /> Disabled
                                    </label>
                                </div>
                                <div *ngIf="showAlert1StrobeRed" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert1StrobeRed type="radio" [value]="1"
                                            (change)="onAlert1StrobeChange(1)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1StrobeRed == true"
                                            class="form-check-input" name="Alert1Strobes" />Strobe Red
                                    </label>
                                </div>
                                <div *ngIf="showAlert1StrobeBlue" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert1StrobeBlue type="radio" [value]="2"
                                            (change)="onAlert1StrobeChange(2)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1StrobeBlue == true"
                                            class="form-check-input" name="Alert1Strobes" />Strobe Blue
                                    </label>
                                </div>
                                <div *ngIf="showAlert1StrobeBlue && showAlert1StrobeRed" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert1StrobeRedBlue type="radio" [value]="3"
                                            (change)="onAlert1StrobeChange(3)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1StrobeRed == true && deviceConfiguration?.Alert1StrobeBlue == true"
                                            class="form-check-input" name="Alert1Strobes" />Strobe Red/Blue
                                    </label>
                                </div>
                                <div *ngIf="showAlert1StrobeWhite" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert1CameraFlash type="radio" [value]="4"
                                            (change)="onAlert1StrobeChange(4)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1StrobeWhite== true && deviceConfiguration?.Alert1HighLeds == 1"
                                            class="form-check-input" name="Alert1Strobes" />Camera Flash
                                    </label>
                                </div>
                                <div *ngIf="showAlert1StrobeWhite" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert1StrobeWhite type="radio" [value]="5"
                                            (change)="onAlert1StrobeChange(5)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert1StrobeWhite == true && deviceConfiguration?.Alert1HighLeds == 2"
                                            class="form-check-input" name="Alert1Strobes" />Strobe White
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-2">
                            <p>Display Content</p>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1DisplaySpeed">
                                <label>
                                    <input #Alert1DisplaySpeed type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplaySpeed') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert1DisplaySpeed' id="Alert1DisplaySpeed"
                                        name="Alert1DisplaySpeed" formControlName="Alert1DisplaySpeed" />
                                    <span class="fas fa-check"></span>Vehicle Speed
                                </label>
                            </div>
                            <div *ngIf="DeviceSchedule?.devicesAssigned[0]">
                                <div class="checkbox c-checkbox"
                                    *ngIf="showAlert1DisplayBitmap1 && DeviceSchedule?.devicesAssigned[0].modelCode != 'TC-1000'">
                                    <label>
                                        <input #Alert1DisplayBitmap1 type="checkbox"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap1') ? '' : null)"
                                            [checked]='deviceConfiguration?.Alert1DisplayBitmap1'
                                            formControlName="Alert1DisplayBitmap1" />
                                        <span class="fas fa-check"></span>Slow Down
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="DeviceSchedule?.devicesAssigned[0]">
                                <div class="checkbox c-checkbox"
                                    *ngIf="showAlert1DisplayBitmap2 && DeviceSchedule?.devicesAssigned[0].modelCode != 'TC-1000'">
                                    <label>
                                        <input #Alert1DisplayBitmap2 type="checkbox"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap2') ? '' : null)"
                                            [checked]='deviceConfiguration?.Alert1DisplayBitmap2'
                                            formControlName="Alert1DisplayBitmap2" />
                                        <span class="fas fa-check"></span>Too Fast
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="DeviceSchedule?.devicesAssigned[0]">
                                <div class="checkbox c-checkbox"
                                    *ngIf="showAlert1DisplayBitmap2 && DeviceSchedule?.devicesAssigned[0] &&  DeviceSchedule?.devicesAssigned[0].modelCode == 'TC-1000'">
                                    <label>
                                        <input #Alert1DisplayBitmap2 type="checkbox"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap2') ? '' : null)"
                                            [checked]='deviceConfiguration?.Alert1DisplayBitmap2'
                                            formControlName="Alert1DisplayBitmap2" />
                                        <span class="fas fa-check"></span>Slow Down
                                    </label>
                                </div>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1DisplayBitmap3">
                                <label>
                                    <input #Alert1DisplayBitmap3 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap3') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert1DisplayBitmap3' id="Alert1DisplayBitmap3"
                                        name="Alert1DisplayBitmap3" formControlName="Alert1DisplayBitmap3" />
                                    <span class="fas fa-check"></span>Sharp Curve
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1DisplayBitmap4">
                                <label>
                                    <input #Alert1DisplayBitmap4 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap4') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert1DisplayBitmap4' id="Alert1DisplayBitmap4"
                                        name="Alert1DisplayBitmap4" formControlName="Alert1DisplayBitmap4" />
                                    <span class="fas fa-check"></span>Smiley Face
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1DisplayBitmap5">
                                <label>
                                    <input #Alert1DisplayBitmap5 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap5') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert1DisplayBitmap5' id="Alert1DisplayBitmap5"
                                        name="Alert1DisplayBitmap5" formControlName="Alert1DisplayBitmap5" />
                                    <span class="fas fa-check"></span>Left Turn
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1DisplayBitmap6">
                                <label>
                                    <input #Alert1DisplayBitmap6 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap6') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert1DisplayBitmap6' id="Alert1DisplayBitmap6"
                                        name="Alert1DisplayBitmap6" formControlName="Alert1DisplayBitmap6" />
                                    <span class="fas fa-check"></span>Right Turn
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1DisplayBitmap7">
                                <label>
                                    <input #Alert1DisplayBitmap7 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap7') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert1DisplayBitmap7' id="Alert1DisplayBitmap7"
                                        name="Alert1DisplayBitmap7" formControlName="Alert1DisplayBitmap7" />
                                    <span class="fas fa-check"></span>School Zone
                                </label>
                            </div>

                            <br>
                            <p *ngIf="showAlert1Beacons">Output Relays</p>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1Beacons">
                                <label>
                                    <input #Alert1Beacons type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1Beacons') ? '' : (BeaconsOverride?.checked || Relay1Override?.checked || Relay2Override?.checked) ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert1Beacons' id="Alert1Beacons"
                                        name="Alert1Beacons" formControlName="Alert1Beacons" />
                                    <span class="fas fa-check"></span>Beacon Mode
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1OutputRelay1">
                                <label>
                                    <input #Alert1OutputRelay1 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1OutputRelay1') ? '' : (fgDeviceConfigurationAlert1.get('Alert1Beacons').value) ? '' : null)"
                                        [checked]='fgDeviceConfigurationAlert1.get("Alert1Beacons").value || deviceConfiguration?.Alert1OutputRelay1'
                                        formControlName="Alert1OutputRelay1" />
                                    <span class="fas fa-check"></span>Output 1 Closed
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert1OutputRelay2">
                                <label>
                                    <input #Alert1OutputRelay2 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1OutputRelay2') ? '' : (fgDeviceConfigurationAlert1.get('Alert1Beacons').value) ? '' : null)"
                                        [checked]='fgDeviceConfigurationAlert1.get("Alert1Beacons").value || deviceConfiguration?.Alert1OutputRelay2'
                                        formControlName="Alert1OutputRelay2" />
                                    <span class="fas fa-check"></span>Output 2 Closed
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="col-lg-6 col-sm-12 pl-lg-2 pl-0 pr-0 mt-2">
        <div class="card">
            <div class="card-header">
                <h5 class="mt-0 mb-0 font-weight-bold">Alert 2</h5>
            </div>
            <div class="card-body">
                <form [formGroup]="fgDeviceConfigurationAlert2">
                    <div class="form-row">
                        <div class="col-md-3 col-sm-12 mb-2">
                            <label>Speed</label>
                            <div *ngIf="!isEditMode" class="form-control">
                                {{deviceConfiguration?.SpeedAlert2 ? deviceConfiguration.SpeedAlert2 : '&nbsp;'}}</div>
                            <input *ngIf="isEditMode" [class]='controlValidationClass(inputSpeedAlert2)'
                                [attr.disabled]="(!Display.checked ? '' : null)" id="SpeedAlert2" name="SpeedAlert2"
                                formControlName="SpeedAlert2" type="text" placeholder="Speed Alert 2" />
                            <div *ngIf="isEditMode && controlInvalidFlag(inputSpeedAlert2)" class="invalid-feedback">
                                Please provide a valid Speed Alert 2 from Minimum Speed to Maximum Speed</div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 col-sm-12 mb-2">
                            <div *ngIf="showAlert2FlashRate">
                                <label>Display Behavior</label>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="0" (change)="onAlert2FlashRateChange(0)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2FlashRate') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2FlashRate==0" class="form-check-input"
                                            name="Alert2FlashRate">On Steady
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="20" (change)="onAlert2FlashRateChange(20)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2FlashRate') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2FlashRate==20"
                                            class="form-check-input" name="Alert2FlashRate">Flash Fast
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="40" (change)="onAlert2FlashRateChange(40)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2FlashRate') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2FlashRate==40"
                                            class="form-check-input" name="Alert2FlashRate">Flash Slow
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="50" (change)="onAlert2FlashRateChange(50)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2FlashRate') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2FlashRate==50"
                                            class="form-check-input" name="Alert2FlashRate">Flash MUTCD
                                    </label>
                                </div>
                            </div>
                              <div *ngIf="showAlert2HighLeds &&  (!showAlert2StrobeBlue || !showAlert2StrobeRed || !showAlert2StrobeWhite)">
                                <br>
                                <label>Strobes</label>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="0" (change)="onAlert2HighLedsChange(0)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2HighLeds') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2HighLeds==0" class="form-check-input"
                                            name="Alert2HighLeds">Disabled
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="1" (change)="onAlert2HighLedsChange(1)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2HighLeds') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2HighLeds==1" class="form-check-input"
                                            name="Alert2HighLeds">Flash Once
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="2" (change)="onAlert2HighLedsChange(2)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2HighLeds') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2HighLeds==2" class="form-check-input"
                                            name="Alert2HighLeds">Strobe
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" [value]="3" (change)="onAlert2HighLedsChange(3)"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2HighLeds') ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2HighLeds==3" class="form-check-input"
                                            name="Alert2HighLeds">Strobe Alternating
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="showAlert2StrobeBlue || showAlert2StrobeRed || showAlert2StrobeWhite">
                                <br>
                                <label>Strobes</label>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert2StrobeDisabled type="radio" [value]="0"
                                            (change)="onAlert2StrobeChange(0)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2StrobeBlue == false && deviceConfiguration?.Alert2StrobeRed == false 
                                            && deviceConfiguration?.Alert2StrobeWhite == false"
                                            class="form-check-input" name="Alert2Strobes" />Disabled
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label *ngIf="showAlert2StrobeRed" class="form-check-label">
                                        <input #Alert2StrobeRed type="radio" [value]="1"
                                            (change)="onAlert2StrobeChange(1)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2StrobeRed == true"
                                            class="form-check-input" name="Alert2Strobes" />Strobe Red
                                    </label>
                                </div>
                                <div *ngIf="showAlert2StrobeBlue" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert2StrobeBlue type="radio" [value]="2"
                                            (change)="onAlert2StrobeChange(2)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2StrobeBlue == true"
                                            class="form-check-input" name="Alert2Strobes" />Strobe Blue
                                    </label>
                                </div>
                                <div *ngIf="showAlert2StrobeBlue && showAlert2StrobeRed" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert2StrobeRedBlue type="radio" [value]="3"
                                            (change)="onAlert2StrobeChange(3)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2StrobeBlue == true && deviceConfiguration?.Alert2StrobeRed == true"
                                            class="form-check-input" name="Alert2Strobes" />Strobe Red/Blue
                                    </label>
                                </div>
                                <div *ngIf="showAlert2StrobeWhite" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert2CameraFlash type="radio" [value]="4"
                                            (change)="onAlert2StrobeChange(4)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2StrobeWhite== true && deviceConfiguration?.Alert2HighLeds == 1"
                                            class="form-check-input" name="Alert2Strobes" />Camera Flash
                                    </label>
                                </div>
                                <div *ngIf="showAlert2StrobeWhite" class="form-check">
                                    <label class="form-check-label">
                                        <input #Alert2StrobeWhite type="radio" [value]="5"
                                            (change)="onAlert2StrobeChange(5)"
                                            [attr.disabled]="(!isEditMode || !Display.checked ? '' : null)"
                                            [checked]="deviceConfiguration?.Alert2StrobeWhite == true && deviceConfiguration?.Alert2HighLeds == 2"
                                            class="form-check-input" name="Alert2Strobes" />Strobe White
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-2">
                            <p>Display Content</p>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2DisplaySpeed">
                                <label>
                                    <input #Alert2DisplaySpeed type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2DisplaySpeed') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert2DisplaySpeed' id="Alert2DisplaySpeed"
                                        name="Alert2DisplaySpeed" formControlName="Alert2DisplaySpeed" />
                                    <span class="fas fa-check"></span>Vehicle Speed
                                </label>
                            </div>
                            <div *ngIf="DeviceSchedule?.devicesAssigned[0]">
                                <div class="checkbox c-checkbox"
                                    *ngIf="showAlert2DisplayBitmap1 && DeviceSchedule?.devicesAssigned[0].modelCode != 'TC-1000'">
                                    <label>
                                        <input #Alert1DisplayBitmap1 type="checkbox"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2DisplayBitmap1') ? '' : null)"
                                            [checked]='deviceConfiguration?.Alert2DisplayBitmap1'
                                            formControlName="Alert2DisplayBitmap1" />
                                        <span class="fas fa-check"></span>Slow Down
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="DeviceSchedule?.devicesAssigned[0]">
                                <div class="checkbox c-checkbox"
                                    *ngIf="showAlert2DisplayBitmap2 && DeviceSchedule?.devicesAssigned[0].modelCode != 'TC-1000'">
                                    <label>
                                        <input #Alert1DisplayBitmap2 type="checkbox"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert1DisplayBitmap2') ? '' : null)"
                                            [checked]='deviceConfiguration?.Alert2DisplayBitmap2'
                                            formControlName="Alert2DisplayBitmap2" />
                                        <span class="fas fa-check"></span>Too Fast
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="DeviceSchedule?.devicesAssigned[0]">
                                <div class="checkbox c-checkbox"
                                    *ngIf="showAlert2DisplayBitmap2 && DeviceSchedule?.devicesAssigned[0] &&  DeviceSchedule?.devicesAssigned[0].modelCode == 'TC-1000'">
                                    <label>
                                        <input #Alert1DisplayBitmap2 type="checkbox"
                                            [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2DisplayBitmap2') ? '' : null)"
                                            [checked]='deviceConfiguration?.Alert2DisplayBitmap2'
                                            formControlName="Alert2DisplayBitmap2" />
                                        <span class="fas fa-check"></span>Slow Down
                                    </label>
                                </div>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2DisplayBitmap3">
                                <label>
                                    <input #Alert2DisplayBitmap3 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2DisplayBitmap3') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert2DisplayBitmap3' id="Alert2DisplayBitmap3"
                                        name="Alert2DisplayBitmap3" formControlName="Alert2DisplayBitmap3" />
                                    <span class="fas fa-check"></span>Sharp Curve
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2DisplayBitmap4">
                                <label>
                                    <input #Alert2DisplayBitmap4 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2DisplayBitmap4') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert2DisplayBitmap4' id="Alert2DisplayBitmap4"
                                        name="Alert2DisplayBitmap4" formControlName="Alert2DisplayBitmap4" />
                                    <span class="fas fa-check"></span>Smiley Face
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2DisplayBitmap5">
                                <label>
                                    <input #Alert2DisplayBitmap5 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2DisplayBitmap5') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert2DisplayBitmap5' id="Alert2DisplayBitmap5"
                                        name="Alert2DisplayBitmap5" formControlName="Alert2DisplayBitmap5" />
                                    <span class="fas fa-check"></span>Left Turn
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2DisplayBitmap6">
                                <label>
                                    <input #Alert2DisplayBitmap6 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2DisplayBitmap6') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert2DisplayBitmap6' id="Alert2DisplayBitmap6"
                                        name="Alert2DisplayBitmap6" formControlName="Alert2DisplayBitmap6" />
                                    <span class="fas fa-check"></span>Right Turn
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2DisplayBitmap7">
                                <label>
                                    <input #Alert2DisplayBitmap7 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2DisplayBitmap7') ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert2DisplayBitmap7' id="Alert2DisplayBitmap7"
                                        name="Alert2DisplayBitmap7" formControlName="Alert2DisplayBitmap7" />
                                    <span class="fas fa-check"></span>School Zone
                                </label>
                            </div>

                            <br>
                            <p *ngIf="showAlert2Beacons">Output Relays</p>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2Beacons">
                                <label>
                                    <input #Alert2Beacons type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2Beacons') ? '' : (BeaconsOverride?.checked || Relay1Override?.checked || Relay2Override?.checked) ? '' : null)"
                                        [checked]='deviceConfiguration?.Alert2Beacons' id="Alert2Beacons"
                                        name="Alert2Beacons" formControlName="Alert2Beacons" />
                                    <span class="fas fa-check"></span>Beacon Mode
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2OutputRelay1">
                                <label>
                                    <input #Alert2OutputRelay1 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2OutputRelay1') ? '' : (fgDeviceConfigurationAlert2.get('Alert2Beacons').value) ? '' : null)"
                                        [checked]='fgDeviceConfigurationAlert2.get("Alert2Beacons").value || deviceConfiguration?.Alert2OutputRelay1'
                                        formControlName="Alert2OutputRelay1" />
                                    <span class="fas fa-check"></span>Output 1 Closed
                                </label>
                            </div>
                            <div class="checkbox c-checkbox" *ngIf="showAlert2OutputRelay2">
                                <label>
                                    <input #Alert2OutputRelay2 type="checkbox"
                                        [attr.disabled]="(!isEditMode || !Display.checked || isReadOnly('Alert2OutputRelay2') ? '' : (fgDeviceConfigurationAlert2.get('Alert2Beacons').value) ? '' : null)"
                                        [checked]='fgDeviceConfigurationAlert2.get("Alert2Beacons").value || deviceConfiguration?.Alert2OutputRelay2'
                                        formControlName="Alert2OutputRelay2" />
                                    <span class="fas fa-check"></span>Output 2 Closed
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--test:{{ConfigMapOptions[13]|json}}<br>
test2:{{DeviceSchedule.devicesAssigned|json}}-->
<!-- {{fgDeviceConfiguration.value|json}} -->
<!-- isEditMode:{{isEditMode}}<br/>
FormState:{{this.formState}}<br/>
isReadOnly:{{isReadOnly('BeaconsOverride')}}<br/> -->