import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class DeviceLocationsService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'devicelocations');
  }

  // deleteByUuid(uuid: string) {
  //   return this.delete(uuid);
  // }

  read() {
    return this.all();
  }

  readByUuid(uuid: string) {
    return this.get(uuid);
  }
  
  readDeviceByLocationUuid(uuid: string): Observable<any> {
    var apiParam: string = `${uuid}/device`;
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  readByDeviceUuid(uuid: string, sortDescending?: boolean, maxResults?: number): Observable<any> {
    var apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${(sortDescending ? sortDescending : false)}`;
    if (maxResults) apiParam = apiParam + `/${maxResults}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  readByDeviceUuidDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date): Observable<any> {
    var apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  create(deviceLocation: any): Observable<any> {
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}`;
    //var headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }

    return this.http.post<any>(
      apiParam,
      deviceLocation
    );
  }

  readPeriodAnalysisByDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date,
    timeSegment: number,
    timeFilterBegin: number = 0,
    timeFilterEnd: number = 24): Observable<any> {
    var apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;
    apiParam = apiParam + `/` + timeSegment.toFixed(0).toString();
    apiParam = apiParam + `/` + timeFilterBegin.toFixed(2).toString();
    apiParam = apiParam + `/` + timeFilterEnd.toFixed(2).toString();

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/PeriodAnalysis/${apiParam}`);
  }

  readBinAnalysisByDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date,
    speedBinOneOption: number,
    speedBinBreakOne: number,
    speedBinTwoOption: number,
    speedBinBreakTwo: number,
    speedBinThreeOption: number): Observable<any> {
    var apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;
    apiParam = apiParam + `/` + speedBinOneOption.toFixed(0).toString();
    apiParam = apiParam + `/` + speedBinBreakOne.toFixed(0).toString();
    apiParam = apiParam + `/` + speedBinTwoOption.toFixed(0).toString();
    apiParam = apiParam + `/` + speedBinBreakTwo.toFixed(0).toString();
    apiParam = apiParam + `/` + speedBinThreeOption.toFixed(0).toString();

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/BinAnalysis/${apiParam}`);
  }

  readPeriodSummaryByDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date,
    timeSegment: number): Observable<any> {
    let apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;
    apiParam = apiParam + `/` + timeSegment.toString();

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/PeriodSummary/${apiParam}`);
  }

  readBinSummaryByDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/BinSummary/${apiParam}`);
  }

  readDailySummaryTotalsByDateRange(
    uuid: string,
    speedLimit: number,
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam: string = `${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${uuid}/DailySummaryTotals/${speedLimit}/${apiParam}`);
  }

  readNewActiveByDateRange(
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam: string = `${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/NewActive/${apiParam}`);
  }

  readMostActiveByDateRange(
    beginDate: Date,
    endDate: Date,
    maxRecords: number): Observable<any> {
    let apiParam: string = `${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;
    if (maxRecords) apiParam = apiParam + `/${maxRecords.toString()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/MostActive/${apiParam}`);
  }

  
  readDeviceLocationSettingsRecentByLocationUuid(uuid: string): Observable<any> {
    var apiParam: string = `${uuid}/settings/recent`;
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }


}
