import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { FirmwaresService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GridOptions, ChartType, ColDef, ValueGetterParams } from 'ag-grid-community';
import { LibrariesService } from '../../../../core/libraries/libraries.service';

@Component({
  selector: 'app-firmwares-admin-view',
  templateUrl: './firmwares-admin-view.component.html',
  styleUrls: ['./firmwares-admin-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FirmwaresAdminViewComponent implements OnInit, AfterViewInit, OnDestroy {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public firmwaresTemp: any[] = [];
  public firmwares: any[] = [];
  public firmwaresSelected: any[] = [];

  private _connectivityTypeId: number = -1;
  get connectivityTypeId(): number {
    return this._connectivityTypeId;
  }
  @Input() set connectivityTypeId(Id: number) {
    this._connectivityTypeId = Id
    if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
  }


  @Output() onViewSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @ViewChild('datatableFilter') datatableFilter: ElementRef;

  //ag-grid variables start

  public gridOptions: GridOptions;
  public chartThemes;
  public rowStyle;
  public isSelected: boolean = false;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  @Input() public chartView: boolean = false;

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    private router: Router,
    public firmwaresService: FirmwaresService) {
      this.chartThemes = [
        'ag-pastel',
        'ag-default',
        'ag-material-dark',
        'ag-vivid-dark',
        'ag-solar',
      ];
    this.rowStyle = {
      cursor: 'pointer'
    }
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      rowStyle: this.rowStyle,
      rowHeight: 40,
      rowSelection: 'multiple',
      cursor: "pointer",
      suppressRowClickSelection: true,
      onCellClicked: (e) => {
        e.node.setSelected(true)
        this.isSelected = true;
    },
    //chartThemeOverrides: this.chartThemeOverrides,
    enableCharts: false,
    enableRangeSelection: false
    // pagination: true,
    // rowSelection: 'single'
  }
  }

  columnDefs = [
    { headerName: "Identifier", field: "uuid", sortable: true, resizable: true, minWidth: 120, maxWidth: 350, suppressMenu: true },
    { headerName: "Code", field: "code", sortable: true, resizable: true, minWidth: 120, maxWidth: 300, suppressMenu: true },
    { headerName: "File Name", field: "fileName", sortable: true, resizable: true, minWidth: 120, maxWidth: 300, suppressMenu: true },
    { headerName: "Size (Bytes)", field: "fileSize", sortable: true, resizable: true, minWidth: 150, maxWidth: 300, suppressMenu: true },
    { headerName: "Description", field: "description", sortable: true, resizable: true, minWidth: 150, suppressMenu: true },
  ]

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this
    setTimeout(function () {
      self.getFirmwares()
    }, 500);
  }

  getFirmwares(): any {
    //Don't need for admin component
    //if (!this.settingsService.firmware) return;

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 25);

    return this.firmwaresService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.firmwares = result;
          this.firmwaresTemp = result;
          if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
        },
        error => {
          this.notificationsService.error("Server Error (getFirmwares)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  updateFilter(event) {
    const val = event.toLowerCase();
    // filter our data

    var self = this

    const temp = this.firmwaresTemp.filter(function (d) {
      return (
        (
          (d.code != null && d.code.toLowerCase().indexOf(val) !== -1) ||
          (d.uuid != null && d.uuid.toLowerCase().indexOf(val) !== -1) ||
          (d.description != null && d.description.toLowerCase().indexOf(val) !== -1) ||
          (d.fileName != null && d.fileName.toLowerCase().indexOf(val) !== -1) ||
          val.toString().trim() == ''
        )
      );
    });

    // update the rows
    this.firmwares = temp;
    
  }

  // onSelect({ selected }) {
  //   this.firmwaresSelected.splice(0, this.firmwaresSelected.length);
  //   if (selected && selected[0].uuid) {
  //     console.log('onSelect Event', selected);
  //     this.onViewSelected.emit(selected[0]);
  //     //this.router.navigate(['/administration/firmwares', selected[0].uuid])
  //     //alert(selected[0].uuid)
  //   }
  // }

  onResize(event) {
    this.gridOptions.api.sizeColumnsToFit();
 
  }

  onRowSelected({selected}) {
    //console.log(event);
    this.firmwaresSelected.splice(0, this.firmwaresSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onViewSelected.emit(selected[0]);
  }
}

  onRowClicked(event) {
    console.log(event)

    if (this.isSelected && !this.chartView) {
      this.router.navigate(['/administration/firmwares/', event.node.data.uuid]);
    }

    else if(this.isSelected && this.chartView){
      console.log(event.node)
      this.router.navigate(['/charts/', event.node.data.deviceLocation.uuid]);
    }
 
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

}
