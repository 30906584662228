<div class="row">
  <div class="col-lg-12">
    <!-- START widget-->
    <div class="card bg-default">
      <div class="card-header">
        <div class="row">
          <div class="col-12 col-md-4">
            <h4 class="pt-2 mb-0">Sign
              Group{{showDeviceSchedulesForm ? "" : "s - Listing"}}{{configurationsForm ? "" : " - View"}}{{
              showDeviceSchedulesForm && configurationsForm && configurationsForm.isEditMode
              ? (configurationsForm.formState == configurationsForm.formStates.New ? " - New" : " - Edit") : "" }}
            </h4>
          </div>

          <div class="col-12 col-md-8 float-right text-right">

            <button
              *ngIf="!showDeviceSchedulesForm && settingsService.userAccessCheck(userTypes.EndUser,userRoles.Manager)"
              class="btn btn-primary" (click)="buttonAdd($event)" [disabled]="showDeviceSchedulesForm">
              <span class="fas fa-plus-square pr-2"></span>Add</button>

            <button *ngIf="configurationsForm && showDeviceSchedulesForm" [disabled]="(configurationsForm &&configurationsForm.isSaveDisabled) ||
                (configurationsForm.formState == configurationsForm.formStates.Saving)" type="button"
              class="btn btn-primary ml-2" (click)="buttonBack($event)">
              <span class="fa fas fa-th-list pr-2"></span>Back to Group List</button>
            <button
              *ngIf="configurationsForm && showDeviceSchedulesForm && configurationsForm.isEditMode 
                  && (configurationsForm.formState == configurationsForm.formStates.New || configurationsForm.formState == configurationsForm.formStates.Edit)"
              [disabled]="configurationsForm.formState != configurationsForm.formStates.New && configurationsForm.formState != configurationsForm.formStates.Edit"
              type="button" class="btn btn-delete ml-2" (click)="openLocationDeleteModal(locationDeleteModal)">
              <span class="fas fa-trash pr-2"></span>Delete</button>
            <button *ngIf="configurationsForm && showDeviceSchedulesForm  && !configurationsForm.isEditMode &&
              settingsService.userAccessCheck(userTypes.EndUser,userRoles.Manager)"
              [disabled]="configurationsForm.formState != configurationsForm.formStates.Read" type="button"
              class="btn btn-primary ml-2" (click)="buttonEdit($event)" id="highlight">
              <span class="fas fa-edit pr-2"></span>Edit</button>


            <button
              *ngIf="configurationsForm && showDeviceSchedulesForm && configurationsForm.isEditMode 
                  && (configurationsForm.formState == configurationsForm.formStates.New || configurationsForm.formState == configurationsForm.formStates.Edit)"
              [disabled]="(configurationsForm && configurationsForm.isSaveDisabled) ||
              (configurationsForm.formState != configurationsForm.formStates.New && configurationsForm.formState != configurationsForm.formStates.Edit)"
              type="button" class="btn btn-success ml-2" (click)="buttonSave($event)">
              <span class="fas fa-save pr-2"></span>Save</button>
            <button
              *ngIf="configurationsForm && showDeviceSchedulesForm && configurationsForm.isEditMode 
                  && (configurationsForm.formState == configurationsForm.formStates.New || configurationsForm.formState == configurationsForm.formStates.Edit)"
              [disabled]="configurationsForm.formState != configurationsForm.formStates.New && configurationsForm.formState != configurationsForm.formStates.Edit"
              type="button" class="btn btn-cancel ml-2" (click)="buttonCancel($event)">
              <span class="fas fa-ban pr-2"></span>Cancel</button>

          </div>
        </div>


        <div *ngIf="showDeviceSchedulesForm">
          <app-configurations-form #configurationsForm [DeviceScheduleUuid]="DeviceScheduleUuid"
            (onFormEventDeviceSchedule)="onFormEventDeviceSchedule($event)"></app-configurations-form>
        </div>
      </div>

      <div *ngIf="!showDeviceSchedulesForm" class="bg-body">
        <app-configurationview (onDeviceScheduleSelected)="onDeviceScheduleSelected($event)"></app-configurationview>
      </div>

    </div>
    <!-- END widget-->
    <!-- {{configurationsForm?.isSaveDisabled}}
    {{configurationsForm?.formState}} -->
  </div>
</div>
<ng-template #locationDeleteModal>
  <div class="modal-header">
    <h3 class="m-0">Confirm Delete</h3>
  </div>
  <div class="modal-body text-center">
    <i class="fas fa-trash-alt fa-2x bg-primary rounded p-3"></i>
    <h4 class="pt-3">This action deletes this configuration and<br />will unassign any selected devices.</h4>
    <h4 class="pt-2">Delete this configuration?</h4>
    <h6 class="pt-3 pb-3">(Deletion is immediate)</h6>
    <button type="button" class="btn btn-danger mr-3" (click)="LocationDeleteConfirm()">Delete</button>
    <button type="button" class="btn btn-cancel" (click)="LocationDeleteCancel()">Cancel</button>
  </div>
</ng-template>