  <ul class="nav nav-tabs">
    <li *ngFor="let tab of tabs" (click)="selectTab(tab)" [class.nav-item]="true" [class.active]="tab.active" [class.disabled]="tab.disabled" [hidden]="tab.hidden">
      <a [class.nav-link]="true" [class.active]="tab.active" [class.disabled]="tab.disabled" href="#">{{tab.title}}</a>
    </li>
    <!-- dynamic tabs -->
    <li *ngFor="let tab of dynamicTabs" (click)="selectTab(tab)" [class.nav-item]="true" [class.active]="tab.active" [class.disabled]="tab.disabled" [hidden]="tab.hidden">
      <a [class.nav-link]="true" [class.active]="tab.active" [class.disabled]="tab.disabled" href="#">{{tab.title}} <span class="tab-close" *ngIf="tab.isCloseable" (click)="closeTab(tab)">x</span></a>
    </li>
  </ul>
  <ng-content></ng-content>
  <ng-template dynamic-tabs #container></ng-template>