import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiIdService } from './_api-id.service';

@Injectable()
export class UserTypesService extends ApiIdService {
  constructor(
    protected router: Router,
    protected http: HttpClient, 
  ) {
    super(router, http, 'usertypes');
  }

  read() {
    return this.all();
  }

  readById(id: number) {
    return this.get(id);
  }

}
