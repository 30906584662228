import { Component, OnInit, ViewChild, ElementRef, Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { ModelsAdminViewComponent } from '../../../shared/component/views/models-admin-view/models-admin-view.component';
import { ConfigOptionControlComponent } from '../../../shared/component/controlsUi/configOptionControl/configOptionControl.component';
import { ModelFormComponent } from '../../../shared/component/forms/models/model-form/model-form.component';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-models-admin',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss'],
})
export class ModelsAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ModelFormComponent) public modelForm: ModelFormComponent;
  @ViewChild(ModelsAdminViewComponent) public modelView: ModelsAdminViewComponent;
  @ViewChild(ConfigOptionControlComponent) public configControl: ConfigOptionControlComponent;
    
  userTypes = userTypes;
  userRoles = userRoles;
  
  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  private paramSub: any;
  public showForm: boolean = false;

  private _modelId: number = -1;
  get modelId(): number {
    return this._modelId;
  }
  set modelId(newModelId: number) {
    if (newModelId == 0) {
      this._modelId = newModelId;
      this.showForm = false;
    } else {
      this._modelId = newModelId;
      this.showForm = true;
    }
  }

  ngOnInit() {

    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.modelId = params == undefined ? -1 
        : params['id'].toString().toLowerCase() == 'all' ? 0 
        : params['id'].toString().toLowerCase() == 'add' ? -1 
        : params['id'];
    });
  }
  
  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    
  }

  onFormEvent(event) {
    switch (event.toString()) {
      case "saved":
        {
          //TODO
          break;
        }
      case "cancelled":
        {
          //TODO
          break;
        }
    }
  }

  onViewSelected(event) {
    if (event && event.id) {
      this.router.navigate(['/administration/models', event.id.toString()], {replaceUrl: false})
    }
  }

  buttonAdd(event) {
    this.router.navigate(['/administration/models', 'add'], {replaceUrl: false})
  }
  
  buttonBack(event)
  {
    this.router.navigate(['/administration/models/all'])
  }

  buttonEdit(event)
  {
    this.modelForm.formState = this.modelForm.formStates.Edit
  }

  buttonSave(event)
  {
    this.modelForm.formState = this.modelForm.formStates.Save
  }

  buttonCancel(event)
  {
    this.modelForm.formState = this.modelForm.formStates.Cancelled
  }

}
