import { Component, ElementRef, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild, ViewChildren, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { EventsService } from '../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../core/settings/settings.service';
import { LibrariesService } from '../../core/libraries/libraries.service';
import { DeviceDailySummariesService, DeviceLocationsService, DeviceLocationSettingsService, CountriesService, BearingsService, DevicesService, deviceNotifyTypes, deviceStatuses } from '../../core/api/api.services';
import { DatatableComponent, id } from '@swimlane/ngx-datatable';
import { MappingService, mapMarkerTypes } from '../../core/mapping/mapping.service';
import { ErrorsService } from '../../core/errors/errors.service';
import * as _ from "lodash";
import { Subscription, Subject } from 'rxjs';
import { takeUntil, filter, distinctUntilChanged } from 'rxjs/operators';
import { Map, MapboxGeoJSONFeature, Marker, MarkerOptions, MapMouseEvent, GeoJSONSource, LngLatLike, LngLatBounds, LngLat } from 'mapbox-gl';
import * as GeoJSON from 'geojson';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SafeHtmlPipe } from '../../shared/pipes/safeHtmlPipe'
import { map, remove } from 'lodash';
import { LayerComponent } from 'ngx-mapbox-gl/lib/layer/layer.component';
import * as mapboxgl from 'mapbox-gl';
import { ColorsService } from '../../core/colors/colors.service';
import { JsonpClientBackend } from '@angular/common/http';

declare const H: any;

export enum DeviceLocationsFormState {
  Initializing = 1,
  List = 2,
  Read = 3,
  New = 4,
  Edit = 5,
  Save = 6,
  Saving = 7,
  Saved = 8,
  Cancel = 9,
  Cancelled = 10,
}
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit, OnDestroy {
  @Output() onFormEventDevice: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeviceLocationSelected: EventEmitter<any> = new EventEmitter()
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter()
  @ViewChild('svgMarkerLte') svgMarkerLte: ElementRef;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public devicesService: DevicesService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public deviceLocationsService: DeviceLocationsService,
    public DeviceLocationSettingsService: DeviceLocationSettingsService,
    public bearingsService: BearingsService,
    public countriesService: CountriesService,
    public mappingService: MappingService,
    public colorsService: ColorsService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) { }

  private paramSub: any;

  public dataLoading: boolean = false;
  public mySessionData: any;
  public showFilterOptions: boolean = false;
  public mapContainer: Map;
  public isMapLoaded: boolean = false;
  public mapPoints: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: 'FeatureCollection',
    features: []
  };
  public markerTypes = mapMarkerTypes

  public mapSettings = {
    settingsList: {
      active: this.activeToggle,
      inactive: this.inactiveToggle,
      prevLocation: this.prevToggle,
      interrupted: this.disruptedToggle,
      offline: this.disconnectedToggle,
    }
  }

  public USZoom: number = 4;
  public mapLatDefault: number = 39.396662
  public mapLngDefault: number = -97.005892
  public mapInitiazlied: boolean = false;
  public mapFirstLoad: boolean = true;
  public mapFirstDraw: boolean = true;

  get currentDate(): Date {
    return new Date();
  }

  get activeToggle(): boolean {
    return this.settingsService.read('Map Settings', 'activeToggle', false);
  }

  set activeToggle(newActiveToggle: boolean) {
    this.settingsService.write('Map Settings', 'activeToggle', newActiveToggle);
  }

  get inactiveToggle(): boolean {
    return this.settingsService.read('Map Settings', 'inactiveToggle', false);
  }

  set inactiveToggle(newInactiveToggle: boolean) {
    this.settingsService.write('Map Settings', 'inactiveToggle', newInactiveToggle);
  }

  get disruptedToggle(): boolean {
    return this.settingsService.read('Map Settings', 'disruptedToggle', false);
  }

  set disruptedToggle(newDisruptedToggle: boolean) {
    this.settingsService.write('Map Settings', 'disruptedToggle', newDisruptedToggle);
  }

  get disconnectedToggle(): boolean {
    return this.settingsService.read('Map Settings', 'disconnectedToggle', false);
  }

  set disconnectedToggle(newDisconnectedToggle: boolean) {
    this.settingsService.write('Map Settings', 'disconnectedToggle', newDisconnectedToggle);
  }

  get prevToggle(): boolean {
    return this.settingsService.read('Map Settings', 'prevToggle', false);
  }

  set prevToggle(newPrevToggle: boolean) {
    this.settingsService.write('Map Settings', 'prevToggle', newPrevToggle);
  }



  get isEditMode(): boolean {
    // if (this.formState == DeviceWifiFormState.New) return true
    // if (this.formState == DeviceWifiFormState.Edit) return true

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  get isMapEnabled(): boolean {
    // if (this.formState == DeviceWifiFormState.New) return true
    // if (this.formState == DeviceWifiFormState.Edit) return true

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  public formEnabled = false;
  public formStates = DeviceLocationsFormState
  private _formState: DeviceLocationsFormState = DeviceLocationsFormState.Initializing
  get formState(): DeviceLocationsFormState {
    return this._formState
  }
  @Input() set formState(newFormState: DeviceLocationsFormState) {
    // var updateFormState: DeviceLocationsFormState = null
    // this.fgDeviceLocation.disable()
    // this.fgDeviceLocationSetting.disable()

    // switch (newFormState) {
    //   case this.formStates.Read: {
    //     updateFormState = newFormState
    //     this.deviceLocationSelect()
    //     break
    //   }
    //   case this.formStates.New: {
    //     this.fgDeviceLocation.enable()
    //     this.fgDeviceLocationSetting.enable()
    //     this.getPrevLocsAll()
    //     updateFormState = newFormState
    //     break
    //   }
    //   case this.formStates.Edit: {
    //     updateFormState = newFormState
    //     this.fgDeviceLocation.enable()
    //     this.fgDeviceLocationSetting.enable()
    //     this.deviceLocationSettingsEdit()
    //     break
    //   }
    //   case this.formStates.Save: {
    //     //presetting to address form state sequencing durring validation
    //     this.fgDeviceLocation.enable()
    //     this.fgDeviceLocationSetting.enable()
    //     this._formState = newFormState
    //     this.deviceLocationSave()
    //     //this.deviceLocationSettingsSave()
    //     break
    //   }
    //   case this.formStates.Saving: {
    //     this.fgDeviceLocation.enable()
    //     this.fgDeviceLocationSetting.enable()
    //     break
    //   }
    //   case this.formStates.Cancel: {
    //     this.deviceLocationSettingsCancel()
    //     updateFormState = this.formStates.Read
    //     break
    //   }
    //   case this.formStates.Cancelled: {
    //     this.deviceLocationSettingsBack()
    //     this.formState = this.formStates.List
    //     break
    //   }
    //   default: 
    //     {
    //       updateFormState = newFormState
    //     }
    // }

    // if (updateFormState != null) this._formState = updateFormState
  }

  public fgCharts: FormGroup = this.formBuilder.group({
    deviceListingTypeId: "0"
  });

  public fgChartTypes: FormGroup = this.formBuilder.group({
    chartTypeId: "0"
  });


  ngAfterViewInit() {
    console.log('ngAfterViewInit')
    this.mapEventsInitialition();
    // this.mapInitialition();
    // this.formValueChangesSetup()
    var self = this
    setTimeout(function () {
      self.mapResize()
    }, 250);
  }

  ngOnInit() {
    //this.checkToggleSettings()
    // this.paramSub = this.route.params
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(params => {
    //     this.deviceLocationUuid = this.librariesService.guidValidate(params['uuid']) ? params['uuid'] : ""
    //   });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //console.log("NavigationEnd",this.route.component.name.toString(), event,this.route)
        if (this.router.url.toLowerCase().split("?")[0] == '/map') {
          console.log(this.route.routeConfig.component.name + ":NavigationEnd")

          var self = this
          setTimeout(function () {
            self.mapContainer.resize()
            self.refreshMap(null)
          }, 50);
        }
      }
    });


    var self = this;

    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(position => {
        self.mapLatDefault = position.coords.latitude
        self.mapLngDefault = position.coords.longitude
      })
    };

    //this.checkToggleSettings();

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    // this.mapEventsDestroy()
  }

  refreshMap(event) {
    var self = this;
    if (!this.settingsService.client) {
      setTimeout(() => {
        self.refreshMap(null);
      }, 100);
    }
    else {
      if (!this.mapFirstLoad)
        setTimeout(function () {
          self.getPrevLocsAll()
          // self.getDeviceLocation()
        }, 500);
    }
  }


  onMapLoad(mapInstance: Map) {
    var self = this;
    this.isMapLoaded = true
    this.mapContainer = mapInstance
    mapInstance.resize()

    if (this.mapPoints && this.mapPoints.features.length == 0)
      this.buttonMapUS(null)
    else this.buttonMapFit(null)

    setTimeout(function () {
      self.getPrevLocsAll()
    }, 100);
  }

  buttonMapUS(event) {
    this.mapContainer.setZoom(this.USZoom)
    this.mapContainer.setCenter([this.mapLngDefault, this.mapLatDefault])

    var bounds: LngLatBounds = new LngLatBounds();
    bounds.extend(new LngLat(-123.1207, 49.2827));
    bounds.extend(new LngLat(-98.2300, 26.2034));
    bounds.extend(new LngLat(-68.5895, 47.2587));
    this.mapContainer.fitBounds(bounds, { padding: 5 })
    console.log(this.mapContainer.getCenter())
    // vancouver 49.2827° N, 123.1207° W
    // mcallen 26.2034° N, 98.2300° W
    // ft kent 47.2587° N, 68.5895° W
  }

  buttonMapFit(event) {
    var bounds: LngLatBounds = new LngLatBounds();
    var lnglat: LngLat

    this.mapPoints.features.forEach(function (feature) {
      lnglat = new LngLat(feature.geometry.coordinates[0], feature.geometry.coordinates[1])
      bounds.extend(lnglat);
    });
    this.mapContainer.fitBounds(bounds, { padding: 100 })
  }

  public devicePrevLocsAll: any[] = [];
  getPrevLocsAll(): any {
    this.dataLoading = true;
    this.devicePrevLocsAll = [];
    //this.mapPrevLocsAllRemove()

    if (!this.settingsService.client) return;
    if (this.mapFirstLoad) this.settingsService.showSpinner("mapSpinner", false, 250);

    return this.deviceLocationsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.devicePrevLocsAll = result.sort((a, b) => a.latitude > b.latitude ? -1 : a.latitude < b.latitude ? 1 : 0)
          this.getDevicesAll()
        },
        error => {
          this.settingsService.hideSpinner("mapSpinner");
          this.notificationsService.error("Server Error (getPrevLocsAll)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  public devicesAll: any[] = [];
  getDevicesAll(): any {
    this.devicesAll = []
    if (!this.settingsService.client) {
      return;
    }

    return this.devicesService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any[]) => {
          this.devicesAll = result.filter(x => x.deviceLocation != null).sort((a, b) => a.deviceLocation.latitude > b.deviceLocation.latitude ? -1 : a.deviceLocation.latitude < b.deviceLocation.latitude ? 1 : 0)
          this.showMarkers()
          this.mapFirstLoad = false;
          this.dataLoading = false;
          this.settingsService.hideSpinner("mapSpinner");
        },
        error => {
          this.settingsService.hideSpinner("mapSpinner");
          this.notificationsService.error("Server Error (devicesAll)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.mapFirstLoad = false;
          this.dataLoading = false;
        });
  }

  // public deviceLocation: any;
  // getDeviceLocation(): any {

  //   this.deviceLocation = null
  //   if (this.deviceLocationUuid == "" || !this.settingsService.client) return;

  //   return this.deviceLocationsService.readByUuid(this.deviceLocationUuid)
  //     .pipe(takeUntil(this.onDestroy$))
  //     .subscribe(
  //       (result: any) => {
  //         this.deviceLocation = result;
  //         console.log(this.deviceLocation)
  //       },
  //       error => {
  //         this.notificationsService.error("Server Error (getDeviceLocation)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
  //       });
  // }

  // getDeviceLocationAddressDisplay(): string {
  //   if (!this.deviceLocation) return ''

  //   var display: string = this.deviceLocation.address || ''

  //   if (display.length > 0 && this.deviceLocation.city && this.deviceLocation.city.length > 0) display = display + ', '
  //   display = display + (this.deviceLocation.city || '')

  //   if (display.length > 0 && this.deviceLocation.stateProvinceCode && this.deviceLocation.stateProvinceCode.length > 0) display = display + ', '
  //   display = display + (this.deviceLocation.stateProvinceCode || '')

  //   if (display.length > 0 && this.deviceLocation.postalCode && this.deviceLocation.postalCode.length > 0) display = display + ', '
  //   display = display + (this.deviceLocation.postalCode || '')

  //   if (display.length > 0 && this.deviceLocation.country && this.deviceLocation.country.length > 0) display = display + ', '
  //   display = display + (this.deviceLocation.country || '')

  //   return display
  // }

  mapInitialition() {
    // this.mapLocationMarker = new H.map.Marker({ lat: this.mapLatDefault, lng: this.mapLngDefault });
    // this.mapLocationMarker.setZIndex(1000)
    // this.mapLocationMarker.setVisibility(false)
    // // Ensure that the marker can receive drag events
    // this.mapLocationMarker.draggable = true;

    // this.map.addObject(this.mapLocationMarker);

    this.mapInitiazlied = true;
  }

  mapEventsInitialition() {
    var self = this;

    window.addEventListener('resize', function () {
      self.mapResize()
    });

    // self.map.addEventListener('mapviewchangeend', function () {
    //   console.log('self.mapFirstLoad 1', self.mapFirstLoad);

    //         if (self.mapFirstLoad) {
    //           self.mapFirstLoad = false;
    //           console.log('self.mapFirstLoad 2', self.mapFirstLoad);
    //           self.getPrevLocsAll(self.mappingService.largeViewIsDefault)
    //           self.getDeviceLocation()
    //           return;
    //         }

    //         var zoom = self.map.getZoom();
    //         var vBounds = self.map.getViewBounds();

    //         console.log('mapviewchangeend', zoom,
    //           vBounds,
    //           vBounds.getTop(),
    //           vBounds.getLeft(),
    //           vBounds.getBottom(),
    //           vBounds.getRight()
    //         )

    //         if (Math.abs(vBounds.getLeft()) == 180 || Math.abs(vBounds.getRight()) == 180) {
    //           console.log('mapviewchangeend - 180')
    //           return;
    //         }

    //         self.mappingService.largeZoom = zoom;
    //         self.mappingService.largeViewBounds.top = vBounds.getTop();
    //         self.mappingService.largeViewBounds.left = vBounds.getLeft();
    //         self.mappingService.largeViewBounds.bottom = vBounds.getBottom();
    //         self.mappingService.largeViewBounds.right = vBounds.getRight();

    //         self.mappingService.largeViewBounds = self.mappingService.largeViewBounds;

    //       });

    // self.getPrevLocsAll(self.mappingService.largeViewIsDefault)
    //self.mapResize()
  }

  mapEventsDestroy() {
    window.removeEventListener('resize', function () { });
  }

  mapResize() {
    if (document.getElementById('footerContainer') == null || document.getElementById('mapContainer') == null) return;

    var self = this;

    var mapHeight: number =
      document.body.clientHeight - 15 -
      document.getElementById('mapContainer').getBoundingClientRect().top -
      (document.getElementById('footerContainer').getBoundingClientRect().bottom -
        document.getElementById('footerContainer').getBoundingClientRect().top)

    mapHeight = mapHeight.round(0)

    if (document.getElementById('mapContainer'))
      document.getElementById('mapContainer').style.height = mapHeight.toString() + 'px';
    if (document.getElementById('mapContainerLoader'))
      document.getElementById('mapContainerLoader').style.height = mapHeight.toString() + 'px';

    setTimeout(function () {
      if (self.mapContainer != null) self.mapContainer.resize()
      //if (self.mapInitiazlied) self.map.getViewPort().resize()
    }, 50);
  }

  // mapPrevLocsAllRemove() {
  //   if (this.mapPrevLocsAllGroup) {
  //     this.map.removeObject(this.mapPrevLocsAllGroup)
  //     this.mapPrevLocsAllGroup.removeAll()
  //     this.mapPrevLocsAllGroup = null
  //   }
  // }

  // mapWifiRemove() {
  //   if (this.mapWifiGroup) {
  //     this.map.removeObject(this.mapWifiGroup)
  //     this.mapWifiGroup.removeAll()
  //     this.mapWifiGroup = null
  //   }
  // }

  // mapCellularRemove() {
  //   if (this.mapCellularGroup) {
  //     this.map.removeObject(this.mapCellularGroup)
  //     this.mapCellularGroup.removeAll()
  //     this.mapCellularGroup = null
  //   }
  // }

  public selectedFeature: any;
  public selectedLocationUuid: string = "";
  markerClick(locationUuid, event) {
    console.log(locationUuid, event)
    //this.selectedFeature = null;

    if (locationUuid == this.selectedLocationUuid) return;

    this.selectedLocationUuid = ""

    this.selectedFeature = this.mapPoints.features.find(x => x.properties.locationUuid == locationUuid);
    if (this.selectedFeature && this.selectedFeature.properties?.locationUuid) {
      var self = this
      setTimeout(function () {
        self.selectedLocationUuid = self.selectedFeature.properties?.locationUuid
        self.mapContainer.resize()
      }, 300);

    }
    console.log(this.selectedFeature)
  }

  getMarkerSvg(featureProperties: any): string {

    if (featureProperties.notification != null) {
      var ntfy = featureProperties.notification;
      if (ntfy.typeId == deviceNotifyTypes.Communication) {
        return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.CellularComm)
      }
      if (ntfy.typeId == deviceNotifyTypes.CriticalBattery) {
        return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.BatteryCritical)
      }
      if (ntfy.typeId == deviceNotifyTypes.LowBattery) {
        return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.BatteryLow)
      }
      if (ntfy.typeId == deviceNotifyTypes.Offline) {
        return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.CellularOffline)
      }
    }

    if (featureProperties.deviceStatusId == deviceStatuses.Inactive && featureProperties.connectivityTypeId != 0) {
      return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.Inactive)
    }
    if (featureProperties.deviceUuid == '') {
      return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.PreviousLocation)
    }
    if (featureProperties.connectivityTypeId == 0) {
      return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.Wifi)
    }
    else {
      return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.Cellular)
    }
  }


  getMarkerType(featureProperties: any): mapMarkerTypes {

    if (featureProperties.notification != null) {
      var ntfy = featureProperties.notification;
      if (ntfy.typeId == deviceNotifyTypes.Communication) {
        return mapMarkerTypes.CellularComm;
      }
      if (ntfy.typeId == deviceNotifyTypes.CriticalBattery) {
        return mapMarkerTypes.BatteryCritical;
      }
      if (ntfy.typeId == deviceNotifyTypes.LowBattery) {
        return mapMarkerTypes.BatteryLow;
      }
      if (ntfy.typeId == deviceNotifyTypes.Offline) {
        return mapMarkerTypes.CellularOffline;
      }
    }

    if (featureProperties.deviceStatusId == deviceStatuses.Inactive && featureProperties.connectivityTypeId != 0) {
      return mapMarkerTypes.Inactive;
    }
    if (featureProperties.deviceUuid == '') {
      return mapMarkerTypes.PreviousLocation;
    }
    if (featureProperties.connectivityTypeId == 0) {
      return mapMarkerTypes.Wifi;
    }
    else {
      return mapMarkerTypes.Cellular;
    }
  }

  showMarker(featureProperties: any): boolean {

    let count: number = 0;
    switch (this.getMarkerType(featureProperties)) {
      case mapMarkerTypes.Inactive:
        count = this.mapPoints.features.filter(f => f.properties.locationUuid == featureProperties.locationUuid && f.properties.deviceUuid != '').length;
        if (count == 1) return true
        return false;
      case mapMarkerTypes.PreviousLocation:
        count = this.mapPoints.features.filter(f => f.properties.locationUuid == featureProperties.locationUuid && f.properties.deviceUuid != '').length;
        if (count == 0) return true
        return false;
      default:
        return true;
    }

  }

  showMarkers() {
    var newMarkerOptions: MarkerOptions = {}
    var newMarker: Marker;
    let queryLoc: any
    let queryFeature: any

    let mapPointsNew: GeoJSON.FeatureCollection<GeoJSON.Point> = {
      type: 'FeatureCollection',
      features: []
    };

    // this.mapPoints.features.forEach(function (feature) {
    //   lnglat = new LngLat(feature.geometry.coordinates[0], feature.geometry.coordinates[1])
    //   bounds.extend(lnglat);
    // });

    if (this.devicePrevLocsAll.length > 0) {
      for (let loc of this.devicePrevLocsAll) {

        queryLoc = this.devicesAll.find(
          x => x.deviceLocation != null &&
            x.deviceLocation.uuid == loc.uuid
        )

        if (!queryLoc && loc.latitude != 0 && loc.longitude != 0) {

          // queryFeature = this.mapPoints.features.find(x => x.properties.locationUuid == locationUuid);

          mapPointsNew.features.push(
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [loc.longitude, loc.latitude]
              },
              properties: {
                description: `Name: ${loc.name}\n${loc.address}\n${loc.city}, ${loc.stateProvinceCode} ${loc.postalCode}\n${loc.country} (Click For More Options)`,
                locationUuid: this.librariesService.guidValidate(loc.uuid) ? loc.uuid.toString() : '',
                deviceUuid: '',
                connectivityTypeId: 0,
                deviceStatusId: null,
                notification: null,
                //className: "deviceMarker",
                color: this.colorsService.APP_COLORS['map-device-location']
              },
            },
          )

        }
      }
    }
    



    if (this.devicesAll.length > 0) {
      for (let device of this.devicesAll) {
        if (device.deviceLocation != null && device.deviceLocation.latitude != 0 && device.deviceLocation.longitude != 0) {

          let notifications: any[] = []
          if (device.notificationsActive.length > 0) notifications = device.notificationsActive.filter((n: { typeId: number; }) => n.typeId <= 50);


          mapPointsNew.features.push(
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [device.deviceLocation.longitude, device.deviceLocation.latitude]
              },
              properties: {
                description: `Name: ${device.deviceLocation.name}\n${device.deviceLocation.address}\n${device.deviceLocation.city}, ${device.deviceLocation.stateProvinceCode} ${device.deviceLocation.postalCode}\n${device.deviceLocation.country} (Click For More Options)`,
                locationUuid: this.librariesService.guidValidate(device.deviceLocation.uuid) ? device.deviceLocation.uuid.toString() : '',
                deviceUuid: device.uuid,
                connectivityTypeId: device.connectivityTypeId,
                deviceStatusId: device.deviceStatusId,
                notification: notifications.length == 0 ? null : notifications[0],
                //className: "deviceMarker",
                color: device.connectivityTypeId == 10 ? this.colorsService.APP_COLORS['map-device-cellular'] : this.colorsService.APP_COLORS['map-device-wifi']
              },
            },
          )
        }
      }
    }

    let dataChanged: boolean = false;

    //verify all new ones exist
    for (var i = mapPointsNew.features.length - 1; i >= 0; i--) {

      queryFeature = this.mapPoints.features.find(x =>
        x.properties.locationUuid == mapPointsNew.features[i].properties.locationUuid &&
        x.properties.deviceUuid == mapPointsNew.features[i].properties.deviceUuid &&
        x.properties.connectivityTypeId == mapPointsNew.features[i].properties.connectivityTypeId
      );
      if (!queryFeature) {
        dataChanged = true;
        break;
      }

    }

    //verify none were removed
    if (dataChanged = false) {
      for (var i = this.mapPoints.features.length - 1; i >= 0; i--) {

        queryFeature = mapPointsNew.features.find(x =>
          x.properties.locationUuid == this.mapPoints.features[i].properties.locationUuid &&
          x.properties.deviceUuid == this.mapPoints.features[i].properties.deviceUuid &&
          x.properties.connectivityTypeId == this.mapPoints.features[i].properties.connectivityTypeId
        );
        if (!queryFeature) {
          dataChanged = true;
          break;
        }

      }
    }

    if (dataChanged = true) {
      console.log(this.route.routeConfig.component.name + ":mapPoints-dataChanged", this.mapPoints, mapPointsNew)
      this.mapPoints = mapPointsNew;
    }


    // for (var i = this.mapPoints.features.length-1 ; i >=0 ; i--) {

    //   queryLoc = this.devicesAll.find(
    //       x => x.deviceLocation != null && 
    //       x.deviceLocation.uuid == this.mapPoints.features[i].properties.locationUuid && 
    //       x.uuid == this.mapPoints.features[i].properties.deviceUuid
    //     );

    //   if (!queryLoc) queryLoc = this.devicePrevLocsAll.find(
    //       x => x.uuid == this.mapPoints.features[i].properties.locationUuid &&
    //       this.mapPoints.features[i].properties.deviceUuid == ''
    //     );

    //   if (!queryLoc) this.mapPoints.features.splice(i,1)
    // }

    if (this.mapFirstLoad && this.mapPoints && this.mapPoints.features.length > 0) this.buttonMapFit(null)

    this.mapContainer.resize()
    //console.log(this.mapPoints)
    return
  }

  showFilterOptionsClick(event) {
    console.log(this.showFilterOptions);
    console.log(this.mapPoints);
    this.showFilterOptions = !this.showFilterOptions;
  }





  // showMarkers(displayPrevLoc) {
  //   let markerArray = [];

  //   if (displayPrevLoc && this.devicePrevLocsAll.length > 0) {
  //     for (let loc of this.devicePrevLocsAll) {
  //       if (loc.latitude != 0 && loc.longitude != 0) {
  //         let marker = L.marker([loc.latitude, loc.longitude]);
  //         let self = this;

  //         marker.on('click', function(e) {
  //             var uuidLoc = ""

  //             if (loc.uuid && loc.firmwareUuidTarget && loc.modelId &&
  //               loc.deviceLocation && loc.deviceLocation.latitude && loc.deviceLocation.longitude &&
  //               self.librariesService.guidValidate(loc.deviceLocation.uuid)) {
  //                 uuidLoc = loc.deviceLocation.uuid.toString()
  //             } else if (loc.uuid && loc.bearingId &&
  //               loc.latitude && loc.longitude && 
  //               self.librariesService.guidValidate(loc.uuid)) {
  //                 uuidLoc = loc.uuid.toString()
  //             }

  //             if (uuidLoc != "")
  //               self.router.navigate(['/charts', uuidLoc], { replaceUrl: false })
  //         });

  //         markerArray.push(marker);
  //       }
  //     }
  //   }

  //   if (this.devicesAll.length > 0) {
  //     for (let device of this.devicesAll) {
  //       if (device.deviceLocation != null && device.deviceLocation.latitude != 0 && device.deviceLocation.longitude != 0) {
  //         let icon = L.icon({iconUrl: this.mappingService.getMarkerTypeSvg(device.connectivityTypeId == 0 ? mapMarkerTypes.Wifi : mapMarkerTypes.Cellular)});
  //         let marker = L.marker([device.deviceLocation.latitude, device.deviceLocation.longitude], {icon: icon})

  //         marker.on('click', function(e) {
  //           var uuidLoc = ""

  //           if (device.uuid && device.firmwareUuidTarget && device.modelId &&
  //             device.deviceLocation && device.deviceLocation.latitude && device.deviceLocation.longitude &&
  //             self.librariesService.guidValidate(device.deviceLocation.uuid)) {
  //               uuidLoc = device.deviceLocation.uuid.toString()
  //           } else if (device.uuid && device.bearingId &&
  //             device.latitude && device.longitude && 
  //             self.librariesService.guidValidate(device.uuid)) {
  //               uuidLoc = device.uuid.toString()
  //           }

  //           if (uuidLoc != "")
  //             self.router.navigate(['/charts', uuidLoc], { replaceUrl: false })
  //         });

  //         markerArray.push(marker);
  //       }
  //     }
  //   }

  //   this.group = L.featureGroup(markerArray).addTo(this.map);

  //   var self = this;
  //   setTimeout(function () {
  //     self.map.fitBounds(self.group.getBounds());
  //     self.map.setZoom(self.roadZoom);
  //   }, 100);
  // }

  // mapMarkersDisplay(displayPrevLoc) {
  //   var newLocs: number = 0;

  //   this.mapPrevLocsAllRemove()
  //   this.mapPrevLocsAllGroup = new H.map.Group();

  //   this.mapWifiRemove()
  //   this.mapWifiGroup = new H.map.Group();

  //   this.mapCellularRemove()
  //   //this.mapCellularGroup = new H.map.Group();

  //   newLocs = 0;
  //   if (displayPrevLoc && this.devicePrevLocsAll.length > 0) {
  //     for (let loc of this.devicePrevLocsAll) {
  //       if (loc.latitude != 0 && loc.longitude != 0) {
  //         //alert(loc.latitude+","+loc.longitude)
  //         var icon = new H.map.Icon(this.mappingService.getMarkerTypeSvg(mapMarkerTypes.PreviousLocation))
  //         var coords = { lat: loc.latitude, lng: loc.longitude }
  //         var mrkr: H.map.Marker = new H.map.Marker(coords, { icon: icon })
  //         mrkr.setData(loc)

  //         this.mapPrevLocsAllGroup.addObject(mrkr)
  //         newLocs += 1
  //       }
  //     }
  //   }
  //   if (newLocs > 0) {
  //     this.map.addObject(this.mapPrevLocsAllGroup);
  //   } else this.mapPrevLocsAllGroup = null

  //   newLocs = 0;
  //   if (this.devicesAll.length > 0) {
  //     for (let device of this.devicesAll) {
  //       if (device.deviceLocation != null && device.deviceLocation.latitude != 0 && device.deviceLocation.longitude != 0) {
  //         //alert(loc.latitude+","+loc.longitude)
  //         var icon = new H.map.Icon(this.mappingService.getMarkerTypeSvg(device.connectivityTypeId == 0 ? mapMarkerTypes.Wifi : mapMarkerTypes.Cellular))
  //         var coords = { lat: device.deviceLocation.latitude, lng: device.deviceLocation.longitude }
  //         var mrkr: H.map.Marker = new H.map.Marker(coords, { icon: icon })
  //         mrkr.setData(device)
  //         mrkr.setZIndex(100);
  //         this.mapWifiGroup.addObject(mrkr)
  //         newLocs += 1
  //       }
  //     }
  //   }
  //   if (newLocs > 0) {
  //     this.map.addObject(this.mapWifiGroup);
  //   } else this.mapWifiGroup = null

  // }

  // onDeviceLocationSelect(event) {
  //   if (event) {
  //     var uuidLoc = ""

  //     if (event.uuid && event.firmwareUuidTarget && event.modelId &&
  //       event.deviceLocation && event.deviceLocation.latitude && event.deviceLocation.longitude &&
  //       this.librariesService.guidValidate(event.deviceLocation.uuid)) {
  //       uuidLoc = event.deviceLocation.uuid.toString()
  //     }
  //     else if (event.uuid && event.bearingId &&
  //       event.latitude && event.longitude &&
  //       this.librariesService.guidValidate(event.uuid)) {
  //       uuidLoc = event.uuid.toString()
  //     }

  //     if (uuidLoc != "")
  //       this.router.navigate(['/charts', uuidLoc], { replaceUrl: false })

  //     //var url = this.router.createUrlTree(['.',event.toString()], {relativeTo: this.route})
  //     //this.location.go(url.toString());
  //     //this.router.navigateByUrl('/devices/wifi/'+event.toString())
  //   }
  // }

}
