<div class="row">
  <div id="cardContainer" class="col-12">
    <div id="cardBase" [ngClass]="showChartOptionsSticky ? 'card bg-default card-header-sticky' : 'card bg-default'">
      <div id="cardBaseHeader" class="card-header">
        <div id="cardBaseHeaderRow" class="row">
          <div class="col-8">
            <h4 class="pt-2">Speed Bin Charts: {{
              fgChart.get('dateRange').value[0]==fgChart.get('dateRange').value[1] ?
              (fgChart.get('dateRange').value[0] | date:'MM-dd-yyyy') :
              (fgChart.get('dateRange').value[0] | date:'MM-dd-yyyy') +' to ' + (fgChart.get('dateRange').value[1] |
              date:'MM-dd-yyyy') }}
            </h4>
          </div>
          <div class="col-4 mb-2 float-right text-right">
            <button class="btn btn-primary" (click)="savePdf()">Save as PDF</button>
            <button type="button" class="btn btn-primary ml-2" (click)="showChartOptionsClick($event)"
              title="Show/Hide Chart Dispaly Options">
              <span [ngClass]="showChartOptions ? 'fas fa-chevron-down' : 'fas fa-chevron-left'"></span></button>
          </div>
        </div>

        <div *ngIf="showChartOptions" class="row card-body pl-3 pr-3 mt-0 pt-0">
          <form [formGroup]="fgChart" class="col-md-12 p-0">

            <div class="form-row row">
              <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                <label>Date Range</label>
                <div class="input-group">
                  <input readonly style="background-color: white;" class="form-control" #drp="bsDaterangepicker"
                    bsDaterangepicker [bsConfig]="{showWeekNumbers: false}" [(ngModel)]="fgChart.get('dateRange').value" formControlName="dateRange" />
                  <!-- ^^ Neeed to change this to use Reactive Forms -->
                  <div class="input-group-append">
                    <span class="input-group-text input-group-text-override fas fa-calendar btn btn-primary"
                      (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></span>
                  </div>

                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                <label>---</label>
                ---
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                <label>Additional Chart Options</label>
                <div class="mt-2">
                  <label class="checkbox c-checkbox" style="display: inline">
                    <input formControlName="binGroupsEnabled" type="checkbox" />
                    <span class="fas fa-check"></span>Speed-Bin Groupings</label>
                </div>
              </div>

            </div>
            <div *ngIf="fgChart.get('binGroupsEnabled').value" class="form-row row">
              <!-- ************************************* speed Bin-Groups ************************************* -->
              <div class="col-lg-12 col-md-12 col-sm-12 mt-1">
                <div class="row">
                  <div id=binGroupLeftDisplay class="binGroupLeftRightDisplayRadio col-4 text-left">
                    <label class="font-weight-bold">Left Speed Group (Applicable Charts)</label>
                    <div class="c-radio">
                      <label class="mr-2">
                        <input id="binGroupLeftDisplay1" type="radio" formControlName="binGroupLeftDisplay" value="0" />
                        <span class="fas fa-circle"></span>Hide</label>
                      <label class="mr-2">
                        <input id="binGroupLeftDisplay2" type="radio" formControlName="binGroupLeftDisplay" value="1" />
                        <span class="fas fa-circle"></span>Combine</label>
                      <label class="mr-2">
                        <input id="binGroupLeftDisplay3" type="radio" formControlName="binGroupLeftDisplay" value="2" />
                        <span class="fas fa-circle"></span>Each</label>
                    </div>
                  </div>
                  <div id=binGroupCenterDisplay class="binGroupCenterDisplayRadio col-4 text-center">
                    <label class="font-weight-bold">Center Speed Group (Applicable Charts)</label>
                    <div class="c-radio">
                      <label class="mr-2">
                        <input id="binGroupCenterDisplay1" type="radio" formControlName="binGroupCenterDisplay"
                          value="0" />
                        <span class="fas fa-circle"></span>Hide</label>
                      <label class="mr-2">
                        <input id="binGroupCenterDisplay2" type="radio" formControlName="binGroupCenterDisplay"
                          value="1" />
                        <span class="fas fa-circle"></span>Combine</label>
                      <label class="mr-2">
                        <input id="binGroupCenterDisplay3" type="radio" formControlName="binGroupCenterDisplay"
                          value="2" />
                        <span class="fas fa-circle"></span>Each</label>
                    </div>
                  </div>
                  <div id=binGroupRightDisplay class="binGroupLeftRightDisplayRadio col-4 text-right">
                    <label class="font-weight-bold">Right Speed Group (Applicable Charts)</label>
                    <div class="c-radio text-right">
                      <label class="mr-2">
                        <input id="binGroupRightDisplay1" type="radio" formControlName="binGroupRightDisplay"
                          value="0" />
                        <span class="fas fa-circle"></span>Hide</label>
                      <label class="mr-2">
                        <input id="binGroupRightDisplay2" type="radio" formControlName="binGroupRightDisplay"
                          value="1" />
                        <span class="fas fa-circle"></span>Combine</label>
                      <label class="">
                        <input id="binGroupRightDisplay3" type="radio" formControlName="binGroupRightDisplay"
                          value="2" />
                        <span class="fas fa-circle"></span>Each</label>
                    </div>
                  </div>
                </div>
                <ion-range-slider #sliderBinGroups id="sliderBinGroups" name="sliderBinGroups" type="double" min="0"
                  max="12" step="1" grid="true" grid_num="12" [from]="fgChart.get('binGroupSplitOne').value"
                  from_min="0" from_max="12" from_shadow="true" [to]="fgChart.get('binGroupSplitTwo').value" to_min="0"
                  to_max="12" to_shadow="false" prefix="" postfix="" decorate_both="true" [prettify]="speedBinsFormat"
                  (onUpdate)="updateSliderBinGroups(sliderBinGroups, $event)"
                  (onChange)="updateSliderBinGroups(sliderBinGroups, $event)"
                  (onFinish)="finishSliderBinGroups(sliderBinGroups, $event)">
                </ion-range-slider>
              </div>
            </div>

          </form>
        </div>

      </div>
    </div>

    <div id="chartOptionsStickySpacer" [style.display]="showChartOptionsSticky ? '' : 'none'"></div>


    <div id="chartSpeedBinsPercent" *ngIf="true" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 mt-3 pl-2 pr-2">
          <app-speedbins-percent-chart #chartSpeedBinsPercent [chartHeight]="500" [deviceLocation]="deviceLocation"
            [dateBegin]="fgChart.get('dateRange').value[0]" [dateEnd]="fgChart.get('dateRange').value[1]"
            [speedBinOneOption]="+(fgChart.get('binGroupLeftDisplay').value)"
            [speedBinBreakOne]="+(fgChart.get('binGroupSplitOne').value)"
            [speedBinTwoOption]="+(fgChart.get('binGroupCenterDisplay').value)"
            [speedBinBreakTwo]="+(fgChart.get('binGroupSplitTwo').value)"
            [speedBinThreeOption]="+(fgChart.get('binGroupRightDisplay').value)"
            (onChartJsEvent)="onChartJsEvent($event)">
          </app-speedbins-percent-chart>
        </div>
      </div>
    </div>

    <div id="chartSpeedBinsCount" *ngIf="true" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 mt-3 pl-2 pr-2">
          <app-speedbins-counts-chart #chartSpeedBinsCount [chartHeight]="500" [deviceLocation]="deviceLocation"
            [dateBegin]="fgChart.get('dateRange').value[0]" [dateEnd]="fgChart.get('dateRange').value[1]"
            [speedBinOneOption]="+(fgChart.get('binGroupLeftDisplay').value)"
            [speedBinBreakOne]="+(fgChart.get('binGroupSplitOne').value)"
            [speedBinTwoOption]="+(fgChart.get('binGroupCenterDisplay').value)"
            [speedBinBreakTwo]="+(fgChart.get('binGroupSplitTwo').value)"
            [speedBinThreeOption]="+(fgChart.get('binGroupRightDisplay').value)"
            (onChartJsEvent)="onChartJsEvent($event)">
          </app-speedbins-counts-chart>
        </div>
      </div>
    </div>

    <div id="chartSpeedBinsAverage85" *ngIf="true" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 mt-3 pl-2 pr-2">
          <app-speedbins-average85-chart #chartSpeedBinsAverage85 [chartHeight]="500" [deviceLocation]="deviceLocation"
            [dateBegin]="fgChart.get('dateRange').value[0]" [dateEnd]="fgChart.get('dateRange').value[1]"
            [speedBinOneOption]="+(fgChart.get('binGroupLeftDisplay').value)"
            [speedBinBreakOne]="+(fgChart.get('binGroupSplitOne').value)"
            [speedBinTwoOption]="+(fgChart.get('binGroupCenterDisplay').value)"
            [speedBinBreakTwo]="+(fgChart.get('binGroupSplitTwo').value)"
            [speedBinThreeOption]="+(fgChart.get('binGroupRightDisplay').value)"
            (onChartJsEvent)="onChartJsEvent($event)">
          </app-speedbins-average85-chart>
        </div>
      </div>
    </div>

    <div id="chartDailySpeedSummaryVehicleCounts" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 pl-2 pr-2">
          <app-daily-speedsummary-vehiclecounts-chart #chartDailySpeedSummaryVehicleCounts [chartHeight]="500"
            [deviceLocation]="deviceLocation" [device]="_device" [dateBegin]="fgChart.get('dateRange').value[0]"
            [dateEnd]="fgChart.get('dateRange').value[1]" (onChartJsEvent)="onChartJsEvent($event)">
          </app-daily-speedsummary-vehiclecounts-chart>
        </div>
      </div>
    </div>

    <div id="chartDailySpeedSummaryVehiclePercentages" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 pl-2 pr-2">
          <app-daily-speedsummary-vehiclepercentages-chart #chartDailySpeedSummaryVehiclePercentages [chartHeight]="500"
            [deviceLocation]="deviceLocation" [device]="_device" [dateBegin]="fgChart.get('dateRange').value[0]"
            [dateEnd]="fgChart.get('dateRange').value[1]" (onChartJsEvent)="onChartJsEvent($event)">
          </app-daily-speedsummary-vehiclepercentages-chart>
        </div>
      </div>
    </div>

  </div>
</div>