
import {filter,  takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceNotificationsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject } from 'rxjs';
import { DataArraysCompareOutput } from '../../../../models/DataArraysCompareOutput';
import { LibrariesService } from '../../../../core/libraries/libraries.service';

@Component({
  selector: 'app-alertsgrid',
  templateUrl: './alertsgrid.component.html',
  styleUrls: ['./alertsgrid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertsGridComponent implements OnInit, AfterViewInit, OnDestroy {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public deviceAlerts: any[] = [];
  public deviceAlertSelected: any[] = [];

  @Output() onDeviceAlertSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @Output() onDataLoading: EventEmitter<boolean> = new EventEmitter()
  public dataLoading:boolean = false

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public deviceNotificationsService: DeviceNotificationsService,
    public errorsService: ErrorsService,
    private router: Router) { }

  ngOnInit() {
  }
  
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this
    setTimeout(function () {
      self.getDeviceAlerts()
    }, 500);
  }

  getDeviceAlerts(): any {
    if (!this.settingsService.client) return;

    this.dataLoading = true;
    this.onDataLoading.emit(this.dataLoading)

    let today = new Date();
    let dateEnd: Date = today;
    let dateBegin: Date = new Date(dateEnd);
    dateBegin.setDate(dateBegin.getDate() - 30);

    return this.deviceNotificationsService.getCleared(dateBegin, dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          
          let dacOut:DataArraysCompareOutput = this.librariesService.dataArraysCompare(
            this.deviceAlerts,result,"id")

          if (this.deviceAlerts.length == 0 || dacOut.arrayAdded.length > 0 || dacOut.arrayDeleted.length > 0)
          {
            if (this.deviceAlerts.length > 0) console.log('deviceAlerts changed',this.deviceAlerts[0]['id'],dacOut)
            this.deviceAlerts = result;
          }

          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
        },
        error => {
          this.notificationsService.error("Server Error (getDeviceAlerts)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
        });
  }

  onSelect({ selected }) {
    this.deviceAlertSelected.splice(0, this.deviceAlertSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onDeviceAlertSelected.emit(selected[0]);
      this.router.navigate(['/devices/', selected[0].uuid])
    }
  }
}
