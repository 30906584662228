<div class="card">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-6 align-middle">
                <input type='text' class="form-control"
                    placeholder='Type to filter by Device Model, Serial, or Name/Label...'
                    (keyup)='updateFilter($event)' />
            </div>
        </div>

        <ngx-datatable #table class='bootstrap no-detail-row' [columnMode]="'flex'" [footerHeight]="20"
            [rowHeight]="'auto'" [limit]="250" [rows]='myResponses'>

            <ngx-datatable-column [resizeable]="false" name="Serial" [flexGrow]="1" >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div><a href="/administration/devices/{{row.deviceUuid}}"
                            style="color: grey; cursor: grab;">{{getDeviceSerial(row)}}</a></div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Firmware Description" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.firmwareCode}}</div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Token Requests" [sortable]=true prop=tokenRequestCount
                [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                        {{"Created:    " + row.tokenCreatedDate.replace("T", " ").replace("Z", "")}}<br>{{"Request: " + row.tokenRequestDate.replace("T", " ").replace("Z", "")}}<br>{{"Token Requests: " + row.tokenRequestCount}}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Hello/Goobye Date" [flexGrow]="1" prop=helloDate
                [sortable]=true *ngIf="goodbyeDate !=null">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                        {{"HD:  " + row.helloDate.replace("T", " ").replace("Z", "")}}<br>{{"GD: " + row.goodbyeDate.replace("T", " ").replace("Z", "")}}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Hello/Goobye Date" prop=helloDate [flexGrow]="1"
                [sortable]=true *ngIf="goodbyeDate ==null">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                        {{"HD:  " + row.helloDate.replace("T", " ").replace("Z", "")}}<br> {{"GD: " + row.goodbyeDate}}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Hello/Goodbye Data" [flexGrow]="2">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.helloData}}<br>{{row.goodbyeData}}</div>
                </ng-template>
            </ngx-datatable-column>

        </ngx-datatable>

    </div>
</div>