<div class="newpassword-bg"></div>
<div class="newpassword-header">
    <div class="container row no-gutters">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-sm-8 col-md-7 col-lg-5 col-xl-5">
            <img src="assets/img/rs-logo-white.svg" class="newpassword-header-logo">
        </div>
        <div class="col-sm-2 col-md-3 col-lg-5 col-xl-5"></div>
    </div>
</div>
<div class="container newpassword-container">
    <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-sm-8 col-md-7 col-lg-5 col-xl-5">
            <div class="newpassword-form">
                <h2>Reset Password</h2>
                
                <form [formGroup]="fgNewPassword" name="form" (ngSubmit)="f.form.valid && reset()" #f="ngForm" novalidate style="opacity: 1;">
                            <div class="form-group" class="form-group" >
                                    <label for="newpassword">Enter New Password</label>
                                    <input [class]="librariesService.controlValidationClass(fgNewPassword.get('newpassword'))"  id="newpassword" name="newpassword" formControlName="newpassword" type="password" class="form-control" placeholder="Minimum of 8 Characters Including Uppercase, Lowercase and Number" autocomplete="new-password"/>
                                    <div *ngIf="librariesService.controlInvalidFlag(fgNewPassword.get('newpassword'))" class="invalid-feedback" >Minimum of 8 Characters Including Uppercase, Lowercase and Number</div>
                            </div>
                            <div class="form-group" >
                                    <label for="confirmpassword">Confirm New Password</label>
                                    <input [class]="librariesService.controlValidationClass(fgNewPassword.get('confirmpassword'))"  id="confirmpassword" name="confirmpassword" formControlName="confirmpassword" class="form-control" type="password" autocomplete="new-password" />
                                    <div *ngIf="librariesService.controlInvalidFlag(fgNewPassword.get('confirmpassword'))" class="invalid-feedback">Does
                                    not match Password</div>
                            </div>
                            <div>
                                    <button (click)="back($event)" class="btn btn-primary">Back</button>
                                    <div class="float-right text-right">
                                        <button [disabled]="!fgNewPassword.get('confirmpassword').valid" (click)="reset" class="btn btn-primary">Submit</button>
                                    </div>
                            </div>
                </form>

            </div>
        </div>
        <div class="col-sm-2 col-md-3 col-lg-5 col-xl-5"></div>
    </div>
</div>
<div class="newpassword-footer">
    <div class="row no-gutters">
        <div class="col-2"></div>
        <div class="col-8">{{ settingsService.app.name }} - {{ settingsService.app.description }} &copy; {{settingsService.app.year}}</div>
        <div class="col-2"></div>
    </div>
</div>