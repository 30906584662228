import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class DeviceTelemetriesService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient) {
    super(router, http, 'devicetelemetries');
  }

  read() {
    return this.all();
  }

  readByUuid(uuid: string) {
    return this.get(uuid);
  }

  readByDeviceUuidDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  readByDeviceUuidRecent(
    uuid: string,
    sortDescending: Boolean): Observable<any> {
    let apiParam = `${uuid}/recent`;
    apiParam = apiParam + `/${sortDescending}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  readByDeviceUuidRecentCount(
    uuid: string,
    sortDescending: Boolean,
    count: number): Observable<any> {
    let apiParam = `${uuid}/recent`;
    apiParam = apiParam + `/${sortDescending}`;
    apiParam = apiParam + `/${count}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

}
