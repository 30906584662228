import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Enumerated list of event types. Payload notes in comments on each line
export enum EventTypes {
  //Examples:
  //error, //text of error
  //toasterCreateOk, //text of toaster
  //toasterCreateWarning, //text of toaster
  //toasterCreateError, //text of toaster
  UserLogin, //null payload
  UserLogout, //null payload
  DayScheduleDefaultConfigApplied, //Config Uuid payload
}

export interface Event {
  type: EventTypes;
  payload: any;
}

@Injectable()
export class EventsService {
  public eventTypes = EventTypes;
  public events: EventEmitter<Event> = new EventEmitter();

  private dataRefreshSubject = new BehaviorSubject<void>(null);

  dataRefresh$ = this.dataRefreshSubject.asObservable();

  constructor() {
  }

  sendEvent(eventType: EventTypes, eventPayload: any) {
  
      let newEvent: Event = {
          type: eventType,
          payload: eventPayload
      }

      console.log("Event",newEvent)
      this.events.emit(newEvent);
  }

  eventIsType(event: Event, eventType: EventTypes):boolean {
      //compare type and perform validation if needed (none needed yet)

      if (event.type == eventType) return true;
      return false;

  }

  triggerDataRefresh() {
    this.dataRefreshSubject.next();
  }

}