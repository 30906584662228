<div class="row">
  <form [formGroup]="fgData" class="col-12 float-right text-right">
      <table class="table m-0 p-0 border-0">
          <tbody>
              <tr>
                <i class="fas fa-file-excel pt-2 fa-2x" title="Download xlsx" (click)="downloadExcel()"></i>
                <i class="fas fa-analytics pt-2 fa-2x ml-2" title="Graph" (click)="createLineGraph()"></i>
                  <td class="m-0 p-0 border-0 float-right">
                      <div class="input-group">
                          <div class="input-group-append">
                              <span
                                  class="input-group-text input-group-text-override fas fa-calendar btn btn-primary ml-2"
                                  (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></span>

                              <input readonly style="background-color: white;" class="date-range"
                                  #drp="bsDaterangepicker" bsDaterangepicker [bsConfig]="{showWeekNumbers: false}" [(ngModel)]="fgData.value.dateRange"
                                  formControlName="dateRange" />
                          </div>

                          <button type="button" class="btn btn-primary m1-2" (click)="getDataForLastMonth()">
                              30 Days
                          </button>
                          <!-- <button type="button" class="btn btn-primary ml-2" (click)="getDataForLastWeek()">
                              7 Days
                          </button> -->
                          <button type="button" class="btn btn-primary ml-2" (click)="buttonRefresh($event)">
                              <span class="fas fa-sync-alt"></span>
                          </button>
                      </div>
                  </td>
              </tr>

          </tbody>
      </table>
  </form>
</div>

<ag-grid-angular name="dataTable" style="height: 70vh; width: 100%;" class="ag-theme-alpine dataTable m-0 pt-2 border-0"
    [animateRows]="true" [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="deviceData" [chartThemes]="chartThemes"
     [gridOptions]="gridOptions" [getChartToolbarItems]="getChartToolbarItems">
</ag-grid-angular>