import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class ClientsService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'clients');
  }

  create(client: any): Observable<any> {
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/register`;
    //var headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    return this.http.post<any>(
      apiParam,
      client
    );
  }

  read() {
    return this.all();
  }

  readByUuid(uuid: string) {
    return this.get(uuid);
  }

}
