
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild, Input, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AgGridModule } from 'ag-grid-angular';
import { GridOptions, ChartType, ChartOptions } from 'ag-grid-community';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DevicesService } from '../../../../core/api/api.services';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay } from 'lodash';

@Component({
  selector: 'app-deviceconnectionsview',
  templateUrl: './deviceconnectionsview.component.html',
  styleUrls: ['./deviceconnectionsview.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeviceConnectionsViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onDeviceConnectionsSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('deviceConnectionsTable') dataView: DatatableComponent;

  @Input() public dateBegin: Date = null;
  @Input() public dateEnd: Date = null;
  @Input() public showFirmware: boolean = false;
  @Input() public lteMode: boolean = false;
  @Input() public timeSegment = 0;

  //Observables
  public gridOptions: GridOptions;
  public chartThemes;
  public chartThemeOverrides;
  public popupParent;
  public sidebar;
  private onDestroy$: Subject<void> = new Subject<void>();
  public getTimeNow: boolean = true;

  public deviceConnectionsTemp: any[] = [];
  public lteData: any[] = [];
  public deviceConnections: any[] = [];
  public deviceConnectionsSelected: any[] = [];
  private paramSub: any;

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  private _deviceUuid = '';

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  columnDefs = [
    {
      headerName: 'Authenticated', field: 'tokenCreatedDate', chartDataType: 'time', hide: true, sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (new Date(data.value)).toLocaleString()
          : '') : '';
      }
    },
    {
      headerName: 'Connected', field: 'helloDate', chartDataType: 'time', sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (new Date(data.value)).toLocaleString()
          : '') : '';
      }
    },
    { headerName: 'Rssi', field: 'helloData.rssi', chartDataType: 'series', sortable: true, tooltipField: 'helloData.rssi', hide: true, resizable: true, maxWidth: 150 },
    { headerName: 'Csq Att.', field: 'helloData.quality.csq', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Modem Init Att.', field: 'helloData.quality.at', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'SimC Init Att.', field: 'helloData.quality.sim', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Battery Voltage', field: 'helloData.power.bV', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true, 
      cellRenderer: (data) => {
        return this.paddingZeros(data);
      } },
    { headerName: 'Battery Current', field: 'helloData.power.bI', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true, 
      cellRenderer: (data) => {
        return this.paddingZeros(data);
      } },
    { headerName: 'Solar Voltage', field: 'helloData.power.sV', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true, 
      cellRenderer: (data) => {
        return this.paddingZeros(data);
      } },
    { headerName: 'Solar Current', field: 'helloData.power.sI', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true, 
      cellRenderer: (data) => {
        return this.paddingZeros(data);
      } },
    { headerName: 'Lte Rssi', field: 'helloData.extra.lteRssi', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Lte Rsrp', field: 'helloData.extra.lteRsrp', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Lte Sinr', field: 'helloData.extra.lteSinr', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Lte Rsrq', field: 'helloData.extra.lteRsrq', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'AccessTech', field: 'helloData.extra.att', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Network Operator', field: 'helloData.extra.net', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Lte Band', field: 'helloData.extra.band', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Channel Id', field: 'helloData.extra.cid', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Full Network', field: 'helloData.extra.fnn', sortable: true, hide: true, resizable: true, maxWidth: 150, suppressMenu: true },
    { headerName: 'Firmware', field: 'firmwareCode', sortable: true, resizable: true, maxWidth: 150, suppressColumnsToolPanel: true, suppressMenu: true },
    { headerName: 'Transactions', field: 'tokenRequestCount', sortable: true, resizable: true, maxWidth: 150, suppressMenu: true },
    {
      headerName: 'Last Request', field: 'tokenRequestDate', sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (new Date(data.value)).toLocaleString()
          : '') : '';
      }
    },
    {
      headerName: 'Disconnected', field: 'goodbyeDate', sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (new Date(data.value)).toLocaleString()
          : '') : '';
      }
    },
    {
      headerName: 'Next Connection', field: 'nextActivityDate', sortable: true, resizable: true, maxWidth: 300, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (new Date(data.value)).toLocaleString()
          : '') : '';
      }
    },
    { headerName: "", sortable: false, resizable: false, flex: 2, suppressColumnsToolPanel: true, suppressMenu: true }

  ];

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',

        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true

        }
      }
    ]
  }

  valueGetter = function (params) {

    return params.node.rowIndex;

  }

  get deviceUuid(): string {
    return this._deviceUuid;
  }
  set deviceUuid(newDeviceUuid: string) {
    if (this.librariesService.guidValidate(newDeviceUuid)) {
      this._deviceUuid = newDeviceUuid;
      this.getDeviceConnections();
    } else {
      this._deviceUuid = '';
      this.deviceConnectionsTemp = [];
      this.deviceConnections = [];
      this.deviceConnectionsSelected = [];
    }
  }

  public fgData: FormGroup = this.formBuilder.group({
    dateRange: [new Date(), new Date()]
  });

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public deviceService: DevicesService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
    this.chartThemes = [
      'ag-default',

    ];
    this.chartThemeOverrides = {
      line: {
        series: {
          lineDash: [1, 1],
          tooltip: {
            title: {
              enabled: true,

            },
            enabled: true,
            renderer: function (params) {
              let date = new Date(params.xValue);
              return (
                '<div class= "ag-chart-tooltip-title" style="background-color:' + params.color + '">' +
                params.yName +
                '</div>' +
                '<div class= "ag-chart-tooltip-content">' +
                date.toLocaleDateString() + "-" + date.toLocaleTimeString() + "<br>" +
                "Value : " + "&nbsp" + params.yValue +
                '</div>'
              );
            },
          },
          marker: {
            enabled: true,
            size: 4,
            opacity: 1
          },
        },
        axes: {
          time: {
            label: {
              formatter: params => {
                Date.parse(params)
                return this.datePipe.transform(new Date(params.value), "MM-dd-yyyy")
              },
              rotation: -70,
              fontSize: 12,
            },
          },
          tick: {
            size: 10
          },
        },

        navigator: {
          enabled: true,
          height: 20,
          width: 100,
          margin: 10,
          mask: {
            fill: '#046b32'
          }
        },
      },
      scatter: {
        data: this.gridOptions,
        series: {
          paired: false,
          xKey: 'helloDate',
          yKey: 'helloData.rssi'

        },
        axes: {
          time: {
            position: 'bottom',
            label: {
              formatter: params => {
                return this.datePipe.transform(new Date(params.value), "MM-dd-yyyy")
              },
              rotation: -70,
              fontSize: 10,
            },
            title: {
              text: 'Time',
              enabled: true
            },
          },
          category: {
            title: {
              text: 'test1',
              enabled: true,
            }
          }
        },
      }
    }
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      rowSelection: "multiple",
      sideBar: this.sideBar,
      enableCharts: true,
      enableRangeSelection: true,
    }
    if (sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')) {
      this.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
      this.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
      this.dateBegin.toLocaleString();
      this.dateEnd.toLocaleString();
      this.fgData.patchValue({
        dateRange: [this.dateBegin, this.dateEnd]
      })
    }
    else {
      this.setLastDates(7)
    }
  }

  ngOnInit() {
    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.deviceUuid = params['uuid'];
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    if (this.showFirmware == false) {
      this.gridOptions.columnApi.setColumnVisible('firmwareCode', false);

    }
    if (this.lteMode) {
      this.gridOptions.columnApi.setColumnVisible('firmwareCode', false);
      this.gridOptions.columnApi.setColumnVisible('helloData.quality.csq', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.rssi', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.quality.at', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.quality.sim', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.power.bV', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.power.bI', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.power.sV', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.power.sI', true);
      this.gridOptions.columnApi.setColumnVisible('tokenCreatedDate', false);
      this.gridOptions.columnApi.setColumnVisible('tokenRequestDate', false);
      this.gridOptions.columnApi.setColumnVisible('nextActivityDate', false);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.lteRssi', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.lteRsrp', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.lteSinr', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.lteRsrq', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.att', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.net', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.band', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.cid', true);
      this.gridOptions.columnApi.setColumnVisible('helloData.extra.fnn', true);

    }
    else {

      this.gridOptions.api.setSideBarVisible(false)

    }
    // this.dataTableEventsInitialize();
    self.fgData.get('dateRange').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        sessionStorage.setItem('dateBegin', this.fgData.get('dateRange').value[0])
        sessionStorage.setItem('dateEnd', this.fgData.get('dateRange').value[1])
        self.getDeviceConnections();
      });

    setTimeout(() => {
      self.getDeviceConnections();
    }, 500);
  }

  // dataTableEventsInitialize() {
  //   var self = this;
  //   window.addEventListener('resize', function () {
  //     self.dataPeriodSummariesResize();
  //   });
  // }

  // dataPeriodSummariesResize() {
  //   console.log('dataPeriodSummariesResize');

  //   if (document.getElementById('footerContainer') == null)
  //     return;

  //   var dataPeriodSummariesHeight: number =
  //     document.body.clientHeight - 30 -
  //     (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[0]).getBoundingClientRect().top -
  //     (document.getElementById('footerContainer').getBoundingClientRect().bottom -
  //       document.getElementById('footerContainer').getBoundingClientRect().top)

  //   dataPeriodSummariesHeight = dataPeriodSummariesHeight.round(0);
  //   if (dataPeriodSummariesHeight <= 400) {
  //     return
  //   }

  //   (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[0]).style.height = dataPeriodSummariesHeight.toString() + 'px';
  //   console.log('!!!', (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[0]).style.height);

  // }

  refreshGrid() {
    this.autoSizeColumns();
  }


  autoSizeColumns() {
    // if (this.gridOptions) return

    let allColumnIds = [];
    this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);

  }

  getDeviceConnections(): any {
    this.deviceConnectionsTemp = [];
    this.deviceConnections = [];
    this.deviceConnectionsSelected = [];




    if (sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')) {
      this.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
      this.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
      this.dateBegin.toLocaleString();
      this.dateEnd.toLocaleString();
      this.fgData.get('dateRange').value[0] = this.dateBegin;
      this.fgData.get('dateRange').value[1] = this.dateEnd;

      let dateTestEnd: Date = new Date();
      if (dateTestEnd.getMonth() == this.dateEnd.getMonth() && (dateTestEnd.getDay() == this.dateEnd.getDay() || this.dateEnd.getDay() > dateTestEnd.getDay()) ) {
        this.getTimeNow = true;
      }
      else {
        this.getTimeNow = false;
      }

    }
    else {
      this.dateBegin = this.fgData.get('dateRange').value[0];
      this.dateEnd = this.fgData.get('dateRange').value[1];
      this.getTimeNow = true;
    }


    let timeDiff = this.dateEnd.getTime() - this.dateBegin.getTime();
    let daysDiff = (timeDiff / (1000 * 3600 * 24)).round(0)

    if (daysDiff > 30) {
      this.notificationsService.warn("", "Date Period should be less or equal 30 days", { timeOut: 15000, clickToClose: true });

      const dateEndNew = new Date(this.dateEnd);
      const dateStartNew = new Date(this.dateEnd.setDate(this.dateEnd.getDate() - 30));

      this.fgData.get('dateRange').value[1] = dateEndNew;
      this.fgData.get('dateRange').value[0] = dateStartNew;

      this.fgData.patchValue({
        dateRange: [dateStartNew, dateEndNew]
      });
    }

    if (!this.settingsService.client || !this.deviceUuid) {
      return;
    }

    return this.deviceService.readConnectionsByUuidAndDateRange(this.deviceUuid, this.dateBegin, this.dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any[]) => {
          sessionStorage.setItem('dateBegin', this.dateBegin.toString());
          sessionStorage.setItem('dateEnd', this.dateEnd.toString());
          //decsending sort
          var sortedResult = result.sort((a, b) => a.tokenCreatedDate > b.tokenCreatedDate ? -1 : a.tokenCreatedDate < b.tokenCreatedDate ? 1 : 0)

          this.deviceConnections = sortedResult;
          this.deviceConnectionsTemp = sortedResult;
          if (this.deviceConnections.length > 0) {
            for (var i = 0; i < this.deviceConnections.length; i++) {
              if (this.deviceConnections[i].helloData == "Invalid or Missing JSON") {
                console.log('Missing or invalid JSON @index ' + i)
              }
              else {
                this.deviceConnections[i].helloData = JSON.parse(this.deviceConnections[i].helloData);
                this.deviceConnections[i].goodbyeData = JSON.parse(this.deviceConnections[i].goodbyeData);
                this.deviceConnections[i].helloDate = Date.parse(this.deviceConnections[i].helloDate)
                if (this.deviceConnections[i].helloData.rssi >= 99) {
                  this.deviceConnections[i].helloData.rssi = 0;
                }
                if (this.deviceConnections[i].helloData.extra) {
                  if (this.deviceConnections[i].helloData.extra.lteRssi < -500 || this.deviceConnections[i].helloData.extra.lteRsrp < -500 || this.deviceConnections[i].helloData.extra.lteRsrq < -500) {
                    this.deviceConnections[i].helloData.extra.lteRssi = 0;
                    this.deviceConnections[i].helloData.extra.lteRsrp = 0;
                    this.deviceConnections[i].helloData.extra.lteRsrq = 0;
                  }
                }
              }
            }
          }

        },
        error => {
          this.notificationsService.error('Server Error (getDeviceConnections)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  createLineGraph() {
    console.log('minMax Creation');
    let params = {
      cellRange: {
        columns: ['helloDate', 'helloData.rssi', 'helloData.power.bV', 'helloData.quality.at', "helloData.quality.csq", "helloData.quality.sim",
          "helloData.extra.lteRssi", "helloData.extra.lteRsrq", "helloData.extra.lteRsrp", "helloData.extra.lteSinr", "helloData.power.bI", "helloData.power.sV", "helloData.power.sI"]
      },
      chartType: ChartType.Line,
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: "LTE Log Data"
          },
        },
      },
    };

    this.gridOptions.api.createRangeChart(params)
  }

  downloadExcel() {
    let today = new Date();
    let dateString = this.datePipe.transform(new Date(today), "MM-dd-yy");
    let params = {
      columnKeys: ['helloDate', 'tokenRequestCount', 'tokenRequestDate', 'goodbyeDate', 'nextActivityDate'],
      fileName: 'Connections ' + dateString,
      processCellCallback: this.myCellCallback,
    }
    this.gridOptions.api.exportDataAsExcel(params)
  }

  myCellCallback(params) {
    if (params.value > 10000) {
      let formattedCell = new Date(params.value).toLocaleString();
      params = formattedCell;
      return params;
    }
    return params.value
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }


  onSelect({ selected }) {
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onDeviceConnectionsSelected.emit(selected[0].id);
    }
  }


  buttonSave(event) {
  }

  buttonCancel(event) {
  }

  buttonRefresh(event) {
    if (this.getTimeNow === true) {
      this.setLastDates(30);
      this.getDeviceConnections();
    }
    else if (this.getTimeNow === false) {
      this.getDeviceConnections();
    }
  }

  getDataForLastWeek() {
    this.setLastDates(7);
    this.getDeviceConnections();
  }

  getDataForLastMonth() {
    this.setLastDates(30);
    this.getDeviceConnections();
  }


  setLastDates(days: number) {
    let dateBegin: Date = new Date();
    dateBegin.setDate(dateBegin.getDate() - days);

    this.fgData.patchValue({
      dateRange: [dateBegin, new Date()]
    });
  }


  /* paddingZeros() takes a number and makes it a float with at least three decimal places via toFixed().
     The rest checks to see if there are more or less than three decimal places.
     If there are more, it cuts off at three and less, it adds zeroes until there are three decimal places.
     It consoles an error if the data is NaN and therefore leaves the table cell blank. */

  paddingZeros(data) {
    try {
      let originalData = data.value.toFixed(3);
        originalData = originalData.toString();
        if(originalData.indexOf(".") !== -1){
          let splitData =  originalData.split(".");
          if(splitData[1].length > 3) {
            let newSplitData = splitData[1].slice(0, 3);
            originalData = splitData[0] + "." + newSplitData;
            return originalData;
          }
          return splitData[1].length < 1 ? originalData + ".000" : 
              splitData[1].length === 1 ? originalData + "00" : 
                splitData[1].length === 2 ? originalData + "0" : originalData;
        }
        return originalData.indexOf(".") === -1 ? originalData + ".000" : originalData;
    } catch (error) {
      console.log("No valid power sensor data.");
    }
    
  }
}
