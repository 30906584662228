<div class="card card-border-gray bg-default">
  <div class="card-header card-header-gray">
    <div class="row">
      <div class="col-12">
        <h4 class="mt-0 mb-0">Selected Devices</h4>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row" *ngIf="isEditMode && (!deviceSelected || deviceSelected.length==0)">
      <div class="col-12 text-center text-danger">Please Select Devices To Enabled Configuration</div>
    </div>
    <div class="row">
      <div class="col-12">
        <ngx-datatable #devicesSelectedTable class='bootstrap no-detail-row' (select)='onSelect($event)'
          [selectionType]="'single'" [selected]="deviceSelectedSelected" [columnMode]="'force'" [footerHeight]="20"
          [rowHeight]="'auto'" [limit]="20" [rows]='deviceSelected'>

          <ngx-datatable-column [resizeable]="false" maxWidth="30" name="" minWidth="30">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div [hidden]="!isEditMode"><i class="far fa-minus-square red" (click)="removeSelectedDevice()"></i></div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [resizeable]="false" name="Device Label" prop="label">
          </ngx-datatable-column>
          <ngx-datatable-column [resizeable]="false" name="Serial" prop="serial"></ngx-datatable-column>
          <ngx-datatable-column [resizeable]="false" name="Model" prop="modelCode">
          </ngx-datatable-column>
          <ngx-datatable-column [resizeable]="false" name="Location" prop="deviceLocation.name">
          </ngx-datatable-column>
          <ngx-datatable-column [resizeable]="false" name="Options" prop="configOptions">
          </ngx-datatable-column>

        </ngx-datatable>
      </div>
    </div>
  </div>

</div>