import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class VerificationsService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient, 
  ) {
    super(router, http, 'verifications');
  }


  verify(): Observable<any> {
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/mobile-verify`;
   
    return this.http.post<any>(
      apiParam,
      {value: ''},
    );
  }

  confirm(code: string): Observable<any>{
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/mobile-verify-confirm`;
    return this.http.post<any>(
        apiParam,
        {code},
      );
    }
    
  }
