<div class="login-bg"></div>
<div class="login-header">
    <div class="container row no-gutters">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-sm-8 col-md-7 col-lg-5 col-xl-5">
            <img src="assets/img/rs-logo-white.svg" class="login-header-logo">
        </div>
        <div class="col-sm-2 col-md-3 col-lg-5 col-xl-5"></div>
    </div>
</div>
<div [ngClass]="{'container': true, 'login-container': !isIE(), 'login-container-ie': isIE()}">
    <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-sm-8 col-md-7 col-lg-5 col-xl-5">
            <div class="login-form">
                <h2>Login</h2>
                <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate style="opacity: 1;">
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                        <label for="username">Email Address</label>
                        <input type="text" class="form-control" name="username" [(ngModel)]="model.username"
                            #username="ngModel" required />
                        <div *ngIf="f.submitted && !username.valid" class="help-block">Email Address is required</div>
                    </div>
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                        <label for="password">Password</label>
                        <input type="password" class="form-control" name="password" [(ngModel)]="model.password"
                            #password="ngModel" required />
                        <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
                    </div>
                    <div class="">
                        <button [disabled]="loading" class="btn btn-primary">Login</button>
                        <img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        <div class="float-right text-right">
                            <a [routerLink]="['/resetpassword']" class="btn btn-link">Forgot Password?</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-2 col-md-3 col-lg-5 col-xl-5"></div>
    </div>
    <div class="row mt-4" *ngIf="isIE()">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-sm-8 col-md-7 col-lg-5 col-xl-5">
            <div class="warning-card">
                <span class="fas fa-exclamation-triangle text-danger"></span><span class="font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;Browser&nbsp;Alert</span><br/>
                <div class="pt-1 pb-2">Your browser is out of date and unsupported by Microsoft.  Please switch to the new and improved Microsoft Edge.</div>
                <div class="text-right"><a href="https://www.microsoft.com/edge" target="_blank"><div class="btn btn-dark">Switch Now</div></a></div>     
            </div>
        </div>
        <div class="col-sm-2 col-md-3 col-lg-5 col-xl-5"></div>
    </div>
</div>
<div class="login-footer">
    <div class="row no-gutters">
        <div class="col-2"></div>
        <div class="col-8">{{ settingsService.app.name }} - {{ settingsService.app.description }} &copy;
            {{settingsService.app.year}}</div>
        <div class="col-2"></div>
    </div>
</div>