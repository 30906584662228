
import { filter, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import {
  DevicesService,
  DeviceSchedulesService,
  FirmwaresService,
  ModelsService,
  DeviceDailySummariesService,
  DeviceLocationsService
} from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { DeviceLocationsViewComponent } from '../../views/devicelocationsview/devicelocationsview.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { DeviceCrudDto } from '../../../../models/device';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { ColorsService } from '../../../../core/colors/colors.service';
import { Subject } from 'rxjs';


export enum DeviceLocationStatusCardDataTypes {
  current = 0,
  lastAvailable = 1
}

@Component({
  selector: 'app-device-status-card',
  templateUrl: './device-status-card.component.html',
  styleUrls: ['./device-status-card.component.scss']
})

export class DeviceStatusCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEventDevice: EventEmitter<any> = new EventEmitter<any>();

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public formLoaded: boolean = false;

  private _deviceUuid: string = null;
  private _deviceLocationUuid: string = null;
  get deviceLocationUuid(): string {
    return this._deviceLocationUuid;
  }
  @Input() set deviceLocationUuid(newDeviceLocationUuid: string) {
    if (this.librariesService.guidValidate(newDeviceLocationUuid)) {
      this._deviceLocationUuid = newDeviceLocationUuid;
      if (this.formLoaded) {
        this.getDeviceLocation();
      }
    }
    else {
      this._deviceLocationUuid = null;
    }
  }

  private _minimumPadding: boolean = false;
  get minimumPadding(): boolean {
    return this._minimumPadding;
  }


  public dataTypes = DeviceLocationStatusCardDataTypes;
  private _chartDataType: DeviceLocationStatusCardDataTypes = DeviceLocationStatusCardDataTypes.current;
  get dataType(): DeviceLocationStatusCardDataTypes {
    if (this.router.url.toLowerCase().split("?")[0] == '/map') {
      this._chartDataType = this.dataTypes.lastAvailable;
      return this._chartDataType;
    }
    else {
      return this._chartDataType;
    }
  }

  @Input() set minimumPadding(boolValue: boolean) {
    this._minimumPadding = boolValue;
  }


  @Output() chartData: any =
    [{
      "label": "vehicles",
      "color": "#768294",
      "data": [
        [0, null], [1, null], [2, null], [3, null], [4, null], [5, null], [6, null], [7, null], [8, null]
      ]
    }, {
      "label": "Violators",
      "color": this.colorsService.byName('danger'),
      "data": [
        [0.5, null], [1.5, null], [2.5, null], [3.5, null], [4.5, null], [5.5, null], [6.5, null], [7.5, null], [8.5, null]
      ]
    }
    ];

  @Output() chartOptions = {
    series: {
      bars: {
        align: 'left',
        show: true,
        fill: 0.8,
        barWidth: 0.9 / this.chartData.length,
        lineWidth: 2
      },
      // points: {
      //   show: true,
      //   radius: 1
      // },
      // splines: {
      //     show: true,
      //     tension: 0.4,
      //     lineWidth: 1,
      //     fill: 0.5
      // }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 5,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => { return this.decimalPipe.transform(y, '1.0') }
    },
    xaxis: {
      ticks: [[1, "--"], [2, "--"], [3, "--"], [4, "--"], [5, "--"], [6, "--"], [7, "--"], [8, "--"]],
      // tickLength: 0,
      //min: -0.2,
      // max: 2.7,
      tickColor: '#fcfcfc',
      // tickFormatter: (v, axis) => {
      //   if (!v || v == 0) return "----"
      //   return this.datePipe.transform(new Date(v), 'MM/dd')
      // },
      font: {
        size: 8,
        lineHeight: 13,
        style: "italic",
        weight: "bold",
        family: "sans-serif",
        variant: "small-caps",
        color: "#545454"
      },

    },
    yaxis: {
      min: 0,
      max: 1000, // optional: use it for a clear represetation
      tickColor: '#eee',
      tickLength: 0,
      // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
      tickFormatter: (v) => { return this.decimalPipe.transform(v, '1.0') },
      font: {
        size: 8,
        lineHeight: 13,
        style: "italic",
        weight: "bold",
        family: "sans-serif",
        variant: "small-caps",
        color: "#545454"
      }
    },

    legend: {
      show: false,
      //labelFormatter: null or (fn: string, series object -> string),
      //labelBoxBorderColor: color,
      noColumns: 2,
      //position: "ne" or "nw" or "se" or "sw",
      margin: [0, 0], //number of pixels or [x margin, y margin],
      //backgroundColor: null or color,
      //backgroundOpacity: number between 0 and 1,
      container: "#violatorsLegend", //null or jQuery object/DOM element/jQuery expression,
      //sorted: null/false, true, "ascending", "descending", "reverse", or a comparator
    },
    shadowSize: 0
  };

  public device: any = null; // new DeviceCrudDto();
  public deviceLocation: any = null;
  private cardUuid: string = "";

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public devicesService: DevicesService,
    public deviceLocationsService: DeviceLocationsService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public colorsService: ColorsService,
    private router: Router,
  ) { }


  ngOnInit() {
    this.cardUuid = this.librariesService.guidCreate();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.loadFormData()
  }

  loadFormData() {
    let self = this
    setTimeout(function () {
      // self.getModels();
      // self.getFirmwares();
      // self.getdeviceSchedules();
      if (self.deviceLocation == null) {
        self.getDeviceLocation();
      }
      self.formLoaded = true;
    }, 100);
  }

  getDeviceLocation(): any {
    console.log(this.dataType)
    this.deviceLocation = null;

    if (!this.settingsService.client || !this._deviceLocationUuid) return;

    return this.deviceLocationsService.readByUuid(this.deviceLocationUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: any) => {
        this.deviceLocation = result;

        //controls what the date range of data is displayed on the graph 
        if (this._chartDataType == 0) {
          this.getDevice();
          this.getChartData();
        }
        else if (this._chartDataType == 1) {
          this.getDevice();
          this.getDeviceLocationSettingsRecent();
        }
      },
        error => {
          this.notificationsService.error('Server Error (getDeviceLocation)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getDevice(): any {
    this.device = null;

    if (!this.settingsService.client) return;

    return this.deviceLocationsService.readDeviceByLocationUuid(this.deviceLocationUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: any) => {
        this.device = result;

        //this.getDeviceLocation();
        //this.getChartData();
      },
        error => {
          // this.notificationsService.error('Server Error (getDevice)', 
          //   this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        }
      );
  }

  public chartDataDate: string = "";
  getChartData(): any {
    this.chartDataDate = "";

    if (!this.settingsService.client) {
      return;
    }


    let today = new Date();
    let yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    yesterday.setDate(yesterday.getDate() - 1)
    let dateEnd: Date = yesterday;
    let dateBegin: Date = new Date(dateEnd);
    dateBegin.setDate(dateBegin.getDate() - 6);

    return this.deviceLocationsService.readBinSummaryByDateRange(
      this.deviceLocation.uuid, dateBegin, dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          const chartDataApply: any = JSON.parse(JSON.stringify(this.chartData));
          this.chartOptions.legend.container = "#" + this.cardUuid;

          const chartOptionsApply: any = (this.chartOptions);

          const tks = new Array();
          const vhcl = new Array();
          const vltr = new Array();
          let yaxismax: number = 1000;
          let yAxisMaxValue: number = 0;
          let xValue: Date;
          this.deviceLocation.speedLimit = result[result.length - 1].speedLimit;

          let tickVal: number = -1;

          result.forEach(element => {
            xValue = new Date(element.recordDate);

            tickVal += 1.5;
            tks.push([tickVal, this.datePipe.transform(xValue.getTime(), 'MM/dd')]);

            vhcl.push([tickVal - 0.5, element.vehicles == 0 ? null : element.vehicles]);
            vltr.push([tickVal, element.violators == 0 ? null : element.violators]);

            // vhcl.push([xValue.getTime(), element.vehicles]);
            // vltr.push([xValue.getTime(), element.violators]);

            if (element.vehicles > yAxisMaxValue) { yAxisMaxValue = element.vehicles }
            if (element.violators > yAxisMaxValue) { yAxisMaxValue = element.violators }

            if (element.vehicles > yaxismax) {
              yaxismax = element.vehicles;
            }
            if (element.violators > yaxismax) {
              yaxismax = element.violators;
            }
          });

          if (result.length > 0) {
            var lastDate = new Date(result[result.length - 1].recordDate);
            this.chartDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          //console.log(vhcl,vltr,tks)

          chartDataApply[0].data = vhcl;
          chartDataApply[1].data = vltr;
          chartOptionsApply.xaxis.ticks = tks;
          chartOptionsApply.yaxis.max = (yAxisMaxValue * 1.2)

          this.chartOptions = chartOptionsApply;
          this.chartData = chartDataApply;
        },
        error => {
          this.notificationsService.error('Error (getChartData)', this.errorsService.errorParse(error), { clickToClose: true });
        });

  }

  getDeviceLocationSettingsRecent() {

    this.chartDataDate = "";

    return this.deviceLocationsService.readDeviceLocationSettingsRecentByLocationUuid(
      this.deviceLocation.uuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          if (result && result.dailySummaryLast) {
            let dailySummaryLastDate = new Date(result.dailySummaryLast)
            this.getLastAvailableChartData(dailySummaryLastDate)
          }
        },
        error => {
          this.notificationsService.error('Error (getDeviceLocationSettingsRecent)', this.errorsService.errorParse(error), { clickToClose: true });
        });

  }

  getLastAvailableChartData(dailySummaryLastDate: Date) {

    this.chartDataDate = "";

    if (!this.settingsService.client) {
      return;
    }

    let dateBegin: Date = new Date(dailySummaryLastDate);
    dateBegin.setDate(dailySummaryLastDate.getDate() - 6);

    return this.deviceLocationsService.readBinSummaryByDateRange(
      this.deviceLocation.uuid, dateBegin, dailySummaryLastDate)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          const chartDataApply: any = JSON.parse(JSON.stringify(this.chartData));
          this.chartOptions.legend.container = "#" + this.cardUuid;

          const chartOptionsApply: any = (this.chartOptions);

          const tks = new Array();
          const vhcl = new Array();
          const vltr = new Array();
          let yaxismax: number = 1000;
          let yAxisMaxValue: number = 0;
          let xValue: Date;
          this.deviceLocation.speedLimit = result[result.length - 1].speedLimit;

          let tickVal: number = -1;

          result.forEach(element => {
            xValue = new Date(element.recordDate);

            tickVal += 1.5;
            tks.push([tickVal, this.datePipe.transform(xValue.getTime(), 'MM/dd')]);

            vhcl.push([tickVal - 0.5, element.vehicles == 0 ? null : element.vehicles]);
            vltr.push([tickVal, element.violators == 0 ? null : element.violators]);

            // vhcl.push([xValue.getTime(), element.vehicles]);
            // vltr.push([xValue.getTime(), element.violators]);

            if (element.vehicles > yAxisMaxValue) { yAxisMaxValue = element.vehicles }
            if (element.violators > yAxisMaxValue) { yAxisMaxValue = element.violators }

            if (element.vehicles > yaxismax) {
              yaxismax = element.vehicles;
            }
            if (element.violators > yaxismax) {
              yaxismax = element.violators;
            }
          });

          if (result.length > 0) {
            var lastDate = new Date(result[result.length - 1].recordDate);
            this.chartDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          //console.log(vhcl,vltr,tks)

          chartDataApply[0].data = vhcl;
          chartDataApply[1].data = vltr;
          chartOptionsApply.xaxis.ticks = tks;
          chartOptionsApply.yaxis.max = (yAxisMaxValue * 1.2)

          this.chartOptions = chartOptionsApply;
          this.chartData = chartDataApply;
        },
        error => {
          this.notificationsService.error('Error (getLastAvailableChartData)', this.errorsService.errorParse(error), { clickToClose: true });
        });

  }

}
