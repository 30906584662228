
import { filter, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import {
  DevicesService,
  DeviceSchedulesService,
  FirmwaresService,
  ModelsService,
  DeviceDailySummariesService,
  DeviceLocationsService
} from '../../../../core/api/api.services';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsService } from '../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { ColorsService } from '../../../../core/colors/colors.service';
import { Subject } from 'rxjs';


export enum DeviceLocationStatusCardDataTypes {
  current = 0,
  lastAvailable = 1
}

@Component({
  selector: 'app-config-option-card',
  templateUrl: './config-option-card.component.html',
  styleUrls: ['./config-option-card.component.scss']
})

export class ConfigOptionCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onConfigOptionAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfigOptionRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfigOptionDropDownSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dropdownOption') dropdownOption: ElementRef;

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public componentLoaded: boolean = false;

  private _isEditMode: boolean = false;
  get IsEditMode(): boolean {
    return this._isEditMode;
  }
  @Input() set IsEditMode(newIsEditMode: boolean) {
    this._isEditMode = newIsEditMode;
  }

  private _allowConfigEdit: boolean = false;
  get AllowConfigEdit(): boolean {
    return this._allowConfigEdit;
  }
  @Input() set AllowConfigEdit(newAllowConfigEdit: boolean) {
    this._allowConfigEdit = newAllowConfigEdit;
  }

  private _configOption: any = null;
  get ConfigOption(): any {
    return this._configOption;
  }
  @Input() set ConfigOption(newConfigOption: any) {
    if (newConfigOption && newConfigOption.code && newConfigOption.description) {
      this._configOption = newConfigOption;
      if (this.componentLoaded) {
        //this.getDeviceLocation();
      }
    }
    else {
      this._configOption = null;
    }
  }
  private _valueText: string = null;
  get ValueText(): string {
    return this._valueText;
  }
  @Input() set ValueText(newValueText: string) {
    this._valueText = newValueText;
  }

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public devicesService: DevicesService,
    public deviceLocationsService: DeviceLocationsService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public colorsService: ColorsService,
    private router: Router,
  ) { }


  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.loadFormData()
  }

  loadFormData() {
    let self = this
    setTimeout(function () {
      // self.getModels();
      // self.getFirmwares();
      // self.getdeviceSchedules();
      // if (self.deviceLocation == null) {
      //   self.getDeviceLocation();
      // }
      self.componentLoaded = true;
    }, 100);
  }

  onConfigOptionAdd(id: any) {
    this.onConfigOptionAdded.emit(id);
  }
  onConfigOptionRemove(id: any) {
    this.onConfigOptionRemoved.emit(id);
  }

  dropDownChange(event: any) {
    if (event && this.dropdownOption.nativeElement &&  this.dropdownOption.nativeElement.value)
    {
      let configOptionValue = this.ConfigOption.configOptionValues.find(v => v.code == this.dropdownOption.nativeElement.value);
      this.onConfigOptionDropDownSelected.emit( 
        {
          optionId: this.ConfigOption.id, 
          code: this.ConfigOption.code, 
          id: configOptionValue.id, 
          valueText: this.dropdownOption.nativeElement.value
        });
    }
      
  }

}
