import { Component, ElementRef, OnInit, ViewEncapsulation, ViewChild, Input, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent, ClickType } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DevicesService } from '../../../../core/api/api.services';
import { takeUntil, filter, count } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ColorsService } from '../../../../core/colors/colors.service';
import { Router, ActivatedRoute } from '@angular/router';
// import { P } from '@angular/core/src/render3';
import { RowComp, MouseEventService } from 'ag-grid-community';
import { MouseEvent } from 'ngx-bootstrap/utils/facade/browser';
import { timeout } from 'rxjs-compat/operator/timeout';


@Component({
	selector: 'app-signResponsesView',
	templateUrl: './signResponsesView.component.html',
	styleUrls: ['./signResponsesView.component.scss'],
	encapsulation: ViewEncapsulation.None
})



export class SignResponsesViewComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() onDeviceSelected: EventEmitter<any> = new EventEmitter()
	private onDestroy$: Subject<void> = new Subject<void>();
	private _connectivityTypeId: number = -1;

	get connectivityTypeId(): number {
		return this._connectivityTypeId;
	}
	@Input() set connectivityTypeId(Id: number) {
		this._connectivityTypeId = Id
		if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
	}

	@ViewChild(DatatableComponent) dataView: DatatableComponent;
	@ViewChild('datatableFilter') datatableFilter: ElementRef;

	//observables
	public signResponsesTemp: any[] = [];
	public signResponses: any[] = [];
	public myResponses: any[] = [];
	public devicesSelected: any[] = [];
	public myTempResponse: any[] = [];
	public counter = 0;
	public goodbyeDate;
	public win = (window as any);




	datePipe = new DatePipe('en-US');
	decimalPipe = new DecimalPipe('en-US');

	constructor(
		public menu: MenuService,
		public eventsService: EventsService,
		public notificationsService: NotificationsService,
		public settingsService: SettingsService,
		public devicesService: DevicesService,
		public colorsService: ColorsService,
		private router: Router,
		private route: ActivatedRoute,
		public errorsService: ErrorsService) {
	}

	private paramSub: any;

	ngOnInit(): void {
		this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
			//this.deviceUuid = params['uuid'];
		});

	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
	}

	ngAfterViewInit(): void {
		let self = this;
		setTimeout(() => {
			self.getDevices();
			self.getConnectionsData();
		}, 500);
	}

	getDevices(): any {
		if (!this.settingsService.client) return;
		this.settingsService.showSpinner("devices", false, 250);

		return this.devicesService.read()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.signResponses = result;
					this.getConnectionsData();
					this.signResponsesTemp = result;
					if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)

				},
				error => {
					this.notificationsService.error("Server Error (getDevices)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
				});


	}

	getConnectionsData(): any {
		for (var i = 0; i < this.signResponses.length; i++) {

			if (!this.settingsService.client) return;
			this.settingsService.showSpinner("devices", false, 250);

			this.devicesService.readRecentConnectionsByUuid(this.signResponses[i].uuid, 7)
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(
					(results: any) => {

						this.counter++;
						this.myResponses = this.myResponses.concat(results);
						this.myTempResponse = this.myTempResponse.concat(results);

						//return this.myResponses;		

					},
					error => {
						this.notificationsService.error('Error (getConnectionsData)', this.errorsService.errorParse(error), { clickToClose: true });
					})

		};


	}

	getDeviceSerial(row): string {
		let deviceSerial = this.signResponses.find(s => s.uuid == row.deviceUuid);
		if (deviceSerial) {
			//let tmpTmpResp = this.myTempResponse.find(s => s.deviceUuid == row.deviceUuid);
			//tmpTmpResp.serial = deviceSerial.serial;


			for (var i = 0; i < this.myTempResponse.length; i++) {
				if (this.myTempResponse[i].deviceUuid == row.deviceUuid) {
					this.myTempResponse[i].serial = deviceSerial.serial;
				}

			}
			return deviceSerial.serial;

		}
		else {
			return "No Data";
		}

	}


	updateFilter(event) {
		var self = this;
		const val = event.target.value.toLowerCase();
		// filter our data
		const temp = this.myTempResponse.filter(function (r) {
			return (
				(
					self.connectivityTypeId == -1 ||
					self.connectivityTypeId == r.connectivityTypeId
				) &&
				(
					(r.helloDate || "").toLowerCase().indexOf(val) !== -1 ||
					(r.serial || "").toLowerCase().indexOf(val.trim()) !== -1 ||
					(r.goodbyeDate || "").toLowerCase().indexOf(val) !== -1 ||
					(r.deviceUuid || "").toLowerCase().indexOf(val) !== -1 ||
					val.toString().trim() == ''
				)
			);
		});

		// update the rows
		this.myResponses = temp;
		// Whenever the filter changes, always go back to the first page
		this.dataView.offset = 0;
	}



}