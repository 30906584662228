<div [style.height.px]="chartHeight">
    <canvas *ngIf="chartDisplay" baseChart
          [datasets]="chartJsData"
          [labels]="chartJsLabels"
          [options]="chartJsOptions"
          [legend]="chartJsLegend"
          [chartType]="chartJsType"
          [colors]="chartJsColors"
          (chartHover)="chartJsHovered($event)"
          (chartClick)="chartJsClicked($event)">
    </canvas>
</div>