<div class="card-body pb-0">

    <div [hidden]="!showDailyEventScheduleForm" class="form-group row">
      <div class="col-12 pr-2 pl-2">
        <form [formGroup]="fgDailyEventSchedule">
  
          <div class="row">
  
            <div class="col-md-6 col-sm-5 order-sm-1 col-12 order-2 m-0 p-0">
              <div class="form-row">
                <div class="col-sm-12 col-12 mb-2">
                  <span class="fas fa-calendar-alt pl-1 pr-2"
                    [style.color]="'#'+fgDailyEventSchedule.value.colorCode"></span><label>Daily Event Schedule Name</label>
                  <div *ngIf="!isEditMode" class="form-control">{{DailyEventSchedule?.name ? DailyEventSchedule.name : '&nbsp;'}}</div>
                  <input *ngIf="isEditMode"
                    [class]="librariesService.controlValidationClass(fgDailyEventSchedule.get('name'))"
                    name="name" formControlName="name" type="text" placeholder="Daily Event Schedule Name" />
                  <div *ngIf="librariesService.controlInvalidFlag(fgDailyEventSchedule.get('name'))"
                    class="invalid-feedback">Please
                    provide a daily event schedule name</div>
                </div>
  
              </div>
            </div>
            <div class="col-md-6 col-sm-7 order-sm-2 col-12 order-1 pr-0 float-right text-right">
                <button *ngIf="!isEditMode" [disabled]="false" type="button" class="btn btn-primary ml-2"
                (click)="buttonCancel($event)">
                <span class="fas fa-calendar-alt pr-2"></span>Event Schedules</button>
  
              <button *ngIf="isEditMode" [disabled]="false" type="button" class="btn btn-primary ml-2"
                (click)="buttonAdd($event)">
                <span class="fas fa-clock pr-2"></span>Add Time</button>
  
              <button *ngIf="isEditMode" [disabled]="false" type="button" class="btn btn-success ml-2"
                (click)="buttonOk($event)">
                <span class="fas fa-check pr-2"></span>Done</button>
  
              <!-- disabled for now -->
              <button *ngIf="false && isEditMode" [disabled]="false" type="button" class="btn btn-danger ml-2"
                (click)="buttonCancel($event)">
                <span class="fas fa-times pr-2"></span>Cancel</button>
            </div>
  
          </div>
  
          <div *ngIf="DailyEventSchedule" class="row">
            <div class="col-12 m-0 p-0">
              <!-- (select)='onSelect($event)'  -->
              <ngx-datatable #dailyEventScheduleTimesTable class='bootstrap no-detail-row table-hover'
                [selectionType]="'single'" [selected]="deviceScheduleConfigurationEventsSelected" [columnMode]="'flex'"
                [footerHeight]="20" [rowHeight]="'auto'" [limit]="24"
                [rows]='DailyEventSchedule.deviceScheduleConfigurationEvents' (select)='onSelect($event)'>
  
                <ngx-datatable-column [resizeable]="false" name="Time" [flexGrow]="0.5">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ dailyEventTimeFormat(row.time) }}
                  </ng-template>
                </ngx-datatable-column>
  
                <ngx-datatable-column [resizeable]="false" name="Event Name" prop="name" [flexGrow]="2.5"></ngx-datatable-column>
                <!-- <ngx-datatable-column [resizeable]="false" name="Event Name" [flexGrow]="2.5">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.time=="00:00" && false ? environment.dayScheduleDefaultEventName : row.name }}
                  </ng-template>
                </ngx-datatable-column> -->

                <ngx-datatable-column [resizeable]="false" name="" [flexGrow]="0.5">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <button *ngIf="isEditMode && row.time !='00:00'" type="button" class="btn btn-xs btn-danger ml-2"
                      (click)="buttonDelete(row)"><span class="fas fa-trash-alt"></span></button>
                    <!-- <button *ngIf="isEditMode" type="button" class="btn btn-xs btn-primary ml-2"
                      (click)="buttonEdit(row)"><span class="fas fa-edit pr-2"></span>Edit</button> -->
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
  
        </form>
      </div>
    </div>
  
  
    <div class="row">
        <div class="col-12 pl-0 pr-0 pb-0">
      <app-dayschedulestime-form [DeviceSchedule]="DeviceSchedule" [DailyEventSchedule]="DailyEventSchedule" [ConfigMapOptions]="ConfigMapOptions"
        (onFormEvent_EventScheduleTime)="onEventScheduleTimeEmitter($event)" [hidden]="!showDailyEventScheduleTimeForm"
        #eventScheduleTimeForm></app-dayschedulestime-form>
      </div>    
    </div>
  

    <!-- {{fgDailyEventSchedule.value | json}} -->
    <!-- {{ConfigMapOptions | json}} -->
  </div>