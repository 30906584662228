import { Component, ElementRef, EventEmitter, ViewEncapsulation, OnInit, AfterViewInit, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../../core/settings/settings.service';
import { ModelsService, FirmwaresService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { PasswordValidator } from '../../../../../core/validators/password.validator';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ConfigOptionControlComponent } from '../../../../../shared/component/controlsUi/configOptionControl/configOptionControl.component';
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

export enum ModelFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8
}

@Component({
  selector: 'app-model-form',
  templateUrl: './model-form.component.html',
  styleUrls: ['./model-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModelFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() configCode: any;
  @ViewChild(ConfigOptionControlComponent) public configControl: ConfigOptionControlComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  get formTitle() {
    if (this.modelId && this.modelId != -1) {
      return 'Edit Model'
    } else {
      return 'Add Model'
    }
  }

  public formLoaded: boolean = false;
  public formStates = ModelFormState;
  private _formState: ModelFormState = this.formStates.Initializing;
  get formState(): ModelFormState {
    return this._formState
  }
  @Input() set formState(newFormState: ModelFormState) {
    var updateFormState: ModelFormState = null
    this.fgModel.disable()

    switch (newFormState) {
      case this.formStates.Read: {
        updateFormState = newFormState
        this.formRead()
        break
      }
      case this.formStates.New: {
        updateFormState = newFormState
        this.fgModel.enable()
        this.formAdd()
        break
      }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.fgModel.enable()
        this.formEdit()
        break
      }
      case this.formStates.Save: {
        this._formState = newFormState
        this.fgModel.enable()
        this.formSave()

        break
      }
      case this.formStates.Saved: {
        this._formState = newFormState
        break
      }
      case this.formStates.Cancelled: {
        this.formCancel()
        break
      }
      default: {
        updateFormState = newFormState
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState
    }
  }

  private _modelId: number = -1;
  get modelId(): number {
    return this._modelId;
  }
  @Input() set modelId(newModelId: number) {
    this._modelId = newModelId;

    if (this.formLoaded && newModelId != -1) {
      this.getFirmwares();
      this.getModel();
    }

    if (newModelId == -1)
      this.formState = this.formStates.New
  }

  public model: any = null;
  public firmwares: any[] = [];

  get isEditMode(): boolean {
    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  public isModified = false;

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    public firmwaresService: FirmwaresService,
    public modelsService: ModelsService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  public fgModel: FormGroup = this.formBuilder.group({
    id: '',
    code: ['', [Validators.required]],
    description: '',
    firmwareUuidTarget: ['', [Validators.required]],
    userDisplay: false
  });

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(() => {
      if (self.model == null) {
        self.getFirmwares();
        self.getModel();
      }
      self.formLoaded = true
    }, 500);
  }

  clearModel() {
    this.model = null;
    this.fgModel.patchValue(
      {
        id: '',
        code: '',
        description: '',
        firmwareUuidTarget: '',
        userDisplay: false
      }
    )
  }

  getFirmwares(): any {
    this.firmwares = [];

    return this.firmwaresService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          console.log('result', result);
          this.firmwares = result;
        },
        error => {
          this.notificationsService.error("Server Error (getFirmwares)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getModel(): any {
    if (!this.modelId || this.modelId == -1) return;
    console.log('get model() ');

    return this.modelsService.readById(this.modelId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.fgModel.patchValue(
            {
              id: result.id,
              code: result.code,
              description: result.description,
              firmwareUuidTarget: result.firmwareUuidTarget,
              userDisplay: result.userDisplay
            }
          )

          this.formState = this.formStates.Read;
          this.model = result;
        },
        error => {
          this.notificationsService.error("Server Error (getModel)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.formState = this.formStates.Read
        });
  }

  onSuccessItem(event) {
    if (event && event.status == 200 && event.response) {
      var modelAdd = JSON.parse(event.response);
      this.modelId = modelAdd.Id
      this.formState = this.formStates.Edit
    }
  }

  formRead() {
  }

  formAdd() {
    this.isModified = false;
    this.onFormEvent.emit("add")
  }

  formEdit() {
    this.isModified = false;
    this.onFormEvent.emit("edit")
  }

  formSave() {
    if (this.fgModel.valid == false) {
      this.librariesService.validateFormGroup(this.fgModel)

      this.formState = this.modelId == -1 ? this.formStates.New : this.formStates.Edit
      this.onFormEvent.emit("edit")
      this.notificationsService.warn("Validation Warning (Model)", "Verify Required Data", { timeOut: 5000, clickToClose: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEvent.emit("saving")

    if (this.modelId == -1) {
      const model = {
        id: +this.fgModel.value.id,
        code: this.fgModel.value.code,
        description: this.fgModel.value.description,
        firmwareUuidTarget: this.fgModel.value.firmwareUuidTarget,
        modelOptionValues: this.configControl.model.modelOptionValues,
        userDisplay: this.fgModel.value.userDisplay
      }
      return this.modelsService.create(model)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            this.modelId = result.id
            this.formState = this.formStates.Read
            this.onFormEvent.emit("saved")
            this.notificationsService.success("Success", "Model Saved", { timeOut: 2000, clickToClose: true });
          },
          error => {
            this.notificationsService.error("Server Error (modelSave)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });
    } else {
      return this.modelsService.readById(this.modelId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            result.code = this.fgModel.value.code
            result.description = this.fgModel.value.description,
              result.firmwareUuidTarget = this.fgModel.value.firmwareUuidTarget,
              result.userDisplay = this.fgModel.value.userDisplay
            result.modelOptionValues = this.model.modelOptionValues;

            return this.modelsService.put(result)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {
                  this.modelId = result.id

                  this.formState = this.formStates.Read
                  this.onFormEvent.emit("saved")
                  this.notificationsService.success("Success", "Model Saved", { timeOut: 2000, clickToClose: true });
                },
                error => {
                  this.notificationsService.error("Server Error (modelSave: put)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                  this.formState = this.formStates.Edit
                });

          },
          error => {
            this.notificationsService.error("Server Error (modelSave: getModel)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });

    }
  }

  formCancel() {
    this.librariesService.untouchedFormGroup(this.fgModel)
    this.formState = this.formStates.Read
    this.onFormEvent.emit("cancelled")
    this.getModel()
  }

}
