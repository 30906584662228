<div class="row">
    <!-- START dashboard main content-->
    <div class="col-lg-12">
        <!-- START chart-->
        <div class="row">
            <div class="col-lg-12">
                <!-- START widget-->
                <div class="card bg-default">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h4 class="pt-2 mb-0">Device Search</h4>
                            </div>
                        </div>
                    </div>
                    <div class="card border-0">
                        <div class="card-body pt-0">
                            <form [formGroup]="fgSearch">
                                <div class="row">
                                    <div class="col-lg-4 align-middle">

                                        <input type='text' #dataTableFilter class="form-control"
                                            formControlName="serial" (keydown.enter)="getDeviceBySerial($event)"
                                            placeholder='Enter a Serial to Search for a Device' />
                                    </div>

                                    <div>
                                        <button (click)="getDeviceBySerial($event)" class="btn btn-primary pb-1">Search</button>
                                    </div>
                                </div>
                            </form>
                            <ag-grid-angular #agGrid id="adminDevicesTable" name="adminDevicesTable"
                                style="height: 78vh; width: 100%;"
                                class="ag-theme-balham adminDevicesTable m-0 pt-2 border-0" [animateRows]="true"
                                [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="device" [rowStyle]="rowStyle"
                                (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions">
                            </ag-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>