//see: (this had a bug that needed to be fixed, missed used shouldReuseRoute) 
//https://medium.com/@rajeshpillai1996/what-is-routereusestrategy-how-to-cache-components-with-angular-routereusestrategy-82da7790cd2b
//see for more help: https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f
import {
    RouteReuseStrategy,
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    RouterModule,
    Routes,
    UrlSegment
} from '@angular/router';

export class RouteReuseService implements RouteReuseStrategy {
    
    private handlers: { [key: string]: DetachedRouteHandle } = {};
    private debug: boolean = false;

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (!route.routeConfig || route.routeConfig.loadChildren) {
            console.log('shouldDetach', route);
            return false;
        }
        let shouldReuse = false;
        
        if (this.debug) console.log('checking if this route should be re used or not', route);

        if (route.routeConfig.data) {
            route.routeConfig.data.reuse ? shouldReuse = true : shouldReuse = false;
        }
        return shouldReuse;
    }
    store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
        
        if (this.debug) console.log('storing handler',route);

        if (handler) {
            this.handlers[this.getUrl(route)] = handler;
        }
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        
        if (this.debug) console.log('checking if it should be re attached');

        let reUseUrl = false;
        if (route.routeConfig) {
            if (route.routeConfig.data) {
                reUseUrl = route.routeConfig.data.reuse;
            }
        }
        if (!reUseUrl) return false;
        
        if (this.debug) console.log('shouldAttach-handlers', this.handlers[this.getUrl(route)])

        return !!this.handlers[this.getUrl(route)];
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        
        if (this.debug) console.log('retrieve', route);

        if (!route.routeConfig || route.routeConfig.loadChildren) {
            return null;
        };
        return this.handlers[this.getUrl(route)];
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        
        if (this.debug) console.log('shouldReuseRoute', future,current);

        const defaultReuse = (future.routeConfig === current.routeConfig);
        return defaultReuse;
    }
    getUrl(route: ActivatedRouteSnapshot): string {
        
        if (this.debug) console.log('route', route);

        if (route.routeConfig) {
            const url = route.routeConfig.path; 
            if (this.debug) console.log('returning url', url);
            return url;
        }
    }
}