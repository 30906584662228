import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiIdService } from './_api-id.service';

@Injectable()
export class CountriesService extends ApiIdService {
  constructor(
    protected router: Router,
    protected http: HttpClient, 
  ) {
    super(router, http, 'countries');
  }

  read() {
    return this.all();
  }

  readById(id: number) {
    return this.get(id);
  }
  
  readByRank() {
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/byrank`)
  }

}
