import { NgModule, Optional, SkipSelf } from '@angular/core';
import {AlertsService } from './alerts/alerts.service';
//import { AuthenticationService } from './authentication/authentication.service';
//import { AuthenticationGuard } from './authentication/authentication.guard';
//import { AuthenticationInterceptor } from './authentication/authentication.interceptor';
import { EventsService } from './events/events.service';
import { ErrorsService } from './errors/errors.service';
import { LibrariesService } from './libraries/libraries.service';
import { MappingService } from './mapping/mapping.service';
import { MenuService } from './menu/menu.service';
//import { SettingsService, userTypes, userRoles } from './settings/settings.service';
import { ThemesService } from './themes/themes.service';
import { TranslatorService } from './translator/translator.service';
import {
    BearingsService,
    ClientsService,
    ConfigMapsService,
    CountriesService,
    DeviceDailySummariesService,
    DevicesService,
    DeviceNotificationsService,
    DeviceGroupsService,
    DeviceSchedulesService,
    DeviceLocationsService,
    DeviceConfigurationsService,
    DeviceTelemetriesService,
    DeviceLocationSettingsService,
    FirmwaresService,
    ConnectivityTypeIdService,
    ModelsService,
    ModemsService,
    UsersService,
    RolesService,
    VerificationsService,
    UserTypesService,
    SubscriptionsService,
    SubscriptionTypesService,
    SubscriptionScopesService} from './api/api.services';
import { throwIfAlreadyLoaded } from './module-import-guard';

@NgModule({
    imports: [
    ],
    providers: [
        AlertsService,
        EventsService,
        LibrariesService,
        ErrorsService,
        MappingService,
        MenuService,
        ThemesService,
        TranslatorService,
        BearingsService,
        ClientsService,
        ConfigMapsService,
        CountriesService,
        DevicesService,
        DeviceNotificationsService,
        DeviceGroupsService,
        DeviceSchedulesService,
        FirmwaresService,
        ConnectivityTypeIdService,
        ModelsService,
        ModemsService,
        UsersService,
        VerificationsService,
        RolesService,
        UserTypesService,
        DeviceLocationsService,
        DeviceLocationSettingsService,
        DeviceDailySummariesService,
        DeviceConfigurationsService,
        DeviceTelemetriesService, 
        VerificationsService,
        SubscriptionsService,
        SubscriptionTypesService,
        SubscriptionScopesService
        
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
