import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './_api.service';

export class ApiUuidService extends ApiService{
  
  constructor (
    protected router: Router,
    protected http: HttpClient, 
    apiRoute: string) {
    super(router, http, apiRoute);
  }

  all(): Observable<any> {
    return this.http.get(this.getApiUrl()+`/${this.apiRoute}`);
  }

  get(uuid: string): Observable<any> {
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${uuid}`);
  }

  post(entity: any): Observable<any> {
    return this.http.post(`${this.getApiUrl()}/${this.apiRoute}`, entity);
  }

  put(entity: any): Observable<any> {
    return this.http.put(`${this.getApiUrl()}/${this.apiRoute}`, entity);
  }

  delete(uuid: string) {
    return this.http.delete(`${this.getApiUrl()}/${this.apiRoute}/${uuid}`);
  }

 
}
