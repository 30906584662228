import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

declare var $: any;

@Injectable()
export class ErrorsService {

    constructor(
        private router: Router
    ) {
    }

    public errorParse(error: any): string {
        console.log(error)
        
        if (error.error && error.error.message)
        {
            return error.error.message+" ("+error.status.toString()+")"
        }
        else if (error.statusText && error.statusText.toString().trim() != "")
        {
            return error.statusText.toString().trim()+" ("+error.status.toString()+")"
        }
        else if (error.message && error.message.toString().trim() != "")
        {
            return error.message.toString().trim()
        }
        else return "Unknown Error"+" ("+error.status.toString()+")"
    }

}
