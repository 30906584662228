import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class DeviceGroupsService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'devicegroups');
  }

  read() {
    return this.all();
  }

  readByUuid(uuid: string) {
    return this.get(uuid);
  }

  create(deviceGroup: any, clientUuid: string): Observable<any> {
    deviceGroup.clientUuid = clientUuid;
    console.log('deviceGroup', deviceGroup);

    const apiParam = `${this.getApiUrl()}/${this.apiRoute}`;

    return this.http.post<any>(
      apiParam,
      deviceGroup
    );
  }
}
