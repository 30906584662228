<div class="card-body">
  <div class="form-group row">
    <div class="col-lg-12">
      <form [formGroup]="fgModel">
        <div class="row">
          <div class="col-12 m-0 p-0">
            <div class="form-row">
              <div class="col-sm-4 col-12 mb-2">
                <label>Code</label>
                <div *ngIf="!isEditMode" class="form-control">{{model?.code ? model.code : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgModel.get('code'))"
                  id="code" name="code" formControlName="code" type="text" placeholder="Model Code" />
                <div *ngIf="librariesService.controlInvalidFlag(fgModel.get('code'))" class="invalid-feedback">Please
                  provide a model code</div>
              </div>
              <div class="col-sm-4 col-12 mb-2">
                <label>Description</label>
                <div *ngIf="!isEditMode" class="form-control">{{model?.description ? model.description :
                  '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgModel.get('description'))"
                  id="description" name="description" formControlName="description" type="text"
                  placeholder="Description" />
                <div *ngIf="librariesService.controlInvalidFlag(fgModel.get('description'))" class="invalid-feedback">
                  Please
                  provide a description</div>
              </div>
              <div class="col-md-4 col-sm-12 mb-2">
                <label>Target Firmware</label>
                <ng-select #firmwareUuidTarget [selectOnTab]="true" [items]="firmwares" bindValue="uuid"
                  bindLabel="code" [clearable]="false" id="firmwareUuidTarget" name="firmwareUuidTarget"
                  formControlName="firmwareUuidTarget" placeholder="Firmware">
                </ng-select>
              </div>
              <div class="col-md-4 col-sm-12 mb-2 pt-2">
                <div class="checkbox c-checkbox pl-1">
                  <label>
                    <input #userDisplay type="checkbox" [attr.disabled]="!isEditMode ? '' : null" id="userDisplay"
                      name="userDisplay" formControlName="userDisplay" />
                    <span class="fas fa-check"></span>Display Device/Model For End-Users</label>
                  <!-- [attr.disabled]="!isEditMode ? '' : null"  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <app-configOptionControl-component [modelView]=true [model]=[model] [IsEditMode]="isEditMode"></app-configOptionControl-component>
    </div>
  </div>
  <div class="pt-0 text-right uuid-opaque">{{ (this.modelId && this.modelId != -1) ?
    this.modelId.toString() : "&nbsp;"}}</div>
</div>