<div class="resetpassword-bg"></div>
<div class="resetpassword-header">
    <div class="container row no-gutters">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-sm-8 col-md-7 col-lg-5 col-xl-5">
            <img src="assets/img/rs-logo-white.svg" class="resetpassword-header-logo">
        </div>
        <div class="col-sm-2 col-md-3 col-lg-5 col-xl-5"></div>
    </div>
</div>
<div class="container resetpassword-container">
    <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-sm-8 col-md-7 col-lg-5 col-xl-5">
            <div class="resetpassword-form">
                <h2>Login</h2>
                <form [formGroup]="fgReset" name="form" (keydown.enter)="$event.preventDefault()" novalidate style="opacity: 1;">
                    <div class="form-group">
                        <label for="email">Email Address</label>
                        <input type="text" class="form-control" id="email" name="email" formControlName="email"
                            (keyup.enter)="reset($event)"  />
                    </div>
                    <div class="pl-3 pr-3 pb-3">
                        Enter/confirm the account email address and press the Reset button. 
                        If the email address is the active account, 
                        a link will be sent enabling the user to reset their password.
                    </div>
                    <div>
                        <button (click)="back($event)" class="btn btn-primary">Back</button>
                        <div class="float-right text-right">
                            <button (click)="reset($event)" [disabled]="!this.fgReset.valid" class="btn btn-primary">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-2 col-md-3 col-lg-5 col-xl-5"></div>
    </div>
</div>
<div class="resetpassword-footer">
    <div class="row no-gutters">
        <div class="col-2"></div>
        <div class="col-8">{{ settingsService.app.name }} - {{ settingsService.app.description }} &copy; {{settingsService.app.year}}</div>
        <div class="col-2"></div>
    </div>
</div>