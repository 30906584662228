import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class DeviceSchedulesService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'deviceschedules');
  }

  read() {
    return this.all();
  }

  readByUuid(uuid: string) {
    return this.get(uuid);
  }


  create(deviceSchedule: any): Observable<any> {
    console.log('deviceSchedule', deviceSchedule);
    //const apiParam = `${this.getApiUrl()}/${this.apiRoute}`;

    return this.post(
      deviceSchedule
    );
  }
  deleteById(uuid: string) {
    return this.delete(uuid);
  }
}
