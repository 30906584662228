import { Component, EventEmitter, OnInit, AfterViewInit, Input, Output, OnChanges, SimpleChanges, SimpleChange, OnDestroy, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-plugin-datalabels';

import { DevicesService, DeviceLocationsService } from '../../../../core/api/api.services';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ColorsService } from '../../../../core/colors/colors.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-speedbins-counts-chart',
  templateUrl: './speedbins-counts-chart.component.html',
  styleUrls: ['./speedbins-counts-chart.component.scss']
})
export class SpeedBinsCountsChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() onChartJsEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(BaseChartDirective) chartObject: BaseChartDirective;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  public isViewInit: boolean = true;
  @Input() @Output() public chartHeight: number = 400;

  @Input() public dateEnd: Date = null;
  @Input() public dateBegin: Date = null;
  @Input() public speedBinOneOption: number = 0;
  @Input() public speedBinBreakOne: number = 0;
  @Input() public speedBinTwoOption: number = 0;
  @Input() public speedBinBreakTwo: number = 0;
  @Input() public speedBinThreeOption: number = 0;

  private _device: any = null;
  get deviceUuid(): string {
    return this._device ? this._device.uuid : "";
  }
  @Input() set device(newDevice: any) {

    if (newDevice && this.librariesService.guidValidate(newDevice.uuid)) {

      this._device = newDevice;
    }
    else {
      this._device = null;
    }
  }

  private _deviceLocation: any = null;
  get deviceLocationUuid(): string {
    return this._deviceLocation ? this._deviceLocation.uuid : "";
  }
  @Input() set deviceLocation(newDeviceLocation: any) {

    if (newDeviceLocation && this.librariesService.guidValidate(newDeviceLocation.uuid)) {

      this._deviceLocation = newDeviceLocation;
    }
    else {
      this._deviceLocation = null;
    }
  }

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public colorsService: ColorsService,
    public devicesService: DevicesService,
    public deviceLocationsService: DeviceLocationsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService
  ) { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(() => {
      if (self.isViewInit == false) {
        self.isViewInit = true;
        self.getChartJsData();
      }
    }, 50);
  }

  ngOnChanges(changes: SimpleChanges) {

    // const name: SimpleChange = changes.dateEnd;
    // console.log('prev value: ', name.previousValue);
    // console.log('got name: ', name.currentValue);

    console.log('ngOnChanges', changes)
    console.log('deviceLocationUuid', this.deviceLocationUuid)
    console.log('dateBegin', this.dateBegin)
    console.log('dateEnd', this.dateEnd)
    console.log('speedBinOneOption', this.speedBinOneOption)
    console.log('speedBinBreakOne', this.speedBinBreakOne)
    console.log('speedBinTwoOption', this.speedBinTwoOption)
    console.log('speedBinBreakTwo', this.speedBinBreakTwo)
    console.log('speedBinThreeOption', this.speedBinThreeOption)
    console.log('isViewInit', this.isViewInit)

    if (this.isViewInit &&
      this.librariesService.guidValidate(this.deviceLocationUuid)) this.getChartJsData()

  }

  public chartDisplay: boolean = false;
  public chartDpsData: any = null;

  private charJsSeries1Color: any = this.colorsService.byName('chart-purple');
  public chartJsData: any[] = [
    {
      data: [], label: '', borderWidth: 1, fill: false,
      datalabels: {
        display: false,
        anchor: 'end',
        align: 'end',
        color: function (context) { return context.dataset.backgroundColor; },
        offset: -2,
        font: {
          weight: 'bold'
        }
      }
    },
    {
      data: [], label: '', borderWidth: 1, fill: false,
      datalabels: {
        display: false,
        anchor: 'end',
        align: 'end',
        color: function (context) { return context.dataset.backgroundColor; },
        offset: -2,
        font: {
          weight: 'bold'
        }
      }
    }
  ];

  public chartJsLabels: string[] = [];
  public chartJsType: string = 'line';
  public chartJsLegend: boolean = true;

  private _chartJsOptions: any = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    animation: {
      duration: 300,
      easing: "easeOutQuint",
    },
    title: {
      display: true,
      fontSize: 18,
      text: '---Chart Title---'
    },
    scales: {
      yAxes: [{
        stacked: false,
        scaleLabel: {
          display: true,
          labelString: 'Percent (%)'
        },
        ticks: {
          beginAtZero: true,
          suggestedMax: 0
        }
      }],
      xAxes: [{
        stacked: false,
        scaleLabel: {
          display: true,
          labelString: 'Day and Time'
        },
        ticks: {
          // callback: function(value, index, values) {

          //   let dateValue: Date = new Date(value)
          //   let dvMonth: string = (dateValue.getMonth()+1).toString()
          //   let dvDay: string = dateValue.getDate().toString()
          //   let dvYear: string = dateValue.getFullYear().toString()

          //   let dateStart: Date = new Date(values[0])
          //   let dateNext: Date = (values.length > 1 ? new Date(values[1]) : null)
          //   let dateEnd: Date = new Date(values[values.length-1])
          //   let year: string = dateStart.getFullYear()==dateEnd.getFullYear() ? "" : "/"+dvYear
          //   let monthDay: string = dvMonth+"/"+dvDay
          //   let time: string = dateValue.getHours().toString()+":"+(dateValue.getMinutes() >= 10 ? dateValue.getMinutes().toString() : "0"+dateValue.getMinutes().toString())

          //   if (dateNext && dateStart.toLocaleDateString("en-US")!=dateNext.toLocaleDateString("en-US"))
          //   {
          //     return monthDay+year
          //   }
          //   else if (dateStart.toLocaleDateString("en-US")==dateEnd.toLocaleDateString("en-US"))
          //   {
          //       if (values.length==1)
          //       {
          //         return monthDay
          //       }
          //       else return time
          //   }
          //   else
          //   {
          //     return monthDay+year+" "+time
          //   }
          // },
          // stepSize: 1,
          // min: 0,
          maxRotation: 90,
          minRotation: 0,
          autoSkipPadding: 13,
          autoSkip: true
        }
      }]
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // datalabels: {
      //   // backgroundColor: function (context) {
      //   //   return context.dataset.backgroundColor;
      //   // },
      //   anchor: 'end',
      //   align: 'end',
      //   //color: 'darkblue',
      //   color: function(context) {
      //     let index = context.dataIndex;
      //     let value = context.dataset.data[index];
      //     return value < 0 ? 'red' :  // draw negative values in red
      //         index % 2 ? 'blue' :    // else, alternate values in blue and green
      //         'green';
      //   },
      //   // borderColor: 'white',
      //   // borderRadius: 25,
      //   // borderWidth: 2,
      //   offset: -2,
      //   font: {
      //     weight: 'bold'
      //   },
      //   formatter: Math.round
      // }
    },
  };
  get chartJsOptions(): any {
    return this._chartJsOptions;
  }
  set chartJsOptions(newChartJsOptions: any) {
    this._chartJsOptions = _.cloneDeep(newChartJsOptions);
  }

  public chartJsColors = [
    {
      backgroundColor: this.colorsService.byName('chart-blue'),
      borderColor: this.colorsService.byName('chart-blue-border'),
      pointHoverBackgroundColor: this.colorsService.byName('chart-blue'),
      pointHoverBorderColor: this.colorsService.byName('chart-blue-border')
    },
    {
      backgroundColor: this.colorsService.byName('chart-red'),
      borderColor: this.colorsService.byName('chart-red-border'),
      pointHoverBackgroundColor: this.colorsService.byName('chart-red'),
      pointHoverBorderColor: this.colorsService.byName('chart-red-border')
    }
  ];

  private getChartJsData_active: boolean = false;
  getChartJsData(): any {

    if (this.getChartJsData_active == true) return;
    this.getChartJsData_active = true;

    if (
      !this.settingsService.client ||
      this.deviceLocationUuid == "" ||
      this.dateBegin == null ||
      this.dateEnd == null ||
      this.speedBinOneOption == null ||
      this.speedBinBreakOne == null ||
      this.speedBinTwoOption == null ||
      this.speedBinBreakTwo == null ||
      this.speedBinThreeOption == null) {

      console.log('getChartJsData')
      console.log('deviceLocationUuid', this.deviceLocationUuid)
      console.log('dateBegin', this.dateBegin)
      console.log('dateEnd', this.dateEnd)
      console.log('speedBinOneOption', this.speedBinOneOption)
      console.log('speedBinBreakOne', this.speedBinBreakOne)
      console.log('speedBinTwoOption', this.speedBinTwoOption)
      console.log('speedBinBreakTwo', this.speedBinBreakTwo)
      console.log('speedBinThreeOption', this.speedBinThreeOption)
      console.log('isViewInit', this.isViewInit)

      this.getChartJsData_active = false;

      setTimeout(() => {
        this.getChartJsData();
      }, 200);
      return;
    }

    if (this.librariesService.guidValidate(this.deviceUuid))
      return this.devicesService.readBinAnalysisByDateRange(
        this.deviceUuid, this.dateBegin, this.dateEnd,
        this.speedBinOneOption, this.speedBinBreakOne,
        this.speedBinTwoOption, this.speedBinBreakTwo,
        this.speedBinThreeOption
      )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.chartDpsData = result;

            let maxChartValue = Math.max(...this.chartDpsData.speedBinsPeakPercent)
            // let minSpeedLimit = Math.min(...this.chartDpsData.speedLimit)
            // let maxSpeedLimit = Math.max(...this.chartDpsData.speedLimit)
            // let textSpeedLmit = minSpeedLimit == maxSpeedLimit ? maxSpeedLimit.toString() : maxSpeedLimit.toString() + ' to ' + maxSpeedLimit.toString() 
            let textSpeedLmit = Math.max(...this.chartDpsData.speedLimit) //this.chartDpsData.speedLimit[0]
            this._chartJsOptions.scales.yAxes[0].ticks.suggestedMax = (maxChartValue * 1.15)
            this._chartJsOptions.scales.yAxes[0].ticks.suggestedMin = 0

            this._chartJsOptions.title.text =
              'Average/Peak Vehicle Counts - Speed Limit ' + (textSpeedLmit && textSpeedLmit > 0 ? textSpeedLmit + ' ' + this.chartDpsData.unitSpeed : '[Unassigned]') + ' - ' +
              this.chartDpsData.deviceLocation.name

            this._chartJsOptions.scales.yAxes[0].scaleLabel.labelString = "Number of Vehicles"
            this._chartJsOptions.scales.xAxes[0].scaleLabel.labelString = (this.dateBegin.toLocaleDateString() == this.dateEnd.toLocaleDateString() ?
              this.dateBegin.toLocaleDateString() : this.dateBegin.toLocaleDateString() + ' to ' + this.dateEnd.toLocaleDateString())

            let tmpLabels: string[] = new Array(this.chartDpsData.speedBinsLow.length)
            for (let i = 0; i < tmpLabels.length; i++) {
              if (this.chartDpsData.speedBinsLow[i] ==
                this.chartDpsData.speedBinsHigh[i]) {
                tmpLabels[i] = this.librariesService.speedBinTextGet("",
                  this.chartDpsData.unitSpeed,
                  this.chartDpsData.speedBinsLow[i])
              }
              else {
                tmpLabels[i] = this.librariesService.speedBinTextGet("",
                  this.chartDpsData.unitSpeed,
                  this.chartDpsData.speedBinsLow[i],
                  this.chartDpsData.speedBinsHigh[i])
              }
            }
            this.chartJsLabels = tmpLabels

            this.chartJsData[0].data = this.chartDpsData.speedBinsAverage
            this.chartJsData[0].label = '# of Vehicles (Average)'
            this.chartJsData[1].data = this.chartDpsData.speedBinsPeak
            this.chartJsData[1].label = '# of Vehicles (Peak)'

            //this.chartJsType= this.chartJsData[0].data.length==1 ? "bar" : "line"

            // this.chartJsData[1].data = this.chartDpsData.violators
            // this.chartJsData[1].label = 'Violators'

            this.chartDisplay = true;
            this.chartJsOptions = this._chartJsOptions;
            this.getChartJsData_active = false;
          },
          error => {
            this.getChartJsData_active = false;
            this.notificationsService.error("Server Error", this.errorsService.errorParse(error), { clickToClose: true });
          });

    if (this.librariesService.guidValidate(this.deviceLocationUuid))
      return this.deviceLocationsService.readBinAnalysisByDateRange(
        this.deviceLocationUuid, this.dateBegin, this.dateEnd,
        this.speedBinOneOption, this.speedBinBreakOne,
        this.speedBinTwoOption, this.speedBinBreakTwo,
        this.speedBinThreeOption
      )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.chartDpsData = result;

            let maxChartValue = Math.max(...this.chartDpsData.speedBinsPeakPercent)
            // let minSpeedLimit = Math.min(...this.chartDpsData.speedLimit)
            // let maxSpeedLimit = Math.max(...this.chartDpsData.speedLimit)
            // let textSpeedLmit = minSpeedLimit == maxSpeedLimit ? maxSpeedLimit.toString() : maxSpeedLimit.toString() + ' to ' + maxSpeedLimit.toString() 
            let textSpeedLmit = Math.max(...this.chartDpsData.speedLimit) //this.chartDpsData.speedLimit[0]
            this._chartJsOptions.scales.yAxes[0].ticks.suggestedMax = (maxChartValue * 1.15)
            this._chartJsOptions.scales.yAxes[0].ticks.suggestedMin = 0

            this._chartJsOptions.title.text =
              'Average/Peak Vehicle Counts - Speed Limit ' + (textSpeedLmit && textSpeedLmit > 0 ? textSpeedLmit + ' ' + this.chartDpsData.unitSpeed : '[Unassigned]') + ' - ' +
              this.chartDpsData.deviceLocation.name

            this._chartJsOptions.scales.yAxes[0].scaleLabel.labelString = "Number of Vehicles"
            this._chartJsOptions.scales.xAxes[0].scaleLabel.labelString = (this.dateBegin.toLocaleDateString() == this.dateEnd.toLocaleDateString() ?
              this.dateBegin.toLocaleDateString() : this.dateBegin.toLocaleDateString() + ' to ' + this.dateEnd.toLocaleDateString())

            let tmpLabels: string[] = new Array(this.chartDpsData.speedBinsLow.length)
            for (let i = 0; i < tmpLabels.length; i++) {
              if (this.chartDpsData.speedBinsLow[i] ==
                this.chartDpsData.speedBinsHigh[i]) {
                tmpLabels[i] = this.librariesService.speedBinTextGet("",
                  this.chartDpsData.unitSpeed,
                  this.chartDpsData.speedBinsLow[i])
              }
              else {
                tmpLabels[i] = this.librariesService.speedBinTextGet("",
                  this.chartDpsData.unitSpeed,
                  this.chartDpsData.speedBinsLow[i],
                  this.chartDpsData.speedBinsHigh[i])
              }
            }
            this.chartJsLabels = tmpLabels

            this.chartJsData[0].data = this.chartDpsData.speedBinsAverage
            this.chartJsData[0].label = '# of Vehicles (Average)'
            this.chartJsData[1].data = this.chartDpsData.speedBinsPeak
            this.chartJsData[1].label = '# of Vehicles (Peak)'

            //this.chartJsType= this.chartJsData[0].data.length==1 ? "bar" : "line"

            // this.chartJsData[1].data = this.chartDpsData.violators
            // this.chartJsData[1].label = 'Violators'

            this.chartDisplay = true;
            this.chartJsOptions = this._chartJsOptions;
            this.getChartJsData_active = false;
          },
          error => {
            this.getChartJsData_active = false;
            this.notificationsService.error("Server Error", this.errorsService.errorParse(error), { clickToClose: true });
          });
  }

  // events
  public chartJsClicked(e: any): void {
    this.onChartJsEvent.emit("click")
    //console.log("chartJsClicked",e);
  }

  public chartJsHovered(e: any): void {
    //console.log(e);
  }

}
