import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class SubscriptionsService extends ApiUuidService {
    constructor(
        protected router: Router,
        protected http: HttpClient,
    ) {
        super(router, http, 'subscriptions');
    }

    Create(subscription: any) {
        var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}`;

        return this.http.post<any>(
            apiParam,
            subscription
        );

    }

    Update(subscription: any) {
        var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}`;

        return this.http.put<any>(
            apiParam,
            subscription
        );
    }

    readByUuid(uuid: string) {
        return this.get(uuid);
    }

    Read() {
        return this.all();
    }


}