import { Injectable } from '@angular/core';

@Injectable()
export class ColorsService {

    APP_COLORS = {
        'primary': '#5d9cec',
        'success': '#27c24c',
        'info': '#23b7e5',
        'warning': '#ff902b',
        'danger': '#f05050',
        'inverse': '#131e26',
        'green': '#37bc9b',
        'pink': '#f532e5',
        'purple': '#7266ba',
        'dark': '#3a3f51',
        'yellow': '#fad732',
        'gray-darker': '#232735',
        'gray-dark': '#3a3f51',
        'gray-medium': '#808080',
        'gray': '#dde6e9',
        'gray-light': '#e4eaec',
        'gray-lighter': '#edf1f2',

        'chart-red': 'rgba(255,0,0,0.5)',
        'chart-red-border': 'rgba(220,20,60,1)',
        'chart-blue': 'rgba(0,0,255,0.5)',
        'chart-blue-border': 'rgba(0,0,255,1)',
        'chart-green': 'rgba(52,168,83,0.85)',
        'chart-green-border': 'rgba(52,168,83,1)',
        'chart-purple': 'rgba(128,0,128,0.5)',
        'chart-purple-border': 'rgba(128,0,128,1)',
        'chart-yellow': 'rgba(251,188,2,0.7)',
        'chart-yellow-border': 'rgba(251,188,2,1)',
        'chart-orange': 'rgba(232,86,35,0.7)',
        'chart-orange-border': 'rgba(232,86,35,1)',

        'map-device-cellular': '#4db546',
        'map-device-wifi': '#5d9cec',
        'map-device-alert': '#ff902b',
        'map-device-warning': '#ffc107',
        'map-device-location': '#7b9eb0',
        'map-device-inactive': '#555555',
        'map-device-hidden': 'transparent',
        'map-device-search': '#4b96f3',
        
    };

    //Another Optoin
    // 'chart-red': 'rgba(220,20,60,0.7)',
    // 'chart-red-border': 'rgba(220,20,60,1)',
    // 'chart-blue': 'rgba(0,0,204,0.7)',
    // 'chart-blue-border':  'rgba(0,0,204,1)',
    // 'chart-green': 'rgba(0,102,0,0.7)',
    // 'chart-green-border':  'rgba(0,102,0,1)',
    // 'chart-purple': 'rgba(128,0,128,0.7)',
    // 'chart-purple-border':  'rgba(128,0,128,1)',
    // 'chart-yellow': 'rgba(255,102,0,0.7)',
    // 'chart-yellow-border':  'rgba(255,102,0,1)',    

    //Google Orange and green
    // 'chart-red': 'rgba(255,0,0,0.7)',
    // 'chart-red-border': 'rgba(220,20,60,1)',
    // 'chart-blue': 'rgba(0,0,255,0.7)',
    // 'chart-blue-border':  'rgba(0,0,255,1)',
    // 'chart-green': 'rgba(52,168,83,0.7)',
    // 'chart-green-border':  'rgba(52,168,83,1)',
    // 'chart-purple': 'rgba(128,0,128,0.7)',
    // 'chart-purple-border':  'rgba(128,0,128,1)',
    // 'chart-yellow': 'rgba(251,188,2,0.7)',
    // 'chart-yellow-border':  'rgba(251,188,2,1)',

    //Original
    // 'chart-red': 'rgba(220,20,60,0.7)',
    // 'chart-red-border': 'rgba(220,20,60,1)',
    // 'chart-blue': 'rgba(0,0,204,0.7)',
    // 'chart-blue-border':  'rgba(0,0,204,1)',
    // 'chart-green': 'rgba(0,102,0,0.7)',
    // 'chart-green-border':  'rgba(0,102,0,1)',
    // 'chart-purple': 'rgba(128,0,128,0.7)',
    // 'chart-purple-border':  'rgba(128,0,128,1)',
    // 'chart-yellow': 'rgba(255,102,0,0.7)',
    // 'chart-yellow-border':  'rgba(255,102,0,1)',

    public palette24: string[] = [
        'FE0000',
        '0026FF',
        'B100FE',
        'FE6A00',
        '017F01',
        'FFD800',
        '01FFFF',
        'FF00D4',
        '00FF01',

        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000',
        '000000'
    ];

    constructor() { }

    byName(name) {
        // console.log(name +' -> ' + this.APP_COLORS[name])
        return (this.APP_COLORS[name] || '#fff');
    }

}
