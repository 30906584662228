<div class="card bg-default col-12 mt-3">
  <div  class="pl-1 pb-1 pt-2">
    <label><strong>&nbsp;</strong><a [routerLink]="['/charts' , deviceLocation?.uuid || '']"><strong>{{deviceLocation?.name || ''}}</strong></a><br />
      &nbsp;{{deviceLocation?.address || ''}}<br />
      &nbsp;{{deviceLocation?.city||''}}{{deviceLocation?.city==null ? '':',&nbsp;'}}{{deviceLocation?.stateProvinceCode||''}}&nbsp;{{deviceLocation?.postalCode||''}}&nbsp;{{deviceLocation?.country||''}}</label>
      <div class="div-menu">
        <a *ngIf="deviceLocation" [routerLink]="['/charts' , deviceLocation?.uuid || '']" title="Open Charts"><span class="btn btn-primary pl-1 pr-1 pt-1 pb-1 mt-0 btn-card"><em class="fas fa-chart-area"></em></span></a><br/>
        <a *ngIf="device" [routerLink]="['/configurations', device?.deviceScheduleUuid || '']" title="Open Schedule"><span class="btn btn-primary pl-1 pr-1 pt-1 pb-1 mt-1 btn-card"><em class="fas fa-calendar-alt"></em></span></a>
      </div>
  </div>
  <div class="p-1 bg-light mb-3">
      <small *ngIf="device" class="pl-2">
        <a [routerLink]="['/devices', device?.uuid || '']" title="{{device?.label}}">Device {{device?.serial}}</a>
      </small>
      <small *ngIf="!device" class="pl-2">No Active Device</small>
      <small class="pr-2 ptx-3 text-right float-right">7-Day Activity</small>
      <div *ngIf="device?.deviceLocation.unitIdSpeed  == '0'" flot [dataset]="chartData" [options]="chartOptions" [height]="125"></div>
      <div *ngIf="device?.deviceLocation.unitIdSpeed == '1'" flot [dataset]="chartData" [options]="chartOptionsKph" [height]="125"></div>

  </div>
</div>