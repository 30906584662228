import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiIdService } from './_api-id.service';

@Injectable()
export class DeviceLocationSettingsService extends ApiIdService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'devicelocationsettings');
  }

  readById(id: number) {
    return this.get(id);
  }

  readByDeviceUuid(uuid: string, sortDescending: boolean): Observable<any> {
    var apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${sortDescending}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  readByDeviceUuidDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date): Observable<any> {
    var apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  create(deviceLocationSetting: any) : Observable<any> {
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}`;
    //var headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }

    return this.http.post<any>(
          apiParam, 
          deviceLocationSetting
        );
  }
  
  deleteById(id: number) {
    return this.delete(id);
  }

}
