<div class="card border-0">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-6 align-middle">
                <input type='text' #dataTableFilter class="form-control"
                    placeholder='Type to filter by Device Model, Serial, or Name/Label...'
                    (keyup)='updateFilter($event)' />
            </div>
        </div>
 
        <ag-grid-angular #agGrid id="subscriptionsTable" name="subscriptionsTable" style="height: 700px;" class="ag-theme-balham subscriptionsTable m-0 pt-2 border-0"
            [animateRows]="true" [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="subscriptions"
            (rowSelected)="onRowSelected($event)" [chartThemes]="chartThemes" [rowStyle]="rowStyle"
            (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions"
            [getChartToolbarItems]="getChartToolbarItems">
        </ag-grid-angular>
     
    </div>
</div>