import { Component, Input, EventEmitter, Output} from '@angular/core';



@Component({
	selector: 'app-popoverControl-component',
	templateUrl: 'popoverControl.component.html',
	styleUrls: ['./popoverControl.component.scss']
})

export class PopoverControlComponent {
	@Input() PopoverBody: string; 
	@Input() PopoverHeader: string;
	@Input() dataText: string;
	@Input() highlightStatus: number = 0;
	@Input() editable: boolean = true;
	@Output() PopoverEvent: EventEmitter<String> = new EventEmitter();

	getPopoverType(){
		if (this.editable == true){
			this.PopoverBody = "To change this field, click the Edit button to change this form";
			this.PopoverHeader = "Need Help?";

		}
		else {
			
		}
	}

	onShown() {
		this.highlightStatus=1;
		this.PopoverEvent.emit("Popover-Shown");
		document.getElementById("highlight").style.border="5px solid yellow";
		document.getElementById("highlight").style.animationName = "highlighterAnimation";
		document.getElementById("highlight").style.animationDuration = "10s";
		console.log(this.editable)
		
	}

	onHidden() {
		document.getElementById("highlight").style.border = "1.5px solid transparent";
		this.highlightStatus=0;
		this.PopoverEvent.emit("Popover-Hidden");
		
		
	}


}



