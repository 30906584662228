import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

export enum deviceStatuses {
  OutOfService = -1,
  Inactive = 0,
  Active = 10,
  LteOffline = 20,
  LteOnline = 30
}

@Injectable()
export class DevicesService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'devices');
  }

  create(device: any): Observable<any> {
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/register`;
    //var headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }

    return this.http.post<any>(
      apiParam,
      device
    );
  }

  read(admin: boolean = false) {
    return admin ? this.http.get(`${this.getApiUrl()}/${this.apiRoute}/admin`) : this.all()
  }

  readByUuid(uuid: string) {
    return this.get(uuid);
  }

  readByConfigMapIdCurrent(id: number) {
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/configmapid/${id}`);
  }

  countByCommunicationType(commType: number) {
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/count/${commType}`);
  }

  readPeriodAnalysisByDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date,
    timeSegment: number,
    timeFilterBegin: number = 0,
    timeFilterEnd: number = 24): Observable<any> {
    var apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;
    apiParam = apiParam + `/` + timeSegment.toFixed(0).toString();
    apiParam = apiParam + `/` + timeFilterBegin.toFixed(2).toString();
    apiParam = apiParam + `/` + timeFilterEnd.toFixed(2).toString();

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/PeriodAnalysis/${apiParam}`);
  }

  readBinAnalysisByDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date,
    speedBinOneOption: number,
    speedBinBreakOne: number,
    speedBinTwoOption: number,
    speedBinBreakTwo: number,
    speedBinThreeOption: number): Observable<any> {
    var apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;
    apiParam = apiParam + `/` + speedBinOneOption.toFixed(0).toString();
    apiParam = apiParam + `/` + speedBinBreakOne.toFixed(0).toString();
    apiParam = apiParam + `/` + speedBinTwoOption.toFixed(0).toString();
    apiParam = apiParam + `/` + speedBinBreakTwo.toFixed(0).toString();
    apiParam = apiParam + `/` + speedBinThreeOption.toFixed(0).toString();

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/BinAnalysis/${apiParam}`);
  }

  readTelemetryByUuidAndDateRange(uuid: string, beginDate: Date, endDate: Date): Observable<any> {
    let apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getUTCMonth() + 1}-${beginDate.getUTCDate()}-${beginDate.getUTCFullYear()}`;
    apiParam = apiParam + `/${endDate.getUTCMonth() + 1}-${endDate.getUTCDate()}-${endDate.getUTCFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/Telemetry/${apiParam}`);
  }

  readRecentTelemetryByUuid(uuid: string, days: number): Observable<any> {
    const apiParam = `${uuid}/recent/${days}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/Telemetry/${apiParam}`);
  }

  readRecentConnectionsByUuid(uuid: string, days: number): Observable<any> {
    const apiParam = `${uuid}/recent/${days}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/Connections/${apiParam}`);
  }

  readConnectionsByUuidAndDateRange(uuid: string, beginDate: Date, endDate: Date): Observable<any> {
    let apiParam: string = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getUTCMonth() + 1}-${beginDate.getUTCDate()}-${beginDate.getUTCFullYear()}`;
    apiParam = apiParam + `/${endDate.getUTCMonth() + 1}-${endDate.getUTCDate()}-${endDate.getUTCFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/Connections/${apiParam}`);
  }

  readImagesByDeviceUuid(uuid: string, startDate: String, count: number, descending: Boolean, includeUnrecognizedPlates: Boolean, plateNumber: string): Observable<any> {
   // let apiParam: string = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`;
    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${uuid}/images/${startDate}/${count}/${descending}/${includeUnrecognizedPlates}/${plateNumber}`);
  }

  queueRealtimeData(uuid: string): Observable<any> {
    const apiParam = `${uuid}/queuerealtimedata`;

    return this.http.put(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`, null);
  }

  bulkUpdate(devices: any){
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/bulkupdate`;

    return this.http.put<any>(
      apiParam,
      devices
    );

  }

  getDeviceBySerial(serial: number){
    var apiParam: string = `${serial}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/Search/${apiParam}`);

    

  }
}
