<ngx-spinner name="deviceconfiguration"></ngx-spinner>
<div class="card border-0">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-6 align-middle">
                <input type='text' #datatableFilter class="form-control" placeholder='Type to filter by Name or Description'
                    (keyup)='updateFilter($event.target.value.toLowerCase())' />
            </div>
        </div>
        <ag-grid-angular #agGrid id="devicesViewTable" name="devicesViewTable" style="height: 78vh; width: 100%;"
            class="ag-theme-balham devicesViewTable m-0 pt-2 border-0" (window:resize)="onResize($event)"
            [animateRows]="true" [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="deviceSchedules"
            (rowSelected)="onRowSelected($event)" [chartThemes]="chartThemes" [rowStyle]="rowStyle"
            (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions"
            [getChartToolbarItems]="getChartToolbarItems">
        </ag-grid-angular>
    </div>
</div>

<!-- <ngx-datatable #table class='bootstrap no-detail-row' (select)='onSelect($event)' [selectionType]="'single'"
    [columnMode]="'force'" [footerHeight]="20" [rowHeight]="'auto'" [limit]="15" [rows]='deviceSchedules'>

    <ngx-datatable-column [resizeable]="false" name="Name" minWidth="75">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.name}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Description" minWidth="75">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.description}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Device Count" minWidth="75">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.deviceCount}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Configuration Type" minWidth="75">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.typeId == 0 ? "Static" : "Calendar"}}</div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable> -->
<!-- </div>
</div> -->