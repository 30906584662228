/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */

import { Component, Input } from '@angular/core';

@Component({

  selector: 'ng-bootstrap-tab',
  templateUrl: './tabControl.component.html',
  styleUrls: ['./tabControl.component.scss'],
})

export class TabControlComponent {
  @Input('tabTitle') title: string;
  @Input() public active: boolean = false;
  @Input() public isCloseable: boolean = false;
  @Input() public hidden: boolean = false;
  private _disabled: boolean = false;
  get disabled(): boolean {
    return this.active ? false : this._disabled;
  }
  @Input() set disabled(newDisabled: boolean) {
    this._disabled = newDisabled;
  }
  @Input() template;
  @Input() dataContext;
}
