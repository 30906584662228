
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, Directive, AfterViewInit, OnDestroy, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { AgChartPaddingOptions, GridOptions } from 'ag-grid-community';
import { DeviceAdministrationFormComponent } from '../../../shared/component/forms/devices/deviceadministration-form/deviceadministration-form.component';
import { DeviceRegistrationsViewComponent } from '../../../shared/component/views/deviceregistrationsview/deviceregistrationsview.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { Subject } from 'rxjs';
import { FirmwaresService, ClientsService } from '../../../core/api/api.services';
import { ConnectivityTypeIdService } from '../../../core/api/connectivitytypes.service';
import { ModelsService } from '../../../core/api/models.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';
import { ErrorsService } from '../../../core/errors/errors.service';
import { DevicesService } from '../../../core/api/devices.service';
import { ModemsService } from '../../../core/api/modems.service';
import { DeviceConnectionsViewComponent } from '../../../shared/component/views/deviceconnectionsview/deviceconnectionsview.component';
import { DeviceTelemetriesViewComponent } from '../../../shared/component/views/devicetelemetriesview/devicetelemetriesview.component';


@Component({
  selector: 'app-devices-admin-admin',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DeviceAdministrationFormComponent) public deviceAdminForm: DeviceAdministrationFormComponent;
  @ViewChild(DeviceRegistrationsViewComponent) deviceRegView: DeviceRegistrationsViewComponent;
  @ViewChild(DeviceConnectionsViewComponent) public deviceConnectView: DeviceConnectionsViewComponent;
  @ViewChild(DeviceTelemetriesViewComponent) public deviceTelemView: DeviceTelemetriesViewComponent;

  //@ViewChild("btnDevWiFi") btnDevWiFi: ElementRef;

  userTypes = userTypes;
  userRoles = userRoles;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public clientsService: ClientsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public firmwaresService: FirmwaresService,
    public modelsService: ModelsService,
    public modemsService: ModemsService,
    public connectivityTypeIdService: ConnectivityTypeIdService,

    private errorsService: ErrorsService,
    private modalService: BsModalService,
    private devicesService: DevicesService,

    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder
  ) { }

  private firstLoad: boolean = true;

  public modalRef: BsModalRef;
  public bulkList: any[] = [];

  private paramSub: any;
  public showDeviceForm: boolean = false;

  public gridOptions: GridOptions;
  public serialsSelected: string;
  public bulkItemList = [
    {
      option: "Client",
      value: 0
    },
    {
      option: "Firmware",
      value: 1
    }
  ]
  public toUpdateList: any[] = [];
  public clients: any[] = [];
  public firmwares: any[] = [];
  public modems: any[] = []
  public models: any[] = [];
  public connectivityTypes: any[] = [];
  public bulkClient: boolean = false;
  public bulkFirmware: boolean = false;

  public clientList = new FormControl();

  public get dataLoading(): boolean {
    if (!this.deviceRegView) return false;
    return this.deviceRegView.dataLoading;
  }
  public viewCurrent = 'All';
  private _deviceUuid: string = "";
  get deviceUuid(): string {
    return this._deviceUuid;
  }
  set deviceUuid(newDeviceUuid: string) {

    if (newDeviceUuid && this.librariesService.guidValidate(newDeviceUuid)) {
      this._deviceUuid = newDeviceUuid;
      this.showDeviceForm = true;
    }
    else {
      this._deviceUuid = "";
      this.showDeviceForm = false;

    }
  }

  public updateForm: FormGroup;


  public fgDevice: FormGroup = this.formBuilder.group({
    clientUuid: "",
    serial: ['', [Validators.required, Validators.minLength(6)]],
    modelId: [null, [Validators.required]],
    firmwareUuidTarget: [null, [Validators.required]],
    connectivityTypeId: [0, Validators.required],
    modemImei: ['', [
      function (control: AbstractControl) {
        if (!control.parent) return null;
        if (control.parent.value['connectivityTypeId'] == 0 || (control.value && control.value >= 0)) {
          return null;
        }
        return { modemImei: false }
      }

    ]],
    label: ['', [Validators.required, Validators.minLength(2)]],
    modemId: [null, [
      function (control: AbstractControl) {
        if (!control.parent) return null
        if (control.parent.value['connectivityTypeId'] == "0" || (control.value && control.value >= 0)) {
          return null
        }
        return { modemId: false }
      },

    ]],
    simIccid: "",
    simImsi: ""
  });

  public fgDeviceUpdate: FormGroup = this.formBuilder.group({
    updateOptions: [0, Validators.required],
    deviceUuids: this.formBuilder.array([]),
    clientUuid: [null, Validators.required],
    firmwareUuidTarget: [null, Validators.required]

  })


  ngOnInit() {

    var self = this;
    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.deviceUuid = params['uuid'];
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.dataRefresh();

  }

  dataRefresh() {
    var self = this;
    if (!this.settingsService.client ||
      !this.deviceRegView) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }
    else {
      self.getClients();
    }
  }

  onDataLoading(event) {
    if (this.dataLoading && this.firstLoad) this.settingsService.showSpinner("devices", false, 250);
    this.firstLoad = false;

    if (!this.dataLoading) this.settingsService.hideSpinner("devices");
  }


  onFormEventDevice(event) {

    switch (event.toString()) {
      case "saved":
        {
          //alert(this.deviceUuid)
          break;
        }
      case "cancelled":
        {
          // //this.showDeviceForm = false;
          // this.deviceUuid = "";
          // //this.location.go(this.router.createUrlTree(['/administration/devices/all'], { relativeTo: this.route }).toString())
          // this.router.navigate(['/administration/devices/all'])
          break;
        }
    }

  }

  onDeviceSelected(event) {

    if (event && event.uuid && event.uuid.toString().length > 10) {
      //var url = this.router.createUrlTree(['.',event.toString()], {relativeTo: this.route})
      //this.location.go(url.toString());
      this.router.navigate(['/administration/devices', event.uuid.toString()], { replaceUrl: false })
      //this.router.navigateByUrl('/administration/devices/'+event.toString())
    }
  }

  onSelectTab(data: TabDirective): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      //console.log(data)
    }, 25);
  }

  refreshTelemetries(event) {
    if (sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')) {
      if ((this.deviceTelemView.fgData.get('dateRange').value[0] != sessionStorage.getItem('dateBegin')) ||
        (this.deviceTelemView.fgData.get('dateRange').value[1] != sessionStorage.getItem('dateEnd'))) {
        this.deviceTelemView.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
        this.deviceTelemView.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
        this.deviceTelemView.fgData.patchValue({
          dateRange: [this.deviceTelemView.dateBegin, this.deviceTelemView.dateEnd]
        })
        this.onSelectTab(event);
      } else {
        this.onSelectTab(event);
      }
    } else {
      this.onSelectTab(event);
    }
  }

  refreshConnections(event) {
    if (sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')) {
      if ((this.deviceConnectView.fgData.get('dateRange').value[0] != sessionStorage.getItem('dateBegin')) ||
        (this.deviceConnectView.fgData.get('dateRange').value[1] != sessionStorage.getItem('dateEnd'))) {
        this.deviceConnectView.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
        this.deviceConnectView.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
        this.deviceConnectView.fgData.patchValue({
          dateRange: [this.deviceConnectView.dateBegin, this.deviceConnectView.dateEnd]
        });
        this.onSelectTab(event);
      } else {
        this.onSelectTab(event);
      }
    } else {
      this.onSelectTab(event);
    }
  }

  buttonAdd(template: TemplateRef<any>) {
    //alert(this.deviceAdminForm.formState)
    //this.showDeviceForm = true;
    //this.deviceAdminForm.formState = this.deviceAdminForm.formStates.New
    this.getModels();
    this.getFirmwares();
    this.getConnectivityTypes();
    this.getModems();
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  buttonUpdate(template: TemplateRef<any>) {

    if (this.deviceRegView.getSelectedNodes().length <= 0) {
      this.notificationsService.warn('Validation Warning (Bulk Update)',
        'Please Select Devices to Update', { timeOut: 5000, clickToClose: true });
      return;
    }

    this.getClients();
    this.getFirmwares();
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    console.log(this.deviceRegView.getSelectedNodes().length)
    this.bulkList = this.deviceRegView.getSelectedNodes();

    for (var i = 0; i < this.bulkList.length; i++) {
      if (i < 1) {
        this.serialsSelected = this.bulkList[i].data.serial

      }
      else {
        this.serialsSelected += ", " + this.bulkList[i].data.serial;

      }
    }
  }

  buttonBack(event) {
    this.router.navigate(['/administration/devices/all'])
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  buttonEdit(event) {
    this.deviceAdminForm.formState = this.deviceAdminForm.formStates.Edit
  }

  buttonSave(event) {
    this.deviceAdminForm.formState = this.deviceAdminForm.formStates.Save
    this.eventsService.triggerDataRefresh();
  }

  buttonCancel(event) {
    this.deviceAdminForm.formState = this.deviceAdminForm.formStates.Cancelled
  }

  buttonListing(event) {
    // this.router.navigate(['/devices/wifi/all'])
    this.router.navigate(['/administration/devices/all'])
  }

  buttonRefresh(event) {
    this.deviceRegView.getDevices();
  }


  deviceRegisterConfirm() {
    this.fgDevice.value.clientUuid = this.settingsService.client.uuid;

    if (!this.fgDevice.valid && this.fgDevice.controls.connectivityTypeId.value != 0) {
      this.librariesService.validateFormGroup(this.fgDevice);
      return
    }

    if (this.fgDevice.controls.connectivityTypeId.value == 0) {
      this.fgDevice.value.modemId = null;
      this.fgDevice.value.modemImei = '';
    }

    this.devicesService.create(this.fgDevice.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.modalRef.hide();
          this.fgDevice.reset();
          this.router.navigate(['/administration/devices', result.uuid], { replaceUrl: false })
        },
        error => {
          console.log(this.errorsService.errorParse(error))
          this.notificationsService.error("Save Error or Duplicate Serial", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });

  }

  deviceRegisterCancel(): void {
    this.modalRef.hide();
    this.serialsSelected = "";
    this.bulkList = [];
    this.fgDevice.reset();
    this.fgDevice.controls['connectivityTypeId'].setValue(0);

  }

  getModels(): any {
    this.models = [];

    if (!this.settingsService.client) return;

    return this.modelsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.models = result;
        },
        error => {
          this.notificationsService.error("Server Error (Getting Models)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }



  getModems(): any {
    this.modems = [];

    if (!this.settingsService.client) return;

    return this.modemsService.read()
      .pipe(
        takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.modems = result;
        },
        error => {
          this.settingsService.hideSpinner("deviceAdminForm");
          this.notificationsService.error("Server Error (Getting Modems)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getFirmwares(): any {
    this.firmwares = [];

    if (!this.settingsService.client) return;

    return this.firmwaresService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.firmwares = result;
          console.log(this.firmwares)
        },
        error => {
          this.notificationsService.error("Server Error (Getting Firmwares)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getConnectivityTypes(): any {
    this.connectivityTypes = [];
    if (!this.settingsService.client) return;
    return this.connectivityTypeIdService.read().pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.connectivityTypes = result;
        },
        error => {
          this.notificationsService.error("Server Error (Getting ConnectivityTypes)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });


  }

  getClients(): any {
    this.clients = [];

    //alert(this.deviceUuid);

    return this.clientsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.clients = result;
          console.log(this.clients)
        },
        error => {
          this.notificationsService.error("Server Error (getClients)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  dropDownChange(event) {
    console.log(event)
    this.toUpdateList = [];
    if (event == "Client") {
      for (var i = 0; i < this.clients.length; i++) {
        this.toUpdateList[i] = this.clients[i]
        this.fgDeviceUpdate.value.firmwareUuidTarget = null;
        //this.fgDeviceUpdate.setValue('firmwareUuidTarget')

      }
    }
    else if (event == "Firmware") {
      for (var i = 0; i < this.firmwares.length; i++) {
        this.toUpdateList[i] = this.firmwares[i]
        this.fgDeviceUpdate.value.clientUuid = null;

      }
    }


  }

  devicesUpdate() {

    for (var i = 0; i < this.bulkList.length; i++) {
      this.fgDeviceUpdate.value.deviceUuids[i] = this.bulkList[i].data.uuid;
    }
    //console.log(this.fgDeviceUpdate);
    if (this.fgDeviceUpdate.value.clientUuid == null) {
      this.fgDeviceUpdate.value.clientUuid = this.settingsService.client.uuid;
    }
    return this.devicesService.bulkUpdate(this.fgDeviceUpdate.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          let myResult = result;
          console.log(myResult);
          this.modalRef.hide();
          this.dataRefresh();

          this.notificationsService.success("Success", "Devices Updated", { timeOut: 2000, clickToClose: true });


        },
        error => {
          this.notificationsService.error("Server Error (Device Update Failed)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });


  }
}
