<ngx-spinner name="alertsSpinner"></ngx-spinner>
<div class="row">
  <div class="col-12">
    <div class="card bg-default">
      <div class="card-header">



        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <h4 class="pt-2 pb-0 mt-1">Device Alerts</h4>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 float-right text-right row pr-0 mr-0">

            <table class="table m-0 p-0 border-0">
              <tbody>
                <tr>
                  <td class="m-0 p-0 border-0 text-nowrap">
                    <!-- <div class="btn btn-primary pl-2 pr-2 pt-1 pb-1 mr-3" (click)="buttonMapUS($event)">
                      <span class="fas fa-globe-americas"></span></div> -->
                    <button class="btn btn-primary m1-2" (click)="dataRefresh()">
                      <img *ngIf="!dataLoading" src="assets/img/spinner-arrows-static.png" width="22" height="22">
                      <img *ngIf="dataLoading" src="assets/img/spinner-arrows.png" width="22" height="22">
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>


      </div>
    </div>
  </div>
</div>


<div class="row mt-3">
  <div class="col-lg-12">
    <div class="card bg-default">
      <div class="card-header"><h4 class="mt-0 mb-0">Active</h4>
        <div class="bg-body mt-2">
          <app-topalertsview (onDataLoading)="onDataLoading($event)" (onClearAlert)="onClearAlert($event)"></app-topalertsview>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-lg-12">
    <div class="card bg-default">
      <div class="card-header"><h4 class="mt-0 mb-0">Cleared/Dismissed</h4>
        <div class="bg-body mt-2">
          <app-alertsgrid (onDataLoading)="onDataLoading($event)" (onDeviceAlertsSelected)="onDeviceAlertsSelected($event)" ></app-alertsgrid>
        </div>
      </div>
    </div>
  </div>
</div>