<div class="card border-0">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-6 align-middle">
                <input type='text' #dataTableFilter class="form-control"
                    placeholder='Type to filter by Device Model, Serial, or Name/Label...'
                    (keyup)='updateFilter($event)' />
            </div>
        </div>
        <ag-grid-angular #agGrid id="devicesViewTable" name="devicesViewTable" style="height: 78vh; width: 100%;"
            class="ag-theme-balham devicesViewTable m-0 pt-2 border-0" (window:resize)="onResize($event)"
            [animateRows]="true" [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="devices"
            (rowSelected)="onRowSelected($event)" [chartThemes]="chartThemes" [rowStyle]="rowStyle"
            (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions"
            [getChartToolbarItems]="getChartToolbarItems">
        </ag-grid-angular>
    </div>
</div>

<!-- <ngx-datatable #table class='bootstrap no-detail-row' (select)='onSelect($event)' [selectionType]="'single'"
    [selected]="devicesSelected" [columnMode]="'force'" [footerHeight]="20" [rowHeight]="'auto'" [limit]="15"
    [rows]='devices'>

    <ngx-datatable-column [resizeable]="false" name="Type" minWidth="50" maxWidth="50" prop="connectivityTypeId">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div *ngIf="row.connectivityTypeId==00" class="mt-2 text-primary"><em class="fas fa-wifi fa-2x"></em></div>
            <div *ngIf="row.connectivityTypeId==10" class="mt-2 text-green"><em class="fas fa-signal-alt fa-2x"></em>
            </div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngIf="!chartView" [resizeable]="false" name="Serial/Model" minWidth="75" maxWidth="150"
        prop="serial">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div><a href="/devices/{{row.uuid}}">{{row.serial}}</a><br>{{row.model?.code}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngIf="chartView" [resizeable]="false" name="Serial/Model" minWidth="75" maxWidth="150"
        prop="serial">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div *ngIf="!row.deviceLocation"><a href="/charts/{{row.uuid}}">{{row.serial}}</a><br>{{row.model?.code}}</div>
            <div *ngIf ="row.deviceLocation"><a href="/charts/{{row.deviceLocation.uuid}}">{{row.serial}}</a><br>{{row.model?.code}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Name/Schedule" prop="label">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.label}}<br>{{row.deviceSchedule?.name}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Current Location Name" prop="deviceLocation.name">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.deviceLocation?.name}}<br>Vehicle Flow: {{row.deviceLocation?.bearing?.description}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Nearest Street Address" prop="deviceLocation.address">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.deviceLocation?.address}}<br
                    *ngIf="row.deviceLocation?.address">{{getLocationAddressDisplay(row)}}</div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [resizeable]="false" name="Last Communication" prop="lastActivity">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.lastActivity | date:'MM-dd-yyyy HH:mm:ss'}}</div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable> -->