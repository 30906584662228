import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiIdService } from './_api-id.service';

@Injectable()
export class DeviceDailySummariesService extends ApiIdService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
  ) {
    super(router, http, 'devicedailysummaries');
  }

  fileUploadUrl() {
    return (`${this.getApiUrl()}/${this.apiRoute}/upload`);
  }

  read() {
    return this.all();
  }

  readById(id: number) {
    return this.get(id);
  }

  readDeviceDailySummariesSummaryByDeviceRecent(
    uuid: string,
    sortDescending: Boolean): Observable<any> {
    let apiParam = `${uuid}/recent`;
    apiParam = apiParam + `/${sortDescending}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  readDeviceDailySummariesSummaryByDeviceRecentCount(
    uuid: string,
    sortDescending: Boolean,
    count: number): Observable<any> {
    let apiParam = `${uuid}/recent`;
    apiParam = apiParam + `/${sortDescending}`;
    apiParam = apiParam + `/${count}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  readDeviceDailySummariesSummaryByDeviceDateRange(
    uuid: string,
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam = `${uuid}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/${apiParam}`);
  }

  readDeviceDailySummariesSummaryMonthByDateRange(
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam = `${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/SummaryMonth/${apiParam}`);
  }

  readDeviceDailySummariesSummaryMonthByDeviceDateRange(
    uuidDevice: string,
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam = `${uuidDevice}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/SummaryMonth/${apiParam}`);
  }

  readDeviceDailySummariesSummaryDayByDateRange(
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam = `${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/SummaryDay/${apiParam}`);
  }

  readDeviceDailySummariesSummaryDayByDeviceDateRange(
    uuidDevice: string,
    beginDate: Date,
    endDate: Date): Observable<any> {
    let apiParam = `${uuidDevice}`;
    apiParam = apiParam + `/${beginDate.getMonth() + 1}-${beginDate.getDate()}-${beginDate.getFullYear()}`;
    apiParam = apiParam + `/${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/SummaryDay/${apiParam}`);
  }

  readDeviceDailySummariesSummaryDayByRecentDays(
    uuidDevice: string,
    days: number): Observable<any> {
    
    let apiParam = `${uuidDevice}/recent/${days}`;

    return this.http.get(`${this.getApiUrl()}/${this.apiRoute}/SummaryDay/${apiParam}`);
  }


}
