<form *ngIf="isEditMode" [formGroup]="fgDailyEventsCalendar" class="row">
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-2 pl-1 pr-1">
        <label>Schedule</label>
        <ng-select #eventSchedulesControl [selectOnTab]="true" [items]="DeviceSchedule.deviceScheduleConfigurations"
            bindValue="sequence" bindLabel="name" [clearable]="true" id="sequence" name="sequence"
            formControlName="sequence" placeholder="No Schedule">
            <ng-template ng-label-tmp let-item="item">
                <span class="fas fa-calendar-alt pr-2" [style.color]="'#'+item.colorCode"></span>{{item.name}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">

                <span class="fas fa-calendar-alt pr-2" [style.color]="'#'+item.colorCode"></span>{{item.name}}

            </ng-template>

        </ng-select>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-2 pl-1 pr-1">
        <label>Selected Date Range</label>

        <div class="input-group" [hidden]="true">
            <div class="form-control">
                {{!CalendarDateBegin || !CalendarDateEnd ? 'No Range Selected' : (CalendarDateBegin | date:'shortDate')+ ' to '+(CalendarDateEnd | date:'shortDate') }}
            </div>
            <div class="input-group-append"> 
                <span class="input-group-text input-group-text-override-Warning btn btn-warning" btnCheckbox formControlName="applyAuto"><i
                        class="pl-1 pr-1 fas fa-bolt"></i></span>
            </div>
        </div>
        <div class="input-group" >
            <div class="input-group-prepend">
                    <span tooltip="Auto-Apply Enabled or Disable" placement="bottom" delay="500" class="input-group-text input-group-text-override-Warning btn btn-warning" btnCheckbox formControlName="applyAuto"><i
                        class="pl-1 pr-1 fas fa-bolt"></i></span>
            </div>
            <input readonly style="background-color: white;" class="form-control" #drp="bsDaterangepicker"
                bsDaterangepicker formControlName="dateRange" [bsConfig]="{showWeekNumbers: false}" (bsValueChange)="onControlSelectChange($event)">
            <div class="input-group-append">
                <span tooltip="Calendar Range Select" placement="bottom" delay="500" class="input-group-text input-group-text-override fas fa-calendar btn btn-primary"
                    (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></span>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2 pl-1 pr-1">
        <label>{{fgDailyEventsCalendar.get('applyAuto').value ? "Range Applcation Method (Auto-Apply Enabled)" : "Click To Apply Schedule To Selected Range"}}</label><br />
        <button [hidden]="fgDailyEventsCalendar.get('applyAuto').value" type="button" class="btn btn-primary ml-1"
            (click)="ApplyRange(0)"><span class="fas fa-calendar-alt pr-2"></span>All Days</button>
        <button [hidden]="fgDailyEventsCalendar.get('applyAuto').value"  type="button" class="btn btn-primary ml-1"
            (click)="ApplyRange(1)"><span class="fas fa-calendar-week pr-2"></span>Weekdays</button>
        <button [hidden]="fgDailyEventsCalendar.get('applyAuto').value"  type="button" class="btn btn-primary ml-1"
            (click)="ApplyRange(2)"><span class="fas fa-calendar-day pr-2"></span>Weekends</button>
            <button [hidden]="fgDailyEventsCalendar.get('applyAuto').value" [disabled]="(CalendarUndo.length==0)" type="button"
            class="btn btn-danger ml-2" (click)="ApplyUndo($event)"
            tooltip="Undo Last Change" placement="bottom" delay="500"><span
                class="fas fa-undo"></span></button>

        <table class="p-0 m-0 b-0" [hidden]="!fgDailyEventsCalendar.get('applyAuto').value">
            <tbody>
                <tr>
                    <td class="text-nowrap"><span class="input-group btn-group border-0 text-nowrap" btnRadioGroup
                            formControlName="applyType">
                            <label class="btn btn-primary border-0 m-0" btnRadio="0" tabindex="0" role="button"><span
                                    class="fas fa-calendar-alt pr-2"></span>All</label>
                            <label class="btn btn-primary border-0 m-0" btnRadio="1" tabindex="0" role="button"><span
                                    class="fas fa-calendar-week pr-2"></span>Weekdays</label>
                            <label class="btn btn-primary border-0 m-0" btnRadio="2" tabindex="0" role="button"><span
                                    class="fas fa-calendar-day pr-2"></span>Weekends</label>

                        </span></td>
                    <td class="text-nowrap"><button [disabled]="(CalendarUndo.length==0)" type="button"
                            class="btn btn-danger ml-2" (click)="ApplyUndo($event)"
                            tooltip="Undo Last Change" placement="bottom" delay="500"
                            ><span
                                class="fas fa-undo"></span></button></td>
                </tr>
            </tbody>
        </table>

    </div>
</form>

<div class="row">
    <div class="col-12 pl-1 pr-0">
        <app-bootstrap-year-calendar #dailyEventsCalendarView (clickDay)="onClickDay($event)"
            (dayContextMenu)="onDayContextMenu($event)" (selectRange)="onCalendarSelectRange($event)">
        </app-bootstrap-year-calendar>
    </div>
</div>
<!-- {{ fgDailyEventsCalendar.value | json}} -->
<!-- {{ DeviceSchedule?.deviceScheduleConfigurations | json}} -->