
import {map, filter,  takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { ApiService } from '../api/_api.service';
import { LibrariesService } from '../libraries/libraries.service';
import { EventsService } from '../events/events.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService extends ApiService implements OnInit, OnDestroy {

    // private _isAuthenticating: boolean = false;
    // get IsAuthenticating():boolean {
    //     return this._isAuthenticating;
    // }
    // set IsAuthenticating(isAuthenticating:boolean){
    //     this._isAuthenticating = isAuthenticating;
    // }

    //Observables
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        protected router: Router,
        protected http: HttpClient,
        protected eventsService: EventsService,
        protected librariesService: LibrariesService) {
        super(router, http, "token");
    }

    ngOnInit() {
  
    }
    
    ngOnDestroy() {
        this.onDestroy$.next();
    }
    
    private _authResponse: any;
    private _authResponseRefresh: Date;
    get authResponse(): any {

        let dateNow = new Date()
        
        if (!this._authResponse || 
            !this._authResponseRefresh ||
            (dateNow.getTime() - this._authResponseRefresh.getTime())/1000 >= 60
            ) {

            this.authResponseClear()
            this._authResponse = JSON.parse(localStorage.getItem('authenticationResponse'));;
            this._authResponseRefresh = dateNow;
            
        }
        return this._authResponse
    }
    private authResponseClear(){
        this._authResponse = null;
        this._authResponseRefresh = null;
        this._tokenPayload = null;
    }

    get tokenAccess(): string {
        if (this.authResponse && this.authResponse.access_token) {
            return this.authResponse.access_token;
        }
        return '';
    }
    get tokenRefresh(): string {
        if (this.authResponse && this.authResponse.refresh_token) {
            return this.authResponse.refresh_token;
        }
        return '';
    }
    get tokenExpires(): Date {
        if (this.authResponse && this.authResponse.expires) {
            return this.authResponse.expires;
        }
        return null;
    }

    private _tokenPayload: any = null;
    get tokenPayload(): any {
        if (this._tokenPayload) return this._tokenPayload;
        if (this.tokenAccess == '') return [];
        this._tokenPayload = this.parseJwt(this.tokenAccess);
        return this._tokenPayload;
    }

    get tokenUserUuid(): string {
        let tknSub = '';
        if (this.tokenPayload && this.tokenPayload.sub) tknSub = this.tokenPayload.sub.toString()
        if (this.librariesService.guidValidate(tknSub))
            return tknSub;
        return ''
    }

    public parseJwt(tokenAccess) {
        var base64Url = tokenAccess.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    };

    login(username: string, password: string) {

        localStorage.setItem('authenticationResponse', null);
        this.authResponseClear();

        return this.http.post<any>(this.getApiUrl() + '/token/auth',
            { grant_type: "password", username: username, password: password }).pipe(
            map(authReq => {
                // login successful if there's a jwt token in the response
                if (authReq && authReq.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('authenticationResponse', JSON.stringify(authReq));
                    this.eventsService.sendEvent(this.eventsService.eventTypes.UserLogin,'');
                    return authReq;
                }
                else {
                    return {
                        error: true,
                        errorText: "Invalid Authentication Response"
                    }
                }

            }));
    }

    clear() {
        // remove user from local storage to log user out
        this.authResponseClear();
        localStorage.removeItem('authenticationResponse');
        //this.router.navigateByUrl('/login')
    }

    logout() {

        this.clear();

        //this.router.navigateByUrl('/login')
        this.eventsService.sendEvent(this.eventsService.eventTypes.UserLogout,'');
        window.location.href = '/login';
    }
}