
<div class="row">
  <!-- <div class="col-md-12" *ngIf="showUpload">
    <div ng2FileDrop [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="box-upload">
      <span class="fas fa-cloud-upload" style="padding-right: 10px"></span>Drag & Drop Files Here To Upload Or
      <label *ngIf="!multipleFiles" class="file-upload-link">Click Here<input style="display: none" id="file=upload-link" type="file" ng2FileSelect [uploader]="uploader"/></label>
      <label *ngIf="multipleFiles" class="file-upload-link">Click Here<input style="display: none" id="file=upload-link" type="file" ng2FileSelect [uploader]="uploader" multiple/>
      </label>
      To Select Files
    </div>
  </div> -->
  <!-- <div class="col-md-2 bar-upload align-middle text-center">
            <div class="badge badge-upload badge-success ng-binding ng-scope" *ngIf="uploader?.queue?.length">Queue length: {{ uploader?.queue?.length }}</div>
        </div>  -->
  <div class="col-md-3 bar-upload bar-upload-btn align-middle float-right text-middle">
    <label for="file1" class="file-upload-btn">
      <label class="btn-sm btn-primary btn-block file-upload-btn">
        <span class="fas fa-folder-open" style="padding-right: 10px"></span>
        Select Files To Upload
        <input style="display: none" id="file1" type="file" ng2FileSelect [uploader]="uploader" multiple/>
      </label>
    </label>
  </div>
</div>
<div class="row">
  <div class="col-md-12" style="margin-bottom: 10px">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th [hidden]="!uploader.options.isHTML5">Size</th>
          <th [hidden]="!uploader.options.isHTML5">Progress</th>
          <th class="text-center">Status</th>
          <th class="text-right">Actions/Messages</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of uploader.queue">
          <td class="td-upload-table">
            <strong>{{ item?.file?.name }}</strong>
          </td>
          <td class="td-upload-table" *ngIf="uploader.options.isHTML5" nowrap>
            {{ item?.file?.size / 1024 | number : ".1-1" }} KB
          </td>
          <td class="td-upload-table" *ngIf="uploader.options.isHTML5">
            <div class="progress" style="margin-bottom: 0">
              <div class="progress-bar" role="progressbar" [ngStyle]="{ width: item.progress + '%' }"></div>
            </div>
          </td>
          <td class="text-center td-upload-table">
            <span *ngIf="item.isSuccess">
              <i class="text-success fas fa-check"></i>
            </span>
            <span *ngIf="item.isCancel">
              <i class="text-warning fas fa-ban mr"></i>
            </span>
            <span *ngIf="item.isError">
              <i class="text-danger fas fa-remove"></i>
            </span>
          </td>
          <td class="td-upload-table text-right" nowrap>
            <span *ngIf="item.isSuccess">
              <i class="font-weight-bold text-success">{{uploaderFileItemMessage(item)}}</i>
            </span>
            <span *ngIf="item.isCancel">
              <i class="font-weight-bold text-warning">{{uploaderFileItemMessage(item)}}</i>
            </span>
            <span *ngIf="item.isError">
              <i class="font-weight-bold text-danger">{{uploaderFileItemMessage(item)}}</i>
            </span>
            <span>
              <button type="button" class="btn btn-primary btn-xs mr-2" (click)="item.upload()" [hidden]="item.isReady || item.isUploading || item.isSuccess || item.isError" 
                [disabled]="this.deviceLocForm?.formState != this.deviceLocForm?.formStates.Read">
                <span class="fa icon-cloud-upload mr"></span> Upload
              </button>
              <button type="button" class="btn btn-cancel btn-xs mr-2" (click)="item.cancel()" [hidden]="!item.isUploading">
                <span class="icon-close mr"></span> Cancel
              </button>
              <button type="button" class="btn btn-delete btn-xs" (click)="item.remove()" [hidden]="item.isReady || item.isUploading || item.isSuccess || item.isError">
                <span class="icon-trash mr"></span> Remove
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- <div class="row" *ngIf="multipleFiles && uploader?.queue?.length && uploader?.queue?.length > 0">
        <div class="col-md-12" style="margin-bottom: 10px">
            <div *ngIf="uploader?.queue?.length && uploader?.queue?.length > 0">
                Queue progress:
                <div class="progress" style="margin-bottom: 10px">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                </div>
            </div>
        </div>
    </div> -->
<div class="row" *ngIf="multipleFiles && uploader?.queue?.length && uploader?.queue?.length > 1">
  <div class="col-md-12 float-right text-right" style="padding: 15px">
    <div *ngIf="uploader?.queue?.length && uploader?.queue?.length > 0">
      <button
        type="button"
        class="btn btn-primary btn-s mr-2"
        (click)="uploader.uploadAll()"
        [hidden]="!uploader.getNotUploadedItems().length || uploader.isUploading"
        [disabled]="this.deviceLocForm?.formState != this.deviceLocForm?.formStates.Read">
        <span class="fa icon-cloud-upload mr"></span> Upload all
      </button>
      <button
        type="button"
        class="btn btn-cancel btn-s mr-2"
        (click)="uploader.cancelAll()"
        [hidden]="!uploader.isUploading">
        <span class="icon-close mr"></span> Cancel all
      </button>
      <button
        type="button"
        class="btn btn-delete btn-s"
        (click)="uploader.clearQueue()"
        [hidden]="!uploader.queue.length || uploader.isUploading">
        <span class="icon-trash mr"></span> Clear Queue
      </button>
    </div>
  </div>
</div>
<div *ngIf="deviceHasLocation == false" [hidden]="this.deviceLocForm?.formState == this.deviceLocForm?.formStates.Read">
  <app-devicelocations-form></app-devicelocations-form>
</div>

<!-- <p>
        <small>See implementation details
            <a href="http://valor-software.com/ng2-file-upload/">here</a>
        </small>
</p> -->
