<ngx-spinner name="chartdatatables"></ngx-spinner>
<div class="row">
  <div id="cardContainer" class="col-12">
    <div id="cardBase" [ngClass]="showChartOptionsSticky ? 'card bg-default card-header-sticky' : 'card bg-default'">
      <div id="cardBaseHeader" class="card-header">
        <div id="cardBaseHeaderRow" class="row">
          <div class="col-8">
            <h4 class="pt-2">Location Data: {{
              fgData.get('dateRange').value[0]==fgData.get('dateRange').value[1] ?
              (fgData.get('dateRange').value[0] | date:'MM-dd-yyyy') :
              (fgData.get('dateRange').value[0] | date:'MM-dd-yyyy') +' to ' + (fgData.get('dateRange').value[1] |
              date:'MM-dd-yyyy') }}
            </h4>
          </div>
          <div class="col-4 mb-2 float-right text-right">
            <button class="btn btn-primary" (click)="saveCsv()">Save as CSV</button>
            <button type="button" class="btn btn-primary ml-2" (click)="showChartOptionsClick($event)"
              title="Show/Hide Chart Dispaly Options">
              <span [ngClass]="showChartOptions ? 'fas fa-chevron-down' : 'fas fa-chevron-left'"></span></button>
          </div>
        </div>

        <div *ngIf="showChartOptions" class="row card-body pl-3 pr-3 mt-0 pt-0">
          <form [formGroup]="fgData" class="col-md-12 p-0">

            <div class="form-row row">
              <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                <label>Date Range</label>
                <div class="input-group">
                  <input readonly style="background-color: white;" class="form-control" #drp="bsDaterangepicker"
                    bsDaterangepicker [bsConfig]="{showWeekNumbers: false}" [(ngModel)]="fgData.get('dateRange').value" formControlName="dateRange" />
                  <!-- ^^ Neeed to change this to use Reactive Forms -->
                  <div class="input-group-append">
                    <span class="input-group-text input-group-text-override fas fa-calendar btn btn-primary"
                      (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></span>
                  </div>

                </div>
              </div>
              
              <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                <label>Data Table Type</label>
                <div class="input-group btn-group" btnRadioGroup (click)="librariesService.validateFormGroup(fgCharts)"
                  formControlName="dataTableType">
                  <label class="form-control btn btn-primary border-0" btnRadio="0" tabindex="0" role="button"><span
                      class="fas fa-map mr-2"></span>Stats</label>
                  <label class="form-control btn btn-primary border-0" btnRadio="1" tabindex="0" role="button"><span
                      class="fas fa-th-list mr-2"></span>Speeds</label>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12 mb-2" *ngIf="fgData.get('dataTableType').value == '0'">
                <label>Time Segment</label>
                <div class="input-group btn-group" btnRadioGroup formControlName="timeSegment">
                  <label class="form-control btn btn-primary border-0" btnRadio="0" tabindex="0" role="button">Full-Day</label>
                  <label class="form-control btn btn-primary border-0" btnRadio="30" tabindex="0" role="button">30-Minutes</label>
                  <label class="form-control btn btn-primary border-0" btnRadio="60" tabindex="0" role="button">60-Minutes</label>
                </div>
              </div>
              
            </div>

          </form>
        </div>

      </div>
    </div>

    <div id="chartOptionsStickySpacer" [style.display]="showChartOptionsSticky ? '' : 'none'"></div>

    <div id="dataPeriodSummaries" *ngIf="fgData.get('dataTableType').value == '0'" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 mt-3 pl-2 pr-2">
          <app-periodsummaries-grid #gridPeriodSummaries [dateBegin]="fgData.get('dateRange').value[0]"
              [dateEnd]="fgData.get('dateRange').value[1]" [timeSegment]="fgData.get('timeSegment').value" [gridData]="periodSummariesData">
          </app-periodsummaries-grid>
        </div>
      </div>
    </div>
    <div id="dataBinSummaries" *ngIf="fgData.get('dataTableType').value == '1'" class="card bg-default mt-3">
      <div class="card-header">
        <div class="col-md-12 mt-3 pl-2 pr-2">
          <app-binsummaries-grid #gridBinSummaries [dateBegin]="fgData.get('dateRange').value[0]"
              [dateEnd]="fgData.get('dateRange').value[1]" [gridData]="binSummariesData">
          </app-binsummaries-grid>
        </div>
      </div>
    </div>



  </div>
</div>