
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, OnInit, AfterViewInit, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { SettingsService } from '../../../../../core/settings/settings.service';
import { DevicesService, FirmwaresService, ModelsService, ModemsService, ClientsService, ConfigMapsService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { DeviceLocationSettingsViewComponent } from '../../../views/devicelocationsettingsview/devicelocationsettingsview.component';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ConfigOptionControlComponent } from '../../../../../shared/component/controlsUi/configOptionControl/configOptionControl.component';

export enum DeviceAdministrationFormState {
  Initializing = 1,
  Read = 2,
  //New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-deviceadministration-form',
  templateUrl: './deviceadministration-form.component.html',
  styleUrls: ['./deviceadministration-form.component.scss']
})

export class DeviceAdministrationFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEventDevice: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(DeviceLocationSettingsViewComponent) deviceLocView: DeviceLocationSettingsViewComponent;
  @ViewChild(ConfigOptionControlComponent) configOptionControl: ConfigOptionControlComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  get isValidDevice() {
    return (this.DeviceUuid && this.DeviceUuid.toString() != "" && this.librariesService.guidValidate(this.DeviceUuid.toString()))
  }

  public formLoaded: boolean = false;
  public formStates = DeviceAdministrationFormState
  private _formState: DeviceAdministrationFormState = DeviceAdministrationFormState.Initializing
  get formState(): DeviceAdministrationFormState {
    return this._formState
  }
  @Input() set formState(newFormState: DeviceAdministrationFormState) {
    var updateFormState: DeviceAdministrationFormState = null

    this.fgDevice.disable()

    switch (newFormState) {
      // case this.formStates.New: {
      //   updateFormState = newFormState
      //   this.fgDevice.disable()
      //   break
      // }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.fgDevice.enable()
        this.deviceEdit()
        break
      }
      case this.formStates.Save: {
        this._formState = newFormState
        this.fgDevice.enable()
        this.deviceSave()
        break
      }
      case this.formStates.Cancelled: {
        this.deviceCancel()
        break
      }
      default: {
        updateFormState = newFormState
      }
    }

    if (updateFormState != null) this._formState = updateFormState
  }


  private _deviceUuid: string = null;
  get DeviceUuid(): string {
    return this._deviceUuid;
  }
  @Input() set DeviceUuid(newDeviceUuid: string) {
    if (this.librariesService.guidValidate(newDeviceUuid)) {
      this._deviceUuid = newDeviceUuid;
    } else {
      this._deviceUuid = "";
    }
  }

  public device: any = null;
  public clients: any[] = [];
  public models: any[] = [];
  public modems: any[] = [];
  public firmwares: any[] = [];
  public configOptions: any[] = [];
  public configOptionsSelected: any[] = [];

  public isModified = false;
  private paramSub: any;

  get isEditMode(): boolean {
    switch (this.formState) {
      //case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
        //case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public clientsService: ClientsService,
    public devicesService: DevicesService,
    public firmwaresService: FirmwaresService,
    public configMapsService: ConfigMapsService,
    public modelsService: ModelsService,
    public modemsService: ModemsService,
    public errorsService: ErrorsService,
    private router: Router,
    public librariesService: LibrariesService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }


  public fgDevice: FormGroup = this.formBuilder.group({
    clientUuid: ["", [Validators.required]],
    serial: ["", [Validators.required, Validators.minLength(6)]],
    firmwareUuidTarget: ["", [Validators.required]],
    connectivityTypeId: "10",
    gpsModeId: "1",
    label: "",
    modelId: [0, [Validators.required, Validators.min(1)]],
    modemId: [null, [
      function (control: AbstractControl) {
        if (!control.parent) return null
        if (control.parent.value['connectivityTypeId'] == "0" || (control.value && control.value >= 0)) {
          return null
        }
        return { modemId: false }
      }
    ]],
    modemImei: ["", [
      function (control: AbstractControl) {
        if (!control.parent) return null
        if (control.parent.value['connectivityTypeId'] == "0" || (control.parent.value['modemId'] >= 0 && control.value && control.value.toString().trim() != "")) {
          return null
        }
        return { modemImei: false }
      }
    ]],
    modemIpv4: "",
    modemPhone: "",
    simImsi: "",
    simIccid: "",
    // REMOVED UNTIL PHONE NUMBERS ARE FIGURED OUT
    // modemPhone: ["", [
    //   function (control: AbstractControl) {
    //     if (!control.parent) return null
    //     if ((control.parent.value['connectivityTypeId'] == "0" || (control.parent.value['modemId'] >= 0 && control.value && control.value.toString().trim() != ""))) {
    //       return null
    //     }
    //     return { modemPhone: false }
    //   }
    // ]]
    configOptionValues: []
  });

  ngOnInit() {
    this.paramSub = this.route.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.DeviceUuid = params['uuid']
      });

  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

    this.fgDevice.get('clientUuid').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.settingsService.clientSetAdmin = true;
        this.settingsService.client = this.settingsService.clients.filter(x => x.uuid == val)[0];
      });

    this.fgDevice.get('firmwareUuidTarget').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {

        var configMapId = null;
        let firmware = this.firmwares.find(f => f.uuid == this.fgDevice.value.firmwareUuidTarget)
        if (this.device && firmware) {
          configMapId = firmware.configMapId;
          this.getConfigOptions(configMapId);
        }
      });

    let self = this;
    setTimeout(() => {
      this.settingsService.showSpinner("deviceAdminForm", false, 250);
      self.getClients()
      self.getModels()
      self.getModems()
      self.getFirmwares()
      // if (self.device == null) {
      //   console.log('call getDevice');

      //   self.getDevice()
      //   this.deviceLocView.deviceUuid = this.DeviceUuid;
      // }
      this.formLoaded = true;
    }, 500);

  }

  getClients(): any {
    this.clients = [];

    if (!this.settingsService.client) return;

    return this.clientsService.read()
      .pipe(
        finalize(
          (() => {
            this.getDevice();
          })),
        takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.clients = result;
        },
        error => {
          this.settingsService.hideSpinner("deviceAdminForm");
          this.notificationsService.error("Server Error (Getting Clients)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getModels(): any {
    this.models = [];

    if (!this.settingsService.client) return;

    return this.modelsService.read()
      .pipe(
        finalize(
          (() => {
            this.getDevice();
          })),
        takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.models = result;
        },
        error => {
          this.settingsService.hideSpinner("deviceAdminForm");
          this.notificationsService.error("Server Error (Getting Models)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getModems(): any {
    this.modems = [];

    if (!this.settingsService.client) return;

    return this.modemsService.read()
      .pipe(
        finalize(
          (() => {
            this.getDevice();
          })),
        takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.modems = result;
        },
        error => {
          this.settingsService.hideSpinner("deviceAdminForm");
          this.notificationsService.error("Server Error (Getting Modems)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getFirmwares(): any {
    this.firmwares = [];

    if (!this.settingsService.client) return;

    return this.firmwaresService.read()
      .pipe(
        finalize(
          (() => {
            this.getDevice();
          })),
        takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.firmwares = result;
        },
        error => {
          this.settingsService.hideSpinner("deviceAdminForm");
          this.notificationsService.error("Server Error (Getting Firmwares)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getConfigOptions(configMapId: number): any {
    this.configOptions = [];
    this.configOptionsSelected = [];

    if (!this.settingsService.client || configMapId == null) return;

    return this.configMapsService.readConfigOptions(configMapId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.configOptions = result;
          this.configOptions.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
          // this.refreshConfigOptionsSelected();
        },
        error => {
          this.notificationsService.error("Server Error (Getting Config Options)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }


  getDevice(): any {
    this.device = null;

    if (!this.settingsService.client || !this.DeviceUuid == null) return;
    if (this.clients.length == 0 || this.models.length == 0 || this.modems.length == 0 || this.firmwares.length == 0) return

    this.settingsService.showSpinner("deviceAdminForm", false, 250);

    return this.devicesService.readByUuid(this.DeviceUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.device = result;
          this.fgDevice.patchValue(
            {
              clientUuid: result.clientUuid,
              serial: result.serial,
              modelId: result.modelId,
              firmwareUuidTarget: result.firmwareUuidTarget,
              connectivityTypeId: result.connectivityTypeId.toString(),
              modemId: result.modemId,
              modemImei: result.modemImei,
              modemIpv4: result.modemIpv4,
              modemPhone: result.modemPhone,
              label: result.label,
              gpsModeId: result.gpsModeId.toString(),
              configOptionValues: result.configOptionValues,
              simIccid: result.simIccid,
              simImsi: result.simImsi

            }
          )

          this.deviceLocView.deviceUuid = this.DeviceUuid;
          this.deviceLocView.getDeviceLocationSettings();
          this.configOptionControl.refreshConfigOptionsSelected(this.device);
          if (this.device.configOptionValues.length <= 0) {
            this.notificationsService.warn("Please assign Configuration Options to this device", this)
            this.formState = DeviceAdministrationFormState.Edit;
            this.device.configOptionValues = this.device.model.modelOptionValues;
            this.configOptionControl.refreshConfigOptionsSelected(this.device);
            if (this.device.configOptionValues.length <= 0) {
              this.notificationsService.warn('Configuration Warning: ',
                'Model Missing Options', { timeOut: 5000, clickToClose: true });
            }
          }
          else {
            this.formState = DeviceAdministrationFormState.Read;
          }

          this.settingsService.hideSpinner("deviceAdminForm");
        },
        error => {
          this.settingsService.hideSpinner("deviceAdminForm");
          this.deviceLocView.deviceUuid = "";
          this.notificationsService.error("Server Error (Getting Device)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.formState = DeviceAdministrationFormState.Read
        });
  }

  /* refreshConfigOptionsSelected()
   {
     this.configOptionsSelected = [];
     if (this.device && this.device.configOptionValues && this.device.configOptionValues.length > 0) {
       for (let entry of this.configOptions) {
         let option = this.device.configOptionValues.find(opt => opt.optionId == entry.id);
         let selected = this.configOptionsSelected.find(sel => sel.id == entry.id);
         if (option && !selected) {
           this.configOptionsSelected.push(entry)
         }
       }
     }
     if (this.configOptionsSelected.length > 0) this.configOptionsSelected.sort((a,b) => (a.sequence > b.sequence) ? 1 : -1)
 
   }
 
   isSelctedConfigOption(argConfigOptionId: number): boolean {
     let selected = this.configOptionsSelected.find(x => x.id == argConfigOptionId)
     if (selected) return true;
     return false;
   }
 
   getConfigOptionValueText(argConfigOptionId: number): string {
     let deviceOption = this.device.configOptionValues.find(opt => opt.optionId == argConfigOptionId);
     if (deviceOption) return deviceOption.optionValueText;
     return "";
   }*/

  controlValidationClass(control) {
    return "form-control" + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? " is-invalid" : " is-valid") : "")
  }
  controlInvalidFlag(control) {
    if (!(control.touched)) return null;
    return control.invalid
  }

  deviceEdit() {
    //do setup
    // this.modelDropdown.setValue(this.fgDevice.get('modelId').value)
    // this.firmwareDropdown.setValue(this.fgDevice.get('firmwareUuidTarget').value)
    this.librariesService.validateFormGroup(this.fgDevice)
    this.isModified = false;

    //Send Event
    this.onFormEventDevice.emit("edit")
  }

  deviceSave() {
    console.log("device save")

    if (!this.fgDevice.valid) {
      console.log(this.fgDevice)
      this.librariesService.validateFormGroup(this.fgDevice)
      this.formState = this.formStates.Edit
      this.onFormEventDevice.emit("edit")
      this.notificationsService.warn("Validation Warning (Device)", "Verify Required Data", { timeOut: 5000, clickToClose: true });
      return
    }

    this.settingsService.showSpinner("deviceAdminForm", false, 250);

    this.formState = this.formStates.Saving
    this.onFormEventDevice.emit("saving");

    this.fgDevice.patchValue(
      {
        configOptionValues: this.device.configOptionValues
      }
    )

    this.devicesService.readByUuid(this.DeviceUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          result.clientUuid = this.fgDevice.value.clientUuid
          result.serial = this.fgDevice.value.serial
          result.modelId = this.fgDevice.value.modelId
          result.firmwareUuidTarget = this.fgDevice.value.firmwareUuidTarget
          result.connectivityTypeId = this.fgDevice.value.connectivityTypeId
          result.modemId = this.fgDevice.value.modemId
          result.modemImei = this.fgDevice.value.modemImei
          result.modemIpv4 = this.fgDevice.value.modemIpv4
          result.modemPhone = this.fgDevice.value.modemPhone
          result.label = this.fgDevice.value.label
          result.gpsModeId = this.fgDevice.value.gpsModeId
          result.configOptionValues = this.fgDevice.value.configOptionValues
          result.simIccid = this.fgDevice.value.simIccid
          result.simImsi = this.fgDevice.value.simImsi

          return this.devicesService.put(result)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (result: any) => {
                this._deviceUuid = result.uuid
                this.device = result;
                this.fgDevice.patchValue(
                  {
                    clientUuid: result.clientUuid,
                    serial: result.serial,
                    modelId: result.modelId,
                    firmwareUuidTarget: result.firmwareUuidTarget,
                    connectivityTypeId: result.connectivityTypeId.toString(),
                    modemId: result.modemId,
                    modemImei: result.modemImei,
                    modemIpv4: result.modemIpv4,
                    modemPhone: result.modemPhone,
                    label: result.label,
                    gpsModeId: result.gpsModeId.toString(),
                    configOptionValues: result.configOptionValues,
                    simIccid: result.simIccid,
                    simImsi: result.simImsi
                  }
                )

                //this.refreshConfigOptionsSelected();

                this.formState = this.formStates.Saved

                this.onFormEventDevice.emit("saved")
                this.notificationsService.success("Success", "Device Saved", { timeOut: 2000, clickToClose: true });
                this.settingsService.hideSpinner("deviceAdminForm");
              },
              error => {
                this.notificationsService.error("Server Error (Device Updating)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                this.formState = this.formStates.Edit;
                this.settingsService.hideSpinner("deviceAdminForm");
              });

        },
        error => {
          this.notificationsService.error("Server Error (Device Saving: Getting Device)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.formState = DeviceAdministrationFormState.Read;
          this.settingsService.hideSpinner("deviceAdminForm");
        });
  }

  deviceCancel() {
    this.formState = this.formStates.Read
    this.getDevice()
    this.onFormEventDevice.emit("cancelled")
  }

  configLink() {
    this.router.navigate(['/configurations/' + this.device.deviceSchedule.uuid])
  }

  configOptionAdd(id: number) {
    let option = this.configOptions.find(x => x.id == id);
    if (option) this.configOptionsSelected.push(option);

    if (!(this.device.configOptionValues.find(v => v.optionId == option.id))) {
      this.device.configOptionValues.push(
        {
          id: option.configOptionValues[0].id,
          code: option.configOptionValues[0].code,
          description: option.description,
          optionId: option.id,
          optionCode: option.code,
          optionValueText: option.configOptionValues[0].code,
          required: option.required,
          sequence: option.sequence,
          typeId: option.typeId,
          value: null
        }
      )
    }

  }
  configOptionRemove(id: number) {
    let option = this.configOptionsSelected.find(x => x.id == id);
    let indexSelected = this.configOptionsSelected.findIndex(x => x.id == id);
    if (indexSelected > -1) this.configOptionsSelected.splice(indexSelected, 1);

    let indexOptionValue = this.device.configOptionValues.findIndex(v => v.optionId == option.id);
    if (indexOptionValue > -1) this.device.configOptionValues.splice(indexOptionValue, 1);

  }

  configOptionDropDownSelect(optionObject: any) {
    if (!(optionObject.optionId && optionObject.id && optionObject.code && optionObject.valueText)) return;

    let optionValue = this.device.configOptionValues.find(v => v.optionId == optionObject.optionId);
    optionValue.id = optionObject.id;
    optionValue.code = optionObject.valueText;
    optionValue.optionValueText = optionObject.valueText;
  }

}
