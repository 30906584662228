<div class="row">
  <div class="col-lg-12">
    <!-- START widget-->
    <div class="card bg-default">
      <div class="card-header">
        <div class="row">
          <div class="col-12 col-md-4">
            <h4 class="pt-2 mb-0">Registered Users{{showForm ? " - User" : " - Listing"}}{{ showForm &&
              userForm && userForm.isEditMode
              ? (userForm.formState == userForm.formStates.New ? " - Add" : " - Edit") : "" }}
            </h4>
          </div>
          <div class="col-12 col-md-8 float-right text-right">
            <button *ngIf="!showForm" class="btn btn-primary mr-2" (click)="buttonAdd($event)" [disabled]="showForm">
              <span class="fas fa-plus-square pr-2"></span>Add</button>
            <button *ngIf="userForm && showForm" [disabled]="userForm.formState == userForm.formStates.Saving"
              type="button" class="btn btn-primary ml-2" (click)="buttonBack($event)">
              <span class="fa fas fa-th-list pr-2"></span>Listing</button>
            <button *ngIf="userForm && showForm && !userForm.isEditMode"
              [disabled]="userForm.formState != userForm.formStates.Read" type="button" class="btn btn-primary ml-2"
              (click)="buttonEdit($event)" id="highlight">
              <span class="fas fa-edit pr-2"></span>Edit</button>
            <button *ngIf="userForm && showForm && userForm.isEditMode"
              [disabled]="userForm.formState != userForm.formStates.New && userForm.formState != userForm.formStates.Edit"
              type="button" class="btn btn-success ml-2" (click)="buttonSave($event)">
              <span class="fas fa-save pr-2"></span>Save</button>
            <button
              *ngIf="userForm && showForm && userForm.isEditMode && userForm.formState == userForm.formStates.Edit"
              [disabled]="userForm.formState != userForm.formStates.Edit" type="button" class="btn btn-cancel ml-2"
              (click)="buttonCancel($event)">
              <span class="fas fa-ban pr-2"></span>Cancel</button>

            <button *ngIf="!showForm" class="btn btn-primary m1-2" title="Refresh" (click)="dataRefresh()">
              <img *ngIf="!dataLoading" src="assets/img/spinner-arrows-static.png" width="22" height="22">
              <img *ngIf="dataLoading" src="assets/img/spinner-arrows.png" width="22" height="22">
            </button>
          </div>
        </div>

        <div *ngIf="showForm" class="bg-body mt-2">
          <app-user-form #userForm [userUuid]="userUuid" (onFormEvent)="onFormEvent($event)"></app-user-form>
        </div>
      </div>


      <div *ngIf="!showForm" class="bg-body">
        <app-users-admin-view #userView (onViewSelected)="onViewSelected($event)" (onDataLoading)="onDataLoading($event)"></app-users-admin-view>
      </div>
    </div>
    <!-- END widget-->

  </div>
</div>