<div class="row">
    <div class="col-lg-12">
        <!-- START widget-->
        <div class="card bg-default">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <h4 class="pt-2 mb-0">Subscriptions{{showForm && subscriptionsAdminForm?.subscription ? " - " +
                            subscriptionsAdminForm?.subscription.notes : " - Listing"}}{{
                            showForm && subscriptionsAdminForm && subscriptionsAdminForm.isEditMode
                            ? (subscriptionsAdminForm.formState == subscriptionsAdminForm.formStates.New ? " - Add" : "
                            - Edit") :
                            "" }}
                        </h4>
                    </div>
                    <div class="col-12 col-md-8 float-right text-right">
                        <button *ngIf="!showForm" class="btn btn-primary" (click)="buttonAdd($event)"
                            [disabled]="showForm">
                            <span class="fas fa-plus-square pr-2"></span>Add</button>
                        <button *ngIf="subscriptionsAdminForm && showForm"
                            [disabled]="subscriptionsAdminForm.formState == subscriptionsAdminForm.formStates.Saving"
                            type="button" class="btn btn-primary ml-2" (click)="buttonBack($event)">
                            <span class="fa fas fa-th-list pr-2"></span>Listing</button>
                        <button *ngIf="subscriptionsAdminForm && showForm && !subscriptionsAdminForm.isEditMode"
                            [disabled]="subscriptionsAdminForm.formState == subscriptionsAdminForm.formStates.Edit"
                            type="button" class="btn btn-primary ml-2" (click)="buttonEdit($event)">
                            <span class="fas fa-edit pr-2"></span>Edit</button>
                        <button *ngIf="subscriptionsAdminForm && showForm && subscriptionsAdminForm.isEditMode"
                            [disabled]="subscriptionsAdminForm.formState != subscriptionsAdminForm.formStates.New && subscriptionsAdminForm.formState != subscriptionsAdminForm.formStates.Edit"
                            type="button" class="btn btn-success ml-2" (click)="buttonSave($event)">
                            <span class="fas fa-save pr-2"></span>Save</button>
                        <button
                            *ngIf="subscriptionsAdminForm && showForm && subscriptionsAdminForm.isEditMode && subscriptionsAdminForm.formState == subscriptionsAdminForm.formStates.Edit"
                            [disabled]="subscriptionsAdminForm.formState != subscriptionsAdminForm.formStates.Edit"
                            type="button" class="btn btn-cancel ml-2" (click)="buttonCancel($event)">
                            <span class="fas fa-ban pr-2"></span>Cancel</button>

                        <button *ngIf="!showForm" class="btn btn-primary ml-2" title="Refresh"
                            (click)="dataRefresh()">
                            <img *ngIf="!dataLoading" src="assets/img/spinner-arrows-static.png" width="22" height="22">
                            <img *ngIf="dataLoading" src="assets/img/spinner-arrows.png" width="22" height="22">
                        </button>

                    </div>

                </div>
            </div>

            <div *ngIf="showForm" class="bg-body mt-2">
                <app-subscriptions-form #subscriptionForm [SubscriptionUuid]="subscriptionUuid"
                    (onFormEvent)="onFormEvent($event)"></app-subscriptions-form>
            </div>

            <div *ngIf="!showForm" class="bg-body">
                <div>
                    <app-subscriptions-admin-view  #subscriptionView (onViewSelected)="onViewSelected($event)"> </app-subscriptions-admin-view>
                </div>
            </div>




        </div>
    </div>
    <!-- END widget-->

</div>