<div class="row">
  <div class="col-lg-12">
    <!-- START widget-->
    <div class="card bg-default">
      <div class="card-header">
        <div class="row">
          <div class="col-12 col-md-4">
            <h4 class="pt-2 mb-0">Models{{showForm ? " - " + modelForm?.model?.code : " - Listing"}}{{ showForm &&
              modelForm && modelForm.isEditMode
              ? (modelForm.formState == modelForm.formStates.New ? " - Add" : " - Edit") : "" }}
            </h4>
          </div>
          <div class="col-12 col-md-8 float-right text-right">
            <button *ngIf="!showForm && settingsService.userAccessCheck(userTypes.CloudSuper,userRoles.Reader)" class="btn btn-primary" (click)="buttonAdd($event)" 
              [disabled]="showForm">
              <span class="fas fa-plus-square pr-2"></span>Add</button>
            <button *ngIf="modelForm && showForm" 
              [disabled]="modelForm.formState == modelForm.formStates.Saving"
              type="button" class="btn btn-primary ml-2" (click)="buttonBack($event)">
              <span class="fa fas fa-th-list pr-2"></span>Listing</button>
            <button *ngIf="modelForm && showForm && !modelForm.isEditMode && settingsService.userAccessCheck(userTypes.CloudSuper,userRoles.Reader)" 
              [disabled]="modelForm.formState != modelForm.formStates.Read"
              type="button" class="btn btn-primary ml-2" (click)="buttonEdit($event)">
              <span class="fas fa-edit pr-2"></span>Edit</button>
            <button *ngIf="modelForm && showForm && modelForm.isEditMode" 
              [disabled]="modelForm.formState != modelForm.formStates.New && modelForm.formState != modelForm.formStates.Edit"
              type="button" class="btn btn-success ml-2" (click)="buttonSave($event)">
              <span class="fas fa-save pr-2"></span>Save</button>
            <button *ngIf="modelForm && showForm && modelForm.isEditMode && modelForm.formState == modelForm.formStates.Edit"
              [disabled]="modelForm.formState != modelForm.formStates.Edit" type="button" class="btn btn-cancel ml-2"
              (click)="buttonCancel($event)">
              <span class="fas fa-ban pr-2"></span>Cancel</button>
          </div>
        </div>

        <div *ngIf="showForm" class="bg-body mt-2">
          <app-model-form #modelForm [modelId]="modelId" (onFormEvent)="onFormEvent($event)"></app-model-form>
        </div>
      </div>

      <div *ngIf="!showForm" class="bg-body mt-2">
        <app-models-admin-view #modelView (onViewSelected)="onViewSelected($event)"></app-models-admin-view>
      </div>

      
    </div>
    <!-- END widget-->

  </div>
</div>