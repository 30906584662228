
import {filter,  takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { ClientsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject, Subscription } from 'rxjs';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-clients-admin-view',
  templateUrl: './clients-admin-view.component.html',
  styleUrls: ['./clients-admin-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientsAdminViewComponent implements OnInit, AfterViewInit, OnDestroy {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();
  private clientCreatedSubscription: Subscription;

  public clientsTemp: any[] = [];
  public clients: any[] = [];
  public clientsSelected: any[] = [];

  private _connectivityTypeId: number = -1;
  get connectivityTypeId(): number {
    return this._connectivityTypeId;
  }
  @Input() set connectivityTypeId(Id: number) {
    this._connectivityTypeId = Id
    if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
  }


  @Output() onViewSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @ViewChild('datatableFilter') datatableFilter: ElementRef;

  //ag-grid variables start

  public gridOptions: GridOptions;
  public chartThemes;
  public rowStyle;
  public isSelected: boolean = false;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  @Input() public chartView: boolean = false;

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public errorsService: ErrorsService,
    public settingsService: SettingsService,
    private router: Router,
    public clientsService: ClientsService) {
      this.chartThemes = [
        'ag-pastel',
        'ag-default',
        'ag-material-dark',
        'ag-vivid-dark',
        'ag-solar',
      ];
      this.rowStyle = {
        cursor: 'pointer'
      }
      this.gridOptions = <GridOptions>{
        rowData: this.gridData,
        columnDefs: this.columnDefs,
        rowStyle: this.rowStyle,
        rowHeight: 40,
        rowSelection: 'multiple',
        cursor: "pointer",
        suppressRowClickSelection: true,
        onCellClicked: (e) => {
          e.node.setSelected(true)
          this.isSelected = true;
      },
      //chartThemeOverrides: this.chartThemeOverrides,
      enableCharts: false,
      enableRangeSelection: false
      // pagination: true,
      // rowSelection: 'single'
    }
  }

  columnDefs = [
    { headerName: "Name", field: "name", sortable: true, resizable: true, minWidth: 225, maxWidth: 300, suppressMenu: true },
    { headerName: "Address", field: "address1", sortable: true, resizable: true, minWidth: 225, maxWidth: 300, suppressMenu: true },
    { headerName: "City", field: "city", sortable: true, resizable: true, minWidth: 100, maxWidth: 100, suppressMenu: true },
    { headerName: "State/Prov", field: "stateProvinceCode", sortable: true, resizable: true, minWidth: 100, maxWidth: 100, suppressMenu: true },
    { headerName: "Postal", field: "postalCode", sortable: true, resizable: true, minWidth: 100, maxWidth: 100, suppressMenu: true },
    { headerName: "Country", field: "country", sortable: true, resizable: true, minWidth: 100, suppressMenu: true }
  ]

  ngOnInit() {

    this.clientCreatedSubscription = this.eventsService.dataRefresh$
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(() => {
      this.getClients();
    })

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(() => {
      self.getClients();
    }, 500);
  }

  getClients(): any {
    //Don't need for admin component
    //if (!this.settingsService.client) return;

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 25);

    return this.clientsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.clients = result;
          this.clientsTemp = result;
          if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
        },
        error => {
          this.notificationsService.error("Server Error (getClients)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  updateFilter(event) {
    const val = event.toLowerCase();
    // filter our data

    var self = this

    const temp = this.clientsTemp.filter(function (d) {
      return (
        (
          (d.name != null && d.name.toLowerCase().indexOf(val) !== -1) ||
          (d.address1 != null && d.address1.toLowerCase().indexOf(val) !== -1) ||
          (d.address2 != null && d.address2.toLowerCase().indexOf(val) !== -1) ||
          (d.city != null && d.city.toLowerCase().indexOf(val) !== -1) ||
          (d.stateProvinceCode != null && d.stateProvinceCode.toLowerCase().indexOf(val) !== -1) ||
          (d.postalCode != null && d.postalCode.toLowerCase().indexOf(val) !== -1) ||
          (d.country != null && d.country.toLowerCase().indexOf(val) !== -1) ||
          val.toString().trim() == ''
        )
      );
    });

    // update the rows
    this.clients = temp;
  }

  onSelect({ selected }) {
    this.clientsSelected.splice(0, this.clientsSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onViewSelected.emit(selected[0]);
      //this.router.navigate(['/administration/clients', selected[0].uuid])
      //alert(selected[0].uuid)
    }
  }

  getDisplayAddress(object: any): string {
    if (!object) return ''
    //object = {city: 'hello' ,postalCode: 'goodbye'  }

    var display: string = object.city || ''

    if (display.length > 0 && object.stateProvinceCode && object.stateProvinceCode.length > 0) display = display + ', '
    display = display + (object.stateProvinceCode || '')

    if (display.length > 0 && object.postalCode && object.postalCode.length > 0) display = display + ', '
    display = display + (object.postalCode || '')

    if (display.length > 0 && object.country && object.country.length > 0) display = display + ', '
    display = display + (object.country || '')

    return display
  }

  onResize(event) {
    this.gridOptions.api.sizeColumnsToFit();
 
  }

  onRowSelected({selected}) {
    //console.log(event);
    this.clientsSelected.splice(0, this.clientsSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onViewSelected.emit(selected[0]);
  }
}

  onRowClicked(event) {
    console.log(event)

    if (this.isSelected && !this.chartView) {
      this.router.navigate(['/administration/clients/', event.node.data.uuid]);
    }

    else if(this.isSelected && this.chartView){
      console.log(event.node)
      this.router.navigate(['/charts/', event.node.data.deviceLocation.uuid]);
    }
 
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

}
