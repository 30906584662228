import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ColorsService } from '../colors/colors.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DataArraysCompareOutput } from '../../models/DataArraysCompareOutput';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Injectable()
export class LibrariesService {

    constructor(
        private router: Router,
        protected http: HttpClient,
        private colorsService: ColorsService
    ) {
    }


    public objectCreate(item, itemClass){
        return Object.assign(new itemClass(), item);
      }

    public guidValidate(guid: string): boolean {
        //alert(guid);^{[A-Z0-9]{8}-([A-Z0-9]{4}-){3}[A-Z0-9]{12}}$
        var regexp = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'),
            isValid: boolean = regexp.test(guid);
        return isValid;
    }

    // public guidEmpty(): string {
    //     return "00000000-0000-0000-0000-000000000000"
    // }

    public guidCreate(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public zlsIfNull(val): string {
        return (val || "")
    }

    public arraySequenceReNumber(arrayAny: any[]): boolean {
        var i: number = 0;
        arrayAny.forEach(
            function (objAny) {
                i += 1;
                objAny.sequence = i;
            });
        return true;
    }

    public arrayColorCodeApply(arrayAny: any[]): boolean {
        var i: number = -1;
        var cs: ColorsService = this.colorsService;

        arrayAny.forEach(
            function (objAny) {
                i += 1;
                objAny.colorCode = cs.palette24[i];
            });
        return true;
    }

    private countryMPH: string[] = ["USA", "ATG", "GBR", "PRI"]; //MPH Countries
    public isCountryMPH(country: string) {
        if (country == null) return true;
        for (var i = 0, len = this.countryMPH.length; i < len; i++) {
            if (country.toUpperCase() == this.countryMPH[i].toUpperCase()) return true;
        }
        return false;
    }

    public speedUnitIdToCode(unitId: number): string {
        switch (unitId) {
            case 1:
                return 'KPH'
                break;
            default:
                return 'MPH'
                break;
        }
    }
    public speedBinsGet(firmware: string, unit: string): number[][] {

        switch (unit.toUpperCase()) {
            case "KPH": {
                return ([
                    [10, 20],
                    [21, 30],
                    [31, 35],
                    [41, 50],
                    [51, 60],
                    [61, 70],
                    [71, 80],
                    [81, 90],
                    [91, 100],
                    [101, 110],
                    [111, 120],
                    [121, 0]
                ])
                //break;
            }
            default: { //MPH
                switch (firmware.toLowerCase()) {
                    case "v1": { //old firmware
                        return ([
                            [5, 20],
                            [21, 25],
                            [26, 30],
                            [31, 32],
                            [33, 35],
                            [36, 37],
                            [38, 40],
                            [41, 42],
                            [43, 45],
                            [46, 50],
                            [51, 55],
                            [56, 0]
                        ])
                    }
                    default: {
                        return ([
                            [5, 10],
                            [11, 15],
                            [16, 20],
                            [21, 25],
                            [26, 30],
                            [31, 35],
                            [36, 40],
                            [41, 45],
                            [46, 50],
                            [51, 55],
                            [56, 60],
                            [61, 0]
                        ])
                    }
                }
            }
        }
    }

    public speedBinGet(firmware: string, unit: string, bin: number): number[] {
        var bins: number[][] = this.speedBinsGet(firmware, unit)
        if (bins.length == 0 || bins.length - 1 < bin) return [0, 0]
        return bins[bin]
    }

    public speedBinTextGet(firmware: string, unit: string, binLow: number, binHigh: number = null): string {
        var binRangeLow: number[] = this.speedBinGet(firmware, unit, binLow)
        var binRangeHigh: number[] = binHigh == null ? binRangeLow : this.speedBinGet(firmware, unit, binHigh)
        return (
            binRangeLow[0].toFixed(0).toString() +
            (binRangeHigh[1] == 0 ? "+" :
                "-" + binRangeHigh[1].toFixed(0).toString()) + " " + unit.toUpperCase())
    }

    public untouchedFormGroup(formGroup: FormGroup) {
        if (!formGroup || !formGroup.controls) return;
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsUntouched({ onlySelf: true });
                //control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.untouchedFormGroup(control);
            }
        }
        );
    }
    public validateFormGroup(formGroup: FormGroup) {
        if (!formGroup || !formGroup.controls) return;
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
                //control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.validateFormGroup(control);
            }
        }
        );
    }
    public controlValidationClass(control) {
        //console.log(control)
        return "form-control" + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? " is-invalid" : " is-valid") : "")
    }
    public controlInvalidFlag(control, useTouched: boolean = true) {
        if (useTouched && !(control.touched)) return null;
        return control.invalid
    }

    public datePipe: DatePipe = new DatePipe('en-US');

    public dateIsValid(dateTest): boolean {
        if (Object.prototype.toString.call(dateTest) === "[object Date]") {
            // it is a date
            if (isNaN(dateTest.getTime())) {  // d.valueOf() could also work
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }

    public arraysChanged(arrayOriginal: any[], arrayCompare: any[]): boolean {
        if (arrayOriginal.length != arrayCompare.length) return true;

        for (var i = 0; i < arrayOriginal.length; i++) {
            if (arrayOriginal[i] != arrayCompare[i]) return true
        }
        return false
    }


    public dataArraysCompare(
        arrayOriginal: any[],
        arrayCompare: any[],
        propCompare: string):
        DataArraysCompareOutput {

        let out = new DataArraysCompareOutput();
        if (arrayCompare.length == 0) return out;

        let query: any;
        for (var i = 0; i < arrayOriginal.length; i++) {
            query = arrayCompare.find(x => x[propCompare] == arrayOriginal[i][propCompare]);
            if (query) out.arrayExists.push(out)
            else out.arrayDeleted.push(out)
        }
        for (var i = 0; i < arrayCompare.length; i++) {
            query = arrayOriginal.find(x => x[propCompare] == arrayCompare[i][propCompare]);
            if (!query) out.arrayAdded.push(out)
        }

        return out;
    }


    public getImage(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, { responseType: 'blob' });
    }

    public getUserTimezoneOffsetString(): string {
        var minOffset: number = (new Date()).getTimezoneOffset()
        var hours: number = this.floorFigure(minOffset / 60, 0)
        if (hours < 0) hours = -1 * hours
        var minutes: number = Math.abs(minOffset) >= 60 ? minOffset % 60 : minOffset
        if (minutes < 0) minutes = -1 * minutes

        var sHours = ('0000' + hours.toFixed(0)).slice(-2);
        var sMinutes = ('0000' + minutes.toFixed(0)).slice(-2);

        if (minOffset >= 0)
            return "-" + sHours + ":" + sMinutes
        else return "+" + sHours + ":" + sMinutes

    }

    // public getUserTimezoneOffsetString(): string {
    //     var timeGMT = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm", "+00:00").replace(" ", "T"))
    //     var timeLocal =  new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm").replace(" ", "T"))
    //     var minOffset = (timeGMT.getTime() - timeLocal.getTime())/1000/60; // milliseconds between 

    //     console.log((new Date()).toUTCString(),(new Date()).toLocaleString(), (new Date()).toISOString(), minOffset)
    //     // var hoursGMT = Number(this.datePipe.transform(new Date(), "HH", "+00:00"))
    //     // var minutesGMT = Number(this.datePipe.transform(new Date(), "mm", "+00:00"))
    //     // var hoursLocal = Number(this.datePipe.transform(new Date(), "HH"))
    //     // var minutesLocal = Number(this.datePipe.transform(new Date(), "mm"))

    //     var hours: number = this.floorFigure(minOffset / 60, 0)
    //     if (hours < 0) hours = -1 * hours
    //     var minutes: number = Math.abs(minOffset) >= 60 ? minOffset % 60 : minOffset
    //     if (minutes < 0) minutes = -1 * minutes

    //     var sHours = ('0000' + hours.toFixed(0)).slice(-2);
    //     var sMinutes = ('0000' + minutes.toFixed(0)).slice(-2);

    //     if (minOffset >= 0)
    //         return "-" + sHours + ":" + sMinutes
    //     else return "+" + sHours + ":" + sMinutes

    // }


    public floorFigure(figure: number, decimals: number): number {
        if (!decimals) decimals = 2;
        var d = Math.pow(10, decimals);
        return Number((parseInt((figure * d).toString()) / d).toFixed(decimals));
    };

}
