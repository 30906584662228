
import {filter,  takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Input, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DeviceSchedulesService } from '../../../../core/api/api.services';
import { RoundPipe } from '../../../pipes/round.pipe';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-devicesavailable-view',
  templateUrl: './devicesavailable-view.component.html',
  styleUrls: ['./devicesavailable-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DevicesAvailableViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() addingSelectedDeviceEvent = new EventEmitter<any>();
  @Output() onDeviceAvailableSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild('devicesAvailableTable') dataView: DatatableComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public deviceAvailableTemp: any[] = [];
  public deviceAvailable: any[] = [];
  public deviceAvailableSelected: any[] = [];
  public showDeviceAssigned: boolean = false;
  public isEditMode: boolean = false;
  public isAdding: boolean = false;
  private paramSub: any;

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  private _devicesAvailable: any[];
  @Input() set DevicesAvailable(newDevicesAvailable: any[]) {
    this._devicesAvailable = newDevicesAvailable;
    this.refreshDevicesAvailable();
    //console.log(this.deviceAvailable)
  }
  private _deviceScheduleUuidActive: string = "";
  @Input() set DeviceScheduleUuidActive(newDeviceScheduleUuidActive: string)
  {
    this._deviceScheduleUuidActive = newDeviceScheduleUuidActive;
    this.refreshDevicesAvailable();
  }
  get DeviceScheduleUuidActive():string{
    return this._deviceScheduleUuidActive;
  }

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public deviceSchedulesService: DeviceSchedulesService,
    public round: RoundPipe,
    private route: ActivatedRoute) {
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(() => {
      self.refreshDevicesAvailable();
    }, 500);
  }

  refreshDevicesAvailable(): any {

    this.deviceAvailableTemp = [];
    this.deviceAvailable = [];
    this.deviceAvailableSelected = [];

    if (!this.settingsService.client) { 
      return;
    }

    if (this._devicesAvailable) {
      this.deviceAvailable = this.showDeviceAssigned ? this._devicesAvailable
        : this._devicesAvailable.filter(d => 
          d.deviceScheduleUuid === null ||
          d.deviceScheduleUuid === this.DeviceScheduleUuidActive
          );
      this.deviceAvailableTemp = this.showDeviceAssigned ? this._devicesAvailable
        : this._devicesAvailable.filter(d => 
          d.deviceScheduleUuid === null ||
          d.deviceScheduleUuid === this.DeviceScheduleUuidActive
          );

      if (this.dataView) this.dataView.rows = this.deviceAvailable
    }

  }

  onSelect({ selected }) {
    if (selected && selected[0].uuid) {
      this.onDeviceAvailableSelected.emit(selected[0].id);

      if (this.isAdding) {
        const deviceAvailableSelected = this.deviceAvailableSelected;
        // const addingUuid = this.deviceAvailableSelected[0].uuid;
        // this.dataView.rows = this.dataView.rows.filter(r => r.uuid !== addingUuid);
        this.addingSelectedDeviceEvent.emit(deviceAvailableSelected);
        this.isAdding = false;
      }
    }
  }

  buttonSave(event) {
  }

  buttonCancel(event) {
  }

  refreshAvailableDevicesTable() {
    this.showDeviceAssigned = !this.showDeviceAssigned;
    this.refreshDevicesAvailable();
  }

  addSelectedDevice() {
    this.isAdding = true;
  }

}
