<div class="card-body">
  <div class="form-group row">
    <div class="col-lg-12">
      <form [formGroup]="fgFirmware">
        <!-- <div class="form-row">
          <div class="col-md-6 col-sm-12 mb-2">
            <label>Customer</label>
            <ng-select #firmwareUuid [selectOnTab]="true" [items]="firmwares" bindValue="uuid" bindLabel="name" [clearable]="false"
              id="firmwareUuid" name="firmwareUuid" formControlName="firmwareUuid" placeholder="Customer">
            </ng-select>
          </div>
        </div> -->
        <div *ngIf="this.formState != this.formStates.New" class="row">
          <div *ngIf="lockForm" class="col-12 m-0 mb-2 p-2 lock-form">
              Upload a file to enable the form
          </div>
          <div class="col-12 m-0 p-0">
            <div class="form-row">
                  <div class="col-sm-4 col-12 mb-2">
                    <label>Firmware Uuid</label>
                    <div *ngIf="!isEditMode" class="form-control">{{firmware?.uuid ? firmware.uuid : '&nbsp;'}}</div>
                    <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgFirmware.get('uuid'))" id="uuid"
                      name="uuid" formControlName="uuid" type="text" placeholder="Firmware Uuid" />
                    <div *ngIf="librariesService.controlInvalidFlag(fgFirmware.get('uuid'))" class="invalid-feedback">Please
                      provide a firmware uuid</div>
                  </div>
            </div>
            <div class="form-row">
              <div class="col-sm-4 col-12 mb-2">
                <label>Code Name</label>
                <div *ngIf="!isEditMode" class="form-control">{{firmware?.code ? firmware.code : '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgFirmware.get('code'))" id="code"
                  name="code" formControlName="code" type="text" placeholder="Firmware Code" />
                <div *ngIf="librariesService.controlInvalidFlag(fgFirmware.get('code'))" class="invalid-feedback">Please
                  provide a firmware code</div>
              </div>
              <div class="col-sm-8 col-12 mb-2">
                <label>Description</label>
                <div *ngIf="!isEditMode" class="form-control">{{firmware?.description ? firmware.description :
                    '&nbsp;'}}</div>
                <input *ngIf="isEditMode" [class]="librariesService.controlValidationClass(fgFirmware.get('description'))"
                  id="description" name="description" formControlName="description" type="text" placeholder="Description" />
                <div *ngIf="librariesService.controlInvalidFlag(fgFirmware.get('description'))" class="invalid-feedback">Please
                  provide a description</div>
              </div>
              <div class="col-sm-6 col-12 mb-2">
                <label>Configuration Map</label>
                <ng-select #configMapId [selectOnTab]="true" [items]="configMaps" bindValue="id" bindLabel="code"
                  [clearable]="true" id="configMapId" name="configMapId" formControlName="configMapId" placeholder="Configuration Map">
                </ng-select>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-4 col-8 mb-2">
                <label>File Name</label>
                <div class="form-control">{{firmware?.fileName ? firmware.fileName : '&nbsp;'}}</div>
              </div>
              <div class="col-sm-4 col-4 mb-2">
                <label>File Size (Bytes)</label>
                <div class="form-control">{{firmware?.fileSize ? firmware.fileSize : '&nbsp;'}}</div>
              </div>
              <div class="col-sm-4 col-4 mb-2">
                <label>Upload Time Stamp</label>
                <div class="form-control">{{firmware?.timeStamp ? firmware.timeStamp : '&nbsp;'}}</div>
              </div>
              <div class="col-sm-6 col-12 mb-2">
                <label>File Hash</label>
                <div class="form-control">{{firmware?.fileHash ? firmware.fileHash : '&nbsp;'}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col-12 no-gutters p-0 mt2- mb-2">
            <app-upload [multipleFiles]="false" [showUpload]="this.formState != this.formStates.Read" [uploadUrl]="firmwaresService.fileUploadUrl()" [expectedFileType]="'blob'" (onSuccessItem)="onSuccessItem($event)"></app-upload>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="pt-0 text-right uuid-opaque">{{ (this.firmwareUuid && this.firmwareUuid.toString() !="") ?
    this.firmwareUuid.toString() : "&nbsp;"}}</div>
  <!-- {{fgFirmware.value | json}}<br><br> -->
</div>