import * as _ from 'lodash';
import { environment } from '../../environments/environment';

export class DeviceConfigMapOptions {
    code: string = "";
    prompt: string = null;
    description: string = null;
    notes: string = null;
    dataTypeId: number = 0;
    valueSourceId: number = 0;
    minimum: number = 0;
    maximum: number = 0;
    default: string = "0";
    readOnly: boolean = false
}

export class DeviceScheduleDto {
    uuid: string = "";
    clientUuid: string = "";
    configMapId: number = 0;
    typeId: number = 0;
    name: string = "";
    description: string = "";
    deviceCount: number = 0;
    devicesAssigned: any[] = new Array<any>();
    //devicesAvailable: any[] = new Array<any>();
    deviceScheduleDates: DeviceScheduleCalendarDto[] = [];
    deviceScheduleConfigurationValuesDefault: DeviceScheduleConfigurationEventValuesDto[] = [];
    deviceScheduleConfigurations: DeviceScheduleConfigurationsDto[] = [];

    public deviceScheduleConfigurationValuesDefault_Apply(defaultValues: DeviceScheduleConfigurationEventValuesDto[]) {
        this.deviceScheduleConfigurationValuesDefault =  _.cloneDeep(defaultValues);
        this.deviceScheduleConfigurationsDefault_Refresh();
    }

    public deviceScheduleConfigurationsDefault_Refresh(): void {
        for (let dayConfig of this.deviceScheduleConfigurations) {
            let defaultConfigEvent = dayConfig.deviceScheduleConfigurationEvents.find(e => e.time == "00:00");
            if (!defaultConfigEvent) {
                defaultConfigEvent = new DeviceScheduleConfigurationEventsDto();
                dayConfig.deviceScheduleConfigurationEvents.push(defaultConfigEvent);        
            }
            defaultConfigEvent.time = "00:00";
            defaultConfigEvent.name = environment.dayScheduleDefaultEventName;
            defaultConfigEvent.deviceScheduleConfigurationEventValues = this.deviceScheduleConfigurationValuesDefault;
        }
    }

    public deviceScheduleConfigurationEvent_Add(
        dayConfig: DeviceScheduleConfigurationsDto,
        time: string
    ): DeviceScheduleConfigurationEventsDto {

        time = time.trim();
        let arrayTime = time.split(":");
        if (arrayTime.length != 2) return null;

        let hours: number = +arrayTime[0];
        let minutes: number = +arrayTime[1];
        if (!(hours >= 0 && hours <= 12 && minutes >= 0 && minutes <= 59)) return null;

        let configEvent = dayConfig.deviceScheduleConfigurationEvents.find(e => e.time == time);

        if (!configEvent) {
            configEvent = new DeviceScheduleConfigurationEventsDto();
            configEvent.time = time;
            configEvent.deviceScheduleConfigurationEventValues = this.deviceScheduleConfigurationValuesDefault;
            
            dayConfig.deviceScheduleConfigurationEvents.push(configEvent);
        }
        
        return configEvent;
    }

}

export class DeviceScheduleCalendarDto {
    sequence: number = 0;
    calendarDate: Date = null;
}

export class DeviceScheduleConfigurationsDto {
    sequence: number = 0;
    colorCode: string = "";
    name: string = "";
    description: string = "";
    calendarDates: Date[] = [];
    deviceScheduleConfigurationEvents: DeviceScheduleConfigurationEventsDto[] = [];
}

export class DeviceScheduleConfigurationEventsDto {
    time: string = "";
    name: string = "";
    description: string = "";
    deviceScheduleConfigurationEventValues: DeviceScheduleConfigurationEventValuesDto[] = [];
}

export class DeviceScheduleConfigurationEventValuesDto {
    code: string = "";
    prompt: string = "";
    description: string = "";
    notes: string = "";
    default: string = "";
    value: string = "";
}
