import { Component, OnInit, ViewChild } from '@angular/core';
import { TopAlertsViewComponent } from '../../shared/component/views/topalertsview/topalertsview.component';
import { AlertsGridComponent } from '../../shared/component/grids/alertsgrid/alertsgrid.component';
import { SettingsService } from '../../core/settings/settings.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
    @ViewChild(TopAlertsViewComponent) topAlertsView: TopAlertsViewComponent;
    @ViewChild(AlertsGridComponent) alertsGrid: AlertsGridComponent;

    private firstLoad: boolean = true;

    public get dataLoading(): boolean {
        if (!this.topAlertsView || !this.alertsGrid) return false;
        return this.topAlertsView.dataLoading || this.alertsGrid.dataLoading;
    }

    constructor
        (
            private router: Router,
            private route: ActivatedRoute,
            private settingsService: SettingsService
        ) { }

    ngOnInit() {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.router.url.toLowerCase().split("?")[0] == '/alerts') {
                    console.log(this.route.routeConfig.component.name + ":NavigationEnd")

                    var self = this
                    setTimeout(function () {
                        self.dataRefresh()
                    }, 50);
                }
            }
        });
    }

    onClearAlert(event) {
        this.dataRefresh()
    }


    onDataLoading(event) {
        if (this.dataLoading && this.firstLoad) this.settingsService.showSpinner("alertsSpinner", false, 250);
        this.firstLoad = false;

        if (!this.dataLoading) this.settingsService.hideSpinner("alertsSpinner");
    }

    onDeviceAlertsSelected(event) {

    }

    dataRefresh() {
        var self = this;
        if (!this.settingsService.client ||
            !this.topAlertsView ||
            !this.alertsGrid) {
            setTimeout(() => {
                self.dataRefresh();
            }, 100);
        }
        else {
            this.topAlertsView.getDeviceAlerts();
            this.alertsGrid.getDeviceAlerts();
        }
    }
}
