import { Component, EventEmitter, OnInit, AfterViewInit, Input, Output, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import 'chartjs-plugin-datalabels';
import { AgGridModule } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { DevicesService, DeviceLocationsService } from '../../../../core/api/api.services';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ColorsService } from '../../../../core/colors/colors.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-binsummaries-grid',
  templateUrl: './binsummaries-grid.component.html',
  styleUrls: ['./binsummaries-grid.component.scss']
})
export class BinSummariesGridComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() onChartJsEvent: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');
  public bins: number[][] = this.librariesService.speedBinsGet('', 'MPH');
  public isInit: boolean = true;
  public gridOptions: GridOptions;
  @Input() public dateEnd: Date = null;
  @Input() public dateBegin: Date = null;

  private _gridData: any[] = null;
  get gridData(): any[] {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any[]) {
    this._gridData = newGridData;
  }
  columnDefsEmpty = [];
  columnDefs = [
    {
      headerName: 'Record Date', field: 'recordDate', sortable: true, resizable: true, minWidth: 200,
      cellRenderer: (data) => {
        return (new Date(data.value)).toLocaleDateString();
      }
    },
    { headerName: 'Speed Limit', field: 'speedLimit', sortable: true, resizable: true },
    { headerName: 'Speed Peak', field: 'speedPeak', sortable: true, resizable: true },

    { headerName: `Average ${this.getBinsName(0)}`, field: 'averageBin0', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(1)}`, field: 'averageBin1', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(2)}`, field: 'averageBin2', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(3)}`, field: 'averageBin3', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(4)}`, field: 'averageBin4', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(5)}`, field: 'averageBin5', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(6)}`, field: 'averageBin6', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(7)}`, field: 'averageBin7', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(8)}`, field: 'averageBin8', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(9)}`, field: 'averageBin9', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(10)}`, field: 'averageBin10', sortable: true, resizable: true },
    { headerName: `Average ${this.getBinsName(11)}`, field: 'averageBin11', sortable: true, resizable: true },

    { headerName: `Peak ${this.getBinsName(0)}`, field: 'peakBin0', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(1)}`, field: 'peakBin1', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(2)}`, field: 'peakBin2', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(3)}`, field: 'peakBin3', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(4)}`, field: 'peakBin4', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(5)}`, field: 'peakBin5', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(6)}`, field: 'peakBin6', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(7)}`, field: 'peakBin7', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(8)}`, field: 'peakBin8', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(9)}`, field: 'peakBin9', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(10)}`, field: 'peakBin10', sortable: true, resizable: true },
    { headerName: `Peak ${this.getBinsName(11)}`, field: 'peakBin11', sortable: true, resizable: true },

    { headerName: 'Vehicles', field: 'vehicles', sortable: true, minWidth: 150, resizable: true },
    { headerName: 'Violators', field: 'violators', sortable: true, minWidth: 150, resizable: true },
    { headerName: 'Percentile 50', field: 'percentile50', minWidth: 150, sortable: true, resizable: true },
    { headerName: 'Percentile 85', field: 'percentile85', minWidth: 150, sortable: true, resizable: true },
  ];

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',

        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true
        }
      }
    ]
  }

  getBinsName(index: number) {
    return this.bins == undefined ? '' : index == 11 ? `${this.bins[index][0]}+` : `${this.bins[index][0]}-${this.bins[index][1]}`;
  }

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public colorsService: ColorsService,
    public devicesService: DevicesService,
    public deviceLocationsService: DeviceLocationsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
  ) {
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.bins == undefined ? this.columnDefsEmpty : this.columnDefs,
      sideBar: this.sideBar,
      
      // rowSelection: 'single'
    };
    // this.gridOptions.api.refreshHeader();
    // this.gridOptions.api.setColumnDefs(this.columnDefs);
  }


  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.dataTableEventsInitialize();
    const self = this;
    self.dataBinSummariesResize();
    setTimeout(() => {
      self.isInit = true;
      this.refreshGrid();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isInit) {
      this.dataBinSummariesResize();
    }
  }

  dataTableEventsInitialize() {
    var self = this;
    window.addEventListener('resize', function () {
      self.dataBinSummariesResize();
    });
  }

  dataBinSummariesResize() {
    console.log('@@@dataBinSummariesResize');

    if (document.getElementById('footerContainer') == null)
      return;

    var dataBinSummariesHeight: number =
      document.body.clientHeight - 15 -
      (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[0]).getBoundingClientRect().top -
      (document.getElementById('footerContainer').getBoundingClientRect().bottom -
        document.getElementById('footerContainer').getBoundingClientRect().top)

    dataBinSummariesHeight = Math.max(dataBinSummariesHeight.round(0), 300);

    (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[1]).style.height = dataBinSummariesHeight.toString() + 'px';
    console.log('@@@', (<HTMLElement>document.getElementsByTagName('ag-grid-angular')[1]).style.height);
  }

  refreshGrid() {
    if (this.gridData && this.gridData.length > 0)
      this.bins = this.librariesService.speedBinsGet('', this.librariesService.speedUnitIdToCode(this.gridData[0].unitIdSpeed));

    this.autoSizeColumns();
  }

  autoSizeColumns() {
    let allColumnIds = [];
    this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  }

  saveCsv(fileName) {
    this.gridOptions.api.exportDataAsCsv({ fileName: fileName });
  }
}
