<!-- 
    <div class="card">
    <div class="card-body pt-0"> 
-->
<div class="row">
    <div class="col-12 align-middle text-center" *ngIf="deviceAlerts && deviceAlerts.length == 0; else showAlerts">
        <em class="fas fa-check-circle fa-1x app-alert-green"></em><span class="h4 font-weight-bold ">&nbsp;&nbsp;All Devices Are Ok!</span>
    </div>
    <ng-template #showAlerts>
        <div *ngIf="deviceAlerts" class="col-12 align-middle">
            <div *ngFor="let deviceAlert of deviceAlerts" role="alert"
                class="alert alert-{{deviceAlert.styleName}} alert-dismissible fade show"> 
                <i class={{deviceAlert.iconName}} [ngStyle]="{ 'color': '#' + deviceAlert.iconColor }"></i>
                <strong
                    class="ml-2">{{deviceAlert.createdDate | date}}&nbsp;-&nbsp;{{deviceAlert.title}}</strong>&nbsp;-
                <span style="cursor: pointer;"
                    routerLink="/devices/{{deviceAlert.device.uuid}}"><u>{{deviceAlert.deviceLabel}}</u></span>
                <div class="mt-1" [innerHTML]="deviceAlert.content"></div>
                <button *ngIf="deviceAlert.ButtonDismiss" type="button"
                    (click)="clearAlert(deviceAlert.id)" class="mt-2 mr-2 btn close closeText" data-dismiss="alert" aria-label="Close" >
                    <span aria-hidden="true">Dismiss</span>
                </button>
                <button *ngIf="deviceAlert.ButtonInactive" type="button"
                    (click)="clearAlert(deviceAlert.id)" class="mt-2 mr-2 btn close closeText" data-dismiss="alert" aria-label="Close" >
                    <span aria-hidden="true">Set Device Inactive</span>
                </button>
            </div>
        </div>
    </ng-template>
</div>
<!-- 
    </div>
    </div> 
-->