import { Component, ViewEncapsulation, ViewChild, OnInit, AfterViewInit, HostListener, Input, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import 'chartjs-plugin-datalabels';
import { IonRangeSliderComponent } from "ng2-ion-range-slider";
import { ClientsService, DevicesService } from '../../../core/api/api.services';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { ColorsService } from '../../../core/colors/colors.service';
import { EventsService } from '../../../core/events/events.service';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { StatsVehiclesViolatorsChartComponent } from '../../../shared/component/charts/stats-vehiclesviolators-chart/stats-vehiclesviolators-chart.component';
import * as $ from 'jquery';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-charts-timespan',
  templateUrl: './charts-timespan.component.html',
  styleUrls: ['./charts-timespan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartsTimespanComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sliderTimePeriodOne') sliderTimePeriodOne: IonRangeSliderComponent;
  @ViewChild('sliderTimePeriodTwo') sliderTimePeriodTwo: IonRangeSliderComponent;
  @ViewChild('sliderBinGroups') sliderBinGroups: IonRangeSliderComponent;
  @ViewChild('chartVehiclesViolators') chartVehiclesViolators: StatsVehiclesViolatorsChartComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();
  public dataLoading: boolean = false;
  private stickyTrigger: boolean = false;
  private stickyTriggerScroll: number = 0;

  public timeSegmentsDisabled: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    //console.log("scrolling: window.scrollY:"+window.scrollY);
    //-parseInt($('#chartVehiclesViolators').css('margin-top'))
    var cbHeight = $('#cardBase').height()
      -$('#cardBaseHeaderRow').height()-$('#cardBaseHeaderRow').position().top

    if($('#chartOptionsStickySpacer').height() <= 0)
      $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height()-parseInt($('#chartVehiclesViolators').css('margin-top')) + 'px' });

    var spaceHeight = $('#chartOptionsStickySpacer').height()
      -$('#cardBaseHeaderRow').height()-$('#cardBaseHeaderRow').position().top
    
    //console.log("cbHeight-window.scrollY="+(cbHeight-window.scrollY));
    //console.log("spaceHeight-window.scrollY="+(spaceHeight-window.scrollY));
    
    if (this.showChartOptions && document.documentElement.scrollTop < cbHeight) {
      this.showChartOptions = true;
      this.showChartOptionsSticky = false;
      $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height()-parseInt($('#chartVehiclesViolators').css('margin-top')) + 'px' });
      console.log("scrolling if options: Spacer Set, spaceHeight="+$('#cardBase').height());
    } else if (this.showChartOptionsSticky && document.documentElement.scrollTop < spaceHeight) {
      this.showChartOptions = true;
      this.showChartOptionsSticky = false;
      this.stickyTrigger = true;
      this.stickyTriggerScroll = document.documentElement.scrollTop;

      $("#cardBase").css({ width: '', top: '' });
      console.log("scrolling if sticky: cardBase clear, spaceHeight="+spaceHeight);
    } else if (this.stickyTrigger) {
      //console.log("scrolling stickyTrigger: window.scrollY ="+window.scrollY+', '+this.stickyTriggerScroll); 
      if (document.documentElement.scrollTop - this.stickyTriggerScroll > 20) this.stickyTrigger = false;
    } else {
      // if (this.showChartOptionsSticky == true) 
      // {
      // this.chartOptionsStickySpacerHeight = 10 * $('#cardBaseHeaderRow').position().top+$('#cardBaseHeaderRow').height();
      // if (this.chartOptionsStickySpacerHeight>0) $("#chartOptionsStickySpacer").css({ height: this.chartOptionsStickySpacerHeight+'px'});
      // }
      var new_width = $('#cardContainer').width();
      $('#cardBase').width(new_width);

      var new_top = $('#headerRightNavbar').position().top + $('#headerRightNavbar').height();
      if (this.showChartOptionsSticky != true || spaceHeight < 0) {
        $("#cardBase").css({ top: (new_top) + 'px' });
        this.showChartOptionsSticky = true;
        console.log("scrolling else: Set new_top");
        
        //setTimeout(() => {this.onWindowScroll(event); }, 250);
  
      }
      if (this.showChartOptions != false) {
        this.showChartOptions = false;
      }

       console.log("scrolling else: new_top:"+new_top+", spaceHeight="+spaceHeight);
    }

  }
  @HostListener('window:resize', ['$event'])
  onWindowResize($event) {
    //console.log("scrolling: window.scrollY:"+window.scrollY);
    if (this.showChartOptionsSticky) {
      var new_width = $('#cardContainer').width()
      $('#cardBase').width(new_width)
    }
  }

  public chartDpsData: any = null
  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  private paramSub: any;
  public showDevicesView: boolean = true;
  public showChartOptions: boolean = true;
  public showChartOptionsSticky: boolean = false;
  public chartOptionsStickySpacerHeight: number = 0;

  // private _deviceLocationUuid: string = "";
  @Input() public deviceLocation: any = null;  
  get deviceLocationUuid(): string {
    if (this.deviceLocation) return this.deviceLocation.uuid
    return ""
  }
  // set deviceLocationUuid(newDeviceLocationUuid: string) {
  //   this.fgChart.patchValue({
  //     deviceLocationUuid: newDeviceLocationUuid
  //   });

  //   if (this.librariesService.guidValidate(newDeviceLocationUuid)) {
  //     this._deviceLocationUuid = newDeviceLocationUuid;
  //     this.showDevicesView = false;
  //     this.getDevice()
  //   }
  //   else {
  //     this._deviceLocationUuid = "";
  //     this.deviceLocation = null;
  //     this.showDevicesView = true;
  //   }
  // }

  timePeriodFormat = (num: number) => {
    return this.timePeriodToString(num)
  };
  timePeriodToString(num: number)
  {
    var min: string = (num - Math.floor(num) != 0 ? ":30" : "")
    if (num == 0) return "12 AM"
    if (num >= 24) return "Midnight"
    if (num == 12) return "12 PM"
    if (num == 12.5) return "12:30 PM"
    if (num < 12) return Math.floor((num == 0 ? 12 : num)).toString() + min + " AM";
    if (num > 12) return Math.floor(num - 12).toString() + min + " PM";
  }

  updateSliderBinGroups(slider, event) {
    //console.log("Slider One updated: from=" + slider.from + " to=" + slider.to);
  }
  finishSliderBinGroups(slider, event) {
    //console.log("Slider One finish: from=" + slider.from + " to=" + slider.to);

    this.fgChart.patchValue({
      binGroupSplitOne: slider.from,
      binGroupSplitTwo: slider.to
    });
  }

  updateSliderTimePeriodOne(slider, event) {
    //console.log("Slider One updated: from=" + slider.from + " to=" + slider.to);
  }
  finishSliderTimePeriodOne(slider, event) {
    //console.log("Slider One finish: from=" + slider.from + " to=" + slider.to);

    var sliderTwoBegin: number = this.fgChart.get('timeFilterTwoBegin').value
    var sliderTwoEnd: number = this.fgChart.get('timeFilterTwoEnd').value

    if (this.sliderTimePeriodTwo) {

      this.sliderTimePeriodTwo.update({
        from_min: slider.to,
        to_min: slider.to
      })

      if (this.fgChart.get('timeFilterTwoBegin').value < slider.to){
        sliderTwoBegin = slider.to
      }
      if (this.fgChart.get('timeFilterTwoEnd').value < slider.to){
        sliderTwoEnd = slider.to
      }

    }

    this.fgChart.patchValue({
      timeFilterOneBegin: slider.from,
      timeFilterOneEnd: slider.to,
      timeFilterTwoBegin: sliderTwoBegin,
      timeFilterTwoEnd: sliderTwoEnd
    });
  }

  updateSliderTimePeriodTwo(slider, event) {
    //console.log("Slider One updated: from=" + slider.from + " to=" + slider.to);
  }
  finishSliderTimePeriodTwo(slider, event) {
    //console.log("Slider One finish: from=" + slider.from + " to=" + slider.to);

    this.fgChart.patchValue({
      timeFilterTwoBegin: slider.from,
      timeFilterTwoEnd: slider.to
    });
  }

  public fgChart: FormGroup = this.formBuilder.group({
    dateRange: [new Date(), new Date()],
    timeSegment: "0",
    timeFilterOneBegin: 0,
    timeFilterOneEnd: 0,
    timePeriodTwoEnabled: false,
    timeFilterTwoBegin: 0,
    timeFilterTwoEnd: 0,
  });

  // get fgChartTimePeriodTwoEnabled(): boolean {
  //   return this.fgDevice.get('timePeriodTwoEnabled').value
  // }

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {

    var localDate1: Date = new Date(localStorage.getItem('chartsStandardDateRangeOneBegin'))
    var localDate2: Date = new Date(localStorage.getItem('chartsStandardDateRangeOneEnd'))
    var localTimeSegment: string = localStorage.getItem('chartsStandardTimeSegment')
    var localTimeFilterOneBegin: string = localStorage.getItem('chartsStandardTimeFilterOneBegin')
    var localTimeFilterOneEnd: string = localStorage.getItem('chartsStandardTimeFilterOneEnd')
    var localTimePeriodTwoEnabled: string = localStorage.getItem('chartsStandardTimePeriodTwoEnabled')
    var localTimeFilterTwoBegin: string = localStorage.getItem('chartsStandardTimeFilterTwoBegin')
    var localTimeFilterTwoEnd: string = localStorage.getItem('chartsStandardTimeFilterTwoEnd')

    this.fgChart.patchValue({
      dateRange: [
        localDate1 > new Date('1/1/2000') ? localDate1 : new Date(), 
        localDate2 > new Date('1/1/2000') ? localDate2 : new Date()
      ],
      
      timeSegment: localTimeSegment ? localTimeSegment.toString() : "60",
      timeFilterOneBegin: localTimeFilterOneBegin ? +localTimeFilterOneBegin : 4,
      timeFilterOneEnd: localTimeFilterOneEnd ? +localTimeFilterOneEnd : 19,
      timePeriodTwoEnabled: localTimePeriodTwoEnabled ? (localTimePeriodTwoEnabled=="true"): false,
      timeFilterTwoBegin: localTimeFilterTwoBegin ? +localTimeFilterTwoBegin : 19,
      timeFilterTwoEnd: localTimeFilterTwoEnd ? +localTimeFilterTwoEnd : 24,
    });

  }

  ngOnInit() {
    this.dataLoading = true
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.onChangesFormSetup();
  }

  public timeSegmentRefresh() {
    var dateStart = new Date(this.fgChart.get('dateRange').value[0]);
    var dateEnd = new Date(this.fgChart.get('dateRange').value[1]);
    var today = new Date();
    var yesterday = new Date(today.getFullYear(),today.getMonth(), today.getDate())
    yesterday.setDate(yesterday.getDate()-1)
    if (dateEnd > yesterday){
      dateEnd = yesterday
      //this.fgChart.get('dateRange').value[1] = yesterday;
      this.fgChart.patchValue({
        dateRange: [dateStart, dateEnd]
      });
    }

    var timeDiff = dateEnd.getTime() - dateStart.getTime();
    let daysDiff = (timeDiff / (1000 * 3600 * 24)).round(0)

    if (daysDiff > 7) {
      this.fgChart.patchValue({timeSegment: "0"})
    }

    this.timeSegmentsDisabled = daysDiff > 7

    if (daysDiff > 365) {
      this.notificationsService.error("", "Date Period should be less then 365 days", { timeOut: 15000, clickToClose: true });
      
      let dateEndNew = new Date(dateEnd);
      let dateStartNew = new Date(dateEnd.setDate(dateEnd.getDate() - 365));
 
      //this.fgChart.get('dateRange').value[1] = dateEndNew;
      //this.fgChart.get('dateRange').value[0] = dateStartNew;

      this.fgChart.patchValue({
        dateRange: [dateStartNew, dateEndNew]
      });

    }
  }

  public chartsRefresh() {
    this.dataLoading = true;
    if (!this.deviceLocationUuid || this.deviceLocationUuid == "") return
    localStorage.setItem('chartsStandardTimeSegment', this.fgChart.get('timeSegment').value)
    localStorage.setItem('chartsStandardTimeFilterOneBegin', this.fgChart.get('timeFilterOneBegin').value)
    localStorage.setItem('chartsStandardTimeFilterOneEnd', this.fgChart.get('timeFilterOneEnd').value)
    localStorage.setItem('chartsStandardTimePeriodTwoEnabled', this.fgChart.get('timePeriodTwoEnabled').value)
    localStorage.setItem('chartsStandardTimeFilterTwoBegin', this.fgChart.get('timeFilterTwoBegin').value)
    localStorage.setItem('chartsStandardTimeFilterTwoEnd', this.fgChart.get('timeFilterTwoEnd').value)
    this.dataLoading = false;


  }

  onChangesFormSetup(): void {
    this.fgChart.get('timePeriodTwoEnabled').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {

      // this.sliderTimePeriodTwo.update({
      //   disable: !val
      // })
      // alert('hello')

    });

    this.fgChart.get('dateRange').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {

      this.timeSegmentRefresh();
      this.chartsRefresh()

      localStorage.setItem('chartsStandardDateRangeOneBegin', this.fgChart.get('dateRange').value[0].toLocaleString());
      localStorage.setItem('chartsStandardDateRangeOneEnd', this.fgChart.get('dateRange').value[1].toLocaleString());
    });
    this.fgChart.get('timeSegment').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {
      this.chartsRefresh()
    });
    this.fgChart.get('timeFilterOneBegin').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {
      this.chartsRefresh()
    });
    this.fgChart.get('timeFilterOneEnd').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {
      this.chartsRefresh()
    });
    this.fgChart.get('timeFilterTwoBegin').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {
      this.chartsRefresh()
    });
    this.fgChart.get('timeFilterTwoEnd').valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(val => {
      this.chartsRefresh()
    });
    this.dataLoading = false;
  }

  public savePdf() {
    let doc = new jsPDF();
    let allContainers = $('[class="chartjs-size-monitor"]').parent();
    let allCanvas = [];

    let locationName = $('[class*="location_name"]').text().replace(' ', '_');
    let dateBegin = this.fgChart.get('dateRange').value[0];
    let formattedDateBegin = `${dateBegin.getMonth() + 1}-${dateBegin.getDate()}-${dateBegin.getFullYear()}`;

    let dateEnd = this.fgChart.get('dateRange').value[1];
    let formattedDateEnd = `${dateEnd.getMonth() + 1}-${dateEnd.getDate()}-${dateEnd.getFullYear()}`;

    allContainers.each(function (index, elem) {
      html2canvas(elem as HTMLCanvasElement).then(function(canvas) {
        allCanvas.push({canvas, index});

        if (allContainers.length === allCanvas.length) {
          for (let i = 0, pageNumber = 0; i < allCanvas.length; i++, pageNumber++) {
            let canvas = allCanvas.find(can => {return can.index === i}).canvas as HTMLCanvasElement;
            let imgData = canvas.toDataURL('image/png');
            let marginLeft = 15;
            let marginTop = 15;
            let chartWidth = 180;
            let chartHeight = 120;
            let chartPositionY = pageNumber * 150;

            doc.addImage(imgData, 'PNG', marginLeft, chartPositionY + marginTop, chartWidth, chartHeight);
            if (i % 2) {
              pageNumber = -1;
              if (i !== allCanvas.length - 1) {
                doc.addPage();
              }
            }
          }
          doc.save(`${locationName}_${formattedDateBegin}_to_${formattedDateEnd}.pdf`);
        }
      });
    });
  }

  showChartOptionsClick(event) {
    if (this.showChartOptions && this.showChartOptionsSticky == false && document.documentElement.scrollTop < $('#cardBase').height()) {
      $("#chartOptionsStickySpacer").css({ height: $('#cardBase').height() + 'px' });
      this.showChartOptionsSticky = true;
      $('html, body').animate({
        scrollTop: (
            $("#chartVehiclesViolators").position().top-
            $('#cardBaseHeaderRow').height()-
            $('#cardBaseHeaderRow').position().top )
      }, 200);
      
      setTimeout(() => {this.onWindowScroll(event); }, 250);
    }
    this.showChartOptions = !this.showChartOptions;
  }

  onChartJsEvent(event)
  {
    switch (event.toString()) {
      case "click":
        {
          if (this.showChartOptionsSticky == true && this.showChartOptions == true) {
              this.showChartOptions = false
          }
          break;
        }
      case "---":
        {
          break;
        }
    }
  }

  buttonListing(event) {
    this.router.navigate(['/charts']);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

}

