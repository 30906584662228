<div class="card border-0">
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-lg-6 align-middle">
                <input type='text' #datatableFilter class="form-control" placeholder='Type to filter by User Name, Email, etc.' (keyup)='updateFilter($event?.target?.value?.toLowerCase())'
                />
            </div>
        </div>
        <ag-grid-angular #agGrid id="devicesViewTable" name="devicesViewTable" style="height: 78vh; width: 100%;"
                    class="ag-theme-balham devicesViewTable m-0 pt-2 border-0" (window:resize)="onResize($event)"
                    [animateRows]="true" [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="clients"
                    (rowSelected)="onRowSelected($event)" [chartThemes]="chartThemes" [rowStyle]="rowStyle"
                    (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions"
                    [getChartToolbarItems]="getChartToolbarItems">
        </ag-grid-angular>
    </div>
</div>


<!-- <ngx-datatable #table class='bootstrap no-detail-row' (select)='onSelect($event)' [selectionType]="'single'" [selected]="clientsSelected"
    [columnMode]="'force'" [footerHeight]="20" [rowHeight]="'auto'" [limit]="15" [rows]='clients'>

    <ngx-datatable-column [resizeable]="false" name="" minWidth="50" maxWidth="50">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div *ngIf="row.connectivityTypeId==00" class="mt-2 text-primary"><em class="fas fa-wifi fa-2x"></em></div>
            <div *ngIf="row.connectivityTypeId==10" class="mt-2 text-green"><em class="fas fa-signal-alt fa-2x"></em></div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Name" minWidth="100" maxWidth="175">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.name}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="Address" minWidth="199" maxWidth="175">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.address1}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" name="City, State/Prov, Postal">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div [innerHTML]="getDisplayAddress(row)"></div>
        </ng-template>
    </ngx-datatable-column>

</ngx-datatable> -->
<!-- </div>
</div> -->