
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { UsersService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject } from 'rxjs';
import { GridOptions, ChartType } from 'ag-grid-community';
import { LibrariesService } from '../../../../core/libraries/libraries.service';

@Component({
  selector: 'app-users-admin-view',
  templateUrl: './users-admin-view.component.html',
  styleUrls: ['./users-admin-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersAdminViewComponent implements OnInit, AfterViewInit, OnDestroy {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public usersTemp: any[] = [];
  public users: any[] = [];
  public usersSelected: any[] = [];
  public dataLoading: boolean = false;
  public firstLoad: boolean = false;

  // ag-grid variables start

  public isSelected: boolean = false;
  public rowStyle;
  public rowSelection: 'single' | 'multiple' = 'multiple';

  public gridOptions: GridOptions;
  public chartThemes;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  private _connectivityTypeId: number = -1;
  get connectivityTypeId(): number {
    return this._connectivityTypeId;
  }
  @Input() set connectivityTypeId(Id: number) {
    this._connectivityTypeId = Id
    if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
  }


  @Output() onViewSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @ViewChild('datatableFilter') datatableFilter: ElementRef;
  @Output() onDataLoading: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public errorsService: ErrorsService,
    private router: Router,
    private route: ActivatedRoute,
    public librariesService: LibrariesService,
    public usersService: UsersService) {
    this.chartThemes = [
      'ag-pastel',
      'ag-default',
      'ag-material-dark',
      'ag-vivid-dark',
      'ag-solar',
    ];
    this.rowStyle = {
      cursor: 'pointer'
    }
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      rowStyle: this.rowStyle,
      rowSelection: 'multiple',
      cursor: "pointer",
      suppressRowClickSelection: true,
      onCellClicked: (e) => {
        if (e.colDef.field != 'serial') { // cell is from non-select column
            e.node.setSelected(true);
            this.isSelected = true;
        }
    },

      enableCharts: false,
      enableRangeSelection: false
    }
  }

  columnDefs = [
    { headerName: "Email", field: "email", sortable: true, resizable: true, minWidth: 240, suppressMenu: true },
    { headerName: "First", field: "first", sortable: true, resizable: true, maxWidth: 240, suppressMenu: true },
    { headerName: "Last", field: "last", sortable: true, resizable: true, maxWidth: 240, suppressMenu: true },
    { headerName: "Activity", field: "activityDate", sortable: true, resizable: true, maxWidth: 240, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (this.librariesService.datePipe.transform(new Date(data.value), 'medium'))
          : '') : '';
      } 
    },
    { headerName: "Login/Token", field: "loginDate", sortable: true, resizable: true, maxWidth: 240, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (this.librariesService.datePipe.transform(new Date(data.value), 'medium'))
          : '') : '';
      } 
    },
    { headerName: "Created", field: "createdDate", sortable: true, resizable: true, maxWidth: 240, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (this.librariesService.datePipe.transform(new Date(data.value), 'medium'))
          : '') : '';
      } 
    }
  ]

  ngOnInit() {
    console.log(this.route.routeConfig.component.name + ":ngOnInit", this)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.toLowerCase().split("?")[0] == '/administration/users/all') {
          console.log(this.route.routeConfig.component.name + ":NavigationEnd")

          this.dataRefresh();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.dataRefresh();
  }

  dataRefresh() {
    var self = this;
    if (!this.settingsService.client) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }
    else {
      setTimeout(function () {
        self.getUsers()
      }, 50);
    }
  }

  getUsers(): any {
    //Don't need for admin component
    //if (!this.settingsService.client) return;

    this.dataLoading = true;
    this.onDataLoading.emit(this.dataLoading)


    return this.usersService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          let resetOffset: boolean = this.users.length == 0
          this.users = result;
          this.usersTemp = result;

          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading);
          this.updateFilter(this.datatableFilter.nativeElement.value, resetOffset);


        },
        error => {
          this.notificationsService.error("Server Error (getUsers)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
        });
  }
  
  onRowClicked(event) {
    console.log(event)
    this.router.navigate(['administration/users/', event.node.data.uuid]);
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

  updateFilter(filterValue: string, resetOffset: boolean = true) {
    const val = filterValue;
    // filter our data

    var self = this

    const temp = this.usersTemp.filter(function (d) {
      return (
        (
          (d.first != null && d.first.toLowerCase().indexOf(val) !== -1) ||
          (d.last != null && d.last.toLowerCase().indexOf(val) !== -1) ||
          (d.email != null && d.email.toLowerCase().indexOf(val) !== -1) ||
          val.toString().trim() == ''
        )
      );
    });

    // update the rows
    this.users = temp;
    // Whenever the filter changes, always go back to the first page
    //if (resetOffset) this.dataView.offset = 0;
  }

  onSelect({ selected }) {
    this.usersSelected.splice(0, this.usersSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onViewSelected.emit(selected[0]);
      //this.router.navigate(['/administration/users', selected[0].uuid])
      //alert(selected[0].uuid)
    }
  }

}
