import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiUuidService } from './_api-uuid.service';

@Injectable()
export class FirmwaresService extends ApiUuidService {
  constructor(
    protected router: Router,
    protected http: HttpClient, 
  ) {
    super(router, http, 'firmwares');
  }

  fileUploadUrl(){
    return (`${this.getApiUrl()}/${this.apiRoute}/upload`)
  }

  read() {
    return this.all();
  }

  create(client: any): Observable<any> {
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/register`;
    //var headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    return this.http.post<any>(
      apiParam,
      client
    );
  }

  readByUuid(uuid: string) {
    return this.get(uuid);
  }

  updateByUuid(client: any, uuid: string): Observable<any> {
    var apiParam: string = `${this.getApiUrl()}/${this.apiRoute}/${uuid}`;
    
    return this.http.put<any>(
      apiParam,
      client
    );
  }

}
