<!-- <ngx-spinner name="overview"></ngx-spinner> -->
<div class="row">
  <div class="col-12">
      <div class="card bg-default">
        <div class="card-header">
            
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <h4 class="pt-2 pb-0 mt-1 mb-0">Account Overview</h4>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 float-right text-right row pr-0 mr-0">
  
              <table class="table m-0 p-0 border-0">
                <tbody>
                  <tr>
                    <td class="m-0 p-0 border-0 text-nowrap">
                      <!-- <div class="btn btn-primary pl-2 pr-2 pt-1 pb-1 mr-3" (click)="buttonMapUS($event)">
                        <span class="fas fa-globe-americas"></span></div> -->
                      <button class="btn btn-primary m1-2" (click)="refreshOverview($event)">
                        <img *ngIf="!dataLoading" src="assets/img/spinner-arrows-static.png" width="22" height="22">
                        <img *ngIf="dataLoading" src="assets/img/spinner-arrows.png" width="22" height="22">
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
  
            </div>
          </div>
          
        </div>
      </div>
  </div>
</div>

<!-- START row-->
<div class="card-padding-top row pl-3 pt-0">
  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 pl-0 pt-3">
    <!-- START card-->
    <div class="card bg-danger border-0">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-3 align-baseline">
            <em class="fas fa-life-ring fa-2x"></em>
          </div>
          <div class="col-9 text-right">
            <div class="dashboard-indicator-text">{{DeviceAlertsCount}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">Device Alerts</div>
        </div>
      </div>
      <a class="card-footer bg-gray-dark bt0 clearfix btn-block" routerLink="/alerts">
        <span class="float-left">View Details</span>
        <span class="float-right">
          <em class="fas fa-chevron-circle-right"></em>
        </span>
      </a>
      <!-- END card-->
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 pl-0 pt-3">
    <!-- START card-->
    <div class="card bg-green border-0">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-3 align-baseline">
            <em class="fas fa-signal-alt fa-2x"></em>
          </div>
          <div class="col-9 text-right">
            <div class="dashboard-indicator-text">{{CellularCount}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">Cellular Devices</div>
        </div>
      </div>
      <a class="card-footer bg-gray-dark bt0 clearfix btn-block" [routerLink]="['/devices']"
        [queryParams]="{ view: 'Cellular' }">
        <span class="float-left">View Details</span>
        <span class="float-right">
          <em class="fas fa-chevron-circle-right"></em>
        </span>
      </a>
      <!-- END card-->
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 pl-0 pt-3">
    <!-- START card-->
    <div class="card bg-primary border-0">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-3 align-baseline">
            <em class="fas fa-wifi fa-2x"></em>
          </div>
          <div class="col-9 text-right">
            <div class="dashboard-indicator-text">{{WiFiCount}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">Wi-Fi Devices</div>
        </div>
      </div>
      <a class="card-footer bg-gray-dark bt0 clearfix btn-block" [routerLink]="['/devices']"
        [queryParams]="{ view: 'wi-fi' }">
        <span class="float-left">View Details</span>
        <span class="float-right">
          <em class="fas fa-chevron-circle-right"></em>
        </span>
      </a>
      <!-- END card-->
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 pl-0 pt-3">
    <!-- START card-->
    <div class="card bg-warning border-0">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-3 align-baseline">
            <em class="fas fa-cogs fa-2x"></em>
          </div>
          <div class="col-9 text-right">
            <div class="dashboard-indicator-text">-</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">Analytics</div>
        </div>
      </div>
      <a class="card-footer bg-gray-dark bt0 clearfix btn-block" routerLink="/analytics">
        <span class="float-left">View Details</span>
        <span class="float-right">
          <em class="fas fa-chevron-circle-right"></em>
        </span>
      </a>
      <!-- END card-->
    </div>
  </div>
</div>


<div class="row" *ngIf="deviceLocationsNewActive?.length > 0">
  <div class="col-lg-12 pt-3">
    <div class="row bg-default">
      <div class="col-12">
        <div class="card bg-default">
          <div class="row card-header">
            <div class="card-title col-10">
              <h4 class="mt-0 mb-0">Active Locations</h4>
              <!-- <h4 class="mt-0 mb-0">New Active Locations In Past {{settingsService.getDeviceLocationsNewActiveDays()}}
                Days</h4> -->
            </div>
            <div class="text-right float-right col-2">

              <table style="font-size:smaller;color:#768294" class="float-right">
                <tbody>
                  <tr>
                    <td class="legendColorBox">
                      <div class="boxOutline">
                        <div class="vehiclesBox"></div>
                      </div>
                    </td>
                    <td class="legendLabel pr-2">Vehicles</td>
                    <td class="legendColorBox">
                      <div class="boxOutline">
                        <div class="violatorsBox"></div>
                      </div>
                    </td>
                    <td class="legendLabel">Violators</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row pl-3" *ngIf="deviceLocationsNewActive?.length > 0">
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pl-0" *ngFor="let deviceLocationUuid of deviceLocationsNewActive">
    <app-device-status-card [deviceLocationUuid]="deviceLocationUuid">
    </app-device-status-card>
  </div>
</div>


<div class="row" *ngIf="deviceLocationsMostActive?.length > 0">
  <div class="col-lg-12 pt-3">
    <div class="row bg-default">
      <div class="col-12">
        <div class="card bg-default">
          <div class="row card-header">
            <div class="card-title col-9">
              <h4 class="mt-0 mb-0">Most Active Locations</h4>
              <!-- <h4 class="mt-0 mb-0">Most Active Locations In Past {{settingsService.getDeviceLocationsMostActiveDays()}}
                Days
                {{ deviceLocationsMostActive.length>settingsService.getDeviceLocationsMostActiveMaxRecords() ? 'Top '+settingsService.getDeviceLocationsMostActiveMaxRecords().toString()+' Displayed':  ""}}
              </h4> -->
            </div>
            <div class="text-right float-right col-3">

              <table style="font-size:smaller;color:#768294" class="float-right">
                <tbody>
                  <tr>
                    <td class="legendColorBox">
                      <div style="border:1px solid #ccc;padding:1px">
                        <div style="width:4px;height:0;border:5px solid #5d9cec;overflow:hidden"></div>
                      </div>
                    </td>
                    <td class="legendLabel">Speeders&nbsp;%&nbsp;(Ave)</td>
                    <td class="legendColorBox">
                      <div style="border:1px solid #ccc;padding:1px">
                        <div style="width:4px;height:0;border:5px solid #f05050;overflow:hidden"></div>
                      </div>
                    </td>
                    <td class="legendLabel">Speeders&nbsp;%&nbsp;(Peak)</td>
                    <td class="legendColorBox">
                      <div style="border:1px solid #ccc;padding:1px">
                        <div style="width:4px;height:0;border:5px solid #ff902b;overflow:hidden"></div>
                      </div>
                    </td>
                    <td class="legendLabel">Percentiles</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row pl-3" *ngIf="deviceLocationsMostActive?.length>0">
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pl-0" *ngFor="let deviceLocationUuid of deviceLocationsMostActive">
    <app-device-activity-stats-card [deviceLocationUuid]="deviceLocationUuid">
    </app-device-activity-stats-card>
  </div>
</div>


<div class="card-padding-top row" *ngIf="splineDataOverviewLoad">
  <!-- START dashboard main content-->
  <div class="col-lg-12">
    <!-- START chart-->
    <div class="row">
      <div class="col-lg-12">
        <!-- START widget-->
        <div class="card bg-default">
          <div class="card-header">
            <div class="card-title">
              <h4 class="mt-0 mb-0">Cumulative Vehicle Statistics For All Active Devices</h4>
            </div>
          </div>
          <div class="bg-body h-100 flex-grow">
            <div flot [dataset]="splineDataOverview" [options]="splineOptionsOverview" [height]="splineHeightOverview">
            </div>
          </div>
        </div>
        <!-- END widget-->
      </div>
    </div>
  </div>
</div>