
import {filter,  takeUntil } from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, ViewEncapsulation, OnInit, AfterViewInit, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../../core/settings/settings.service';
import { ClientsService } from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { PasswordValidator } from '../../../../../core/validators/password.validator';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { Subject } from 'rxjs';

export enum ClientFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8,
}

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ClientFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() clientCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  
  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  get formTitle() {
    if (this.clientUuid && this.clientUuid.toString() != "") {
      return 'Edit Client'
    } else return 'Add Client'
  }

  public clients: any;
  public newClient: any;
  public formLoaded: boolean = false;
  public formStates = ClientFormState;
  private _formState: ClientFormState = this.formStates.Initializing;
  get formState(): ClientFormState {
    return this._formState
  }
  @Input() set formState(newFormState: ClientFormState) {

    var updateFormState: ClientFormState = null
    //this.fgClient.disable()

    switch (newFormState) {

      case this.formStates.Read: {
        updateFormState = newFormState
        this.formRead()
        break
      }
      case this.formStates.New: {
        updateFormState = newFormState
        this.formAdd()
        break
      }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.formEdit()
        break
      }
      case this.formStates.Save: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        this.formSave()
        break
      }
      case this.formStates.Saved: {
        //presetting to address form state sequencing durring validation
        this._formState = newFormState
        break
      }
      case this.formStates.Cancelled: {
        this.formCancel()
        break
      }
      default:
        {
          updateFormState = newFormState
        }
    }

    if (updateFormState != null) {
      this._formState = updateFormState
    }
  }

  private _clientUuid: string = "";
  get clientUuid(): string {
    return this._clientUuid;
  }
  @Input() set clientUuid(newClientUuid: string) {
    if (this.librariesService.guidValidate(newClientUuid)) {
      this._clientUuid = newClientUuid;
      if (this.formLoaded) this.getClient()
    }
    else {
      this._clientUuid = "";
    }
    if (newClientUuid.toLowerCase() == 'add') this.formState = this.formStates.New
  }

  private client: any = null; //new ClientCrudDto();

  get isEditMode(): boolean {

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  public isModified = false;

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public clientsService: ClientsService,
    public errorsService: ErrorsService,
    public librariesService: LibrariesService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  public fgClient: FormGroup = this.formBuilder.group({
    uuid: '',
    name: ['', [Validators.required]],
    phoneMain: '',
    phoneFax: '',
    address1: '',
    address2: '',
    city: '',
    stateProvinceCode: '',
    postalCode: '',
    country: ''
  });


  ngOnInit() {

  }
  
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(() => {
      if (this.client == null) self.getClient()
      self.formLoaded = true
    }, 500);
  }

  clearClient(){
    this.client = null;
    this.fgClient.patchValue(
      {
        uuid: '',
        name: '',
        phoneMain: '',
        phoneFax: '',
        address1: '',
        address2: '',
        city: '',
        stateProvinceCode: '',
        postalCode: '',
        country: ''
      }
    )
  }

  getClient(): any {

    this.clearClient()

    if (!this.clientUuid) return;

    return this.clientsService.readByUuid(this.clientUuid)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(
        (result: any) => {

          this.fgClient.patchValue(
            {
              uuid: result.uuid,
              name: result.name,
              phoneMain: result.phoneMain,
              phoneFax: result.phoneFax,
              address1: result.address1,
              address2: result.address2,
              city: result.city,
              stateProvinceCode: result.stateProvinceCode,
              postalCode: result.postalCode,
              country: result.country
            }
          )

          this.formState = this.formStates.Read;
          this.client = result;

          //this.deviceLocationsTemp = result;
        },
        error => {
          this.notificationsService.error("Server Error (getClient)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.formState = this.formStates.Read
        });
  }

  getClients(): any {
		this.clients = [];

		if (!this.settingsService.client) return;

		return this.clientsService.read()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(result: any) => {
					this.clients = result;
				},
				error => {
					this.settingsService.hideSpinner("deviceAdminForm");
					this.notificationsService.error("Server Error (Getting Clients)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
				});
	}

  onClientCreated(value: boolean) {
    this.clientCreated.emit(value);
  }


  formRead(){


  }

  formAdd() {
    //do setup

    this.isModified = false;


    //Send Event
    this.onFormEvent.emit("add")
  }

  formEdit() {
    //do setup

    var self = this;

    this.isModified = false;

    //Send Event
    this.onFormEvent.emit("edit")

    // setTimeout(() => {

    // }, 1000);

  }

  formSave() {

    if (this.fgClient.valid == false) {
      alert(this.fgClient.valid)
      this.librariesService.validateFormGroup(this.fgClient)
      this.formState = this.clientUuid == "" ? this.formStates.New : this.formStates.Edit
      this.onFormEvent.emit("edit")
      this.notificationsService.warn("Validation Warning (Client)", "Verify Required Data", { timeOut: 5000, clickToClose: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEvent.emit("saving")
    console.log(this.fgClient.value)

    if (this.clientUuid == "") {
      return this.clientsService.create(this.fgClient.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
          (result: any) => {
            //alert(result.toString())

            //if successful...
            this.clientUuid = result.uuid
            this.newClient = result;
            
            this.settingsService.clientSetAdmin = true;

            this.formState = this.formStates.Read
            this.onFormEvent.emit("saved")
            this.onClientCreated(result);
            this.notificationsService.success("Success", "Client Saved", { timeOut: 2000, clickToClose: true });
          },
          error => {
            this.notificationsService.error("Server Error (clientSave)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });
    }
    else {
      return this.clientsService.readByUuid(this.clientUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
          (result: any) => {

            result.name = this.fgClient.value.name
            result.phoneMain = this.fgClient.value.phoneMain
            result.phoneFax = this.fgClient.value.phoneFax
            result.address1 = this.fgClient.value.address1
            result.address2 = this.fgClient.value.address2
            result.city = this.fgClient.value.city
            result.stateProvinceCode = this.fgClient.value.stateProvinceCode
            result.postalCode = this.fgClient.value.postalCode
            result.country = this.fgClient.value.country
         
            return this.clientsService.put(result)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (result: any) => {

                  this.clientUuid = result.uuid

                  this.formState = this.formStates.Read
                  this.onFormEvent.emit("saved")
                  this.notificationsService.success("Success", "Client Saved", { timeOut: 2000, clickToClose: true });
                },
                error => {
                  this.notificationsService.error("Server Error (clientSave: put)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                  this.formState = this.formStates.Edit
                });

          },
          error => {
            this.notificationsService.error("Server Error (clientSave: getClient)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });

    }
  }

  formCancel() {
    this.librariesService.untouchedFormGroup(this.fgClient)
    this.formState = this.formStates.Read
    this.onFormEvent.emit("cancelled")
    this.getClient()
  }
  
}
