import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export class ApiService {
  protected apiRoute = '';

    getApiUrl() {
        if (environment.apiUrl) {
            if (location.protocol.substr(0, 5).toLocaleLowerCase() === 'https') {
                return 'https://' + environment.apiUrl;
            } else {
                return 'http://' + environment.apiUrl;
            }
        }
    }

  constructor (
    protected router: Router,
    protected http: HttpClient,
    apiRoute: string) {
    this.apiRoute = apiRoute;
  }

}
