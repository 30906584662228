import { Component, OnInit, ViewChild, ElementRef, Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { ModemsAdminViewComponent } from '../../../shared/component/views/modems-admin-view/modems-admin-view.component';
import { ModemFormComponent } from '../../../shared/component/forms/modems/modem-form/modem-form.component';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modems-admin',
  templateUrl: './modems.component.html',
  styleUrls: ['./modems.component.scss'],
})
export class ModemsAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ModemFormComponent) public modemForm: ModemFormComponent;
  @ViewChild(ModemsAdminViewComponent) public modemView: ModemsAdminViewComponent;
    
  userTypes = userTypes;
  userRoles = userRoles;
  
  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  private paramSub: any;
  public showForm: boolean = false;

  private _modemId: number = -1;
  get modemId(): number {
    return this._modemId;
  }
  set modemId(newModemId: number) {
    if (newModemId == 0) {
      this._modemId = newModemId;
      this.showForm = false;
    } else {
      this._modemId = newModemId;
      this.showForm = true;
    }
  }

  ngOnInit() {
        this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.modemId = params['id'].toString().toLowerCase() == 'all' 
        ? 0 
        : params['id'].toString().toLowerCase() == 'add' ? -1 : params['id'];
    });
  }
  
  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    
  }
  
  onFormEvent(event) {
    switch (event.toString()) {
      case "saved":
        {
          //TODO
          break;
        }
      case "cancelled":
        {
          //TODO
          break;
        }
    }
  }

  onViewSelected(event) {
    if (event && event.id) {
      this.router.navigate(['/administration/modems', event.id.toString()], {replaceUrl: false})
    }
  }

  buttonAdd(event) {
    this.router.navigate(['/administration/modems', 'add'], {replaceUrl: false})
  }
  
  buttonBack(event)
  {
    this.router.navigate(['/administration/modems/all'])
  }

  buttonEdit(event)
  {
    this.modemForm.formState = this.modemForm.formStates.Edit
  }

  buttonSave(event)
  {
    this.modemForm.formState = this.modemForm.formStates.Save
  }

  buttonCancel(event)
  {
    this.modemForm.formState = this.modemForm.formStates.Cancelled
  }

}
