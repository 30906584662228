<!-- 
    <div class="card">
    <div class="card-body pt-0"> 
-->
<ngx-datatable #table class='bootstrap no-detail-row' (select)='onSelect($event)' [selectionType]="'single'"
    [selected]="deviceAlertSelected" [columnMode]="'force'" [footerHeight]="20" [rowHeight]="'auto'" [limit]="15"
    [rows]='deviceAlerts'>

    <ngx-datatable-column [resizeable]="false" minWidth="50" maxWidth="50" name=" ">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="mt-2 text-primary">
                <em class="{{row.iconName}} fa-2x" [ngStyle]="{ 'color': '#' + row.iconColor }"></em>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [resizeable]="false" name="Created Date" minWidth="75" maxWidth="150">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="mt-2">{{row.createdDate | date}}</div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [resizeable]="false" name="Cleared Date" minWidth="75" maxWidth="150">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.clearedDate | date}}</div>
            <span>{{row.clearedBy.userTypeId == 800 ? 'Device' : row.clearedBy.name}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [resizeable]="false" name="Title/Device" minWidth="200" maxWidth="300">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.title}}</div>
            <a routerLink="/devices/{{row.device.uuid}}"><u>{{row.deviceLabel}}</u></a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [resizeable]="false" name="Content">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div [innerHTML]="row.content"></div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<!-- 
    </div>
    </div> 
-->