import { filter, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DevicesService, DeviceConfigurationsService } from '../../../../core/api/api.services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { EventsService } from '../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { DeviceScheduleConfigurationsDto, DeviceScheduleConfigurationEventValuesDto, DeviceScheduleConfigurationEventsDto, DeviceScheduleDto } from '../../../../models/deviceScheduleObjects';
import { ConfigMapOptionsQueryDto } from '../../../../models/configmap';
import * as _ from "lodash";
import { Subject } from 'rxjs';


export enum DailyEventScheduleTimeConfigFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Saved = 7,
  Cancelled = 8
}

@Component({
  selector: 'app-dayschedulestimeconfig-form',
  templateUrl: './dayschedulestimeconfig-form.component.html',
  styleUrls: ['./dayschedulestimeconfig-form.component.scss']
})

export class DaySchedulesTimeConfigFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEvent_EventScheduleTimeConfig: EventEmitter<any> = new EventEmitter<any>();

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public minSpeed: number;
  public maxSpeed: number;

  public showRadar = true;
  public showBeaconsOverride = true;
  public showRelay1Override = true;
  public showRelay2Override = true;
  public showAlert1FlashRate = true;
  public showAlert2FlashRate = true;
  public showAlert2OutputRelay2 = true;
  public showAlert2OutputRelay1 = true;
  public showAlert1OutputRelay2 = true;
  public showAlert1OutputRelay1 = true;
  public showAlert2Beacons = true;
  public showAlert1Beacons = true;
  public showAlert2HighLeds = true;
  public showAlert1HighLeds = true;
  public showAlert1Strobes = true;
  public showAlert2Strobes = true;
  public showAlert1StrobeWhite = true;
  public showAlert2StrobeWhite = true;
  public showAlert1StrobeBlue = true;
  public showAlert2StrobeBlue = true;
  public showAlert1StrobeRed = true;
  public showAlert2StrobeRed = true;
  public showAlert1StrobeRedBlue = true;
  public showAlert2StrobeRedBlue = true;
  public showAlert1CameraFlash = true;
  public showAlert2CameraFlash = true;

  public showAlert1DisplaySpeed = true;
  public showAlert1DisplayBitmap1 = true;
  public showAlert1DisplayBitmap2 = true;
  public showAlert1DisplayBitmap3 = true;
  public showAlert1DisplayBitmap4 = true;
  public showAlert1DisplayBitmap5 = true;
  public showAlert1DisplayBitmap6 = true;
  public showAlert1DisplayBitmap7 = true;

  public showAlert2DisplaySpeed = true;
  public showAlert2DisplayBitmap1 = true;
  public showAlert2DisplayBitmap2 = true;
  public showAlert2DisplayBitmap3 = true;
  public showAlert2DisplayBitmap4 = true;
  public showAlert2DisplayBitmap5 = true;
  public showAlert2DisplayBitmap6 = true;
  public showAlert2DisplayBitmap7 = true;

  public formEnabled = false;
  public formLoaded = false;
  public formStates = DailyEventScheduleTimeConfigFormState;
  private _formState: DailyEventScheduleTimeConfigFormState = DailyEventScheduleTimeConfigFormState.Initializing;
  get formState(): DailyEventScheduleTimeConfigFormState {
    return this._formState;
  }

  @Input() set formState(newFormState: DailyEventScheduleTimeConfigFormState) {
    let updateFormState: DailyEventScheduleTimeConfigFormState = null;

    switch (newFormState) {
      case this.formStates.Read: {
        updateFormState = newFormState;
        break;
      }
      case this.formStates.New: {
        updateFormState = newFormState;
        break;
      }
      case this.formStates.Edit: {
        updateFormState = newFormState;
        // this.deviceEdit();
        break;
      }
      case this.formStates.Save: {
        this._formState = newFormState;
        // this.deviceSave();
        break;
      }
      default: {
        updateFormState = newFormState;
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
    console.log('DailyEventScheduleTimeConfigForm', this._formState)
  }

  public deviceConfiguration: any = null;

  private _dailyEventSchedule: DeviceScheduleConfigurationsDto;
  get DailyEventSchedule(): DeviceScheduleConfigurationsDto {
    return this._dailyEventSchedule;
  }
  @Input() set DailyEventSchedule(newDailyEventSchedule: DeviceScheduleConfigurationsDto) {
    this._dailyEventSchedule = newDailyEventSchedule;
  }

  private _deviceSchedule: DeviceScheduleDto;
  get DeviceSchedule(): DeviceScheduleDto {
    return this._deviceSchedule;
  }

  @Input() set DeviceSchedule(newDeviceSchedule: DeviceScheduleDto) {
    this._deviceSchedule = newDeviceSchedule;

  }

  private _configMapOptions: ConfigMapOptionsQueryDto[];
  get ConfigMapOptions(): ConfigMapOptionsQueryDto[] {
    return this._configMapOptions;
  }
  @Input() set ConfigMapOptions(newConfigMapOptions: ConfigMapOptionsQueryDto[]) {
    this._configMapOptions = newConfigMapOptions;
    console.log('ConfigMapOptions', newConfigMapOptions)
  }

  private _dailyEventScheduleTime: DeviceScheduleConfigurationEventsDto

  get DailyEventScheduleTime(): DeviceScheduleConfigurationEventsDto {
    return this._dailyEventScheduleTime;
  }
  @Input() set DailyEventScheduleTime(newDailyEventScheduleTime: DeviceScheduleConfigurationEventsDto) {
    this._dailyEventScheduleTime = newDailyEventScheduleTime;

    //if (!this.formLoaded) return 
    if (!this.ConfigMapOptions || this.ConfigMapOptions.length <= 0) return;

    let currOptions: ConfigMapOptionsQueryDto[] = _.cloneDeep(this.ConfigMapOptions);

    for (let i = 0; i < currOptions.length; i++) {
      let eventValue: DeviceScheduleConfigurationEventValuesDto[] = [];

      currOptions[i].value = currOptions[i].default;

      if (!currOptions[i].readOnly) {

        eventValue = this.DailyEventScheduleTime.deviceScheduleConfigurationEventValues.filter(r => r.code === currOptions[i].code)

        if (eventValue.length > 0 && eventValue[0].value != null) {
          currOptions[i].value = eventValue[0].value;
        }

      }

    }

    this._dailyEventScheduleTimeOptions = currOptions;
    this.setDeviceConfiguration()

  }

  private _dailyEventScheduleTimeOptions: ConfigMapOptionsQueryDto[];
  get DailyEventScheduleTimeOptions(): DeviceScheduleConfigurationEventValuesDto[] {


    let params = Object.keys(this.fgDeviceConfiguration.value)
      .map(key => ({ 'code': key, value: this.getCorrectValue(this.fgDeviceConfiguration.value[key].toString()) }))
      .concat(Object.keys(this.fgDeviceConfigurationDisplay.value)
        .map(key => ({ 'code': key, value: this.getCorrectValue(this.fgDeviceConfigurationDisplay.value[key].toString()) })))
      .concat(Object.keys(this.fgDeviceConfigurationAlert1.value)
        .map(key => ({ 'code': key, value: this.getCorrectValue(this.fgDeviceConfigurationAlert1.value[key].toString()) })))
      .concat(Object.keys(this.fgDeviceConfigurationAlert2.value)
        .map(key => ({ 'code': key, value: this.getCorrectValue(this.fgDeviceConfigurationAlert2.value[key].toString()) })));

    //console.log(params)

    let options: DeviceScheduleConfigurationEventValuesDto[] = new Array<DeviceScheduleConfigurationEventValuesDto>();

    for (let opt of this._dailyEventScheduleTimeOptions) {
      if (!opt.readOnly) {

        let optValue = params.filter(p => p.code === opt.code)

        if (optValue.length > 0) {

          options.push({
            code: opt.code,
            prompt: opt.prompt,
            description: opt.description,
            notes: opt.notes,
            default: opt.default,
            value: optValue[0].value
          })

        }

      }
    }

    //console.log(options);
    return options;

  }

  get isEditMode(): boolean {
    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving: {
        return true;
      }
      case this.formStates.Cancelled:
      case this.formStates.Saved:
      default: {
        return false;
      }
    }
  }

  public isModified = false;

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public devicesService: DevicesService,
    public deviceConfigurationsService: DeviceConfigurationsService,
    private route: ActivatedRoute
  ) { }

  fgDeviceConfiguration: FormGroup;
  fgDeviceConfigurationDisplay: FormGroup;
  fgDeviceConfigurationAlert1: FormGroup;
  fgDeviceConfigurationAlert2: FormGroup;

  inputEventMinutePochLow: FormControl;
  inputEventMinutePochHigh: FormControl;
  inputSpeedLimit: FormControl;
  inputRadar: FormControl;
  inputDisplayMode: FormControl;
  inputBlinky: FormControl;
  inputPossum: FormControl;
  inputBeaconsOverride: FormControl;
  inputRelay1Override: FormControl;
  inputRelay2Override: FormControl;

  inputMinSpeed: FormControl;
  inputMaxSpeed: FormControl;
  inputDisplay: FormControl;
  inputOverMaxMode: FormControl;

  inputSpeedAlert1: FormControl;
  inputAlert1FlashRate: FormControl;
  inputAlert1HighLeds: FormControl;
  inputAlert1StrobeBlue: FormControl;
  inputAlert1Strobes: FormControl;
  inputAlert2Strobes: FormControl;
  inputAlert1StrobeRed: FormControl;
  inputAlert1StrobeRedBlue: FormControl;
  inputAlert1CameraFlash: FormControl;
  inputAlert1StrobeWhite: FormControl;
  inputAlert1Beacons: FormControl;
  inputAlert1OutputRelay1: FormControl;
  inputAlert1OutputRelay2: FormControl;
  inputAlert1DisplaySpeed: FormControl;
  inputAlert1DisplayBitmap1: FormControl;
  inputAlert1DisplayBitmap2: FormControl;
  inputAlert1DisplayBitmap3: FormControl;
  inputAlert1DisplayBitmap4: FormControl;
  inputAlert1DisplayBitmap5: FormControl;
  inputAlert1DisplayBitmap6: FormControl;
  inputAlert1DisplayBitmap7: FormControl;

  inputSpeedAlert2: FormControl;
  inputAlert2FlashRate: FormControl;
  inputAlert2HighLeds: FormControl;
  inputAlert2StrobeBlue: FormControl;
  inputAlert2StrobeRed: FormControl;
  inputAlert2StrobeRedBlue: FormControl;
  inputAlert2CameraFlash: FormControl;
  inputAlert2StrobeWhite: FormControl;
  inputAlert2Beacons: FormControl;
  inputAlert2OutputRelay1: FormControl;
  inputAlert2OutputRelay2: FormControl;
  inputAlert2DisplaySpeed: FormControl;
  inputAlert2DisplayBitmap1: FormControl;
  inputAlert2DisplayBitmap2: FormControl;
  inputAlert2DisplayBitmap3: FormControl;
  inputAlert2DisplayBitmap4: FormControl;
  inputAlert2DisplayBitmap5: FormControl;
  inputAlert2DisplayBitmap6: FormControl;
  inputAlert2DisplayBitmap7: FormControl;

  paramSub: any;

  ngOnInit() {
    this.createFormGroupDevice();


    // this.eventsService.currentMessage.pipe(filter(message => message.toString() !== ''))
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(
    //     message => {
    //       this.formState = DailyEventScheduleTimeConfigFormState.Initializing;
    //     }
    //   );

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }


  createFormGroupDevice() {
    this.inputEventMinutePochLow = new FormControl('0');
    this.inputEventMinutePochHigh = new FormControl('0');
    this.inputSpeedLimit = new FormControl('', [Validators.required, Validators.maxLength(2)]);
    this.inputRadar = new FormControl('');
    this.inputDisplayMode = new FormControl('');
    this.inputBlinky = new FormControl('');
    this.inputPossum = new FormControl('');
    this.inputBeaconsOverride = new FormControl('');
    this.inputRelay1Override = new FormControl('');
    this.inputRelay2Override = new FormControl('');

    this.inputDisplay = new FormControl('');
    this.inputOverMaxMode = new FormControl('');
    this.inputMinSpeed = new FormControl('', [Validators.required, Validators.min(0), Validators.maxLength(3)]);
    this.inputMaxSpeed = new FormControl('', [Validators.required, Validators.maxLength(3)]);

    this.inputSpeedAlert1 = new FormControl('', [Validators.required, Validators.maxLength(2)]);
    this.inputAlert1FlashRate = new FormControl('');
    this.inputAlert1HighLeds = new FormControl('');
    this.inputAlert1StrobeBlue = new FormControl('');
    this.inputAlert1Strobes = new FormControl('');
    this.inputAlert1StrobeRed = new FormControl('');
    this.inputAlert1StrobeRedBlue = new FormControl('');
    this.inputAlert1StrobeWhite = new FormControl('');
    this.inputAlert1CameraFlash = new FormControl('');
    this.inputAlert1Beacons = new FormControl('');
    this.inputAlert1OutputRelay1 = new FormControl('');
    this.inputAlert1OutputRelay2 = new FormControl('');
    this.inputAlert1DisplaySpeed = new FormControl('');
    this.inputAlert1DisplayBitmap1 = new FormControl('');
    this.inputAlert1DisplayBitmap2 = new FormControl('');
    this.inputAlert1DisplayBitmap3 = new FormControl('');
    this.inputAlert1DisplayBitmap4 = new FormControl('');
    this.inputAlert1DisplayBitmap5 = new FormControl('');
    this.inputAlert1DisplayBitmap6 = new FormControl('');
    this.inputAlert1DisplayBitmap7 = new FormControl('');

    this.inputSpeedAlert2 = new FormControl('', [Validators.required, Validators.maxLength(2)]);
    this.inputAlert2FlashRate = new FormControl('');
    this.inputAlert2HighLeds = new FormControl('');
    this.inputAlert2StrobeBlue = new FormControl('');
    this.inputAlert2StrobeRed = new FormControl('');
    this.inputAlert2StrobeWhite = new FormControl('');
    this.inputAlert2StrobeRedBlue = new FormControl('');
    this.inputAlert2CameraFlash = new FormControl('');
    this.inputAlert2Beacons = new FormControl('');
    this.inputAlert2OutputRelay1 = new FormControl('');
    this.inputAlert2OutputRelay2 = new FormControl('');
    this.inputAlert2DisplaySpeed = new FormControl('');
    this.inputAlert2DisplayBitmap1 = new FormControl('');
    this.inputAlert2DisplayBitmap2 = new FormControl('');
    this.inputAlert2DisplayBitmap3 = new FormControl('');
    this.inputAlert2DisplayBitmap4 = new FormControl('');
    this.inputAlert2DisplayBitmap5 = new FormControl('');
    this.inputAlert2DisplayBitmap6 = new FormControl('');
    this.inputAlert2DisplayBitmap7 = new FormControl('');

    this.fgDeviceConfiguration = new FormGroup({
      "EventMinutePochLow": this.inputEventMinutePochLow,
      "EventMinutePochHigh": this.inputEventMinutePochHigh,
      'SpeedLimit': this.inputSpeedLimit,
      'Radar': this.inputRadar,
      'DisplayMode': this.inputDisplayMode,
      'BeaconsOverride': this.inputBeaconsOverride,
      'Relay1Override': this.inputRelay1Override,
      'Relay2Override': this.inputRelay2Override,
      'Blinky': this.inputBlinky,
      'Possum': this.inputPossum
    });

    this.fgDeviceConfigurationDisplay = new FormGroup({
      'MinSpeed': this.inputMinSpeed,
      'MaxSpeed': this.inputMaxSpeed,
      'Display': this.inputDisplay,
      'OverMaxMode': this.inputOverMaxMode
    });

    this.fgDeviceConfigurationAlert1 = new FormGroup({
      'SpeedAlert1': this.inputSpeedAlert1,
      'Alert1FlashRate': this.inputAlert1FlashRate,
      'Alert1HighLeds': this.inputAlert1HighLeds,
      'Alert1StrobeBlue': this.inputAlert1StrobeBlue,
      'Alert1StrobeRed': this.inputAlert1StrobeRed,
      'Alert1StrobeWhite': this.inputAlert1StrobeWhite,
      'Alert1Beacons': this.inputAlert1Beacons,
      'Alert1OutputRelay1': this.inputAlert1OutputRelay1,
      'Alert1OutputRelay2': this.inputAlert1OutputRelay2,
      'Alert1DisplaySpeed': this.inputAlert1DisplaySpeed,
      'Alert1DisplayBitmap1': this.inputAlert1DisplayBitmap1,
      'Alert1DisplayBitmap2': this.inputAlert1DisplayBitmap2,
      'Alert1DisplayBitmap3': this.inputAlert1DisplayBitmap3,
      'Alert1DisplayBitmap4': this.inputAlert1DisplayBitmap4,
      'Alert1DisplayBitmap5': this.inputAlert1DisplayBitmap5,
      'Alert1DisplayBitmap6': this.inputAlert1DisplayBitmap6,
      'Alert1DisplayBitmap7': this.inputAlert1DisplayBitmap7
    });

    this.fgDeviceConfigurationAlert2 = new FormGroup({
      'SpeedAlert2': this.inputSpeedAlert2,
      'Alert2FlashRate': this.inputAlert2FlashRate,
      'Alert2HighLeds': this.inputAlert2HighLeds,
      'Alert2StrobeBlue': this.inputAlert2StrobeBlue,
      'Alert2StrobeRed': this.inputAlert2StrobeRed,
      'Alert2StrobeWhite': this.inputAlert2StrobeWhite,
      'Alert2Beacons': this.inputAlert2Beacons,
      'Alert2OutputRelay1': this.inputAlert2OutputRelay1,
      'Alert2OutputRelay2': this.inputAlert2OutputRelay2,
      'Alert2DisplaySpeed': this.inputAlert2DisplaySpeed,
      'Alert2DisplayBitmap1': this.inputAlert2DisplayBitmap1,
      'Alert2DisplayBitmap2': this.inputAlert2DisplayBitmap2,
      'Alert2DisplayBitmap3': this.inputAlert2DisplayBitmap3,
      'Alert2DisplayBitmap4': this.inputAlert2DisplayBitmap4,
      'Alert2DisplayBitmap5': this.inputAlert2DisplayBitmap5,
      'Alert2DisplayBitmap6': this.inputAlert2DisplayBitmap6,
      'Alert2DisplayBitmap7': this.inputAlert2DisplayBitmap7
    });
  }

  setDeviceConfiguration(): any {

    this.deviceConfiguration = null;

    if (!this._dailyEventScheduleTimeOptions || this._dailyEventScheduleTimeOptions.length == 0) return

    let currOptions = this._dailyEventScheduleTimeOptions

    this.showRadar = true;
    this.showBeaconsOverride = true;
    this.showRelay1Override = true;
    this.showRelay2Override = true;
    this.showAlert1FlashRate = true;
    this.showAlert2FlashRate = true;
    this.showAlert2OutputRelay2 = true;
    this.showAlert2OutputRelay1 = true;
    this.showAlert1OutputRelay2 = true;
    this.showAlert1OutputRelay1 = true;
    this.showAlert2Beacons = true;
    this.showAlert1Beacons = true;
    this.showAlert2HighLeds = true;
    this.showAlert1HighLeds = true;
    this.showAlert2Strobes = true;
    this.showAlert1Strobes = true;
    this.showAlert1StrobeBlue = true;
    this.showAlert2StrobeBlue = true;
    this.showAlert1StrobeWhite = true;
    this.showAlert2StrobeWhite = true;
    this.showAlert1StrobeRedBlue = true;
    this.showAlert2StrobeRedBlue = true;
    this.showAlert1CameraFlash = true;
    this.showAlert2CameraFlash = true;
    this.showAlert1StrobeRed = true;
    this.showAlert2StrobeRed = true;

    this.showAlert1DisplaySpeed = true;
    this.showAlert1DisplayBitmap1 = true;
    this.showAlert1DisplayBitmap2 = true;
    this.showAlert1DisplayBitmap3 = true;
    this.showAlert1DisplayBitmap4 = true;
    this.showAlert1DisplayBitmap5 = true;
    this.showAlert1DisplayBitmap6 = true;
    this.showAlert1DisplayBitmap7 = true;

    this.showAlert2DisplaySpeed = true;
    this.showAlert2DisplayBitmap1 = true;
    this.showAlert2DisplayBitmap2 = true;
    this.showAlert2DisplayBitmap3 = true;
    this.showAlert2DisplayBitmap4 = true;
    this.showAlert2DisplayBitmap5 = true;
    this.showAlert2DisplayBitmap6 = true;
    this.showAlert2DisplayBitmap7 = true;

    let Radar = '';
    if (!currOptions.filter(r => r.code === 'Radar')[0]) {
      this.showRadar = false;
    } else {
      Radar = currOptions.filter(r => r.code === 'Radar')[0].value;
    }

    let SpeedLimit = +0;
    if (!currOptions.filter(r => r.code === 'SpeedLimit')[0]) {
    } else {
      SpeedLimit = +currOptions.filter(r => r.code === 'SpeedLimit')[0].value;
    }

    let MaxSpeed = +0;
    if (!currOptions.filter(r => r.code === 'MaxSpeed')[0]) {
    } else {
      MaxSpeed = +currOptions.filter(r => r.code === 'MaxSpeed')[0].value;
    }

    let MinSpeed = +0;
    if (!currOptions.filter(r => r.code === 'MinSpeed')[0]) {
    } else {
      MinSpeed = +currOptions.filter(r => r.code === 'MinSpeed')[0].value;
    }

    let Display = '';
    if (!currOptions.filter(r => r.code === 'Display')[0]) {
    } else {
      Display = currOptions.filter(r => r.code === 'Display')[0].value;
    }
    let Blinky = '';
    if (!currOptions.filter(r => r.code === 'Blinky')[0]) {
    } else {
      Blinky = currOptions.filter(r => r.code === 'Blinky')[0].value;
    }
    let Possum = '';
    if (!currOptions.filter(r => r.code === 'Possum')[0]) {
    } else {
      Possum = currOptions.filter(r => r.code === 'Possum')[0].value;
    }

    let OverMaxMode = '';
    if (!currOptions.filter(r => r.code === 'OverMaxMode')[0]) {
    } else {
      OverMaxMode = currOptions.filter(r => r.code === 'OverMaxMode')[0].value;
    }
    let DisplayMode = '';
    if (!currOptions.filter(r => r.code === 'DisplayMode')[0]) {
    } else {
      DisplayMode = currOptions.filter(r => r.code === 'DisplayMode')[0].value;
    }

    let BeaconsOverride = '';
    if (!currOptions.filter(r => r.code === 'BeaconsOverride')[0]) {
      this.showBeaconsOverride = false;
    } else {
      BeaconsOverride = currOptions.filter(r => r.code === 'BeaconsOverride')[0].value;
    }

    let Relay1Override = '';
    if (!currOptions.filter(r => r.code === 'Relay1Override')[0]) {
      this.showRelay1Override = false;
    } else {
      Relay1Override = currOptions.filter(r => r.code === 'Relay1Override')[0].value;
    }

    let Relay2Override = '';
    if (!currOptions.filter(r => r.code === 'Relay2Override')[0]) {
      this.showRelay2Override = false;
    } else {
      Relay2Override = currOptions.filter(r => r.code === 'Relay2Override')[0].value;
    }

    let Alert1FlashRate = '';
    if (!currOptions.filter(r => r.code === 'Alert1FlashRate')[0]) {
      this.showAlert1FlashRate = false;
    } else {
      Alert1FlashRate = currOptions.filter(r => r.code === 'Alert1FlashRate')[0].value;
    }

    let Alert2FlashRate = '';
    if (!currOptions.filter(r => r.code === 'Alert2FlashRate')[0]) {
      this.showAlert2FlashRate = false;
    } else {
      Alert2FlashRate = currOptions.filter(r => r.code === 'Alert2FlashRate')[0].value;
    }

    let Alert1HighLeds = '';
    if (!currOptions.filter(r => r.code === 'Alert1HighLeds')[0]) {
      this.showAlert1HighLeds = false;
    } else {
      Alert1HighLeds = currOptions.filter(r => r.code === 'Alert1HighLeds')[0].value;
    }

    let Alert2HighLeds = '';
    if (!currOptions.filter(r => r.code === 'Alert2HighLeds')[0]) {
      this.showAlert2HighLeds = false;
    } else {
      Alert2HighLeds = currOptions.filter(r => r.code === 'Alert2HighLeds')[0].value;
    }

    let Alert1StrobeBlue = '';
    if (!currOptions.filter(r => r.code === 'Alert1StrobeBlue')[0]) {
      this.showAlert1StrobeBlue = false;
    } else {
      Alert1StrobeBlue = currOptions.filter(r => r.code === 'Alert1StrobeBlue')[0].value;
    }

    let Alert2StrobeBlue = '';
    if (!currOptions.filter(r => r.code === 'Alert2StrobeBlue')[0]) {
      this.showAlert2StrobeBlue = false;
    } else {
      Alert2StrobeBlue = currOptions.filter(r => r.code === 'Alert2StrobeBlue')[0].value;
    }

    let Alert1StrobeRed = '';
    if (!currOptions.filter(r => r.code === 'Alert1StrobeRed')[0]) {
      this.showAlert1StrobeRed = false;
    } else {
      Alert1StrobeRed = currOptions.filter(r => r.code === 'Alert1StrobeRed')[0].value;
    }

    let Alert2StrobeRed = '';
    if (!currOptions.filter(r => r.code === 'Alert2StrobeRed')[0]) {
      this.showAlert2StrobeRed = false;

    } else {
      Alert2StrobeRed = currOptions.filter(r => r.code === 'Alert2StrobeRed')[0].value;

    }

    let Alert1StrobeWhite = '';
    if (!currOptions.filter(r => r.code === 'Alert1StrobeWhite')[0]) {
      this.showAlert1StrobeWhite = false;

    } else {
      Alert1StrobeWhite = currOptions.filter(r => r.code === 'Alert1StrobeWhite')[0].value;

    }

    let Alert2StrobeWhite = '';
    if (!currOptions.filter(r => r.code === 'Alert2StrobeWhite')[0]) {
      this.showAlert2StrobeWhite = false;
      
    } else {
      Alert2StrobeWhite = currOptions.filter(r => r.code === 'Alert2StrobeWhite')[0].value;
      
    }


    let Alert1Beacons = '';
    if (!currOptions.filter(r => r.code === 'Alert1Beacons')[0]) {
      this.showAlert1Beacons = false;
    } else {
      Alert1Beacons = currOptions.filter(r => r.code === 'Alert1Beacons')[0].value;
    }

    let Alert2Beacons = '';
    if (!currOptions.filter(r => r.code === 'Alert2Beacons')[0]) {
      this.showAlert2Beacons = false;
    } else {
      Alert2Beacons = currOptions.filter(r => r.code === 'Alert2Beacons')[0].value;
    }

    let Alert1OutputRelay1 = '';
    if (!currOptions.filter(r => r.code === 'Alert1OutputRelay1')[0]) {
      this.showAlert1OutputRelay1 = false;
    } else {
      Alert1OutputRelay1 = currOptions.filter(r => r.code === 'Alert1OutputRelay1')[0].value;
    }

    let Alert1OutputRelay2 = '';
    if (!currOptions.filter(r => r.code === 'Alert1OutputRelay2')[0]) {
      this.showAlert1OutputRelay2 = false;
    } else {
      Alert1OutputRelay2 = currOptions.filter(r => r.code === 'Alert1OutputRelay2')[0].value;
    }

    let Alert2OutputRelay1 = '';
    if (!currOptions.filter(r => r.code === 'Alert2OutputRelay1')[0]) {
      this.showAlert2OutputRelay1 = false;
    } else {
      Alert2OutputRelay1 = currOptions.filter(r => r.code === 'Alert2OutputRelay1')[0].value;
    }

    let Alert2OutputRelay2 = '';
    if (!currOptions.filter(r => r.code === 'Alert2OutputRelay2')[0]) {
      this.showAlert2OutputRelay2 = false;
    } else {
      Alert2OutputRelay2 = currOptions.filter(r => r.code === 'Alert2OutputRelay2')[0].value;
    }

    let SpeedAlert1 = +0;
    if (!currOptions.filter(r => r.code === 'SpeedAlert1')[0]) {
    } else {
      SpeedAlert1 = +currOptions.filter(r => r.code === 'SpeedAlert1')[0].value;
    }
    let SpeedAlert2 = +0;
    if (!currOptions.filter(r => r.code === 'SpeedAlert2')[0]) {
    } else {
      SpeedAlert2 = +currOptions.filter(r => r.code === 'SpeedAlert2')[0].value;
    }

    let Alert1DisplaySpeed = '';
    if (!currOptions.filter(r => r.code === 'Alert1DisplaySpeed')[0]) {
      this.showAlert1DisplaySpeed = false;
    } else {
      Alert1DisplaySpeed = currOptions.filter(r => r.code === 'Alert1DisplaySpeed')[0].value;
    }

    let Alert2DisplaySpeed = '';
    if (!currOptions.filter(r => r.code === 'Alert2DisplaySpeed')[0]) {
      this.showAlert2DisplaySpeed = false;
    } else {
      Alert2DisplaySpeed = currOptions.filter(r => r.code === 'Alert2DisplaySpeed')[0].value;
    }

    let Alert1DisplayBitmap1 = '';
    if (!currOptions.filter(r => r.code === 'Alert1DisplayBitmap1')[0]) {
      this.showAlert1DisplayBitmap1 = false;
    } else {
      Alert1DisplayBitmap1 = currOptions.filter(r => r.code === 'Alert1DisplayBitmap1')[0].value;
    }

    let Alert1DisplayBitmap2 = '';
    if (!currOptions.filter(r => r.code === 'Alert1DisplayBitmap2')[0]) {
      this.showAlert1DisplayBitmap2 = false;
    } else {
      Alert1DisplayBitmap2 = currOptions.filter(r => r.code === 'Alert1DisplayBitmap2')[0].value;
    }

    let Alert1DisplayBitmap3 = '';
    if (!currOptions.filter(r => r.code === 'Alert1DisplayBitmap3')[0]) {
      this.showAlert1DisplayBitmap3 = false;
    } else {
      Alert1DisplayBitmap3 = currOptions.filter(r => r.code === 'Alert1DisplayBitmap3')[0].value;
    }

    let Alert1DisplayBitmap4 = '';
    if (!currOptions.filter(r => r.code === 'Alert1DisplayBitmap4')[0]) {
      this.showAlert1DisplayBitmap4 = false;
    } else {
      Alert1DisplayBitmap4 = currOptions.filter(r => r.code === 'Alert1DisplayBitmap4')[0].value;
    }

    let Alert1DisplayBitmap5 = '';
    if (!currOptions.filter(r => r.code === 'Alert1DisplayBitmap5')[0]) {
      this.showAlert1DisplayBitmap5 = false;
    } else {
      Alert1DisplayBitmap5 = currOptions.filter(r => r.code === 'Alert1DisplayBitmap5')[0].value;
    }

    let Alert1DisplayBitmap6 = '';
    if (!currOptions.filter(r => r.code === 'Alert1DisplayBitmap6')[0]) {
      this.showAlert1DisplayBitmap6 = false;
    } else {
      Alert1DisplayBitmap6 = currOptions.filter(r => r.code === 'Alert1DisplayBitmap6')[0].value;
    }

    let Alert1DisplayBitmap7 = '';
    if (!currOptions.filter(r => r.code === 'Alert1DisplayBitmap7')[0]) {
      this.showAlert1DisplayBitmap7 = false;
    } else {
      Alert1DisplayBitmap7 = currOptions.filter(r => r.code === 'Alert1DisplayBitmap7')[0].value;
    }

    let Alert2DisplayBitmap1 = '';
    if (!currOptions.filter(r => r.code === 'Alert2DisplayBitmap1')[0]) {
      this.showAlert2DisplayBitmap1 = false;
    } else {
      Alert2DisplayBitmap1 = currOptions.filter(r => r.code === 'Alert2DisplayBitmap1')[0].value;
    }

    let Alert2DisplayBitmap2 = '';
    if (!currOptions.filter(r => r.code === 'Alert2DisplayBitmap2')[0]) {
      this.showAlert2DisplayBitmap2 = false;
    } else {
      Alert2DisplayBitmap2 = currOptions.filter(r => r.code === 'Alert2DisplayBitmap2')[0].value;
    }

    let Alert2DisplayBitmap3 = '';
    if (!currOptions.filter(r => r.code === 'Alert2DisplayBitmap3')[0]) {
      this.showAlert2DisplayBitmap3 = false;
    } else {
      Alert2DisplayBitmap3 = currOptions.filter(r => r.code === 'Alert2DisplayBitmap3')[0].value;
    }

    let Alert2DisplayBitmap4 = '';
    if (!currOptions.filter(r => r.code === 'Alert2DisplayBitmap4')[0]) {
      this.showAlert2DisplayBitmap4 = false;
    } else {
      Alert2DisplayBitmap4 = currOptions.filter(r => r.code === 'Alert2DisplayBitmap4')[0].value;
    }

    let Alert2DisplayBitmap5 = '';
    if (!currOptions.filter(r => r.code === 'Alert2DisplayBitmap5')[0]) {
      this.showAlert2DisplayBitmap5 = false;
    } else {
      Alert2DisplayBitmap5 = currOptions.filter(r => r.code === 'Alert2DisplayBitmap5')[0].value;
    }

    let Alert2DisplayBitmap6 = '';
    if (!currOptions.filter(r => r.code === 'Alert2DisplayBitmap6')[0]) {
      this.showAlert2DisplayBitmap6 = false;
    } else {
      Alert2DisplayBitmap6 = currOptions.filter(r => r.code === 'Alert2DisplayBitmap6')[0].value;
    }

    let Alert2DisplayBitmap7 = '';
    if (!currOptions.filter(r => r.code === 'Alert2DisplayBitmap7')[0]) {
      this.showAlert2DisplayBitmap7 = false;
    } else {
      Alert2DisplayBitmap7 = currOptions.filter(r => r.code === 'Alert2DisplayBitmap7')[0].value;
    }

    this.minSpeed = MinSpeed;
    this.maxSpeed = MaxSpeed;

    this.fgDeviceConfiguration.patchValue(
      {
        Radar: Radar === '1',
        SpeedLimit: SpeedLimit,
        Blinky: Blinky === '1',
        Possum: Possum === '1',
        DisplayMode: (DisplayMode != "0" && DisplayMode != "1" ? "0" : DisplayMode),
        BeaconsOverride: BeaconsOverride === '1',
        Relay1Override: Relay1Override === '1',
        Relay2Override: Relay2Override === '1'
      }
    );
    this.fgDeviceConfigurationDisplay.patchValue(
      {
        MinSpeed: MinSpeed,
        MaxSpeed: MaxSpeed,
        Display: Display === '1',
        OverMaxMode: OverMaxMode
      }
    );
    this.fgDeviceConfigurationAlert1.patchValue(
      {
        SpeedAlert1: SpeedAlert1,
        Alert1FlashRate: Alert1FlashRate,
        Alert1HighLeds: Alert1HighLeds,
        Alert1StrobeBlue: Alert1StrobeBlue === '1',
        Alert1StrobeRed: Alert1StrobeRed === '1',
        Alert1StrobeWhite: Alert1StrobeWhite === '1',
        Alert1Beacons: Alert1Beacons === '1',
        Alert1OutputRelay1: Alert1OutputRelay1 === '1',
        Alert1OutputRelay2: Alert1OutputRelay2 === '1',
        Alert1DisplaySpeed: Alert1DisplaySpeed === '1',
        Alert1DisplayBitmap1: Alert1DisplayBitmap1 === '1',
        Alert1DisplayBitmap2: Alert1DisplayBitmap2 === '1',
        Alert1DisplayBitmap3: Alert1DisplayBitmap3 === '1',
        Alert1DisplayBitmap4: Alert1DisplayBitmap4 === '1',
        Alert1DisplayBitmap5: Alert1DisplayBitmap5 === '1',
        Alert1DisplayBitmap6: Alert1DisplayBitmap6 === '1',
        Alert1DisplayBitmap7: Alert1DisplayBitmap7 === '1'
      }
    );
    this.fgDeviceConfigurationAlert2.patchValue(
      {
        SpeedAlert2: SpeedAlert2,
        Alert2FlashRate: Alert2FlashRate,
        Alert2HighLeds: Alert2HighLeds,
        Alert2StrobeBlue: Alert2StrobeBlue === '1',
        Alert2StrobeRed: Alert2StrobeRed === '1',
        Alert2StrobeWhite: Alert2StrobeWhite === '1',
        Alert2Beacons: Alert2Beacons === '1',
        Alert2OutputRelay1: Alert2OutputRelay1 === '1',
        Alert2OutputRelay2: Alert2OutputRelay2 === '1',
        Alert2DisplaySpeed: Alert2DisplaySpeed === '1',
        Alert2DisplayBitmap1: Alert2DisplayBitmap1 === '1',
        Alert2DisplayBitmap2: Alert2DisplayBitmap2 === '1',
        Alert2DisplayBitmap3: Alert2DisplayBitmap3 === '1',
        Alert2DisplayBitmap4: Alert2DisplayBitmap4 === '1',
        Alert2DisplayBitmap5: Alert2DisplayBitmap5 === '1',
        Alert2DisplayBitmap6: Alert2DisplayBitmap6 === '1',
        Alert2DisplayBitmap7: Alert2DisplayBitmap7 === '1'
      }
    );

    this.deviceConfiguration = {
      Radar: Radar === '1',
      SpeedLimit: SpeedLimit,
      Blinky: Blinky === '1',
      Possum: Possum === '1',
      DisplayMode: (DisplayMode != "0" && DisplayMode != "1" ? "0" : DisplayMode),
      BeaconsOverride: BeaconsOverride === '1',
      Relay1Override: Relay1Override === '1',
      Relay2Override: Relay2Override === '1',

      MinSpeed: MinSpeed,
      MaxSpeed: MaxSpeed,
      Display: Display === '1',
      OverMaxMode: OverMaxMode,

      SpeedAlert1: SpeedAlert1,
      Alert1FlashRate: Alert1FlashRate,
      Alert1HighLeds: Alert1HighLeds,
      Alert1StrobeBlue: Alert1StrobeBlue === '1',
      Alert1StrobeRed: Alert1StrobeRed === '1',
      Alert1StrobeWhite: Alert1StrobeWhite === '1',
      Alert1Beacons: Alert1Beacons === '1',
      Alert1OutputRelay1: Alert1OutputRelay1 === '1',
      Alert1OutputRelay2: Alert1OutputRelay2 === '1',
      Alert1DisplaySpeed: Alert1DisplaySpeed === '1',
      Alert1DisplayBitmap1: Alert1DisplayBitmap1 === '1',
      Alert1DisplayBitmap2: Alert1DisplayBitmap2 === '1',
      Alert1DisplayBitmap3: Alert1DisplayBitmap3 === '1',
      Alert1DisplayBitmap4: Alert1DisplayBitmap4 === '1',
      Alert1DisplayBitmap5: Alert1DisplayBitmap5 === '1',
      Alert1DisplayBitmap6: Alert1DisplayBitmap6 === '1',
      Alert1DisplayBitmap7: Alert1DisplayBitmap7 === '1',

      SpeedAlert2: SpeedAlert2,
      Alert2FlashRate: Alert2FlashRate,
      Alert2HighLeds: Alert2HighLeds,
      Alert2StrobeBlue: Alert2StrobeBlue === '1',
      Alert2StrobeWhite: Alert2StrobeWhite === '1',
      Alert2StrobeRed: Alert2StrobeRed == '1',
      Alert2Beacons: Alert2Beacons === '1',
      Alert2OutputRelay1: Alert2OutputRelay1 === '1',
      Alert2OutputRelay2: Alert2OutputRelay2 === '1',
      Alert2DisplaySpeed: Alert2DisplaySpeed === '1',
      Alert2DisplayBitmap1: Alert2DisplayBitmap1 === '1',
      Alert2DisplayBitmap2: Alert2DisplayBitmap2 === '1',
      Alert2DisplayBitmap3: Alert2DisplayBitmap3 === '1',
      Alert2DisplayBitmap4: Alert2DisplayBitmap4 === '1',
      Alert2DisplayBitmap5: Alert2DisplayBitmap5 === '1',
      Alert2DisplayBitmap6: Alert2DisplayBitmap6 === '1',
      Alert2DisplayBitmap7: Alert2DisplayBitmap7 === '1'
    };

  }

  getSpeedLimitMessage(): string {

    if (this.inputDisplay.value == '0' || isNaN(this.inputSpeedLimit.value)) {
      if (this.inputDisplayMode.value == '0') {
        return 'Please provide a valid Speed Limit from 0 to 99'
      }
      else {
        return 'Please provide a valid Speed Limit from 0 to 160'
      }
    }
    else {
      return 'Please provide a valid Speed Limit value between ' + this.inputMinSpeed.value + ' and ' + this.inputMaxSpeed.value
    }

  }

  isConfigurationValid(): boolean {
    let hasError = false;

    const DisplayEnabled: boolean = this.inputDisplay.value;
    const SpeedLimit = this.inputSpeedLimit.value;
    const MaxSpeed = this.inputMaxSpeed.value;
    const MinSpeed = this.inputMinSpeed.value;
    const DisplayMode = this.inputDisplayMode.value;

    if (DisplayEnabled && (+MinSpeed < 0 || +MinSpeed > +SpeedLimit || +MinSpeed >= +MaxSpeed)) {
      this.fgDeviceConfigurationDisplay.controls['MinSpeed'].setErrors({ 'incorrect': true });
      hasError = true;
    } else this.fgDeviceConfigurationDisplay.controls['MinSpeed'].setErrors(null);

    if (hasError == false && DisplayEnabled && (+MaxSpeed < 0 || +MaxSpeed < +SpeedLimit || +MaxSpeed <= +MinSpeed)) {
      this.fgDeviceConfigurationDisplay.controls['MaxSpeed'].setErrors({ 'incorrect': true });
      hasError = true;
    } else if (hasError == false && DisplayEnabled && (
      (+MaxSpeed > 125 && DisplayMode.toString() == '0') || (+MaxSpeed > 205 && DisplayMode.toString() == '1'))
    ) {
      this.fgDeviceConfigurationDisplay.controls['MaxSpeed'].setErrors({ 'incorrect': true });
      hasError = true;
    } else this.fgDeviceConfigurationDisplay.controls['MaxSpeed'].setErrors(null);


    if ((+SpeedLimit > 125 && DisplayMode.toString() == '0') || (+SpeedLimit > 205 && DisplayMode.toString() == '1')) {
      this.fgDeviceConfiguration.controls['SpeedLimit'].setErrors({ 'incorrect': true });
      hasError = true;
    }

    const SpeedAlert1 = this.inputSpeedAlert1.value;
    const SpeedAlert2 = this.inputSpeedAlert2.value;
    if (hasError == false && DisplayEnabled && (+SpeedAlert1 < +MinSpeed || +SpeedAlert1 > +MaxSpeed)) {
      this.fgDeviceConfigurationAlert1.controls['SpeedAlert1'].setErrors({ 'incorrect': true });
      hasError = true;
    } else this.fgDeviceConfigurationAlert1.controls['SpeedAlert1'].setErrors(null);
    if (hasError == false && DisplayEnabled && (+SpeedAlert2 < +MinSpeed || +SpeedAlert2 > +MaxSpeed)) {
      this.fgDeviceConfigurationAlert2.controls['SpeedAlert2'].setErrors({ 'incorrect': true });
      hasError = true;
    } else this.fgDeviceConfigurationAlert2.controls['SpeedAlert2'].setErrors(null);


    return !(hasError ||
      this.fgDeviceConfiguration.invalid ||
      this.fgDeviceConfigurationDisplay.invalid ||
      this.fgDeviceConfigurationAlert1.invalid ||
      this.fgDeviceConfigurationAlert2.invalid)

  }


  isReadOnly(configOptioncode: string) {
    let option = this.ConfigMapOptions.filter(r => r.code === configOptioncode.toString())[0];
    if (!option) return true;
    return option.readOnly;
  }


  controlValidationClass(control) {
    return 'form-control' + (this.controlInvalidFlag(control) != null
      ? (this.controlInvalidFlag(control) ? ' is-invalid' : ' is-valid')
      : '');
  }
  controlInvalidFlag(control) {
    if (!(control.touched)) {
      return null;
    }
    return control.invalid;
  }

  getCorrectValue(value: string) {
    return value === 'true' ? '1' : value === 'false' ? '0' : value;
  }

  onAlert1FlashRateChange(value) {
    this.fgDeviceConfigurationAlert1.controls['Alert1FlashRate'].setValue(value);
  }

  onAlert2FlashRateChange(value) {
    this.fgDeviceConfigurationAlert2.controls['Alert2FlashRate'].setValue(value);
  }

  onOverMaxModeChange(value) {
    this.fgDeviceConfigurationDisplay.controls['OverMaxMode'].setValue(value);
  }

  onAlert1HighLedsChange(value) {
    this.fgDeviceConfigurationAlert1.controls['Alert1HighLeds'].setValue(value);
  }

  onAlert2HighLedsChange(value) {
    this.fgDeviceConfigurationAlert2.controls['Alert2HighLeds'].setValue(value);
  }

  onAlert1StrobeChange(value) {
    if (value == 0) {
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeWhite'].setValue(false);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeBlue'].setValue(false);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeRed'].setValue(false);
      this.onAlert1HighLedsChange(0);
    }
    else if (value == 1) {
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeBlue'].setValue(false);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeRed'].setValue(true);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeWhite'].setValue(false)
      this.onAlert1HighLedsChange(2);
    }
    else if (value == 2) {
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeBlue'].setValue(true);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeRed'].setValue(false);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeWhite'].setValue(false)
      this.onAlert1HighLedsChange(2);
    }
    else if (value == 3) {
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeBlue'].setValue(true);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeRed'].setValue(true);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeWhite'].setValue(false)
      this.onAlert1HighLedsChange(3);
    }
    else if (value == 4) {
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeWhite'].setValue(true);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeBlue'].setValue(false);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeRed'].setValue(false);
      this.onAlert1HighLedsChange(1);
    }

    else if (value == 5) {
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeWhite'].setValue(true);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeBlue'].setValue(false);
      this.fgDeviceConfigurationAlert1.controls['Alert1StrobeRed'].setValue(false);
      this.onAlert1HighLedsChange(2);
    }

    console.log(this.fgDeviceConfigurationAlert1);
  }

  onAlert2StrobeChange(value) {
    if (value == 0) {
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeWhite'].setValue(false);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeBlue'].setValue(false);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeRed'].setValue(false);
      this.onAlert2HighLedsChange(0);
    }
    else if (value == 1) {
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeBlue'].setValue(false);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeRed'].setValue(true);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeWhite'].setValue(false)
      this.onAlert2HighLedsChange(2);
    }
    else if (value == 2) {
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeBlue'].setValue(true);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeRed'].setValue(false);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeWhite'].setValue(false)
      this.onAlert2HighLedsChange(2);
    }
    else if (value == 3) {
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeBlue'].setValue(true);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeRed'].setValue(true);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeWhite'].setValue(false)
      this.onAlert2HighLedsChange(3);
    }
    else if (value == 4) {
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeWhite'].setValue(true);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeBlue'].setValue(false);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeRed'].setValue(false);
      this.onAlert2HighLedsChange(1);
    }

    else if (value == 5) {
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeWhite'].setValue(true);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeBlue'].setValue(false);
      this.fgDeviceConfigurationAlert2.controls['Alert2StrobeRed'].setValue(false);
      this.onAlert2HighLedsChange(2);
    }

    console.log(this.fgDeviceConfigurationAlert2);

  }

  onDisplayModeChange(value) {
    this.fgDeviceConfiguration.controls['DisplayMode'].setValue(value);
  }

  buttonSave() {
    let hasError = false;
    const MaxSpeed = this.inputMaxSpeed.value;
    const MinSpeed = this.inputMinSpeed.value;
    const DisplayMode = this.inputDisplayMode.value;

    if ((+MaxSpeed > 125 && DisplayMode.toString() === '0') || (+MaxSpeed > 205 && DisplayMode.toString() === 'true')) {
      this.fgDeviceConfigurationDisplay.controls['MaxSpeed'].setErrors({ 'incorrect': true });
      hasError = true;
    }
    if (+MinSpeed < 0) {
      this.fgDeviceConfigurationDisplay.controls['MinSpeed'].setErrors({ 'incorrect': true });
      hasError = true;
    }

    const SpeedAlert1 = this.inputSpeedAlert1.value;
    const SpeedAlert2 = this.inputSpeedAlert2.value;
    if (+SpeedAlert1 < +MinSpeed || +SpeedAlert1 > +MaxSpeed) {
      this.fgDeviceConfigurationAlert1.controls['SpeedAlert1'].setErrors({ 'incorrect': true });
      hasError = true;
    }
    if (+SpeedAlert2 < +MinSpeed || +SpeedAlert2 > +MaxSpeed) {
      this.fgDeviceConfigurationAlert2.controls['SpeedAlert2'].setErrors({ 'incorrect': true });
      hasError = true;
    }

    if (hasError === true) {
      console.log()
      return false;
    }

    const params = Object.keys(this.fgDeviceConfiguration.value)
      .map(key => ({ 'code': key, value: this.getCorrectValue(this.fgDeviceConfiguration.value[key].toString()) }))
      .concat(Object.keys(this.fgDeviceConfigurationDisplay.value)
        .map(key => ({ 'code': key, value: this.getCorrectValue(this.fgDeviceConfigurationDisplay.value[key].toString()) })))
      .concat(Object.keys(this.fgDeviceConfigurationAlert1.value)
        .map(key => ({ 'code': key, value: this.getCorrectValue(this.fgDeviceConfigurationAlert1.value[key].toString()) })))
      .concat(Object.keys(this.fgDeviceConfigurationAlert2.value)
        .map(key => ({ 'code': key, value: this.getCorrectValue(this.fgDeviceConfigurationAlert2.value[key].toString()) })));



    // this.deviceConfigurationsService.create(params, this.deviceUuid, this.settingsService.client.uuid)
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(
    //     (result: any) => {
    //       this.deviceUuid = result.uuid;
    //       console.log('save result', result);
    //       this.formState = this.formStates.Saved;
    //       this.onFormEvent_EventScheduleTimeConfig.emit('saved');
    //       this.notificationsService.success('Success', 'Device Configurations Saved', { timeOut: 2000, clickToClose: true });
    //     },
    //     error => {
    //       this.notificationsService.error('Server Error (deviceConfigurationsSave)',
    //         this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
    //       this.formState = this.formStates.Edit;
    //     });
  }

}
