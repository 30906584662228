import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../settings/settings.service';
import { AuthenticationService } from './authentication.service';

@Injectable({   
    providedIn: 'root', 
  }) 
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router,
        private settingsService: SettingsService,
        private notificationsService: NotificationsService,
        private authenticationService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        var dateUTC: Date = new Date()
        let authRspns = this.authenticationService.authResponse

        if (authRspns) {

            if (!authRspns.access_token) {
                //Missing Authentication Token
                console.log('Guard: Missing Authentication Access Token Value')
            }
            else if (!authRspns.expires) {
                //Missing Authentication Token
                console.log('Guard: Missing Authentication Access Token Expiration Data')
            }
            // else if (dateUTC >= moment.utc(authRspns.expires).toDate() ) {
            //     //do nothing, don't want to add Bearer Token
            //     console.log('Guard: Bearer Token Expired')
  
            //     this.notificationsService.warn("Login Expired", "Please re-enter user name and password to continue", 
            //     {
            //         timeOut: 0,
            //         showProgressBar: false,
            //         clickToClose: true,
            //         clickIconToClose: true
            //     });
            //     this.router.navigateByUrl('/login', { queryParams: { returnUrl: state.url }});
            //     return false;
            // }
            else {        
                // token exists, so return true
                return true;
            }
        }

        this.authenticationService.clear();

        // not logged in so redirect to login page with the return url
        this.router.navigateByUrl('/login', { queryParams: { returnUrl: state.url }});
        return false;
    }
}