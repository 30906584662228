<div class="bg-default" [ngClass]="{'card col-12 mt-3': !minimumPadding}">
  <div class="pl-1 pb-1" [ngClass]="{'pt-2': !minimumPadding, 'pt-1': minimumPadding}">
    <label><strong>&nbsp;</strong><a
        [routerLink]="['/charts' , deviceLocation?.uuid || '']"><strong>{{deviceLocation?.name || ''}}</strong></a><br />
      &nbsp;{{deviceLocation?.address || ''}}<br />
      &nbsp;{{deviceLocation?.city||''}}{{deviceLocation?.city==null ? '':',&nbsp;'}}{{deviceLocation?.stateProvinceCode||''}}&nbsp;{{deviceLocation?.postalCode||''}}&nbsp;{{deviceLocation?.country||''}}</label>
    <div class="div-menu">
      <a *ngIf="deviceLocation" [routerLink]="['/charts' , deviceLocation?.uuid || '']" title="Open Charts"><span
          class="btn btn-primary pl-1 pr-1 pt-1 pb-1 mt-0 btn-card"><em class="fas fa-chart-area"></em></span></a><br />
      <a *ngIf="device" [routerLink]="['/configurations', device?.deviceScheduleUuid || '']" title="Open Schedule"><span
          class="btn btn-primary pl-1 pr-1 pt-1 pb-1 mt-1 btn-card"><em class="fas fa-calendar-alt"></em></span></a>
    </div>
  </div>
  <div class="p-1 bg-light" [ngClass]="{'mb-3': !minimumPadding}">
    <small *ngIf="device" class="pl-2">
      <a [routerLink]="['/devices', device?.uuid || '']" title="{{device?.label}}">Device {{device?.serial}}</a>
    </small>
    <small *ngIf="!device" class="pl-2">No Active Device</small>

    <small class='text-right pr-2 float-right'>Speed Limit: {{!deviceLocation || deviceLocation.speedLimit == null ? '--' : 
      deviceLocation.speedLimit == 0 ? 'Unassigned' : deviceLocation.speedLimit}}</small>
    <div flot [dataset]="chartData" [options]="chartOptions" [height]="125"></div>
  </div>
</div>