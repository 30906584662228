<div *ngIf="[IsEditMode] =='true'" class="d-flex mx-1 mt-3 border-bottom pt-2">
  <h4 class="font-weight-bold mb-1">Available Configuration Options</h4>
</div>
<div *ngIf="[IsEditMode] =='true'" class="form-row">
  <ng-template ngFor let-configOption [ngForOf]="configOptions">
    <app-config-option-card *ngIf="!isSelctedConfigOption(configOption.id)"
      (onConfigOptionAdded)="configOptionAdd($event)" [ConfigOption]="configOption" [IsEditMode]="[isEditMode]"
      [AllowConfigEdit]="false" class="col-lg-3 col-md-6 col-sm-12 mt-2"></app-config-option-card>
    <!-- <div *ngIf="!isSelctedConfigOption(configOption.code)" class="col-lg-3 col-md-6 col-sm-12 mt-2">
      <div class="card bg-light">
        <table class="table p-0">
          <thead>
            <tr>
              <th scope="col" class="p-0" width="99%">
                <h4 class="m-2">{{configOption.code}}</h4>
              </th>
              <th scope="col" class="align-middle p-0" width="1%">
                <div class="btn fas fa-plus-square pl-1 pr-1 pt-1 pb-1 mr-1" width="22" height="22"></div>
              </th>
            </tr>
          </thead>
        </table>
        <p class="ml-2 mr-2">{{configOption.description}}</p>
      </div>
    </div> -->
  </ng-template>
</div>

<div class="d-flex mx-1 mt-4 border-bottom pt-2">
  <h4 class="font-weight-bold mb-1">{{ IsEditMode  ? "Selected " : "" }}Configuration Options</h4>
</div>
<div class="form-row mb-4">
  <ng-template ngFor let-configOptionSelected [ngForOf]="configOptionsSelected">
    <app-config-option-card (onConfigOptionDropDownSelected)="configOptionDropDownSelect($event)"
      (onConfigOptionRemoved)="configOptionRemove($event)" [ConfigOption]="configOptionSelected"
      [ValueText]="getConfigOptionValueText(configOptionSelected.id)" [IsEditMode]="IsEditMode"
      [AllowConfigEdit]="true" class="col-lg-3 col-md-6 col-sm-12 mt-2"></app-config-option-card>
    <!-- <div class="col-lg-3 col-md-6 col-sm-12 }mt-2">
      <div class="card bg-light">
        <table class="table p-0">
          <thead>
            <tr>
              <th scope="col" class="p-0" width="99%">
                <h4 class="m-2">{{configOptionSelected.code}}</h4>
              </th>
              <th scope="col" class="align-middle p-0" width="1%">
                <div *ngIf="isEditMode" class="btn fas fa-minus-square pl-1 pr-1 pt-1 pb-1 mr-1" width="22"
                  height="22"></div>
              </th>
            </tr>
          </thead>
        </table>
        <p class="ml-2 mr-2">{{configOptionSelected.description}}</p>
      </div>
    </div> -->
  </ng-template>
</div>