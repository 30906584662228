<ngx-spinner name="subscriptionsAdminForm"></ngx-spinner>
<div>
    <div class="form-group row">
        <div class="col-lg-12">
            <form [formGroup]="fgSubscription">
                <!--First Row Start-->
                <div class="form-row ml-2">
                    <!--R1--C1-->
                    <div class="col-md-4 col-sm-12 mb-2">
                        <label>Sales Order No.</label>
                        <div *ngIf="!isEditMode" class="form-control">{{subscription?.notes ? subscription.notes :
                            '&nbsp;'}}
                        </div>
                        <input *ngIf="isEditMode" [class]="controlValidationClass(fgSubscription.get('notes'))"
                            id="notes" name="notes" formControlName="notes" type="text"
                            placeholder="Subscription Notes" />
                        <div *ngIf="controlInvalidFlag(fgSubscription.get('notes'))" class="invalid-feedback">Please
                            provide
                            subscription information
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-12 mb-2">
                        <label>Scope</label>
                        <ng-select #scope [selectOnTab]="true" [items]="scopes" bindValue="id" bindLabel="name"
                            [clearable]="false" id="scopId" name="scopeId" formControlName="scopeId"
                            placeholder="Scope">
                        </ng-select>
                    </div>

                    <div class="col-md-2 col-sm-12 mb-2">
                        <label>Subscription Type</label>
                        <ng-select #type [selectOnTab]="true" [items]="subTypes" bindValue="id" bindLabel="name"
                            [clearable]="false" id="typeId" name="typeId" formControlName="typeId" placeholder="Type">
                        </ng-select>
                    </div>

                    <div class="col-md-2 col-sm-12 mb-2">
                        <label>Expiration Date</label>
                        <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                            formControlName="expirationDate" [(ngModel)]="fgSubscription.value.expirationDate"
                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY-MM-DD' }">
                        <div *ngIf="controlInvalidFlag(fgSubscription.get('expirationDate'))" class="invalid-feedback">
                            Please
                            provide an expiration date
                        </div>

                    </div>

                </div>

            </form>
        </div>
    </div>
    <div class="card mt-2">
        <div class="card-body pt-0">
            <div *ngIf="formState == formStates.Read">
                <h4>Devices</h4>
                <ag-grid-angular #agGrid id="subscriptionDevicesTable" name="subscriptionDevicesTable"
                    style="height: 700px;" class="ag-theme-balham adminDevicesTable m-0 pt-2 border-0"
                    [animateRows]="true" [suppressCellSelection]="true" [getDetailRowData]="true"
                    [rowData]="subscriptionDevicesData" 
                    [chartThemes]="chartThemes" [rowStyle]="rowStyle" (rowClicked)="onRowClicked($event)"
                    [gridOptions]="gridOptions" [getChartToolbarItems]="getChartToolbarItems">
                </ag-grid-angular>
            </div>
            <div *ngIf="formState == formStates.Edit && fgSubscription.get('scopeId').value == 1">
                <h4>Available Devices</h4>
                <ag-grid-angular #agGrid id="devicesTable" name="devicesTable" style="height: 700px;"
                    class="ag-theme-balham adminDevicesTable m-0 pt-2 border-0" [animateRows]="true"
                    [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="devices"
                   [chartThemes]="chartThemes" [rowStyle]="rowStyle"
                    (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions2"
                    [getChartToolbarItems]="getChartToolbarItems">
                </ag-grid-angular>
            </div>
            <div *ngIf="formState == formStates.Edit && (fgSubscription.get('scopeId').value != 1)">
                <h4>Devices</h4>
                <ag-grid-angular #agGrid id="devicesTable" name="devicesTable" style="height: 700px;"
                    class="ag-theme-balham adminDevicesTable m-0 pt-2 border-0" [animateRows]="true"
                    [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="devices"
                     [chartThemes]="chartThemes" [rowStyle]="rowStyle"
                    (rowClicked)="onRowClicked($event)" [gridOptions]="gridOptions4"
                    [getChartToolbarItems]="getChartToolbarItems">
                </ag-grid-angular>
            </div>
           
        </div>
    </div>

</div>