import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import * as moment from 'moment';
import { SettingsService, userTypes, userRoles } from '../settings/settings.service';
import { Router } from '../../../../node_modules/@angular/router';
import { environment } from '../../../environments/environment';
import { NotificationsService } from 'angular2-notifications';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(
        public settingsService: SettingsService,
        public notificationsService: NotificationsService,
        public authenticationService: AuthenticationService,
        private router: Router
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add authorization header with jwt token if available
        let dateUTC: Date = new Date()
        let authRspns = this.authenticationService.authResponse;
        let client = JSON.parse(localStorage.getItem('client'));

        let tokenInvalid: boolean = false;
        let tokenExpired: boolean = false;
        let tokenMessage: string = "";

        if (environment.apiUrl.toLowerCase() != request.url.toString().toLowerCase().replace('https://', '').replace('http://', '').substring(0, environment.apiUrl.length)) {
            return next.handle(request)
            //do nothing
            // alert(environment.apiUrl.toLowerCase())
            // alert(request.url.toString().toLowerCase().replace('https://','').replace('http://','').substring(0,environment.apiUrl.length))
        }

        //console.log('Intercepter Start')
        if (!authRspns || !authRspns.access_token) {
            tokenMessage = 'Missing Authentication Token' + (authRspns && !authRspns.access_token ? ' Value' : '')
            console.log('AuthenticationInterceptor: : ' + tokenMessage)
            tokenInvalid = true;
        }
        else if (dateUTC >= moment.utc(authRspns.expires).toDate()) {
            tokenMessage = 'Authentication Expired'
            console.log('AuthenticationInterceptor: ' + 'Bearer Token Expired')
            tokenExpired = true;
        }

        if (authRspns && authRspns.access_token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authRspns.access_token}`
                }
            });
        }

        if (this.settingsService.client && this.settingsService.client.uuid) {
            request = request.clone({
                setHeaders: {
                    ClientUuid: this.settingsService.client.uuid
                }
            });
        }

        // if (tokenError)
        // {
        //     this.notificationsService.error(tokenMessage, "Please login to continue to use application", 
        //     {
        //         timeOut: 20,
        //         showProgressBar: true,
        //         clickToClose: true,
        //         clickIconToClose: true
        //     });
        //     this.router.navigateByUrl('/login', { queryParams: { returnUrl: this.router.url }});
        //     return next.handle(request)
        // }
        // else if (tokenWarning)
        // {
        //     this.notificationsService.warn(tokenMessage, "Please login to continue to use application", 
        //     {
        //         timeOut: 20,
        //         showProgressBar: true,
        //         clickToClose: true,
        //         clickIconToClose: true
        //     });
        //     this.router.navigateByUrl('/login', { queryParams: { returnUrl: this.router.url }});
        //     return next.handle(request)
        // }



        return next.handle(request).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                // do error handling here
                if (err.status == 401) {
                    //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
                    this.settingsService.clearClients();
                    this.notificationsService.remove();
                    this.authenticationService.clear();
                    if (tokenInvalid)
                        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url, token: "invalid" } });
                    else if (tokenExpired)
                        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url, token: "expired" } });
                    else 
                        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                }
            }
        }))

        //return next.handle(request); 

    }
}