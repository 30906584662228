<div *ngIf="!AllowConfigEdit" class="card bg-light">
  <table class="table p-0">
    <thead>
      <tr>
        <th scope="col" class="p-0" width="99%">
          <div class="font-weight-bold m-2">{{ConfigOption.code}}</div>
        </th>
        <th scope="col" class="align-middle p-0" width="1%">
          <div *ngIf="IsEditMode" class="btn fas fa-plus-square pl-1 pr-1 pt-1 pb-1 mr-1" width="22" height="22"
            (click)='onConfigOptionAdd(ConfigOption.id)'></div>
        </th>
      </tr>
    </thead>
  </table>
  <p class="ml-2 mr-2">{{ConfigOption.description}}</p>
</div>
<div *ngIf="AllowConfigEdit" class="card bg-light">
  <table class="table p-0">
    <thead>
      <tr>
        <th scope="col" class="p-0" width="99%">
          <div class="font-weight-bold m-2">{{ConfigOption.code}}</div>
        </th>
        <th scope="col" class="align-middle p-0" width="1%">
          <div *ngIf="IsEditMode" class="btn fas fa-minus-square pl-1 pr-1 pt-1 pb-1 mr-1" width="22" height="22"
            (click)='onConfigOptionRemove(ConfigOption.id)'></div>
        </th>
      </tr>
    </thead>
  </table>
  <p class="ml-2 mr-2">{{ConfigOption.description}}</p>
  <div class="col-12 text-right" *ngIf="ConfigOption.typeId==0">
    <div class="checkbox active-checkbox" id="optionEdit" name="optionEdit">
      <input type="checkbox" checked disabled>
      <span class="fas fa-check"></span><label class="pb-1"
        for="optionEdit">{{ConfigOption.configOptionValues[0]?.code}}</label>
    </div>
    <!--     
    <input type="checkbox" id="optionEdit" name="optionEdit"
      checked disabled>&nbsp;
    <label for="optionEdit">{{ConfigOption.configOptionValues[0].code}}</label> -->
  </div>
  <div class="col-12 text-right" *ngIf="ConfigOption.typeId==3">
    <div *ngIf="!IsEditMode"><label class="pb-1">{{ConfigOption.code}}:&nbsp;{{ValueText}}</label></div>
    <select *ngIf="IsEditMode" class="form-control" #dropdownOption (change)="dropDownChange($event)" style="height: 30px; font-size: 12px;">
      <option *ngFor="let cfgValue of ConfigOption.configOptionValues" [value]="cfgValue.code" [selected]="cfgValue.code==ValueText" >
        {{cfgValue.code}}
      </option>
    </select>
  </div>
</div>