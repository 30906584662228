<div class="card card-border-gray bg-default" [hidden]="!isEditMode">
    <div class="card-header card-header-gray">
        <div class="row">
            <div class="col-12 col-md-4">
                <h4 class="mt-0 mb-0">Available Devices</h4>
            </div>
            <div class="col-12 col-md-8 float-right text-right">
                <div class="checkbox c-checkbox">
                    <label class="mt-0 mb-0">
                        <input #showDeviceAssigned type="checkbox" (change)="refreshAvailableDevicesTable()"
                            id="showDeviceAssigned" name="showDeviceAssigned" />
                        <span class="fas fa-check"></span>Show Device Assigned To Other Schedules</label>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <ngx-datatable #devicesAvailableTable class='bootstrap no-detail-row' (select)='onSelect($event)'
                    [selectionType]="'single'" [selected]="deviceAvailableSelected" [columnMode]="'force'"
                    [footerHeight]="20" [rowHeight]="'auto'" [limit]="20" [rows]='deviceAvailable'>

                    <ngx-datatable-column [resizeable]="false" name="" minWidth="30" maxWidth="30">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div [hidden]="!isEditMode"><i class="far fa-plus-square green"
                                    (click)="addSelectedDevice()"></i></div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [resizeable]="false" name="Device Label" prop="label">
                    </ngx-datatable-column>
                    <ngx-datatable-column [resizeable]="false" name="Serial" prop="serial">
                    </ngx-datatable-column>
                    <ngx-datatable-column [resizeable]="false" name="Model" prop="modelCode">
                    </ngx-datatable-column>
                    <ngx-datatable-column [resizeable]="false" name="Location" prop="deviceLocation.name">
                    </ngx-datatable-column>
                    <ngx-datatable-column [resizeable]="false" name="Options" prop="configOptions">
                    </ngx-datatable-column>

                </ngx-datatable>
            </div>
        </div>
    </div>

</div>