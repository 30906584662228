
import { filter, max, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SettingsService, userTypes, userRoles } from '../../../../../core/settings/settings.service';
import {
  DevicesService,
  DeviceSchedulesService,
  FirmwaresService,
  ModelsService,
  DeviceDailySummariesService
} from '../../../../../core/api/api.services';
import { EventsService } from '../../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { DeviceLocationsViewComponent } from '../../../views/devicelocationsview/devicelocationsview.component';
import { LibrariesService } from '../../../../../core/libraries/libraries.service';
import { DeviceCrudDto } from '../../../../../models/device';
import { DeviceLocationsFormComponent } from '../../devicelocations/devicelocations-form.component';
import { DeviceDataViewComponent } from '../../../views/devicedataview/devicedataview.component';
import { ErrorsService } from '../../../../../core/errors/errors.service';
import { ColorsService } from '../../../../../core/colors/colors.service';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { Subject } from 'rxjs';
import { SafeHtmlPipe } from '../../../../../shared/pipes/safeHtmlPipe'
import * as moment from 'moment';
import { DeviceImagesViewComponent } from '../../../views/deviceImages-view/deviceimagesview.component';
import { DeviceTelemetriesViewComponent } from '../../../views/devicetelemetriesview/devicetelemetriesview.component';
import { DeviceConnectionsViewComponent } from '../../../views/deviceconnectionsview/deviceconnectionsview.component';
import { Router, NavigationExtras } from '@angular/router';


export enum DeviceWifiFormState {
  Initializing = 1,
  Read = 2,
  New = 3,
  Edit = 4,
  Save = 5,
  Saving = 6,
  Cancelled = 8,
}

@Component({
  selector: 'app-devicewifi-form',
  templateUrl: './devicewifi-form.component.html',
  styleUrls: ['./devicewifi-form.component.scss']
})

export class DeviceWifiFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onFormEventDevice: EventEmitter<any> = new EventEmitter<any>();
  // @ViewChild(DeviceLocationsViewComponent) deviceLocView: DeviceLocationsViewComponent;
  @ViewChild( DeviceLocationsFormComponent) public deviceLocForm: DeviceLocationsFormComponent;
  @ViewChild(DeviceDataViewComponent) public deviceDataView: DeviceDataViewComponent;
  @ViewChild(DeviceImagesViewComponent) public deviceImagesView: DeviceImagesViewComponent;
  @ViewChild(DeviceTelemetriesViewComponent) public deviceTelemView: DeviceTelemetriesViewComponent;
  @ViewChild(DeviceConnectionsViewComponent) public deviceConnectView: DeviceConnectionsViewComponent;
  @ViewChild('tabset') staticTabs: TabsetComponent;
  @ViewChild('mapContainer') mapContainer: ElementRef;
  @ViewChild('forwardArrow') forwardArrow: ElementRef;
  @ViewChild('backArrow') backArrow: ElementRef;
  @ViewChild('loadBack') loadBack: ElementRef;
  @ViewChild('loadForward') loadForward: ElementRef;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  get formTitle() {
    if (this.deviceUuid && this.deviceUuid.toString() !== '') {
      return 'Edit Device';
    } else {
      return 'Add Device';
    }
  }

  private mapAppId = 'xIfm_53sblCK-vyAdENsbqlETwTCzTLHbmyUjWNBogs';
  //private mapAppCode = 'QR8uS_T_26wynuRa7eN6vA';
  private mapZ = 16;
  private mapW = 100;
  private mapH = 100;
  private startingDate;

  public cameraEnabled: boolean = false;
  public mapImageUrl: string = '';
  public mapImageUrlTemplateCellular: string = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l-circle+429a3c([lng],[lat])/[lng],[lat],13,0/1280x1280?access_token=pk.eyJ1IjoidGJhcnRvbiIsImEiOiJjamdteGE1MXQwd3o3MnFybWdoeG1zdmZ2In0.8sWOql5nI4-0Cc71fgawag";
  public mapImageUrlTemplateWifi: string = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l-circle+5d9cec([lng],[lat])/[lng],[lat],13,0/1280x1280?access_token=pk.eyJ1IjoidGJhcnRvbiIsImEiOiJjamdteGE1MXQwd3o3MnFybWdoeG1zdmZ2In0.8sWOql5nI4-0Cc71fgawag";

  public formLoaded: boolean = false;

  public formStates = DeviceWifiFormState;
  private _formState: DeviceWifiFormState = DeviceWifiFormState.Initializing;
  get formState(): DeviceWifiFormState {
    return this._formState;
  }
  @Input() set formState(newFormState: DeviceWifiFormState) {
    let updateFormState: DeviceWifiFormState = null;

    switch (newFormState) {
      case this.formStates.New: {
        updateFormState = newFormState;
        this.deviceLocForm.formEnabled = true;
        this.deviceLocForm.formState = this.deviceLocForm.formStates.List;
        break;
      }
      case this.formStates.Edit: {
        updateFormState = newFormState;
        this.deviceEdit();
        this.deviceLocForm.formEnabled = true;

        // this.deviceLocForm.formState = this.deviceLocForm.formStates.List
        break;
      }
      case this.formStates.Save: {
        // presetting to address form state sequencing durring validation
        this._formState = newFormState;
        this.deviceSave();
        this.deviceLocForm.formState = this.deviceLocForm.formStates.List;
        break;
      }
      case this.formStates.Cancelled: {
        this.deviceCancel();
        if (this.deviceLocForm.formState !== this.deviceLocForm.formStates.List) {
          this.deviceLocForm.formState = this.deviceLocForm.formStates.Read;
          this.deviceLocForm.formEnabled = false;
        }
        break;
      }
      default: {
        updateFormState = newFormState;
        this.deviceLocForm.formState = this.deviceLocForm.formStates.List;
        this.deviceLocForm.formEnabled = false;
      }
    }

    if (updateFormState != null) {
      this._formState = updateFormState;
    }
  }

  private _deviceUuid: string = '';

  get deviceUuid(): string {
    return this._deviceUuid;
  }

  @Input() set deviceUuid(newDeviceUuid: string) {
    if (this.librariesService.guidValidate(newDeviceUuid)) {
      this._deviceUuid = newDeviceUuid;
      // if (this.formLoaded) {
      //   //this.getDevice();
      // }
      // // this.deviceLocView.deviceUuid = this.deviceUuid;
    }
    else {
      this._deviceUuid = '';
    }
    if (newDeviceUuid.toLowerCase() === 'add') {
      this.formState = this.formStates.New;
    }
  }

  @Output() voltagesData: any =
    [
      {
        "label": "battery",
        "color": this.colorsService.byName('chart-green-border'),
        "data": [
          [0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [8, 0], [9, 0], [10, 0], [11, 0], [12, 0], [13, 0], [14, 0],
          [15, 0], [16, 0], [17, 0], [18, 0], [19, 0], [20, 0], [21, 0], [22, 0], [23, 0], [24, 0], [25, 0], [26, 0], [27, 0], [28, 0], [29, 0]
        ]
      },
      {
        "label": "solar",
        "color": this.colorsService.byName('chart-yellow-border'),
        "data": [
          [0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [8, 0], [9, 0], [10, 0], [11, 0], [12, 0], [13, 0], [14, 0],
          [15, 0], [16, 0], [17, 0], [18, 0], [19, 0], [20, 0], [21, 0], [22, 0], [23, 0], [24, 0], [25, 0], [26, 0], [27, 0], [28, 0], [29, 0]
        ]
      }
    ];

  @Output() voltagesOptions = {
    series: {
      lines: {
        show: true,
        lineWidth: 2
      },
      points: {
        show: false,
        radius: 2,
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 5,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => {
        return this.datePipe.transform(new Date(x), 'MM/dd hh aa') + ' : ' + this.decimalPipe.transform(y, '1.0')
      }
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'time',
      minTickSize: [1, 'day'],
      tickFormatter: (v, axis) => {
        if (!v || v == 0) return ""

        if (axis.ticks.length > 9) {
          return ""
        }
        else {
          return this.datePipe.transform(new Date(v), 'MM/dd')
        }
      },

    },
    yaxis: {
      min: 0,
      max: 1000,
      tickColor: '#eee',
      tickFormatter: (v) => { return this.decimalPipe.transform(v, '1.0') }
    },
    legend: {
      show: true,
      noColumns: 2,
      margin: [0, 0],
      container: "#voltagesLegend"
    },
    shadowSize: 0
  };

  // tickSize: [12, "hour"],
  // tickFormatter: (v, axis) => {
  //   if (!v || v == 0) {
  //     return '&nbsp;';
  //   }
  //   let tDte = new Date(v);
  //   console.log(tDte.getHours() )
  //   if (tDte.getHours() == 0)
  //     return this.datePipe.transform(new Date(v), 'MM/dd');
  //   else if (tDte.getHours() == 12)
  //     return this.datePipe.transform(new Date(v), 'HH');
  //   else return this.datePipe.transform(new Date(v), 'HH');
  // },

  @Output() temperaturesData: any =
    [
      {
        "label": "temperature",
        "color": this.colorsService.byName('chart-orange'),
        "data": [
          [0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0]
        ]
      }
    ];

  @Output() temperaturesOptions = {
    series: {
      bars: {
        show: true,
        fill: 1
      },
      points: {
        show: false
      }
    },
    bars: {
      align: "center",
      barWidth: 2500000
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 5,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => {
        return this.datePipe.transform(new Date(x), 'MM/dd/yyyy') + ' : ' + this.decimalPipe.transform(y, '1.0');
      }
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'time',
      tickFormatter: (v, axis) => {
        if (!v || v == 0) {
          return '&nbsp;';
        }
        return this.datePipe.transform(new Date(v), 'MM/dd');
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickColor: '#eee',
      tickFormatter: (v) => {
        return this.decimalPipe.transform(v, '1.0');
      }
    },
    legend: {
      show: true,
      noColumns: 2,
      margin: [0, 0],
      container: "#temperaturesLegend"
    },
    shadowSize: 0
  };


  public device: any = null; // new DeviceCrudDto();
  public deviceSchedules: any[] = [];
  public firmwares: any[] = [];
  public models: any[] = [];

  get isEditMode(): boolean {
    // if (this.formState == DeviceWifiFormState.New) return true
    // if (this.formState == DeviceWifiFormState.Edit) return true

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
        {
          return true;
        }
      default:
        {
          return false;
        }
    }
  }

  public isModified = false;

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public devicesService: DevicesService,
    public deviceSchedulesService: DeviceSchedulesService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public firmwaresService: FirmwaresService,
    public modelsService: ModelsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public colorsService: ColorsService,
    private router: Router
  ) { }

  fgDevice: FormGroup;
  inputSerial: FormControl;
  inputModelId: FormControl;
  inputFirmwareUuidTarget: FormControl;
  inputdeviceScheduleUuid: FormControl;
  inputLabel: FormControl;

  public modelDropdown = new FormControl();
  public modelDropdown_doSelect = (value: any) => {
    this.fgDevice.patchValue({ modelId: +value });
  }
  public modelDropdown_removed = (value: any) => {
  }
  public modelDropdown_typed = (value: any) => {
  }

  public firmwareDropdown = new FormControl();
  public firmwareDropdown_doSelect = (value: any) => {
    this.fgDevice.patchValue({ firmwareUuidTarget: value });
  }
  public firmwareDropdown_removed = (value: any) => {
  }
  public firmwareDropdown_typed = (value: any) => {
  }

  public deviceScheduleDropdown = new FormControl();
  public deviceScheduleDropdown_doSelect = (value: any) => {
    this.fgDevice.patchValue({ deviceScheduleUuid: (value ? value : null) })
  }
  public deviceScheduleDropdown_removed = (value: any) => {
  }
  public deviceScheduleDropdown_typed = (value: any) => {
  }

  ngOnInit() {
    this.createFormGroupDevice();
    const self = this;
    window.addEventListener('resize', function () {
      console.log('height---' + (<HTMLElement>self.mapContainer.nativeElement).offsetHeight);
      console.log('width---' + (<HTMLElement>self.mapContainer.nativeElement).offsetWidth);
      self.getMapImage();
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.modelDropdown.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => this.modelDropdown_doSelect(value));
    this.firmwareDropdown.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => this.firmwareDropdown_doSelect(value));
    this.deviceScheduleDropdown.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => this.deviceScheduleDropdown_doSelect(value));

    this.loadFormData();

  }

  loadFormData() {
    console.log("DeviceWifiFormComponent:loadFormData")
    let self = this
    setTimeout(function () {
      self.formState == DeviceWifiFormState.Initializing;
      self.getModels();
      self.getFirmwares();
      self.getdeviceSchedules();
      if (self.device == null) {
        self.getDevice();
      }
      self.formLoaded = true;
    }, 1000);
  }

  redrawMaps() {

  }
  getMapImage() {
    // if (
    //   !this.device ||
    //   !this.device.deviceLocation ||
    //   this.device.deviceLocation.latitude == 0 ||
    //   this.device.deviceLocation.longitude == 0) {
    //   this.mapImageUrl = ""
    //   return
    // }

    let urlTmp: string;

    const mapAppId = 'xIfm_53sblCK-vyAdENsbqlETwTCzTLHbmyUjWNBogs';
    //const mapAppCode = 'QR8uS_T_26wynuRa7eN6vA';
    if (this.device.connectivityTypeId == 0o0) {
      urlTmp = this.mapImageUrlTemplateWifi;
    }
    if (this.device.connectivityTypeId == 10) {
      urlTmp = this.mapImageUrlTemplateCellular;
    }
    
    let map: HTMLElement = this.mapContainer.nativeElement;

    this.mapW = map.offsetWidth;
    this.mapH = map.offsetHeight;

    if (!this.device || !this.device.deviceLocation ||
      this.device.deviceLocation.latitude === 0 ||
      this.device.deviceLocation.longitude === 0) {
      return '';
    }

    urlTmp = urlTmp.replace('[app_id]', mapAppId);
   // urlTmp = urlTmp.replace('[apiKey]', mapAppCode);
    urlTmp = urlTmp.replace('[bgrnd]', '1188DD');
    urlTmp = urlTmp.replace('[fgrnd]', '1188DD');
    urlTmp = urlTmp.replace('[size]', '14');
    urlTmp = urlTmp.replace('[txt]', '-');
    urlTmp = urlTmp.replace('[z]', '16');
    urlTmp = urlTmp.replace('[w]', (this.mapW * 1.1).toFixed(0).toString());
    urlTmp = urlTmp.replace('[h]', (this.mapH * 1.1).toFixed(0).toString());
    urlTmp = urlTmp.replace(/\[lat\]/g, this.device.deviceLocation.latitude);
    urlTmp = urlTmp.replace(/\[lng\]/g, this.device.deviceLocation.longitude);

    this.mapImageUrl = urlTmp;
    map.style.backgroundImage = "url('" + this.mapImageUrl + "')"
  }

  getModels(): any {
    this.models = [];
    if (!this.settingsService.client) {
      return;
    }

    // alert(this.deviceUuid);
    return this.modelsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.models = result;
        },
        error => {
          this.notificationsService.error('Server Error (getModels)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getFirmwares(): any {
    this.firmwares = [];

    if (!this.settingsService.client) {
      return;
    }

    // alert(this.deviceUuid);
    return this.firmwaresService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.firmwares = result;
        },
        error => {
          this.notificationsService.error('Server Error (getFirmwares)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }


  getdeviceSchedules(): any {
    this.deviceSchedules = [];

    if (!this.settingsService.client) {
      return;
    }

    // alert(this.deviceUuid);
    return this.deviceSchedulesService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.deviceSchedules = result;
        },
        error => {
          this.notificationsService.error('Server Error (getdeviceSchedules)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  getDevice(): any {

    this.device = null;

    if (!this.settingsService.client || !this.deviceUuid) {
      return;
    }

    this.settingsService.showSpinner("device", false, 250);

    // alert(this.deviceUuid);
    return this.devicesService.readByUuid(this.deviceUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          this.fgDevice.patchValue(
            {
              serial: result.serial,
              modelId: result.modelId,
              firmwareUuidTarget: result.firmwareUuidTarget,
              deviceScheduleUuid: result.deviceScheduleUuid,
              label: result.label,
            }
          )
          this.formState = DeviceWifiFormState.Read;
          this.device = result;

          for (var i = 0; i < this.device.configOptionValues.length; i++) {
            if (this.device.configOptionValues[i].optionCode == "Camera") {
              this.cameraEnabled = true;
         
            }
          }
          this.device.nextActivity = moment(this.device.lastActivity).add(15, 'm').toDate();
          // this.deviceLocationsTemp = result;
          this.getMapImage();
          this.getViolatorsData();
          this.getVoltagesData();
          this.getConnectionsData();
          //this.getTemperaturesData();
          this.settingsService.hideSpinner("device");
        },
        error => {
          this.settingsService.hideSpinner("device");
          this.notificationsService.error('Server Error (getDevice)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.formState = DeviceWifiFormState.Read;
        });
  }

  createFormGroupDevice() {
    this.inputSerial = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.inputModelId = new FormControl('', [Validators.required, Validators.min(1)]);
    this.inputFirmwareUuidTarget = new FormControl('', [Validators.required]);
    this.inputdeviceScheduleUuid = new FormControl('');
    this.inputLabel = new FormControl('');

    this.fgDevice = new FormGroup({
      'serial': this.inputSerial,
      'modelId': this.inputModelId,
      'firmwareUuidTarget': this.inputFirmwareUuidTarget,
      'deviceScheduleUuid': this.inputdeviceScheduleUuid,
      'label': this.inputLabel
    });
  }

  // validateFormGroup(formGroup: FormGroup) {         //{1}
  //   Object.keys(formGroup.controls).forEach(field => {  //{2}
  //     const control = formGroup.get(field);             //{3}
  //     if (control instanceof FormControl) {             //{4}
  //       control.markAsTouched({ onlySelf: true });
  //     } else if (control instanceof FormGroup) {        //{5}
  //       this.validateFormGroup(control);            //{6}
  //     }
  //   }
  //   );
  // }

  controlValidationClass(control) {
    return 'form-control' + (this.controlInvalidFlag(control) != null
      ? (this.controlInvalidFlag(control) ? ' is-invalid' : ' is-valid')
      : '');
  }
  controlInvalidFlag(control) {
    if (!(control.touched)) {
      return null;
    }
    return control.invalid;
  }

  onConfigurationClick(event){
    console.log(event);
    this.router.navigate(['../configurations/', this.device.deviceSchedule.uuid]);
  }

  deviceEdit() {
    // do setup
    this.modelDropdown.setValue(this.fgDevice.get('modelId').value);
    this.firmwareDropdown.setValue(this.fgDevice.get('firmwareUuidTarget').value);
    this.deviceScheduleDropdown.setValue(this.fgDevice.get('deviceScheduleUuid').value);

    this.isModified = false;

    // Send Event
    this.onFormEventDevice.emit('edit');
  }

  deviceSave() {
    if (!this.fgDevice.valid) {
      this.librariesService.validateFormGroup(this.fgDevice);
      this.formState = this.deviceUuid === '' ? this.formStates.New : this.formStates.Edit;
      this.onFormEventDevice.emit('edit');
      this.notificationsService.warn('Validation Warning (Device)', 'Verify Required Data', { timeOut: 5000, clickToClose: true });
      return;
    }

    this.formState = this.formStates.Saving;
    this.onFormEventDevice.emit('saving');

    this.settingsService.showSpinner(undefined, false, 250);

    if (this.deviceUuid === '') {
      return this.devicesService.create(this.fgDevice.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            // alert(result.toString())
            // if successful...
            this.settingsService.hideSpinner(undefined);

            this.deviceUuid = result.uuid;
            this.formState = this.formStates.Read;

            this.onFormEventDevice.emit('saved')
            this.notificationsService.success('Success', 'Device Saved', { timeOut: 2000, clickToClose: true });
          },
          error => {
            this.settingsService.hideSpinner(undefined);
            this.notificationsService.error('Server Error (deviceSave)',
              this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit;
          });
    }
    else {
      return this.devicesService.readByUuid(this.deviceUuid)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            // result.serial = this.fgDevice.value.serial
            // result.modelId = this.fgDevice.value.modelId
            // result.firmwareUuidTarget = this.fgDevice.value.firmwareUuidTarget
            result.deviceScheduleUuid = this.fgDevice.value.deviceScheduleUuid;
            result.label = this.fgDevice.value.label;

            return this.devicesService.put(result)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (result: any) => {
                  // alert(result.toString())
                  // if successful...
                  this.settingsService.hideSpinner(undefined);

                  this.deviceUuid = result.uuid;
                  this.formState = this.formStates.Read;

                  this.onFormEventDevice.emit('saved');
                  this.notificationsService.success('Success', 'Device Saved', { timeOut: 2000, clickToClose: true });
                },
                error => {
                  this.settingsService.hideSpinner(undefined);
                  this.notificationsService.error('Server Error (deviceSave: put)',
                    this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                  this.formState = this.formStates.Edit;
                });

          },
          error => {
            this.settingsService.hideSpinner(undefined);
            this.notificationsService.error('Server Error (deviceSave: getDevice)',
              this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = DeviceWifiFormState.Read;
          });

    }
  }

  deviceCancel() {
    this.formState = this.formStates.Read;
    this.onFormEventDevice.emit('cancelled');
  }



  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  goBack(event) {
    (<HTMLElement>this.backArrow.nativeElement).hidden = true;
    (<HTMLElement>this.loadBack.nativeElement).hidden = false;
    (<HTMLElement>this.forwardArrow.nativeElement).hidden = true;
    let getEndingDate = new Date(this.startingDate)
    let getStartingDate = new Date(this.startingDate);
    getEndingDate.setDate(getEndingDate.getDate() - 30)
    let endingDate = new Date(getStartingDate);
    let newStartDay = new Date(getEndingDate);

    this.startingDate = newStartDay;

    if (!this.settingsService.client) {
      return;
    }

    this.dateRangeViolatorsData(newStartDay, endingDate);
    this.dateRangeVoltageData(newStartDay, endingDate);
    this.dateRangeConnectionData(newStartDay, endingDate);
    setTimeout(() => {
      (<HTMLElement>this.backArrow.nativeElement).hidden = false;
      (<HTMLElement>this.loadBack.nativeElement).hidden = true;
      (<HTMLElement>this.forwardArrow.nativeElement).hidden = false;
    }, 2000);

  }


  goForward(event) {
    (<HTMLElement>this.backArrow.nativeElement).hidden = true;
    (<HTMLElement>this.loadBack.nativeElement).hidden = false;
    (<HTMLElement>this.forwardArrow.nativeElement).hidden = true;
    console.log(this.startingDate);
    let getEndingDate = new Date(this.startingDate)
    let getStartingDate = new Date(this.startingDate);
    getEndingDate.setDate(getEndingDate.getDate() + 30)
    getStartingDate.setDate(getStartingDate.getDate() + 60)
    let endingDate = new Date(getStartingDate);
    let newStartDay = new Date(getEndingDate);

    let today = new Date();
    let today2 = new Date();
    today2.setDate(today2.getDate() + 1);

    let myDate = today.toLocaleDateString();
    let myDate2 = today2.toLocaleDateString();
    let checkDate = new Date(newStartDay.setDate(newStartDay.getDate()));
    let newCheckDate = checkDate.toLocaleDateString();
    if (myDate == newCheckDate || myDate2 == newCheckDate) {
      (<HTMLElement>this.forwardArrow.nativeElement).hidden = true;
      (<HTMLElement>this.backArrow.nativeElement).hidden = false;
      (<HTMLElement>this.loadBack.nativeElement).hidden = true;
      return;
    }
    this.startingDate = newStartDay;

    this.dateRangeViolatorsData(newStartDay, endingDate);
    this.dateRangeVoltageData(newStartDay, endingDate);
    this.dateRangeConnectionData(newStartDay, endingDate);

    setTimeout(() => {
      (<HTMLElement>this.backArrow.nativeElement).hidden = false;
      (<HTMLElement>this.loadBack.nativeElement).hidden = true;
      (<HTMLElement>this.forwardArrow.nativeElement).hidden = false;
    }, 2000);


  }

  dateRangeViolatorsData(startDay, endingDay) {
    this.ViolatorsDataDate = "";


    return this.deviceDailySummariesService.readDeviceDailySummariesSummaryByDeviceDateRange(this.deviceUuid, startDay, endingDay)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          if (result.length == 0) {
            return;
          }
          const chartData: any = JSON.parse(JSON.stringify(this.violatorsData));
          const chartOptions: any = (this.violatorsOptions);

          const vhcl = new Array();
          const vltr = new Array();
          let yaxismax: number = 1000;
          let xValue: Date;

          result.forEach(element => {
            xValue = new Date(element.recordDate);

            // vhcl.push([xValue.getTime(),((element.vehicles == 0) ? null : element.vehicles) ])
            // vltr.push([xValue.getTime(),((element.violators == 0) ? null : element.violators) ]) 

            vhcl.push([xValue.getTime(), element.vehicles]);
            vltr.push([xValue.getTime(), element.violators]);

            if (element.vehicles > yaxismax) {
              yaxismax = element.vehicles;
            }
            if (element.violators > yaxismax) {
              yaxismax = element.violators;
            }
          });

          if (result.length > 0) {
            var lastDate = new Date(result[0].recordDate);
            this.ViolatorsDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          chartData[0].data = vhcl;
          chartData[1].data = vltr;
          chartOptions.yaxis.max = (yaxismax * 1.2)
          this.violatorsOptions = chartOptions;
          this.violatorsData = chartData;
        },
        error => {
          this.notificationsService.error('Error (getViolatorsData)', this.errorsService.errorParse(error), { clickToClose: true });
        });
  }

  dateRangeVoltageData(startDay, endDay) {
    this.VoltagesDataDate = "";

    return this.devicesService.readTelemetryByUuidAndDateRange(this.deviceUuid, startDay, endDay)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          const chartData: any = JSON.parse(JSON.stringify(this.voltagesData));
          const chartOptions: any = (this.voltagesOptions);
          const batVoltages = new Array();
          const solVoltages = new Array();
          let yaxismax: number = 20;
          let xValue: Date;
          let hours = new Date(result[0].recordDateTime).getHours();
          let count = 0;
          let batteryVolts = 0;
          let solarVolts = 0;

          if (result.length > 0) {
            var lastDate = new Date(result[result.length - 1].recordDateTime);
            this.VoltagesDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          result.forEach(element => {
            xValue = new Date(element.recordDateTime);
            if (hours !== xValue.getHours()) {

              const avgBatVol = batteryVolts / count;
              const avgSolarVol = solarVolts / count;



              const day: Date = new Date(xValue.getFullYear(), xValue.getMonth(), xValue.getDate(), xValue.getHours());
              if (avgBatVol > 36 && avgSolarVol > 36) {
                batVoltages.push([day, 0]);
                solVoltages.push([day, 0]);
              }
              else {
                batVoltages.push([day, avgBatVol]);
                solVoltages.push([day, avgSolarVol]);
              }

              batteryVolts = 0;
              solarVolts = 0;
              count = 0;
              hours = xValue.getHours();
            }
            count++;
            batteryVolts += element.batteryVolts;
            solarVolts += element.solarVolts;

          });

          chartData[0].data = batVoltages.sort(function (a, b) { return b[0] - a[0]; });
          chartData[1].data = solVoltages.sort(function (a, b) { return b[0] - a[0]; });
          let maxBatVolts = Math.max.apply(Math, chartData[0].data.map(function (maxObj) { return maxObj[1] }));
          let maxSolVolts = Math.max.apply(Math, chartData[1].data.map(function (maxObj) { return maxObj[1] }));
          if (maxSolVolts > maxBatVolts) {
            chartOptions.yaxis.max = maxSolVolts * 1.2;
            if (chartOptions.yaxis.max < 15) {
              chartOptions.yaxis.max = 15;
            }
          }
          else {
            chartOptions.yaxis.max = (maxBatVolts * 1.2);
            if (chartOptions.yaxis.max < 15) {
              chartOptions.yaxis.max = 15;
            }
          }
          this.voltagesOptions = chartOptions;
          console.log('voltagesData', chartData)
          this.voltagesData = chartData;

        },
        error => {
          this.notificationsService.error('Error (getVoltagesData)', this.errorsService.errorParse(error), { clickToClose: true });
        });

  }

  dateRangeConnectionData(startDay, endDay) {
    this.ConnectionsDataDate = '';

    return this.devicesService.readConnectionsByUuidAndDateRange(this.deviceUuid, startDay, endDay)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any[]) => {
          const chartData: any = JSON.parse(JSON.stringify(this.connectionsData));
          const chartOptions: any = (this.connectionsOptions);

          const connections = new Array();
          let yaxismax: number = 50;
          let xValue: Date;
          let count = 0;
          const firstDay = new Date(result[0].helloDate);

          let day = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate());
          const lastElemnt = result[result.length - 1];

          result.forEach(element => {
            xValue = new Date(element.helloDate);
            count++;

            if (day.getTime() !== new Date(xValue.getFullYear(), xValue.getMonth(), xValue.getDate()).getTime()
              || element === lastElemnt) {
              connections.push([day, count]);

              if (count > yaxismax) {
                yaxismax = count;
              }

              count = 0;
              day = new Date(xValue.getFullYear(), xValue.getMonth(), xValue.getDate());
            }

          });

          if (result.length > 0) {
            var lastDate = new Date(result[result.length - 1].helloDate);
            this.ConnectionsDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          chartData[0].data = connections;
          chartOptions.yaxis.max = (yaxismax * 1.2);
          this.connectionsOptions = chartOptions;
          console.log('ConnectionsData', chartData)
          this.connectionsData = chartData;
        },
        error => {
          this.notificationsService.error('Error (getConnectionsData)', this.errorsService.errorParse(error), { clickToClose: true });
        });


  }


  onSelectTab($event) {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 35);
  }

  refreshTelemetries(event){
    if(sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')){
      if ((this.deviceTelemView.fgData.get('dateRange').value[0] != sessionStorage.getItem('dateBegin')) ||
      (this.deviceTelemView.fgData.get('dateRange').value[1] != sessionStorage.getItem('dateEnd'))) {
        this.deviceTelemView.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
        this.deviceTelemView.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
        this.deviceTelemView.fgData.patchValue({
          dateRange: [this.deviceTelemView.dateBegin, this.deviceTelemView.dateEnd]
        })
        this.onSelectTab(event);
        // this.deviceTelemView.buttonRefresh(event);
      } else {
        this.onSelectTab(event);
    }
  } else {
    this.onSelectTab(event);
  }
}

  refreshData(event) {
    if(sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')){
      if ((this.deviceDataView.fgData.get('dateRange').value[0] != sessionStorage.getItem('dateBegin')) ||
      (this.deviceDataView.fgData.get('dateRange').value[1] != sessionStorage.getItem('dateEnd'))) {
        this.deviceDataView.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
        this.deviceDataView.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
        this.deviceDataView.fgData.patchValue({
          dateRange: [this.deviceDataView.dateBegin, this.deviceDataView.dateEnd]
        })
        this.onSelectTab(event);
        // this.deviceDataView.buttonRefresh(event);
      } else {
        this.onSelectTab(event);
    }
  } else {
    this.onSelectTab(event);
  }
}

  refreshConnections(event){
    if(sessionStorage.getItem('dateBegin') && sessionStorage.getItem('dateEnd')){
      if ((this.deviceConnectView.fgData.get('dateRange').value[0] != sessionStorage.getItem('dateBegin')) ||
      (this.deviceConnectView.fgData.get('dateRange').value[1] != sessionStorage.getItem('dateEnd'))) {
        this.deviceConnectView.dateBegin = new Date(sessionStorage.getItem('dateBegin'));
        this.deviceConnectView.dateEnd = new Date(sessionStorage.getItem('dateEnd'));
        this.deviceConnectView.fgData.patchValue({
          dateRange: [this.deviceConnectView.dateBegin, this.deviceConnectView.dateEnd]
        })
        this.onSelectTab(event);
        // this.deviceConnectView.buttonRefresh(event);
      } else {
        this.onSelectTab(event);
    }
  } else {
    this.onSelectTab(event);
  }
}

observe(event){
  this.deviceImagesView.buttonRefresh();
}

  dataTableEventsInitialize() {
    var self = this;
    this.dataPeriodSummariesResize();
    window.addEventListener('resize', function () {
      self.dataPeriodSummariesResize();
    });
  }

  dataPeriodSummariesResize() {
    console.log('dataPeriodSummariesResize');

    if (document.getElementById('footerContainer') == null)
      return;
    //test variables to see how this stuff is interacting
    var footerTestOne = document.getElementById('footerContainer').getBoundingClientRect().bottom;
    var footerTestTwo = document.getElementById('footerContainer').getBoundingClientRect().top;
    var subtracted = document.getElementById('footerContainer').getBoundingClientRect().bottom - document.getElementById('footerContainer').getBoundingClientRect().top;
    var agThemeVal = (<HTMLElement>document.getElementsByClassName('ag-theme-alpine connectionTable m-0 pt-2 border-0')[0]).getBoundingClientRect().bottom;

    //actual math for the view here
    var dataPeriodSummariesHeight: number =
      document.body.clientHeight - 250 - subtracted;

    dataPeriodSummariesHeight = dataPeriodSummariesHeight.round(0);

    //limits view height to a min of 400px
    if (dataPeriodSummariesHeight <= 400) {
      return
    }

    (<HTMLElement>document.getElementsByName('connectionsTable')[0]).style.height = dataPeriodSummariesHeight.toString() + 'px';
    (<HTMLElement>document.getElementsByName('dataTable')[0]).style.height = dataPeriodSummariesHeight.toString() + 'px';
    (<HTMLElement>document.getElementsByName('telemetryTable')[0]).style.height = dataPeriodSummariesHeight.toString() + 'px';
    console.log('!!!tabresize!', (<HTMLElement>document.getElementsByName('connectionsTable')[0]).style.height);
    console.log('!!!tabresize!', (<HTMLElement>document.getElementsByName('dataTable')[0]).style.height);
    console.log('!!!tabresize!', (<HTMLElement>document.getElementsByName('telemetryTable')[0]).style.height);

  }

  public ViolatorsDataDate: string = "";
  getViolatorsData(): any {
    this.ViolatorsDataDate = "";

    if (!this.settingsService.client) {
      return;
    }

    // const today = new Date();
    // // var dateEnd: Date = new Date(today.getFullYear(),today.getMonth(),1);
    // const dateEnd: Date = today;
    // const dateBegin: Date = new Date(dateEnd);
    // dateBegin.setDate(dateBegin.getDate() - 30);

    return this.deviceDailySummariesService.readDeviceDailySummariesSummaryDayByRecentDays(
      this.deviceUuid, 30)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          const chartData: any = JSON.parse(JSON.stringify(this.violatorsData));
          const chartOptions: any = (this.violatorsOptions);

          const vhcl = new Array();
          const vltr = new Array();
          let yaxismax: number = 1000;
          let xValue: Date;
          this.startingDate = result[0].date;

          result.forEach(element => {
            xValue = new Date(element.date);

            // vhcl.push([xValue.getTime(),((element.vehicles == 0) ? null : element.vehicles) ])
            // vltr.push([xValue.getTime(),((element.violators == 0) ? null : element.violators) ]) 

            vhcl.push([xValue.getTime(), element.vehicles]);
            vltr.push([xValue.getTime(), element.violators]);

            if (element.vehicles > yaxismax) {
              yaxismax = element.vehicles;
            }
            if (element.violators > yaxismax) {
              yaxismax = element.violators;
            }
          });

          if (result.length > 0) {
            var lastDate = new Date(result[result.length - 1].date);
            this.ViolatorsDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          chartData[0].data = vhcl;
          chartData[1].data = vltr;
          chartOptions.yaxis.max = (yaxismax * 1.2)
          this.violatorsOptions = chartOptions;
          this.violatorsData = chartData;
        },
        error => {
          this.notificationsService.error('Error (getViolatorsData)', this.errorsService.errorParse(error), { clickToClose: true });
        });

  }

  public VoltagesDataDate: string = "";
  getVoltagesData(): any {

    if (!this.settingsService.client) {
      return;
    }

    return this.devicesService.readRecentTelemetryByUuid(this.deviceUuid, 30)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          const chartData: any = JSON.parse(JSON.stringify(this.voltagesData));
          const chartOptions: any = (this.voltagesOptions);
          const batVoltages = new Array();
          const solVoltages = new Array();
          let yaxismax: number = 20;
          let xValue: Date;
          let hours = new Date(result[0]?.recordDateTime).getHours();
          let count = 0;
          let batteryVolts = 0;
          let solarVolts = 0;

          if (result.length > 0) {
            var lastDate = new Date(result[result.length - 1].recordDateTime);
            this.VoltagesDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          result.forEach(element => {
            xValue = new Date(element.recordDateTime);
            if (hours !== xValue.getHours()) {

              const avgBatVol = batteryVolts / count;
              const avgSolarVol = solarVolts / count;



              const day: Date = new Date(xValue.getFullYear(), xValue.getMonth(), xValue.getDate(), xValue.getHours());
              if (avgBatVol > 36 && avgSolarVol > 36) {
                batVoltages.push([day, 0]);
                solVoltages.push([day, 0]);
              }
              else {
                batVoltages.push([day, avgBatVol]);
                solVoltages.push([day, avgSolarVol]);
              }

              batteryVolts = 0;
              solarVolts = 0;
              count = 0;
              hours = xValue.getHours();
            }
            count++;
            batteryVolts += element.batteryVolts;
            solarVolts += element.solarVolts;

          });

          chartData[0].data = batVoltages.sort(function (a, b) { return b[0] - a[0]; });
          chartData[1].data = solVoltages.sort(function (a, b) { return b[0] - a[0]; });
          let maxBatVolts = Math.max.apply(Math, chartData[0].data.map(function (maxObj) { return maxObj[1] }));
          let maxSolVolts = Math.max.apply(Math, chartData[1].data.map(function (maxObj) { return maxObj[1] }));
          if (maxSolVolts > maxBatVolts) {
            chartOptions.yaxis.max = maxSolVolts * 1.2;
            if (chartOptions.yaxis.max < 15) {
              chartOptions.yaxis.max = 15;
            }
          }
          else {
            chartOptions.yaxis.max = (maxBatVolts * 1.2);
            if (chartOptions.yaxis.max < 15) {
              chartOptions.yaxis.max = 15;
            }
          }
          this.voltagesOptions = chartOptions;
          console.log('voltagesData', chartData)
          this.voltagesData = chartData;

        },
        error => {
          this.notificationsService.error('Error (getVoltagesData)', this.errorsService.errorParse(error), { clickToClose: true });
        });

  }

  public ConnectionsDataDate: string = ''
  getConnectionsData(): any {
    this.ConnectionsDataDate = '';

    if (!this.settingsService.client) {
      return;
    }

    return this.devicesService.readRecentConnectionsByUuid(this.deviceUuid, 30)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any[]) => {
          const chartData: any = JSON.parse(JSON.stringify(this.connectionsData));
          const chartOptions: any = (this.connectionsOptions);

          const connections = new Array();
          let yaxismax: number = 50;
          let xValue: Date;
          let count = 0;
          const firstDay = new Date(result[0]?.helloDate);

          let day = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate());
          const lastElemnt = result[result.length - 1];

          result.forEach(element => {
            xValue = new Date(element.helloDate);
            count++;

            if (day.getTime() !== new Date(xValue.getFullYear(), xValue.getMonth(), xValue.getDate()).getTime()
              || element === lastElemnt) {
              connections.push([day, count]);

              if (count > yaxismax) {
                yaxismax = count;
              }

              count = 0;
              day = new Date(xValue.getFullYear(), xValue.getMonth(), xValue.getDate());
            }

          });

          if (result.length > 0) {
            var lastDate = new Date(result[result.length - 1].helloDate);
            this.ConnectionsDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          chartData[0].data = connections;
          chartOptions.yaxis.max = (yaxismax * 1.2);
          this.connectionsOptions = chartOptions;
          console.log('ConnectionsData', chartData)
          this.connectionsData = chartData;
        },
        error => {
          this.notificationsService.error('Error (getConnectionsData)', this.errorsService.errorParse(error), { clickToClose: true });
        });
  }
  public TemperaturesDataDate: string = ''
  getTemperaturesData(): any {

    if (!this.settingsService.client) {
      return;
    }

    return this.devicesService.readRecentTelemetryByUuid(this.deviceUuid, 7)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          const chartData: any = JSON.parse(JSON.stringify(this.temperaturesData));
          const chartOptions: any = (this.temperaturesOptions);
          const temperatures = new Array();
          let yaxismax: number = 100;
          let xValue: Date;
          let hours = new Date(result[0].recordDateTime).getHours();
          let count = 0;
          let temperature = 0;
          const temperatureUnit = this.settingsService.TemperatureUnit;

          if (result.length > 0) {
            var lastDate = new Date(result[result.length - 1].recordDateTime);
            this.TemperaturesDataDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate()).toLocaleDateString();
          }

          result.forEach(element => {
            xValue = new Date(element.recordDateTime);
            if (hours !== xValue.getHours()) {
              let avgTemperature = temperature / count;
              if (temperatureUnit === 'F') {
                avgTemperature = avgTemperature * 9 / 5 + 32;
              }
              temperatures.push([new Date(xValue.getFullYear(), xValue.getMonth(), xValue.getDate()).setHours(hours), avgTemperature]);

              if (avgTemperature > yaxismax) {
                yaxismax = avgTemperature;
              }

              temperature = 0;
              count = 0;
              hours = xValue.getHours();
            }
            count++;
            temperature += element.temperature;
          });
          chartData[0].data = temperatures;
          chartOptions.yaxis.max = (yaxismax * 1.2);
          this.temperaturesOptions = chartOptions;
          this.temperaturesData = chartData;
        },
        error => {
          this.notificationsService.error('Error (getTemperaturesData)', this.errorsService.errorParse(error), { clickToClose: true });
        });

  }



  @Output() violatorsData: any =
    [{
      "label": "vehicles",
      "color": this.colorsService.byName('chart-blue'),
      "data": [
        [0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [8, 0], [9, 0], [10, 0], [11, 0], [12, 0], [13, 0], [14, 0],
        [15, 0], [16, 0], [17, 0], [18, 0], [19, 0], [20, 0], [21, 0], [22, 0], [23, 0], [24, 0], [25, 0], [26, 0], [27, 0], [28, 0], [29, 0]
      ]
    }, {
      "label": "Violators",
      "color": this.colorsService.byName('chart-red'),
      "data": [
        [0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [8, 0], [9, 0], [10, 0], [11, 0], [12, 0], [13, 0], [14, 0],
        [15, 0], [16, 0], [17, 0], [18, 0], [19, 0], [20, 0], [21, 0], [22, 0], [23, 0], [24, 0], [25, 0], [26, 0], [27, 0], [28, 0], [29, 0]
      ]
    }
    ];

  @Output() violatorsOptions = {
    series: {
      lines: {
        show: true,
        fill: 0.8,
        lineWidth: 2
      },
      points: {
        show: false,
        radius: 2
      },
      // splines: {
      //     show: true,
      //     tension: 0.4,
      //     lineWidth: 1,
      //     fill: 0.5
      // }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 5,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => { return this.datePipe.transform(new Date(x), 'MM/dd/yyyy') + ' : ' + this.decimalPipe.transform(y, '1.0') }
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'time',
      tickFormatter: (v, axis) => {
        if (!v || v == 0) return "----"
        return this.datePipe.transform(new Date(v), 'MM/dd')
      },
      //timeformat: "%m/%Y"
      //mode: 'categories'
      //tickFormatter: function (v, axis) {
      //     return "hello";
      //}
    },
    yaxis: {
      min: 0,
      max: 1000, // optional: use it for a clear represetation
      tickColor: '#eee',
      // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
      tickFormatter: (v) => { return this.decimalPipe.transform(v, '1.0') }
    },
    legend: {
      show: true,
      //labelFormatter: null or (fn: string, series object -> string),
      //labelBoxBorderColor: color,
      noColumns: 2,
      //position: "ne" or "nw" or "se" or "sw",
      margin: [0, 0], //number of pixels or [x margin, y margin],
      //backgroundColor: null or color,
      //backgroundOpacity: number between 0 and 1,
      container: "#violatorsLegend", //null or jQuery object/DOM element/jQuery expression,
      //sorted: null/false, true, "ascending", "descending", "reverse", or a comparator
    },
    shadowSize: 0
  };


  @Output() connectionsData: any =
    [{
      "label": "Connections",
      "color": this.colorsService.byName('chart-purple'),
      "data": [
        [0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0]
      ]
    }
    ];

  @Output() connectionsOptions = {
    series: {
      bars: {
        show: true,
        fill: 1,
        order: .85,
        align: 'right',
        barWidth: 40000000
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 5,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => {
        return this.datePipe.transform(new Date(x), 'MM/dd/yyyy') + ' : ' + this.decimalPipe.transform(y, '1.0')
      }
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'time',
      timeformat: "%m/%d",
      minTickSize: [1, 'day']
    },
    yaxis: {
      min: 0,
      max: 100,
      tickColor: '#eee',
      tickFormatter: (v) => { return this.decimalPipe.transform(v, '1.0') }
    },
    legend: {
      show: true,
      noColumns: 1,
      margin: [0, 0],
      container: "#connectionsLegend"
    },
    shadowSize: 0
  };

  getSplineDataOverview(): any {

    if (!this.settingsService.client) return;

    var today = new Date();
    //var dateEnd: Date = new Date(today.getFullYear(),today.getMonth(),1);
    var dateEnd: Date = today;
    var dateBegin: Date = new Date(dateEnd)
    dateBegin.setDate(dateBegin.getDate() - 366);

    return this.deviceDailySummariesService.readDeviceDailySummariesSummaryDayByDateRange(
      dateBegin, dateEnd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          var splineData: any = JSON.parse(JSON.stringify(this.splineDataOverview))
          var splineOptions: any = (this.splineOptionsOverview)

          var vhcl = new Array();
          var vltr = new Array();
          var yaxismax: number = 1000;
          var xValue: Date;

          result.forEach(element => {
            xValue = new Date(element.date)

            vhcl.push([xValue.getTime(), ((element.vehicles == 0) ? null : element.vehicles)])
            vltr.push([xValue.getTime(), ((element.violators == 0) ? null : element.violators)])

            // vhcl.push([(element.month.toString()+'-'+element.year.toString()),element.vehicles])
            // vltr.push([(element.month.toString()+'-'+element.year.toString()),element.violators])

            if (element.vehicles > yaxismax) yaxismax = element.vehicles
            if (element.violators > yaxismax) yaxismax = element.violators
          });

          splineData[0].data = vhcl;
          splineData[1].data = vltr;
          splineOptions.yaxis.max = (yaxismax * 1.2)
          this.splineOptionsOverview = splineOptions;
          //this.splineOptionsOverview.yaxis.max = yaxismax;
          //this.splineOptionsOverview = null;
          this.splineDataOverview = splineData;

          // console.log(JSON.stringify(result))
          // console.log(JSON.stringify(splineOptions))
          // console.log(JSON.stringify(this.splineDataOverview))

          //alert(JSON.stringify(vhcl))
          //alert(JSON.stringify(this.splineDataOverview))
        },
        error => {
          this.notificationsService.error("Server Error", this.errorsService.errorParse(error), { clickToClose: true });
        });

  }

  @Output() splineDataOverview: any =
    [{
      "label": "vehicles",
      "color": "#768294",
      "data": [
        [0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [8, 0], [9, 0], [10, 0], [11, 0], [12, 0], [13, 0], [14, 0],
        [15, 0], [16, 0], [17, 0], [18, 0], [19, 0], [20, 0], [21, 0], [22, 0], [23, 0], [24, 0], [25, 0], [26, 0], [27, 0], [28, 0], [29, 0]
      ]
    }, {
      "label": "Violators",
      "color": "#ff8080",
      "data": [
        [0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [8, 0], [9, 0], [10, 0], [11, 0], [12, 0], [13, 0], [14, 0],
        [15, 0], [16, 0], [17, 0], [18, 0], [19, 0], [20, 0], [21, 0], [22, 0], [23, 0], [24, 0], [25, 0], [26, 0], [27, 0], [28, 0], [29, 0]
      ]
    }
    ];

  @Output() splineOptionsOverview = {
    series: {
      lines: {
        show: true,
        fill: 0.8
      },
      points: {
        show: true,
        radius: 2
      },
      // splines: {
      //     show: true,
      //     tension: 0.4,
      //     lineWidth: 1,
      //     fill: 0.5
      // }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 5,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => { return this.datePipe.transform(new Date(x), 'MM/yyyy') + ' : ' + this.decimalPipe.transform(y, '1.0') }
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'time',
      tickFormatter: (v, axis) => {
        if (!v || v == 0) return "----"
        return this.datePipe.transform(new Date(v), 'MM/yyyy')
      },
      //timeformat: "%m/%Y"
      //mode: 'categories'
      //tickFormatter: function (v, axis) {
      //     return "hello";
      //}
    },
    yaxis: {
      min: 0,
      max: 1000, // optional: use it for a clear represetation
      tickColor: '#eee',
      // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
      tickFormatter: (v) => { return this.decimalPipe.transform(v, '1.0') }
    },
    legend: {
      show: true,
      //labelFormatter: null or (fn: string, series object -> string),
      //labelBoxBorderColor: color,
      noColumns: 2,
      //position: "ne" or "nw" or "se" or "sw",
      margin: [0, 0], //number of pixels or [x margin, y margin],
      //backgroundColor: null or color,
      //backgroundOpacity: number between 0 and 1,
      container: "#splineDataLegend", //null or jQuery object/DOM element/jQuery expression,
      //sorted: null/false, true, "ascending", "descending", "reverse", or a comparator
    },
    shadowSize: 0
  };

  requestRealTimeData() {
    if (this.device == null) return;

    console.log('Realtime Data Requested')

    return this.devicesService.queueRealtimeData(this.device.uuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.device.dataRequestRealTime = true;
          this.notificationsService.success('Success', 'Data Download Requested', { timeOut: 2000, clickToClose: true });
        },
        error => {
          this.settingsService.hideSpinner(undefined);
          this.notificationsService.error('Server Error (deviceRequest: queueRealtimeData)',
            this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  locationModifiedEvent() {
    this.eventsService.triggerDataRefresh();
  }
}
