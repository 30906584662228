<div class="row">
    <form [formGroup]="fgImageControls" class="col-12">
        <table class="table border-0">

            <tbody>

                <tr>
                    <div class="form-row row">
                        <td class="col-4 border-0 mt-2 p-0">
                            <div class="input-group p-0 border-0 float-left">

                                <input type='text' [typeahead]="plateNumberList" class="form-control"
                                    placeholder='Type to filter by License Plate' (keyup.enter)="searchForPlate()"
                                    formControlName="plateNumber" list="dynamicPlates" />
                                <span *ngIf="fgImageControls.get('plateNumber').value" class="fa fa-times icon"
                                    uib-tooltip="clear" (click)="clearPlate()"></span>

                                <button type="button" class="btn btn-primary ml-2" (click)="searchForPlate()">
                                    <span class="fas fa-search"></span>
                                </button>
                            </div>


                        </td>
                        <td class="col-md-4 border-0 mt-2 pl-3 pt-0 pb-0">
                            <div class="input-group btn-group" btnRadioGroup (click)="librariesService.validateFormGroup(fgImageControls)"
                              formControlName="viewType">
                              <label class="form-control btn btn-primary border-0" btnRadio="0" tabindex="0" role="button"><span
                                  class="fas fa-map mr-2"></span>Gallery</label>
                              <label class="form-control btn btn-primary border-0" btnRadio="1" tabindex="0" role="button"><span
                                  class="fas fa-th-list mr-2"></span>Table</label>
                            </div>
                        </td>
                      <!--  <td class="col-4 border-0 mt-2 pl-3 pt-0 pb-0 text-center">
                            <label>Data Table Type</label>
                            <div class="input-group btn-group"
                                (click)="librariesService.validateFormGroup(fgImageControls)"
                                formControlName="viewType">
                                <label class="form-control btn btn-primary border-0" value="0" tabindex="0"
                                    role="button">Gallery</label>
                                <label class="form-control btn btn-primary border-0" value="1" tabindex="0"
                                    role="button">Table</label>

                            </div>
                        </td>-->

                        <td class="col-4 border-0 p-0 mt-2">
                            <div class="input-group justify-content-end">
                                <div class="mt-2 pr-2">
                                    <label (onChange)="buttonRefresh()" class="checkbox c-checkbox"
                                        style="display: inline">
                                        <input formControlName="unprocessedEnabled" type="checkbox" />
                                        <span class="fas fa-check"></span>Show Unrecognized Plates</label>
                                </div>


                                <div class="input-group-append float-right">
                                    <span
                                        class="input-group-text input-group-text-override fas fa-calendar btn btn-primary ml-2"
                                        (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></span>

                                    <input readonly style="background-color: white;" class="date-picker"
                                        #drp="bsDatepicker" bsDatepicker [bsConfig]="{showWeekNumbers: false}" [bsValue]="dateStart"
                                        formControlName="dateRange" />
                                </div>

                                <button type="button" class="btn btn-primary ml-2" (click)="buttonRefresh()">
                                    <span class="fas fa-sync-alt"></span>
                                </button>

                                <button class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}" (click)="scrollToTop()">
                                    <i class="fas fa-arrow-alt-circle-up fa-3x"></i>
                                </button>

                            </div>
                        </td>
                    </div>
                </tr>

            </tbody>

        </table>
    </form>
</div>



<div *ngIf="fgImageControls.get('viewType').value == '0'" class="row mt-2">
    <ng-container class="list-item" *ngFor="let item of deviceImages; let i = index" [attr.data-index]="i">
        <div class="col-xl-3 col-lg-3 md-4 col-sm-6 col-xs-12 pl-2 pr-2 mt-2">
            <div intersectionObserver [intersectionDebounce]="0" [ngSwitch]="visibilityStatus[item]"
                (visibilityChange)="onVisibilityChanged(item, $event)"
                (click)="openImageModal(imageModal, item.path, item.imageIndex, item)" class="image-frame-container">
                <div *ngIf="i + 1 != deviceImages.length" #theFirstElement>


                    <img src="{{item.path}}" class="img-fluid p-0" alt="image">
                    <div class="overlay">{{'Date: ' + (item.fileDate | date:'MM-dd-yyyy HH:mm:ss')}}<br>{{'Plate
                        Number: ' + item.plateNumber}}<br>{{'Country: ' + item.plateCountry}}<br>{{'State: ' +
                        item.plateStateProvince}}<br>{{'Confidence: ' + item.plateConfidence + '\n'}}</div>

                </div>

                <div *ngIf="i + 1 === deviceImages.length" #theLastElement>
                    <a href="{{item.path}}">
                        <img src="{{item.path}}" class="img-fluid" alt="image">
                        <div class="overlay">{{'Date: ' + (item.fileDate | date:'MM-dd-yyyy HH:mm:ss')}}<br>{{'Plate
                            Number: ' + item.plateNumber}}<br>{{'Country: ' + item.plateCountry}}<br>{{'State: ' +
                            item.plateStateProvince}}<br>{{'Confidence: ' + item.plateConfidence + '\n'}}</div>
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- <div intersectionObserver [intersectionDebounce]="0" [ngSwitch]="visibilityStatus[moreImages]"
        (visibilityChange)="onVisibilityChanged(moreImages, $event)">
        <div class="col-12 text-center mt-2" [ngSwitch]="visibilityStatus[moreImages]">
            <span #moreImages class="h4 text-primary font-weight-normal mt-2">More Images</span>
            <div>
                <span class="fas fa-chevron-double-down" (click)="getMoreImages()"></span>
            </div>
        </div>
        <ng-container *ngSwitchCase="intersectionStatus.Visible">{{getMoreImages()}}
        </ng-container>
    </div>
</div>-->
    <div class="col-12 text-center mt-2">
        <div #moreImages class="h4 text-primary font-weight-normal mt-2">More Images
            <div>
                <span class="fas fa-chevron-double-down" (click)="getMoreImages()"></span>
            </div>
        </div>
    </div>
    <ng-template #imageModal class="p-0">
        <div class="modal-body text-center p-0">

            <div class="container-fluid p-0">
                <img src="{{imagePath}}" class="img-fluid p-0">
                <div class="overlay-text-bottom p-2 mb-2">File Name: {{clickedImage.fileMetaData.frameFilename}}</div>
                <div class="overlay-text-bottom-5 p-2 mb-2">File Date: {{clickedImage.fileMetaData.radarDataTime}}</div>
                <div class="overlay-text-bottom-2 p-2 mb-2">Plate Number: {{clickedImage.plateNumber}}</div>
                <div class="overlay-text-bottom-3 p-2 mb-2">Speed Limit: {{clickedImage.fileMetaData.limit}} MPH</div>
                <div class="overlay-text-bottom-4 p-2 mb-2">Vehicle Speed: {{clickedImage.fileMetaData.speed}} MPH</div>
                <div class="overlay-text-bottom-1 p-2 mb-2">Confidence: {{clickedImage.plateConfidence}}</div>

                <div class="fa fa-chevron-double-left fa-2x p-0" #backArrow (click)="arrowBack()"></div>
                <div class="fa fa-chevron-double-right fa-2x p-0" #forwardArrow (click)="arrowForward()"></div>
            </div>
        </div>
    </ng-template>
</div>

<ag-grid-angular *ngIf="fgImageControls.get('viewType').value != '0'" #agGrid id="summaryGrid" name="imagesTable"
    style="height: 600px;" class="ag-theme-alpine connectionTable m-0 pt-2 border-0" [animateRows]="true"
    [suppressCellSelection]="true" [getDetailRowData]="true" [rowData]="deviceImages" [chartThemes]="chartThemes"
    [getChartToolbarItems]="getChartToolbarItems" [chartThemeOverrides]="chartThemeOverrides"
    [gridOptions]="gridOptions">
</ag-grid-angular>