
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';

import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { UsersAdminViewComponent } from '../../../shared/component/views/users-admin-view/users-admin-view.component';
import { UserFormComponent } from '../../../shared/component/forms/users/user-form/user-form.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-users-admin',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(UserFormComponent) public userForm: UserFormComponent;
  @ViewChild(UsersAdminViewComponent) public userView: UsersAdminViewComponent;
  //@ViewChild("btnDevWiFi") btnDevWiFi: ElementRef;

  userTypes = userTypes;
  userRoles = userRoles;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  private paramSub: any;
  public showForm: boolean = false;
  public firstLoad: boolean = false;


  public get dataLoading(): boolean {
    if (!this.userView) return false;
    return this.userView.dataLoading;
  }

  private _userUuid: string = "";
  get userUuid(): string {
    return this._userUuid;
  }
  set userUuid(newDeviceUuid: string) {

    if (newDeviceUuid && newDeviceUuid.toString().toLowerCase() == 'add' || this.librariesService.guidValidate(newDeviceUuid)) {
      this._userUuid = newDeviceUuid;
      this.showForm = true;
    }
    else {
      this._userUuid = "";
      this.showForm = false;

    }
  }

  ngOnInit() {

    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.userUuid = params['uuid'];

    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }

  dataRefresh() {
    var self = this;
    if (!this.settingsService.client ||
      !this.userView) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }
    setTimeout(function () {
      self.userView.getUsers();
    }, 500);
  }

  onDataLoading(event) {
    if (this.dataLoading && this.firstLoad) this.settingsService.showSpinner("users", false, 250);
    this.firstLoad = false;

    if (!this.dataLoading) this.settingsService.hideSpinner("users");
  }


  onFormEvent(event) {

    switch (event.toString()) {
      case "saved":
        {
          //alert(this.userUuid)
          break;
        }
      case "cancelled":
        {
          // //this.showForm = false;
          // this.userUuid = "";
          // //this.location.go(this.router.createUrlTree(['/administration/users/all'], { relativeTo: this.route }).toString())
          // this.router.navigate(['/administration/users/all'])
          break;
        }
    }

  }

  onViewSelected(event) {

    if (event && event.uuid && event.uuid.toString().length > 10) {
      //var url = this.router.createUrlTree(['.',event.toString()], {relativeTo: this.route})
      //this.location.go(url.toString());
      this.router.navigate(['/administration/users', event.uuid.toString()], { replaceUrl: false })
      //this.router.navigateByUrl('/administration/users/'+event.toString())
    }
  }

  buttonAdd(event) {
    //alert(this.userForm.formState)
    //this.showForm = true;
    //this.userForm.formState = this.userForm.formStates.New
    this.router.navigate(['/administration/users', 'add'], { replaceUrl: false })
  }

  buttonBack(event) {
    this.router.navigate(['/administration/users/all'])
  }

  buttonEdit(event) {
    this.userForm.formState = this.userForm.formStates.Edit
  }

  buttonSave(event) {
    this.userForm.formState = this.userForm.formStates.Save
  }

  buttonCancel(event) {
    this.userForm.formState = this.userForm.formStates.Cancelled
  }

}
